import useDeleteConfirmationModal from '@/stores/modals/delete-confirmation'

import api from '../../api'

import { defineStore } from 'pinia'

export const useTopicsStore = defineStore({
    id: 'topics',

    state: () => ({
        items: [],

        isInitialized: false,
        isLoading: false,

        loadingPromise: null
    }),

    getters: {
        topics(store) { return store.items },
        recentTopics(store) { return store.items.filter(t => t.recent) },
        pinnedTopics(store) { return store.items.filter(t => t.pinned) },
        popularTopics(store) { return store.items.filter(t => t.popular) }
    },

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return this.loadingPromise = api.route('me topics').get().json(res => {
                this.items = res.data

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async reload() {
            return this.load(true)
        },

        async all() {
            await this.load()
            return this.items
        },

        find(id) {
            return this.items.find(sl => sl.id == id)
        },

        async delete(topic, force = false) {
            await api.route('me topics delete', topic.id)
                .formData({ _method: 'delete', force: force ? 1 : 0 })
                .post()
                .forbidden(async (res) => {
                    if (res.json.error == 'has-dependents') {
                        return useDeleteConfirmationModal().open('Topic', res.json.dependents)
                            .then(() => this.delete(topic, true))
                    }
                })
                .res()

            return this.reload()
        },

        async safeDelete(topic) {
            try {
                return await this.delete(topic)
            } catch (e) {}
        },

        async store(data) {
            let resp = await api.route('me topics store')
                .formData({ _method: 'post', ...data })
                .post()
                .json()

            await this.reload()

            return this.find(resp.data.id)
        },

        async update(topic, data) {
            await api.route('me topics update', topic.id)
                .formData({ _method: 'put', ...data })
                .post()
                .res()

            return this.reload()
        },

        async duplicate(topic) {
            await api.route('me topics store')
                .formData({
                    name: topic.name,
                    query: topic.query
                })
                .post()
                .json()

            return this.reload()
        }
    }
})

export default useTopicsStore
