<template>
    <ui-modal name="edge-explain" content-class="bg-white border border-gray-200 shadow-xl flex flex-col min-h-0 h-auto w-full rounded-xl" height="100%" max-width="90vw" unstyled>
        <div class="flex items-center px-6 py-6">
            <div class="flex-1 flex items-center gap-x-1.5 text-lg">
                <span class="font-medium bg-gray-75 px-2 rounded-full">
                    {{fromNodeLabel}}
                </span>
                <span class="text-gray-700">
                    → {{edgeLabel}} →
                </span>
                <span class="font-medium bg-gray-75 px-2 rounded-full">
                    {{toNodeLabel}}
                </span>
            </div>

            <a href="#" class="text-xl text-gray-700 hover:text-gray-900 leading-none" @click.prevent="$modal.hide('edge-explain')">
                <ui-icon name="remove"></ui-icon>
            </a>
        </div>

        <div class="w-full h-full bg-gray-75 z-9999 relative rounded-b-md border-t border-gray-200">
            <div class="w-full h-full z-9999 relative" id="vue-flow-container" ref="vueflowContainer">
                <VueFlow
                    id="modal-explain-edge"
                    class="basicflow"
                    :nodes="nodes"
                    :edges="edges"
                    :default-viewport="{ zoom: 0.3 }"

                    :min-zoom="0.1"
                    :max-zoom="10"

                    :deleteKeyCode="false"
                    :fit-view-on-init="false"

                    :nodesDraggable="false"

                    @nodes-initialized="handleLayout()"
                    @nodeClick="handleNodeClick"
                >
                    <template #edge-custom="customEdgeProps">
                        <CustomEdge v-bind="customEdgeProps" />
                    </template>

                    <template #node-feature="{ id, data }">
                        <FeatureNode :id="id" :data="data" />
                    </template>

                    <template #node-channel="{ id, data }">
                        <ChannelNode :id="id" :data="data" />
                    </template>

                    <template #node-content="{ id, data }">
                        <ContentNode :id="id" :data="data" />
                    </template>

                    <Controls v-if="nodes?.length" class="vueflow-controls" :show-interactive="false" position="bottom-left"></Controls>
                    <Background pattern-color="#ccc" variant="dots" :gap="30" />

                    <div class="absolute flex top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" v-if="!explainStore.isLoading && !explainStore.nodes.length">
                        <ui-icon name="slash" class="pt-1" style="height: 2em; width: 2em"></ui-icon>
                        <div class="text-2xl mt-0.5 ml-0.5">No data found :(</div>
                    </div>
                </VueFlow>
            </div>

            <div class="absolute bottom-2 right-2 z-9999 bg-white p-2 rounded-md opacity-60 shadow-sm" v-if="!explainStore.isLoading && edgeWeight > 10">
                Showing 10 of {{ $number(edgeWeight) }} paths
            </div>

            <div class="absolute top-2 right-2 z-9999 bg-white shadow-sm" v-if="nodes?.length">
                <a href="#" class="flex items-center justify-center w-8 h-8 text-xl hover:text-gray-900" v-tooltip="'Save as PNG'" @click.prevent="saveAsPng">
                    <ui-icon name="image"></ui-icon>
                </a>
            </div>

            <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" v-if="explainStore.isLoading">
                <div class="bg-gray-200/80 font-medium backdrop-blur shadow-sm rounded-lg flex flex-col items-center px-6 py-4 gap-y-0.5">
                    <ui-spinner type="clip" class="text-3xl"></ui-spinner>
                    <div>Loading</div>
                </div>
            </div>
        </div>
    </ui-modal>
</template>

<script>


import { VueFlow } from '@vue-flow/core'
import { Background } from '@vue-flow/background'
import { Controls } from '@vue-flow/controls'
import { mapActions, mapState } from 'pinia'

import FeatureNode from "@/components/connections/edge-explain/feature-node"
import ChannelNode from "@/components/connections/edge-explain/channel-node"
import ContentNode from "@/components/connections/edge-explain/content-node"
import CustomEdge from "@/components/connections/edge-explain/custom-edge"
import useEdgeExplainStore from "@/stores/connections/modals/edge-explain.js"

import { toPng } from 'html-to-image'

export default {
    components: {
        VueFlow, Background, Controls, FeatureNode, ChannelNode, ContentNode, CustomEdge
    },

    data: () => ({
        explainStore: useEdgeExplainStore()
    }),

    computed: {
        ...mapState(useEdgeExplainStore, [
            'nodes', 'edges', 'fromNodeLabel', 'toNodeLabel', 'edgeLabel', 'edgeWeight'
        ]),
    },

    methods: {
        ...mapActions(useEdgeExplainStore, [
            'init', 'handleLayout', 'handleNodeClick'
        ]),

        async saveAsPng() {
            try {
                const vueflowContainer = this.$refs.vueflowContainer;

                const dataUrl = await toPng(vueflowContainer, {
                    filter: (node) => {
                        return !node.classList || !node.classList.contains("vueflow-controls")
                    },
                })

                const link = document.createElement("a")
                link.href = dataUrl
                link.download = "graph.png"
                link.click()
            } catch (error) {
                console.error("Error saving the graph as PNG:", error)
            }
        },
    }
}
</script>
