<template>
    <div class="flex shrink-0 h-full bg-white shadow transition-all w-14">
        <div class="flex flex-col w-14 bg-gray-900 px-2 py-4">
            <div class="flex flex-col divide-y divide-gray-800 space-y-4">
                <nav class="space-y-2 text-gray-500">
                    <workspace-icon></workspace-icon>

                    <router-link :to="{ name: 'content' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Content'">
                        <ui-icon name="navigation.content"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'dashboards' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Dashboards'">
                        <ui-icon name="grid"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'channels' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Channels'">
                        <ui-icon name="navigation.channels"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'analysis' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Analysis'">
                        <ui-icon name="navigation.analysis"></ui-icon>
                    </router-link>

                    <!--<router-link :to="{ name: 'features', params: { type: 'email' }}" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Features'">-->
                    <!--    <ui-icon name="navigation.features"></ui-icon>-->
                    <!--</router-link>-->

                    <router-link :to="{ name: 'connections'}" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Connections Explorer'">
                        <ui-icon name="share"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'alerts' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Alerts'">
                        <ui-icon name="at-sign"></ui-icon>
                    </router-link>

                    <router-link :to="{ name: 'settings' }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Settings'">
                        <ui-icon name="navigation.settings"></ui-icon>
                    </router-link>
                </nav>
            </div>

            <nav class="mt-auto">
                <!--<router-link :to="{ name: 'help.page', params: { page: 'home' } }" class="flex items-center justify-center w-10 h-10 mx-auto text-3xl transition ease-in-out duration-150 text-gray-500 hover:text-gray-50" active-class="text-gray-50" v-tooltip.right="'Help'">-->
                <!--    ?-->
                <!--</router-link>-->

                <div class="flex items-center justify-center mt-4">
                    <ui-icon name="g" class="text-gray-50 text-4xl"></ui-icon>
                </div>
            </nav>
        </div>
    </div>
</template>

<script>
import WorkspaceIcon from '@/components/me/workspaces/icon'

export default {
    components: {
        WorkspaceIcon
    }
}
</script>
