<template>
    <ui-modal name="delete-confirmation" unstyled>
        <div class="bg-white border border-2 border-red-600 shadow-xl flex flex-col h-auto w-full rounded-lg px-7 py-6">
            <h1 class="text-lg font-medium text-red-600">
                Are you sure you want to delete this {{entity}}?
            </h1>

            <div class="text-sm mt-3" v-if="hasDependents">
                <p>
                    There are some items using this {{entity}}. Deleting this item will safely remove these usages, however some may report different results or display differently!
                </p>

                <div class="mt-6">
                    <div class="space-y-1.5 text-gray-800 w-full">
                        <a :href="$router.resolve(resolveRoute(dependent)).href" target="_blank" class="flex items-center w-full px-5 py-2 cursor-pointer text-gray-700 hover:text-gray-900 border border-gray-300 rounded-lg text-lg group" :key="i" v-for="dependent, i in dependents">
                            <div>
                                <ui-icon :name="resolveIcon(dependent)" class="text-3xl"></ui-icon>
                            </div>
                            <div class="px-4">
                                <h1 class="text-lg font-medium">{{dependent.name}}</h1>
                                <p class="text-sm text-gray-600 group-hover:text-gray-900">{{resolveType(dependent)}}</p>
                            </div>
                            <div class="ml-auto">
                                <ui-icon name="arrow-right-circle" class="text-2xl"></ui-icon>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <div class="flex justify-end space-x-4" :class="{ 'mt-8': hasDependents }">
                <ui-button type="button" :action="cancel" :disabled="submitting">
                    Cancel
                </ui-button>
                <ui-button type="button" :action="confirm" color="red" :disabled="submitting">
                    Delete
                </ui-button>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import modalsDeleteConfirmationStore from '@/stores/modals/delete-confirmation'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(modalsDeleteConfirmationStore),
        ...mapState(modalsDeleteConfirmationStore, [ 'dependents', 'entity', 'hasDependents', 'submitting' ])
    },

    methods: {
        ...mapActions(modalsDeleteConfirmationStore, [ 'cancel', 'confirm' ]),

        resolveIcon(dependent) {
            return {
                'alerts': 'at-sign',
                'analyses': 'analysis',
                'dashboards': 'dashboard',
                'perspectives': 'perspective',
                'topics': 'topic'
                
            }[dependent.family]
        },

        resolveType(dependent) {
            return {
                'alerts': 'Alert',
                'analyses': 'Analysis Series',
                'dashboards': 'Dashboard Widget',
                'perspectives': 'Filter in Perspective',
                'topics': 'Parent Topic'
                
            }[dependent.family]
        },

        resolveRoute(dependent) {
            return {
                'alerts': { name: 'alerts', query: { show: `alert|id:${dependent.resource.id}` } },
                'analyses': { name: 'analysis.analysis.details', params: { type: dependent.resource.type, id: dependent.resource.id } },
                'dashboards': { name: 'dashboard.boards.board', params: { id: dependent.resource.id } },
                'perspectives': { name: 'content.perspectives.perspective', params: { perspectiveId: dependent.resource.id } },
                'topics': { name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[text]': `${dependent.resource.query}|${dependent.resource.language}|`, 'filter[date]': 'past:1|months', topic: dependent.resource.id } }
                
            }[dependent.family]
        }
    }
}
</script>
