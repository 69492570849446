<template>
    <div class="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm py-16 z-[9999]" @keydown="keyDown" @keyup="keyUp" v-if="isShown" @click.prevent>
        <div class="w-full max-w-2xl mx-auto text-sm text-gray-50 font-semibold px-4 mb-1 text-right">
            Esc to close
        </div>

        <div class="w-full max-w-2xl mx-auto bg-white rounded-md shadow-md max-h-full flex flex-col overflow-hidden p-4" v-click-away="hide">
            <div class="flex items-center">
                <form class="relative grow" @submit.prevent>
                    <div class="absolute top-0 left-4 h-9 flex items-center pointer-events-none text-xl z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="search" @input="loadDebounced" @keydown.esc="hide"
                        autocomplete="off" spellcheck="false"
                        class="block w-full h-9 pl-10 pr-28 py-3 rounded-t-md placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-500 sm:text-sm border-0 z-30 max-h-48 resize-none rounded-b-md text-gray-800 bg-gray-100" placeholder="Search...">

                    <div v-if="hasFilterableWorkspaces" class="absolute flex h-9 items-center top-0 right-3">
                        <ui-dropdown align="left" :z-index="9999" width="w-56">
                            <template v-slot:trigger>
                                <a href="#" v-tooltip="'Search in'" class="text-gray-700 hover:text-gray-900 text-2xl">
                                    <ui-icon name="star" v-if="! workspace"></ui-icon>
                                    <ui-icon name="globe-simple" v-else-if="workspace.id == globalWorkspace.id"></ui-icon>
                                    <ui-icon name="user" v-else-if="workspace.id == personalWorkspace.id"></ui-icon>
                                    <ui-avatar :item="workspace" class="w-5 h-5" v-else></ui-avatar>
                                </a>
                            </template>

                            <template v-slot:content>
                                <div class="text-xs font-medium text-gray-700 px-4 mt-2 mb-1 cursor-pointer">
                                    Search in
                                </div>

                                <ui-dropdown-link icon="star" :selected="! workspace" @click="resetWorkspace">
                                    Everything
                                </ui-dropdown-link>

                                <ui-dropdown-separator></ui-dropdown-separator>

                                <ui-dropdown-link icon="globe-simple" :selected="workspace.id == globalWorkspace.id" @click="setWorkspace(globalWorkspace)">
                                    Global
                                </ui-dropdown-link>
                                <ui-dropdown-link icon="user" :selected="workspace.id == personalWorkspace.id" @click="setWorkspace(personalWorkspace)">
                                    Personal
                                </ui-dropdown-link>

                                <ui-dropdown-separator></ui-dropdown-separator>

                                <div class="max-h-80 overflow-y-auto">
                                    <ui-dropdown-link v-for="regularWorkspace in regularWorkspaces" :key="regularWorkspace.id" icon="none" :selected="workspace.id == regularWorkspace.id" @click="setWorkspace(regularWorkspace)">
                                        <template v-slot:icon>
                                            <div class="w-4 flex justify-center items-center text-center">
                                                <ui-avatar :item="regularWorkspace" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                            </div>
                                        </template>
                                        {{regularWorkspace.name}}
                                    </ui-dropdown-link>
                                </div>
                            </template>
                        </ui-dropdown>
                    </div>
                </form>
            </div>

            <div class="text-gray-800 text-center" v-if="inMaintenance">
                Quick-search service is currently under maintenance, please try again in a few moments.
            </div>

            <div class="flex items-center mt-3">
                <a href="#" @click.prevent="resetFilteredFamilies" class="inline-flex items-center text-sm font-medium leading-none rounded-md px-3 h-6" :class="! filteredFamilies.length ? 'bg-blue-50 text-blue-600' : 'text-gray-700 hover:text-gray-900'">
                    All <span v-if="showKeyboardShortcuts" class="ml-1 text-xs text-gray-400">^1</span>
                </a>
                <a v-for="family, index in supportedFamilies" :key="family.id" href="#" @click.prevent="setFilteredFamilies([family.id])" class="inline-flex items-center text-sm font-medium leading-none rounded-md px-3 h-6" :class="isFamilyIncludedInFilter(family.id) ? 'bg-blue-50 text-blue-600' : (families.includes(family.id) ? 'text-gray-700 hover:text-gray-900' : 'text-gray-400 cursor-default')">
                    {{family.name}} <span v-if="showKeyboardShortcuts" class="ml-1 text-xs text-gray-400">^{{index + 2}}</span>
                </a>
            </div>

            <div class="overflow-y-auto divide-y divide-gray-200 mt-3 -mx-4 -mb-4" ref="results" v-if="filteredResults.length > 0">
                <div :key="index" v-for="result, index in filteredResults">
                    <app-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-if="result.resultType == 'app'"></app-result>
                    <analysis-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'analysis'"></analysis-result>
                    <channel-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'channel'"></channel-result>
                    <dashboard-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'dashboard'"></dashboard-result>
                    <feature-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'feature'"></feature-result>
                    <list-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'list'"></list-result>
                    <perspective-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'perspective'"></perspective-result>
                    <perspective-new-result :query="search" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'perspective-new'"></perspective-new-result>
                    <topic-result :result="result" :focused="result == focusedResult" :ref="`result-${index}`" @click.capture.prevent="selectResult(result)" :store="myQuickSearchStore" v-else-if="result.resultType == 'topic'"></topic-result>
                </div>
            </div>

            <div v-if="search && ! filteredResults.length" class="mt-4 py-4 text-sm text-gray-600 text-center">
                We didn't find any results.
            </div>
        </div>

        <div class="w-full max-w-2xl mx-auto text-sm text-gray-50 font-semibold px-4 mt-1">
            <div>Press <span class="font-mono text-xs">/</span> or <span class="font-mono text-xs">{{ isMac ? 'CMD+P' : 'CTRL+P' }}</span> to quickly open quick-search from anywhere, hold {{ isMac ? 'CTRL' : 'ALT' }} to switch categories</div>
        </div>
    </div>
</template>

<script>
import AnalysisResult from './results/analysis-result'
import AppResult from './results/app-result'
import ChannelResult from './results/channel-result'
import DashboardResult from './results/dashboard-result'
import FeatureResult from './results/feature-result'
import ListResult from './results/list-result'
import PerspectiveResult from './results/perspective-result'
import PerspectiveNewResult from './results/perspective-new-result'
import TopicResult from './results/topic-result'

import useMyQuickSearchStore from '@/stores/me/quick-search'
import useMyWorkspacesStore from '@/stores/me/workspaces'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    components: {
        AnalysisResult, AppResult, ChannelResult, DashboardResult, FeatureResult, ListResult, PerspectiveResult,
        PerspectiveNewResult, TopicResult
    },

    data: () => ({
        focusedResult: null,
        focusedResultRef: null,
        showKeyboardShortcuts: false,

        supportedFamilies: [
            { id: 'app', name: 'App' },
            { id: 'perspectives', name: 'Perspectives' },
            { id: 'topics', name: 'Topics' },
            { id: 'channels', name: 'Channels' },
            { id: 'lists', name: 'Lists' },
            { id: 'analyses', name: 'Analyses' },
            { id: 'features', name: 'Features' }
        ]
    }),

    computed: {
        ...mapState(useMyQuickSearchStore, [
            'isShown',
            'hasFilterableFamilies',
            'hasFilterableWorkspaces',
            'inMaintenance',
            'onSelect',
            'filteredResults',
            'resultsPending',
            'filteredFamilies',
            'workspace',
            'families'
        ]),
        ...mapState(useMyWorkspacesStore, [ 'globalWorkspace', 'personalWorkspace', 'regularWorkspaces' ]),
        ...mapWritableState(useMyQuickSearchStore, [ 'search' ]),
        ...mapStores(useMyQuickSearchStore)
    },

    methods: {
        ...mapActions(useMyQuickSearchStore, [
            'hide',
            'loadDebounced',
            'clear',
            'shouldSearch',
            'resetFilteredFamilies',
            'setFilteredFamilies',
            'isFamilyIncludedInFilter',
            'resetWorkspace',
            'setWorkspace'
        ]),

        keyDown(ev) {
            if ([ 'Control', 'Alt' ].includes(ev.key)) this.showKeyboardShortcuts = true

            if ([ 'ArrowUp', 'ArrowDown', 'Enter' ].includes(ev.key)) return this.handleVerticalNavigation(ev)
            if ((ev.ctrlKey || ev.altKey) && [ '1', '2', '3', '4', '5', '6', '7', '8' ].includes(ev.key)) return this.handleHorizontalNavigation(ev)
        },

        keyUp(ev) {
            if ([ 'Control', 'Alt' ].includes(ev.key)) this.showKeyboardShortcuts = false
        },

        handleVerticalNavigation(ev) {
            if (ev.key == 'Enter') {
                ev.preventDefault()
                return this.selectResult(this.focusedResult)
            }

            ev.preventDefault()

            let selectPrevious = ev.key == 'ArrowUp'
            let currentIndex = this.filteredResults.indexOf(this.focusedResult)

            let focusIndex = currentIndex === false
                ? (selectPrevious ? this.filteredResults.length - 1 : 0)
                : (selectPrevious ? currentIndex - 1 : currentIndex + 1)

            if (focusIndex >= this.filteredResults.length) focusIndex = 0
            if (focusIndex < 0) focusIndex = this.filteredResults.length - 1

            this.focusedResult = this.filteredResults[focusIndex]
            this.focusedResultRef = this.$refs[`result-${focusIndex}`][0]

            this.focusedResultRef.$el.scrollIntoView({ block: 'nearest' })
        },

        handleHorizontalNavigation(ev) {
            if (ev.key == '1') return this.resetFilteredFamilies()

            let family = this.supportedFamilies[parseInt(ev.key) - 2]?.id

            if (this.families.includes(family)) this.setFilteredFamilies([ family ])
        },

        selectResult(result) {
            if (! result) return

            if (this.onSelect && ! result.alwaysOpen) {
                this.onSelect(result)
            } else {
                this.$refs[`result-${this.filteredResults.indexOf(result)}`][0].open()
            }
            
            this.hide()
        }
    },

    watch: {
        isShown(val) {
            if (val) this.$nextTick(() => this.$refs.input.focus())
        }
    }
}
</script>
