import useMyQuickSearchStore from '@/stores/me/quick-search'

import { searchFilters } from '@/helpers/search-filters'

import { defineStore } from 'pinia'
import debounce from 'just-debounce-it'

export const useChannelSearchStream = defineStore({
    id: 'channelSearchStream',

    state: () => ({
        search: '',

        filters: searchFilters(),

        items: []
    }),

    actions: {
        load: debounce(async function () {
            useMyQuickSearchStore().limit = 25
            useMyQuickSearchStore().families = [ 'channels' ]
            useMyQuickSearchStore().search = this.search
            await useMyQuickSearchStore().load().then(() => {
                this.items = useMyQuickSearchStore().results
            })
        }, 100),

        reset() {
            this.clearFilters()
            this.clearSearch()
            this.items = []
        },

        clearFilters() {
            if (this.filters.isEmpty()) return

            this.clearSearch()
            this.filters.clear()
        },

        clearSearch() {
            this.search = ''
        }
    }
})

export default useChannelSearchStream
