<template>
    <div class="h-8 flex items-center mb-6 relative">
        <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
            <a @click.prevent="$page.selectMode('stream')" href="#" class="flex items-center justify-center px-3 border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]">
                Stream
            </a>
            <a @click.prevent="$page.selectMode('overview')" href="#" class="flex items-center justify-center h-full px-3">
                Inspect
            </a>
        </div>

        <toolbar-date :filters="$page.streamStore.filters" v-if="! $page.showFilters" class="ml-4"></toolbar-date>

        <div class="flex items-center h-full ml-3">
            <a @click.prevent="openExportingModal({filters: store.filters, sorting: store.sorting.api, endpoint: 'monitor content export'})" v-tooltip="'Export'" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2">
                <ui-icon name="download"></ui-icon> Export
            </a>

            <a @click.prevent="$modal.show('perspective-analyze')" v-tooltip="'Analyze'" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2" v-if="store.perspective">
                <ui-icon name="analysis"></ui-icon> Analyze
            </a>

            <toolbar-tags :store="store"></toolbar-tags>
        </div>

        <div class="ml-auto"></div>

        <a href="#" class="text-sm px-3 py-0.5 bg-blue-600 text-white rounded-full hover:bg-blue-800 mr-4" @click.prevent="store.reload" v-if="store.itemsNewerCount.data">
            {{$number(store.itemsNewerCount.data)}} new {{store.itemsNewerCount.data > 1 ? 'results' : 'result'}}
        </a>

        <div class="text-sm" v-if="store.itemsCount !== null">
            <span class="font-medium">{{ $number(store.itemsCount) }}{{ store.itemsCount == 10000 ? '+' : '' }}</span>
            results
        </div>

        <toolbar-sorting :store="store" class="ml-4"></toolbar-sorting>

        <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full ml-4">
            <a @click.prevent="$page.streamStore.setLayout('masonry')" href="#" class="flex items-center justify-center px-3" :class="$page.streamStore.layout.id == 'masonry' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'" v-tooltip="'Cards View'">
                <ui-icon name="view-grid" class="text-xl"></ui-icon>
            </a>
            <a @click.prevent="$page.streamStore.setLayout('timeline')" href="#" class="flex items-center justify-center px-3" :class="$page.streamStore.layout.id == 'timeline' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'" v-tooltip="'Timeline View'">
                <ui-icon name="view-list" class="text-xl"></ui-icon>
            </a>
            <a @click.prevent="$page.streamStore.setLayout('media')" href="#" class="flex items-center justify-center px-3" :class="$page.streamStore.layout.id == 'media' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'" v-tooltip="'Media View'">
                <ui-icon name="image" class="text-xl"></ui-icon>
            </a>
        </div>
    </div>

    <teleport to="#root">
        <perspective-analyze-modal :store="$page.streamStore"></perspective-analyze-modal>
    </teleport>
</template>

<script>
import PerspectiveAnalyzeModal from '@/components/content/modals/perspective-analyze'
import ToolbarDate from '../toolbar/date'
import ToolbarSorting from './toolbar-sorting'
import ToolbarTags from './toolbar-tags'

import useModalsExportOptionsStore from '@/stores/modals/export-options'

import { mapActions } from 'pinia'

export default {
    components: {
        PerspectiveAnalyzeModal, ToolbarDate, ToolbarSorting, ToolbarTags
    },

    methods: {
        ...mapActions(useModalsExportOptionsStore, { openExportingModal: 'open' } )
    }
}
</script>
