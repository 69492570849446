import useIndexCardsPerspectivesStore from './index-cards-perspectives'
import useIndexCardsTopicsStore from './index-cards-topics'

import defineMultiCards from '@/stores/reusable/multi-cards'

export const useContentIndexCardsStore = defineMultiCards({
    id: 'contentIndexCards',

    contexts: {
        'perspectives': useIndexCardsPerspectivesStore,
        'topics': useIndexCardsTopicsStore
    }
})

export default useContentIndexCardsStore
