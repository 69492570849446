import Grid from '@/components/ui/cards/layouts/grid'

import { asyncResource } from '@/api'
import { sortByDate } from '@/helpers/sorting'
import defineCards from '@/stores/reusable/cards'

import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const useAlertsIndexUnreadCardsStore = defineCards({
    id: 'alertsIndexUnreadTriggersCards',

    source: defineStore({
        id: 'alertsIndexUnreadTriggersCardsSource',

        state: () => ({
            paginated: true,

            resource: asyncResource({
                request: (api, store, payload) => api.route('me alerts triggers', { alertId: 'unread' })
                    .query(payload)
            })
        }),

        getters: {
            items(store) {
                return store.resource.data || []
            },

            isLoading(store) {
                return store.resource.isFetchingFirst
            },

            isInitialized(store) {
                return store.resource.data
            }
        },

        actions: {
            initialize(params) {
                this.params = params
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            reload() {
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            async loadMore(infiniteScroll) {
                let items = await this.resource.fetchNext(this)

                items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
            }
        }
    }),

    layoutOptions: [
        {
            id: 'grid',
            component: markRaw(Grid),
            settings: { rows: 2 }
        }
    ],

    sortingOptions: [
        {
            id: 'latest', name: 'Latest', icon: 'calendar', default: true,
            apply: items => sortByDate(items, 'createdAt').reverse()
        }
    ]
})

export default useAlertsIndexUnreadCardsStore
