import { arrayWrap } from '@/helpers/array'

export default function findParentComponentByName(vm, componentName) {
    let parent = vm.$parent
    componentName = arrayWrap(componentName)

    while (parent) {
        if (componentName.includes(parent.$options.name)) {
            return parent
        }
        parent = parent.$parent
    }
}