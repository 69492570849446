<template>
    <div>
        <ui-cards-section title="Tokens" :store="appsCardsStore">
            <template v-slot:actions>
                <a @click.prevent="createApp" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-3 ml-3">
                    <ui-icon name="plus" class="text-xl"></ui-icon> Create token
                </a>
            </template>

            <template v-slot:item="{ item }">
                <app-card :app="item" :cards-store="appsCardsStore"></app-card>
            </template>
        </ui-cards-section>

        <Teleport to="#root">
            <new-app-modal></new-app-modal>
        </Teleport>
    </div>
</template>

<script>
import useMyAppsStore from '@/stores/me/apps'
import useAppsCardsStore from '@/stores/settings/apps-cards'
import useNewAppFormStore from '@/stores/settings/new-app-form'

import AppCard from '@/components/settings/stream-cards/app-card'
import NewAppModal from '@/components/settings/modals/new-app'

import { mapActions, mapState, mapStores, mapWritableState } from 'pinia'

export default {
    components: {
        AppCard,
        NewAppModal
    },

    computed: {
        ...mapStores(useAppsCardsStore),

        ...mapState(useMyAppsStore, {
            apps: 'items',
            areAppsInitialized: 'isInitialized'
        }),
        
        ...mapWritableState(useAppsCardsStore, [ 'search' ])
    },

    methods: {
        ...mapActions(useNewAppFormStore, {
            createApp: 'open'
        })
    }
}
</script>
