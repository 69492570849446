<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg overflow-hidden min-w-0 flex flex-col" :class="isComparing ? 'h-80' : 'h-72'">
        <div class="flex justify-between pb-2 p-4">
            <h1 class="text-lg font-medium text-gray-700">Sources</h1>

            <div class="flex gap-x-2">
                <ui-dropdown v-if="isComparing">
                    <template v-slot:trigger>
                        <a href="#" class="flex items-center font-medium text-gray-700 capitalize mb-2">
                            {{ metric }} <ui-icon name="select" class="text-xs"></ui-icon>
                        </a>
                    </template>
                    <template v-slot:content>
                        <ui-dropdown-link icon="hash" @click="setMetric('content')">Content</ui-dropdown-link>
                        <ui-dropdown-link icon="interactions" @click="setMetric('interactions')">Interactions</ui-dropdown-link>
                        <ui-dropdown-link icon="views" @click="setMetric('views')">Views</ui-dropdown-link>
                        <ui-dropdown-link icon="users" @click="setMetric('followers')">Followers</ui-dropdown-link>
                    </template>
                </ui-dropdown>
                <ui-data-quality-icon quality="observed"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div v-for="series in series" :key="series.id" class="flex-1 min-w-0 flex flex-col relative">
                <div v-if="isComparing" class="mb-3 px-4">
                    <span class="rounded px-1.5 py-1 font-medium" :style="{ 'background-color': `${series.color}20`, 'color': series.color }">{{series.name}}</span>
                </div>

                <ui-table :store="tableStore(series)" v-if="tableStore(series).tableRows.length"></ui-table>

                <div class="absolute inset-0 backdrop-blur-sm rounded-lg flex items-center justify-center z-10" :class="{'bg-opacity-20 bg-white': isInitialized, 'bg-white': ! isInitialized}" v-if="isLoading(series)">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapLocalActions, mapLocalState } from '@/helpers/pinia'

export default {
    computed: {
        ...mapLocalState([ 'isComparing', 'isInitialized', 'metric', 'series' ])
    },

    methods: {
        ...mapLocalActions([ 'isLoading', 'setMetric', 'tableStore' ])
    }
}
</script>
