import api from '@/api'

import { defineStore } from 'pinia'

export const useChannelsChannelConnectionsStore = defineStore({
    id: 'channelsChannelConnections',

    state: () => ({
        channel: null,

        channelsUpstream: { data: null, error: null },
        channelsDownstream: { data: null, error: null },
        channelsSharingProperties: { data: null, error: null },
        channelsConnectedFeatures: { data: null, error: null },

        selectedType: 'upstream'
    }),

    actions: {
        load(channel, force = false) {
            if (force) this.$reset()
            if (this.channel?.id != channel.id) this.$reset()

            this.channel = channel

            this.fetchData('upstream', 'channelsUpstream')
            this.fetchData('downstream', 'channelsDownstream')
            this.fetchData('sharing-properties', 'channelsSharingProperties')
            this.fetchData('connected-features', 'channelsConnectedFeatures')
        },

        reload() {
            this.load(this.channel, true)
        },

        fetchData(connectionType, resultKey) {
            return api.route(`monitor channels connections`, { id: this.channel.id, type: connectionType }).get()
                .error(500, err => this[resultKey] = { data: null, error: err?.json?.message || 'unknown' })
                .json(res => this[resultKey] = { data: res.data, error: null })
        }
    }
})

export default useChannelsChannelConnectionsStore
