<template>
    <ui-async :guards="$page.analysis || $page.isNew">
        <ui-header :title="$page.store ? $page.store.name : 'Untitled Analysis'" :avatar="$page.analysis?.avatar" avatar-type="analyses">
            <template v-slot:actions-start>
                <div v-if="$page.analysis" class="flex items-center space-x-1.5 ml-3">
                    <a href="#" class="inline-flex items-center justify-center transition text-base" :class="{ 'text-blue-600 hover:text-blue-900': $page.analysis.pinned, 'text-gray-400 hover:text-gray-900': ! $page.analysis.pinned }" @click.prevent="updateAnalysis($page.analysis, { pinned: $page.analysis.pinned ? 0 : 1 })" v-tooltip="$page.analysis.pinned ? 'Un-pin' : 'Pin'">
                        <ui-icon :name="$page.analysis.pinned ? 'pin-on' : 'pin-off'"></ui-icon>
                    </a>
                </div>

                <ui-contextual-menu v-if="$page.analysis" plain color="black" class="ml-2 mt-1" z-index="60">
                    <ui-dropdown-link icon="pin-off" @click="updateAnalysis($page.analysis, { pinned: 1 })" v-if="! $page.analysis.pinned">
                       Pin
                    </ui-dropdown-link>
                    <ui-dropdown-link icon="pin-off" @click="updateAnalysis($page.analysis, { pinned: 0 })" v-if="$page.analysis.pinned">
                       Un-pin
                    </ui-dropdown-link>

                    <ui-dropdown-separator></ui-dropdown-separator>

                    <ui-dropdown-link icon="settings" @click="editAnalysis($page.analysis, $page.store)">
                       Settings
                    </ui-dropdown-link>

                    <ui-dropdown-separator></ui-dropdown-separator>

                    <ui-dropdown-link icon="copy" @click="duplicateAnalysis($page.analysis)">
                        Duplicate
                    </ui-dropdown-link>

                    <template v-if="$can('manage-world')">
                        <ui-dropdown-separator></ui-dropdown-separator>

                        <ui-dropdown-link icon="wrench" @click="$inspect($page.analysis)">
                            Debug
                        </ui-dropdown-link>
                    </template>
                </ui-contextual-menu>
            </template>

            <template v-slot:actions>
                <div v-if="$page.store.isTakingTooLong">
                    <span class="font-medium">Tip</span>: Save this analysis while it's building and we'll notify you when it's processed!
                </div>

                <ui-button icon="check-square" color="blue" :action="() => { save($page.store.isTakingTooLong) }" :disabled="$page.store.isSaving" v-if="$page.store.isDirty || $page.store.isTakingTooLong">
                    Save
                </ui-button>
            </template>

            <template v-slot:subsection>
                <div id="header-bottom"></div>
            </template>
        </ui-header>

        <div class="pb-8 px-6 xl:px-16 max-w-12xl mx-auto">
            <channel-audience-analysis :store="$page.store" v-if="$page.type.id == 'channel-audience'"/>
            <channel-publish-times-analysis :store="$page.store" v-if="$page.type.id == 'channel-publish-times'"/>
            <content-performance-analysis :store="$page.store" v-if="$page.type.id == 'content-performance'"/>
            <content-publish-times-analysis :store="$page.store" v-if="$page.type.id == 'content-publish-times'"/>
            <content-pull-push-analysis :store="$page.store" v-if="$page.type.id == 'content-pull-push'"/>
            <content-sentiment-analysis :store="$page.store" v-if="$page.type.id == 'content-sentiment'"/>
            <content-top-channels-analysis :store="$page.store" v-if="$page.type.id == 'content-top-channels'"/>
            <content-top-platforms-analysis :store="$page.store" v-if="$page.type.id == 'content-top-platforms'"/>
            <content-keywords-analysis :store="$page.store" v-if="$page.type.id == 'content-keywords'"/>
        </div>
    </ui-async>
</template>

<script>
import ChannelAudienceAnalysis from './analyses/channel-audience'
import ChannelPublishTimesAnalysis from './analyses/channel-publish-times'
import ContentPerformanceAnalysis from './analyses/content-performance'
import ContentPublishTimesAnalysis from './analyses/content-publish-times'
import ContentPullPushAnalysis from './analyses/content-pull-push'
import ContentSentimentAnalysis from './analyses/content-sentiment'
import ContentTopChannelsAnalysis from './analyses/content-top-channels'
import ContentTopPlatformsAnalysis from './analyses/content-top-platforms'
import ContentKeywordsAnalysis from './analyses/content-keywords'

import useAnalysesModalsAnalysisDuplicateStore from '@/stores/analysis/modals/analysis-duplicate'
import useAnalysesModalsAnalysisInfoStore from '@/stores/analysis/modals/analysis-info'
import useMyAnalysesStore from '@/stores/me/analyses'

import useRouter from '@/router'

import { mapActions } from 'pinia'

export default {
    components: {
        ChannelAudienceAnalysis,
        ChannelPublishTimesAnalysis,
        ContentPerformanceAnalysis,
        ContentPublishTimesAnalysis,
        ContentPullPushAnalysis,
        ContentSentimentAnalysis,
        ContentTopPlatformsAnalysis,
        ContentTopChannelsAnalysis,
        ContentKeywordsAnalysis
    },

    methods: {
        ...mapActions(useMyAnalysesStore, {
            'deleteAnalysis': 'delete',
            'updateAnalysis': 'update'
        }),

        ...mapActions(useAnalysesModalsAnalysisInfoStore, { 'editAnalysis': 'open' }),

        ...mapActions(useAnalysesModalsAnalysisDuplicateStore, { 'duplicateAnalysis': 'open' }),

        async save(notify = false) {
            if (this.$page.store.id) {
                await this.$page.store.save(notify)

                if (notify) {
                    useRouter().push({
                        name: 'analysis'
                    })
                }
            } else {
                this.editAnalysis(this.$page.store.analysis, this.$page.store, notify)
            }
        }
    }
}
</script>
