<template>
    <div class="content-card max-w-md w-full relative flex flex-col" style="grid-row-end: span 29;">
        <div class="content-card-content flex flex-1 flex-col">
            <div class="bg-white shadow-xl border rounded-xl flex flex-1 flex-col" :class="isSelected(id) ? ['border-blue-200', 'border-4'] : ['border-gray-200', 'border']">
                <div class="px-4 py-1 flex items-center justify-between">
                    <div class="flex items-center min-w-0">
                        <div class="flex items-center min-w-0">
                            <div class="shrink-0 relative">
                                <img v-if="data.avatar" :src="data.avatar" class="rounded-full bg-gray-800 h-8 w-8">
                                <ui-avatar v-else :name="data.label" class="rounded-full bg-gray-800 h-8 w-8"></ui-avatar>

                                <div class="absolute -right-1 -bottom-0.5" style="height: 1.15em; width: 1.15em;">
                                    <SocialIcon :type="data.subclass" />
                                </div>
                            </div>
                        </div>

                        <div class="ml-3 min-w-0">
                            <div class="inline-flex items-center w-full">
                                <p class="font-semibold truncate min-w-0 leading-tight">{{ data.label }}</p>
                            </div>
                        </div>

                        <div class="ml-3">
                            <ui-dropdown>
                                <template v-slot:trigger>
                                    <button aria-label="Options" aria-haspopup="true" aria-expanded="true">
                                        <ui-icon name="more-vertical"></ui-icon>
                                    </button>
                                </template>

                                <template v-slot:content>
                                    <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'channels.channel.information', params: { id: id } }).href" target="_blank">
                                        Show detail
                                    </ui-dropdown-link>
                                </template>
                            </ui-dropdown>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Handle id="left-source" type="source" position="left"/>
    <Handle id="left-target" type="target" position="left"/>

    <Handle id="right-source" type="source" position="right"/>
    <Handle id="right-target" type="target" position="right"/>
</template>

<script>

import { mapActions } from "pinia"
import { Handle } from '@vue-flow/core'
import SocialIcon from "@/components/connections/edge-explain/social-icon"
import useEdgeExplainStore from "@/stores/connections/modals/edge-explain.js"

export default {
    components: {
        Handle, SocialIcon
    },

    props: {
        id: { type: String, required: true },
        data: { type: Object, required: true },
    },

    methods: {
        ...mapActions(useEdgeExplainStore, [ 'isSelected' ])
    }
}
</script>
