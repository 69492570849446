<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="pin-off" @click="() => updateAlert(alert, { pinned: true })" v-if="! alert.pinned">
           Pin
        </ui-dropdown-link>
        <ui-dropdown-link icon="pin-off" @click="() => updateAlert(alert, { pinned: false })" v-if="alert.pinned">
           Un-pin
        </ui-dropdown-link>

        <ui-dropdown-link icon="play-circle" @click="() => unpause(alert)" v-if="alert.paused">
           Resume
        </ui-dropdown-link>
        <ui-dropdown-link icon="pause-circle" @click="() => pause(alert)" v-if="! alert.paused">
           Pause
        </ui-dropdown-link>

        <ui-dropdown-link @click="() => unmute(alert)" icon="alert" v-if="alert.muted">
            Unmute
        </ui-dropdown-link>
        <ui-dropdown-link icon="alert" submenu submenu-width="w-24" v-if="! alert.muted">
            Mute...

            <template v-slot:submenu>
                <ui-dropdown-link @click="() => mute(alert)">
                    Indefinite
                </ui-dropdown-link>
                <ui-dropdown-separator></ui-dropdown-separator>
                <ui-dropdown-link @click="() => mute(alert, addDate(new Date, { hours: 1 }))">
                    1 Hour
                </ui-dropdown-link>
                <ui-dropdown-link @click="() => mute(alert, addDate(new Date, { days: 1 }))">
                    1 Day
                </ui-dropdown-link>
                <ui-dropdown-link @click="() => mute(alert, addDate(new Date, { weeks: 1 }))">
                    1 Week
                </ui-dropdown-link>
            </template>
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="settings" @click="() => editAlert(alert)">
            Settings
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="copy" @click="() => duplicateAlert(alert)">
            Duplicate
        </ui-dropdown-link>

        <ui-dropdown-link icon="trash" @click="() => deleteAlert(alert)">
            Delete
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="() => $inspect(alert)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useAlertDuplicateStore from '@/stores/alerts/modals/alert-duplicate'
import useAlertEditStore from '@/stores/alerts/modals/alert-edit'
import useMyAlertsStore from '@/stores/me/alerts'

import { add as addDate } from 'date-fns'
import { mapActions } from 'pinia'

export default {
    props: [ 'alert' ],

    methods: {
        ...mapActions(useMyAlertsStore, {
            deleteAlert: 'delete',
            updateAlert: 'update',
            mute: 'mute',
            unmute: 'unmute',
            pause: 'pause',
            unpause: 'unpause'
        }),

        ...mapActions(useAlertEditStore, {
            editAlert: 'open'
        }),

        ...mapActions(useAlertDuplicateStore, {
            duplicateAlert: 'open'
        }),

        addDate
    }
}
</script>
