<template>
    <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-lg h-64" v-if="$page.feature.details.geo?.location">
        <ui-map :center="$page.feature.details.geo.location" :bounds="$page.feature.details.geo.bounds" :marker="true"></ui-map>
    </div>
</template>

<script>
import UiMap from '@/components/ui/map'

export default {
    components: { UiMap }
}
</script>