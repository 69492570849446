<template>
    <ui-dropdown align="right">
        <template v-slot:trigger>
            <a href="#" class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-center h-8 px-3">
                <span class="font-medium">Ordered by <span class="lowercase">{{selected.name}}</span></span>
                <ui-icon name="select" class="ml-2"></ui-icon>
            </a>
        </template>

        <template v-slot:content>
            <template v-for="option, index in availableOptions">
                <ui-dropdown-link :icon="option.icon" @click="store.setSorting(option.id)" :key="option.id" v-if="option.id">
                    {{option.name}}
                </ui-dropdown-link>
                <ui-dropdown-separator :key="`separator-${index}`" v-else></ui-dropdown-separator>
            </template>
        </template>
    </ui-dropdown>
</template>

<script>
export default {
    data: () => ({
        options: [
            { id: 'latest', name: 'Latest', icon: 'calendar' },
            { id: 'oldest', name: 'Oldest', icon: 'calendar' },
            { id: 'ids', name: 'Most Relevant', icon: 'star', available: store => store.filters.value('semantic') },
            {},
            { id: 'interactions', name: 'Most Interactions', icon: 'interactions' },
//            { id: 'trending', name: 'Trending', icon: 'fire' },
            {},
            { id: 'reactions', name: 'Most Reactions', icon: 'thumbs-up' },
            { id: 'comments', name: 'Most Comments', icon: 'comment' },
            { id: 'shares', name: 'Most Shares', icon: 'share' },
            { id: 'views', name: 'Most Views', icon: 'eye' }
        ]
    }),

    computed: {
        availableOptions() {
            return this.options.filter(o => ! o.available || o.available(this.store))
        },

        selected() {
            return this.options.find(o => o.id == this.store.sorting.id)
        }
    }
}
</script>
