<template>
    <ui-modal name="feature-details-text" :title="label">
        <p>{{value}}</p>
    </ui-modal>
</template>

<script>
import useFeatureDetailsText from '@/stores/features/modals/feature-details-text'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useFeatureDetailsText, [
            'label', 'value'
        ])
    }
}
</script>
