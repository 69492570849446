<template>
    <div class="px-6 py-4">
        <div class="flex justify-between items-center h-8 mb-4">
            <div class="border border-gray-300 rounded-md font-medium flex h-full">
                <a @click.prevent="activeTab = 'metrics'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'metrics' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                    Metrics
                </a>
                <a @click.prevent="activeTab = 'replies'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'replies' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                    Replies
                </a>
                <a @click.prevent="activeTab = 'shares'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'shares' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                    Shares
                </a>
                <a @click.prevent="activeTab = 'notes'" href="#" class="flex items-center justify-center px-3" :class="activeTab == 'notes' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                    Notes
                </a>
            </div>
            
            <div v-if="activeTab == 'metrics'" class="text-sm">
                Last updated <span class="font-medium">{{ $dateTime(lastUpdatedAt) }}</span>
            </div>
            <div v-else-if="activeTab == 'replies'" class="flex items-center gap-x-4">
                <div class="text-sm">
                    <span class="font-medium">{{content.conversationChildrenCount || 0}}</span> replies
                </div>
                
                <ui-dropdown align="right">
                    <template v-slot:trigger>
                        <a href="#" class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-center h-8 px-3">
                            <span class="font-medium">Ordered by <span class="lowercase">{{selectedRepliesSorting.name}}</span></span>
                            <ui-icon name="select" class="ml-2"></ui-icon>
                        </a>
                    </template>

                    <template v-slot:content>
                        <template v-for="option, index in repliesSortingOptions">
                            <ui-dropdown-link :icon="option.icon" @click="setRepliesSorting(option.id)" :key="option.id" v-if="option.id">
                                {{option.name}}
                            </ui-dropdown-link>
                            <ui-dropdown-separator :key="`separator-${index}`" v-else></ui-dropdown-separator>
                        </template>
                    </template>
                </ui-dropdown>
            </div>
            <div v-else-if="activeTab == 'shares'" class="flex items-center gap-x-4">
                <div class="text-sm">
                    <span class="font-medium">{{$number(sharesCount)}}</span> shares
                </div>

                <ui-dropdown align="right">
                    <template v-slot:trigger>
                        <a href="#" class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-center h-8 px-3">
                            <span class="font-medium">Ordered by <span class="lowercase">{{selectedSharesSorting.name}}</span></span>
                            <ui-icon name="select" class="ml-2"></ui-icon>
                        </a>
                    </template>

                    <template v-slot:content>
                        <template v-for="option, index in sharesSortingOptions">
                            <ui-dropdown-link :icon="option.icon" @click="setSharesSorting(option.id)" :key="option.id" v-if="option.id">
                                {{option.name}}
                            </ui-dropdown-link>
                            <ui-dropdown-separator :key="`separator-${index}`" v-else></ui-dropdown-separator>
                        </template>
                    </template>
                </ui-dropdown>
            </div>
        </div>

        <details-metrics v-if="activeTab == 'metrics'"></details-metrics>
        <details-replies v-if="activeTab == 'replies'"></details-replies>
        <details-shares v-if="activeTab == 'shares'"></details-shares>
        <details-notes v-if="activeTab == 'notes'"></details-notes>
    </div>
</template>

<script>
import DetailsNotes from './details/notes'
import DetailsMetrics from './details/metrics'
import DetailsReplies from './details/replies'
import DetailsShares from './details/shares'

import useDetailsStore from '@/stores/content/details'
import useMetricsStore from '@/stores/content/details/metrics'
import useRepliesStore from '@/stores/content/details/replies'
import useSharesStore from '@/stores/content/details/shares'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        DetailsNotes, DetailsMetrics, DetailsReplies, DetailsShares
    },
    
    data: () => ({
        repliesSortingOptions: [
            { id: 'date-desc', name: 'Latest', icon: 'calendar' },
            { id: 'date-asc', name: 'Oldest', icon: 'calendar' },
            {},
            { id: 'interactions-desc', name: 'Most Interactions', icon: 'interactions' }
        ],
        
        sharesSortingOptions: [
            { id: 'date-desc', name: 'Latest', icon: 'calendar' },
            { id: 'date-asc', name: 'Oldest', icon: 'calendar' },
            {},
            { id: 'interactions-desc', name: 'Most Interactions', icon: 'interactions' }
        ]
    }),
    
    computed: {
        ...mapState(useDetailsStore, [ 'content' ]),
        ...mapState(useMetricsStore, [ 'lastUpdatedAt' ]),
        ...mapState(useSharesStore, [ 'sharesCount' ]),
        ...mapWritableState(useDetailsStore, [ 'activeTab' ]),
        
        selectedRepliesSorting() {
            return this.repliesSortingOptions.find(o => o.id == useRepliesStore().sorting)
        },

        selectedSharesSorting() {
            return this.repliesSortingOptions.find(o => o.id == useSharesStore().sorting)
        }
    },
    
    methods: {
        ...mapActions(useRepliesStore, { setRepliesSorting: 'setSorting' }),
        ...mapActions(useSharesStore, { setSharesSorting: 'setSorting' })
    }
}
</script>