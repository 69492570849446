<template>
    <div>
        <h1 class="text-gray-700 text-xl font-medium leading-none px-1 my-6 mb-4">Organization</h1>

        <div class="rounded-lg bg-white border border-gray-200 shadow-sm divide-y divide-gray-200">
            <div class="md:grid md:grid-cols-3 md:gap-6 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Licences</h3>
                    <p class="mt-1 text-gray-700">
                        These are the active licences for your organization.
                    </p>
                </div>
                <div class="mt-5 md:mt-0 md:col-span-2 space-y-4">
                    <div class="text-white rounded-lg p-4 bg-gradient-to-br flex items-center" :class="{ 'from-fuchsia-700 to-fuchsia-500': license.trial, 'from-gerulata-blue-700 to-gerulata-blue-500': ! license.trial }" v-for="license, i in $my.user.organization.licenses" :key="i">
                        <ui-icon name="check" class="text-2xl mr-3"></ui-icon>
            
                        <h1 class="text-xl font-medium flex-1" v-if="license.trial">Trial License</h1>
                        <h1 class="text-xl font-medium flex-1" v-else-if="license.partnership">Partnership License</h1>
                        <h1 class="text-xl font-medium flex-1" v-else>Commercial License</h1>
            
                        <p class="text-sm">Valid until <span class="font-semibold">{{$date(license.validTo)}}</span></p>
                    </div>
            
                    <p class="text-gray-500" v-if="! $my.user.organization.licenses.length">No valid licenses have been found.</p>
                </div>
            </div>
        </div>
    </div>
</template>
