<template>
    <div :class="{ 'font-medium': change.field === 'title'}" v-html="value"></div>
    <span v-if="isTooLong && ! isExpanded">
        <a class="cursor-pointer text-blue-500" @click.prevent="expand">Read more</a>
    </span>
</template>

<script>
import { diffWords } from 'diff'

export default {
    props: [ 'change' ],

    data: () => ({
        isExpanded: false
    }),

    methods: {
        diff(from, to) {
            return diffWords(from, to).map(fragment => {
                return `<span class="${fragment.added ? 'font-medium bg-green-200' : fragment.removed ? 'font-medium bg-red-200' : ''}">${fragment.value}</span>`
            }).join('')
        },

        expand() {
            this.isExpanded = true
        }
    },

    computed: {
        isTooLong() {
            return this.diff(this.change.from, this.change.to).length > 500
        },

        value() {
            const diff = this.diff(this.change.from, this.change.to)

            if (this.isTooLong && ! this.isExpanded) {
                return diff.length > 500 ? diff.slice(0, 500) + '...' : diff
            }

            return diff
        }
    }
}
</script>
