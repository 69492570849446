<template>
    <div class="py-6 px-6 xl:px-16 max-w-12xl mx-auto min-h-full flex flex-col">
        <div class="flex items-center justify-between mb-6">
            <h1 class="text-[24px] font-semibold px-1">
                Dashboards
            </h1>

            <ui-button icon="plus-square" color="blue" :action="createBoard">
                New Dashboard
            </ui-button>
        </div>

        <div v-if="isShowingWelcomeMessage" class="flex-1 flex items-center">
            <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                <ui-icon name="navigation.dashboards" class="text-6xl text-gray-400"></ui-icon>
                <h1 class="text-3xl font-semibold mt-6">Welcome to the Dashboards section!</h1>
                <div class="space-y-4 my-6">
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Arrange widgets to create a <span class="font-medium">dashboard</span> to gain a quick overview of particular situation. Useful for on-screen overview or day-to-day analytics.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Various types of <span class="font-medium">widgets</span> are available, like analyses, metrics, content stream, keyword cloud, and more.</p>
                    </div>
                </div>
                <div>
                    <ui-button color="blue" size="lg" :action="createBoard">
                        Create your first Dashboard
                    </ui-button>
                </div>
            </div>
        </div>

        <template v-else>
            <ui-cards-section title="Pinned Dashboards" :store="dashboardsIndexPinnedCardsStore" key="indexPinnedCards">
            </ui-cards-section>

            <ui-cards-section title="Dashboards" :store="dashboardsIndexCardsStore" key="indexCards">
                <template v-slot:actions>
                    <a @click.prevent="createBoard" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2.5">
                        <ui-icon name="plus" class="text-xl"></ui-icon> New dashboard
                    </a>
                </template>
            </ui-cards-section>
        </template>
    </div>
</template>

<script>
import useDashboardsIndexCardsStore from '@/stores/dashboards/index-cards'
import useDashboardsIndexPinnedCardsStore from '@/stores/dashboards/index-pinned-cards'
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useDashboardsIndexCardsStore, useDashboardsIndexPinnedCardsStore),

        isShowingWelcomeMessage() {
            return this.dashboardsIndexCardsStore.isInitialized && this.dashboardsIndexCardsStore.isEmpty
        }
    },

    methods: {
        ...mapActions(useModalsBoardEditStore, {
            createBoard: 'open'
        })
    }
}
</script>
