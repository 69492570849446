import useIndexPinnedCardsStore from '@/stores/alerts/index-pinned-cards'
import useIndexUnreadCardsStore from '@/stores/alerts/index-unread-cards'
import useIndexCardsStore from '@/stores/alerts/index-cards'
import useMyAlertsStore from '@/stores/me/alerts'

import { defineStore } from 'pinia'

export const useAlertsIndexPage = defineStore({
    id: 'alertsIndexPage',

    getters: {
        title: () => 'Alerts'
    },

    actions: {
        afterEnter(to) {
            useMyAlertsStore().initialize()

            useIndexUnreadCardsStore().initialize()
            useIndexPinnedCardsStore().initialize()
            useIndexCardsStore().initialize(to.query.show)

            if (to.query.mute) {
                useMyAlertsStore().initialize().then(() => useMyAlertsStore().mute(useMyAlertsStore().find(to.query.mute)))
            }

            if (to.query.pause) {
                useMyAlertsStore().initialize().then(() => useMyAlertsStore().pause(useMyAlertsStore().find(to.query.pause)))
            }
        }
    }
})

export default useAlertsIndexPage
