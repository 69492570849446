<template>
    <div class="flex items-center justify-center gap-x-1.5 text-3xl font-semibold text-center mb-1">
        {{trigger.meta.content.length}} new items in
        <span class="flex items-center gap-x-1">
            <ui-avatar :item="trigger.meta.subject" class="w-7 h-7"></ui-avatar> {{trigger.meta.subject.name}}
        </span>
    </div>

    <div class="flex items-center justify-center gap-x-1.5 mb-10 text-gray-700">
        {{$dateTime(trigger.createdAt)}}
    </div>

    <div class="flex justify-between mb-4">
        <div>
            <span class="font-medium">{{trigger.meta.content.length}}</span> new items
        </div>
        <div class="flex items-center gap-x-3">
            <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[trigger]': `${trigger.id}|content|${trigger.createdAt}|${trigger.alert.name}` } }" target="_blank" class="flex items-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                <ui-icon name="navigation.content"></ui-icon>
                <span>Show all</span>
            </router-link>
        </div>
    </div>

    <ui-async :guards="content.data" class="space-y-6 max-w-2xl mx-auto">
        <content-card v-for="content in content.data" :content="content" :key="content.id"></content-card>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadMore($event)"></ui-infinite-loading>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-120">
                <ui-spinner v-bind="spinner"></ui-spinner>
            </div>
        </template>
    </ui-async>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'

import useTriggerEveryMatchStore from '@/stores/alerts/trigger-every-match'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ContentCard
    },

    computed: {
        ...mapState(useTriggerEveryMatchStore, [ 'content', 'lastReloadTime', 'subject', 'trigger' ])
    },

    methods: {
        ...mapActions(useTriggerEveryMatchStore, [ 'loadMore' ])
    }
}
</script>
