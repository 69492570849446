<template>
    <ui-modal name="channels-list-merge" :title="'Merge lists'" :scrollable="false">
        <ui-form :store="channelsModalsListMergeStore" v-slot="{ inputs, submitting, errors }">

            <div class="space-y-5">
                <div class="flex items-center">
                    <ui-dropdown class="w-full" width="w-80">
                        <template v-slot:trigger>
                            <label class="block text-sm text-gray-700 font-medium mb-1">
                                Choose a List to be merged into <span class="font-semibold">{{inputs.targetList.name}}</span>
                            </label>
                            <div :class="{'border-1 border-rose-500': errors.sourceList && ! inputs.sourceList.id, 'text-gray-700': ! inputs.sourceList.id }"
                                    class="flex items-center px-3 pr-2 h-9 text-sm hover:bg-gray-50 text-gray-900 rounded-sm cursor-pointer bg-white border border-gray-300 rounded-md shadow-sm">
                                <div class="w-4 flex justify-center items-center text-center mr-2" v-if="inputs.sourceList.id">
                                    <ui-avatar :item="inputs.sourceList" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                </div>
                                {{ inputs.sourceList.name || "Select list to be merged" }}
                                <ui-icon name="chevron-down" class="ml-auto"></ui-icon>
                            </div>
                            <p class="text-red-500" v-if="errors.sourceList && ! inputs.sourceList.id">
                                {{ errors.sourceList[0] }}</p>
                        </template>

                        <template v-slot:content>
                            <ui-async :guards="areListsInitialized">
                                <div class="px-2 py-1">
                                    <div class="relative w-full h-7">
                                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                                            <ui-icon name="search" class="text-sm"></ui-icon>
                                        </div>
                                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="listsSearch">
                                    </div>
                                </div>

                                <ui-dropdown-separator></ui-dropdown-separator>

                                <div class="max-h-64 overflow-y-auto">
                                    <ui-dropdown-link @click="selectList(list)" :icon="true" :key="list.id" v-for="list in lists">
                                        <template v-slot:icon>
                                            <div class="w-4 flex justify-center items-center text-center">
                                                <ui-avatar :item="list" class="w-4 h-4 drop-shadow-sm shrink-0"></ui-avatar>
                                            </div>
                                        </template>
                                        {{ list.name }}
                                    </ui-dropdown-link>
                                    <p v-if="!lists.length" class="px-1">
                                        No lists to show
                                    </p>
                                </div>
                            </ui-async>
                        </template>
                    </ui-dropdown>
                </div>

                <div class="flex items-center space-x-2">
                    <input type="checkbox" id="deleteMergedList" v-model="inputs.deleteSelected">
                    <label for="deleteMergedList">Delete <span class="font-medium">{{inputs.sourceList.name || 'selected list'}}</span> after merge</label>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="gray" :action="cancel">
                    Cancel
                </ui-button>

                <ui-button type="submit" color="blue" :disabled="submitting">
                    Merge
                </ui-button>
            </div>

        </ui-form>
    </ui-modal>
</template>

<script>
import useChannelsModalsListMergeStore from '@/stores/channels/modals/list-merge'
import useMyChannelListsStore from '@/stores/me/channel-lists'

import textSearch from '@/helpers/text-search'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    data: () => ({
        listsSearch: ''
    }),

    computed: {
        ...mapStores(useChannelsModalsListMergeStore),
        ...mapState(useMyChannelListsStore, {
            areListsInitialized: 'isInitialized',

            lists(store) {
                return (this.listsSearch ? textSearch(this.listsSearch, store.items, t => t.name) : store.items)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .filter(item => item.id !== this.channelsModalsListMergeStore.inputs.targetList.id)
            }
        })
    },

    methods: {
        ...mapActions(useChannelsModalsListMergeStore, {
            'selectList': 'select',
            'cancel': 'cancel'
        })
    }
}
</script>
