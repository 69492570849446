import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyChannelListsStore from '@/stores/me/channel-lists'

export const useChannelsModalsListDuplicateStore = defineForm({
    id: 'channelsModalsListDuplicate',

    state: () => ({
        cardsStore: false,
    }),

    inputs: () => ({
        list: {},
        name: null
    }),

    submitRequest() {
        trackEvent('channels', 'duplicated-channel-list-saved')

        return api.route('me lists duplicate', { id: this.inputs.list.id })
            .json({ name: this.inputs.name })
            .post()
    },

    async onResponse(res) {
        useMyChannelListsStore().reload()

        if (this.cardsStore) this.cardsStore.show('list', { id: res.data.id })

        useModal().hide('channels-list-duplicate')
    },

    actions: {
        open(list = {}, cardsStore = null) {
            this.reset()

            this.inputs.list = list
            this.inputs.name = `${list.name} (copy)`

            this.cardsStore = cardsStore

            useModal().show('channels-list-duplicate')
        },

        cancel() {
            useModal().hide('channels-list-duplicate')
        }
    }
})

export default useChannelsModalsListDuplicateStore
