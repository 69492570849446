import { createApp } from 'vue'
import Main from './Main.vue'

let app = createApp(Main)

import { createPinia } from 'pinia'
import { vfmPlugin } from 'vue-final-modal'
import FloatingVue from 'floating-vue'
import VueClickAway from 'vue3-click-away'

app.use(createPinia())
app.use(vfmPlugin({ key: '$modal' }))
app.use(FloatingVue, { defaultDelay: 200, themes: { 'link': { $extend: 'menu', $resetCss: true } } })
app.use(VueClickAway)

import registerComponents from '@/components'
import registerHelpers from '@/helpers'

registerComponents(app)
registerHelpers(app)

import setupAnalytics from '@/analytics'
import setupBugsnag from '@/bugsnag'
import { setupRouter } from '@/router'
import setupShortcuts from '@/shortcuts'

import routes from './routes'

setupAnalytics(app)
setupBugsnag(app)
setupRouter(app, routes)
setupShortcuts()

import '@/assets/styles/tailwind.css'
import '@/assets/styles/fonts.css'
import '@/assets/styles/app.css'

import 'floating-vue/dist/style.css'
import '@/assets/styles/tooltips.css'
import 'vue-select/dist/vue-select.css'
import '@/assets/styles/vue-select.css'

app.mixin({ props: [ 'store' ] })
app.mount('#app')

//setupRouter(app)

import useMyStore from '@/stores/me/my'

useMyStore().initialize()
