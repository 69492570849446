import useAlertEditStore from './alert-edit'
import useMyAlertsStore from '@/stores/me/alerts'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useAlertCreateStore = defineStore({
    id: 'alertsModalsAlertCreate',

    state: () => ({
        subject: null
    }),

    getters: {
        types: store => {
            return useMyAlertsStore().types.filter(
                type => ! type.hidden && (! store.subject || type.subjectFamilies.includes(store.subject.family))
            )
        }
    },

    actions: {
        open(subject) {
            this.subject = subject

            useModal().show('alert-create')
        },

        cancel() {
            useModal().hide('alert-create')
        },

        selectType(type) {
            useAlertEditStore().open({ type, subject: this.subject }, this.subject)
            this.cancel()
        }
    }
})

export default useAlertCreateStore
