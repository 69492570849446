<template>
    <div>
        <ui-header title="New Analysis">
            <template v-slot:actions>
                <form class="w-56 relative" @submit.prevent>
                    <div class="absolute top-0 left-2 h-8 flex items-center pointer-events-none z-40">
                        <ui-icon name="search" class="text-gray-500"></ui-icon>
                    </div>

                    <input type="text" ref="input" v-model="$page.search"
                           autocomplete="off"
                           spellcheck="false"
                           placeholder="Search analyses..."
                           class="block w-full h-8 pl-8 pr-2 rounded placeholder-gray-700 focus:outline-none focus:ring-0 focus:placeholder-gray-700 border-0 z-30 text-gray-900 bg-gray-100 text-sm"
                    >
                </form>
            </template>
        </ui-header>

        <div class="pb-8 px-6 xl:px-16 max-w-12xl mx-auto">
            <div class="mb-10" v-if="featuredTypes.length">
                <h1 class="font-medium text-sm mb-4">Featured Analyses</h1>

                <div class="grid grid-cols-2 lg:grid-cols-3 3xl:grid-cols-4 gap-6">
                    <new-card :type="type" :key="type.id" v-for="type in featuredTypes"></new-card>
                </div>
            </div>

            <div class="mb-10" v-if="contentTypes.length">
                <h1 class="font-medium text-sm mb-4">Content Analyses</h1>

                <table class="rounded-md bg-white border border-gray-200 shadow-sm w-full">
                    <new-row :type="type" :key="type.id" v-for="type in contentTypes"></new-row>
                </table>
            </div>

            <div class="mb-10" v-if="channelTypes.length">
                <h1 class="font-medium text-sm mb-4">Channel Analyses</h1>

                <table class="rounded-md bg-white border border-gray-200 shadow-sm w-full">
                    <new-row :type="type" :key="type.id" v-for="type in channelTypes"></new-row>
                </table>
            </div>

            <div class="mb-10" v-if="featureTypes.length">
                <h1 class="font-medium text-sm mb-4">Feature Analyses</h1>

                <table class="rounded-md bg-white border border-gray-200 shadow-sm w-full">
                    <new-row :type="type" :key="type.id" v-for="type in featureTypes"></new-row>
                </table>
            </div>

            <div class="h-120 flex flex-col items-center justify-center text-gray-600" v-if="noSearchResults">
                <ui-icon name="wind" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-center text-sm mt-3">There are no analyses here, yet.</div>
            </div>
        </div>
    </div>
</template>

<script>
import NewCard from './stream-cards/new-card'
import NewRow from './stream-cards/new-row'

import breakpoint from '@/helpers/breakpoint'
import textSearch from '@/helpers/text-search'

export default {
    components: {
        NewCard, NewRow
    },

    data: () => ({
        types: {
            featured: [
                {
                    id: 'content-performance',
                    name: 'Performance',
                    description: 'Compares published content count, prevalence, interactions and other metrics over time.'
                },
                {
                    id: 'content-top-channels',
                    name: 'Top Channels',
                    description: 'Detailed breakdown of top channels publishing content in the data-set.'
                },
                {
                    id: 'channel-audience',
                    name: 'Follower Count',
                    description: 'Shows channel\'s audience size over a period of time.'
                },
                {
                    id: 'content-publish-times',
                    name: 'Publish Times',
                    description: 'Visualizes the posting activity by day or hour using a heatmap.'
                }
            ],
            channel: [
                {
                    id: 'channel-audience',
                    name: 'Follower Count',
                    description: 'Shows channel\'s audience size over a period of time.'
                },
                {
                    id: 'channel-publish-times',
                    name: 'Publish Times',
                    description: 'Visualizes channel\'s posting activity by day or hour using a heatmap.'
                }
            ],
            content: [
                {
                    id: 'content-performance',
                    name: 'Performance',
                    description: 'Compares published content count, prevalence, interactions and other metrics over time.'
                },
                {
                    id: 'content-top-channels',
                    name: 'Top Channels',
                    description: 'Detailed breakdown of top channels publishing content in the data-set.'
                },
                {
                    id: 'content-top-platforms',
                    name: 'Top Platforms',
                    description: 'Detailed breakdown of top platforms where content was published in the data-set.'
                },
                {
                    id: 'content-publish-times',
                    name: 'Publish Times',
                    description: 'Visualizes the posting activity by day or hour using a heatmap.'
                },
                {
                    id: 'content-pull-push',
                    name: 'Pull-Push',
                    description: 'Visualizes the relationship between the prevalence of published content and engagement over time.'
                },
                {
                    id: 'content-sentiment',
                    name: 'Sentiment',
                    description: 'Shows how the sentiment of published content or its replies changes over time.'
                },
                {
                    id: 'content-keywords',
                    name: 'Keywords',
                    description: 'Generates a keyword cloud of the most common keyword mentioned in the content.'
                }
            ],
            feature: [
            ]
        }
    }),

    computed: {
        featuredTypes() {
            let count = { 'xs': 2, 'sm': 2, 'md': 2, 'lg': 3, 'xl': 3, '2xl': 3, '3xl': 4 }[breakpoint.is]

            return textSearch(this.$page.target, this.types.featured.slice(0, count), v => v.name)
        },

        contentTypes() {
            return textSearch(this.$page.target, this.types.content, v => v.name)
        },

        channelTypes() {
            return textSearch(this.$page.target, this.types.channel, v => v.name)
        },

        featureTypes() {
            return textSearch(this.$page.target, this.types.feature, v => v.name)
        },

        noSearchResults() {
            return ! this.featureTypes.length && ! this.contentTypes.length && ! this.channelTypes.length
                && ! this.featuredLists.length
        },
    }
}
</script>
