<template>
    <ui-modal name="content-smart-tag" title="Smart Tag" icon="ai" :max-width="previewTask ? '800px' : '520px'">
        <ui-form :store="contentModalsSmartTagStore" v-slot="{ inputs, submitting, errors }">
            <template v-if="! previewTask">
                <div class="flex justify-center h-8 mb-5">
                    <div class="border border-gray-300 rounded-md font-medium flex h-full">
                        <a @click.prevent="inputs.type = 'yes-or-no'" href="#" class="flex items-center justify-center px-3" :class="inputs.type == 'yes-or-no' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                            Yes or No
                        </a>
                        <a @click.prevent="inputs.type = 'multi-category'" href="#" class="flex items-center justify-center px-3" :class="inputs.type == 'multi-category' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'text-gray-700 hover:text-gray-900 h-full'">
                            Multi-Category
                        </a>
                    </div>
                </div>

                <smart-tag-yes-no v-if="inputs.type == 'yes-or-no'" :inputs="inputs" :errors="errors"></smart-tag-yes-no>
                <smart-tag-multi-category v-if="inputs.type == 'multi-category'" :inputs="inputs" :errors="errors"></smart-tag-multi-category>

                <ui-async :guards="itemsCount && $my.budgets">
                    <div class="px-5 py-3 bg-gray-50 border border-gray-75 rounded-lg mt-4 flex items-center gap-x-4">
                        <ui-icon name="disc" class="text-gray-400 text-2xl"></ui-icon>
                        <div class="flex-1">
                            <div class="flex gap-x-6">
                                <div>
                                    <div class="font-medium text-gray-800">
                                        {{$my.budgets.smartTags.limit - $my.budgets.smartTags.usage}}
                                    </div>
                                    <div class="text-xs text-gray-700 font-medium leading-tight">
                                        Budget
                                    </div>
                                </div>
                                <div>
                                    <div class="font-medium text-red-700">
                                        {{itemsCount}}
                                    </div>
                                    <div class="text-xs text-gray-700 font-medium leading-tight">
                                        Spending
                                    </div>
                                </div>
                                <div class="ml-auto">
                                    <div class="font-medium text-blue-700" :class="hasBudgetAvailable ? 'text-blue-700' : 'text-red-700'">
                                        {{$my.budgets.smartTags.limit - $my.budgets.smartTags.usage - itemsCount}}
                                    </div>
                                    <div class="text-xs text-gray-700 font-medium leading-tight">
                                        Remaining
                                    </div>
                                </div>
                                <div>
                                    <ui-icon name="help-circle" class="text-lg text-blue-600 hover:text-blue-900" v-tooltip="`Your current budget is ${$my.budgets.smartTags.limit} Smart Tags a month.`"></ui-icon>
                                </div>
                            </div>
                            <div v-if="hasBudgetAvailable" class="mt-1.5">
                                <div class="bg-gray-100 h-1 w-full rounded-full overflow-hidden flex">
                                    <div class="bg-blue-500 h-1" :style="`width:${$my.budgets.smartTags.usage / $my.budgets.smartTags.limit * 100}%;`"></div>
                                    <div class="bg-red-500 h-1" :style="`width:${itemsCount / $my.budgets.smartTags.limit * 100}%;`"></div>
                                </div>
                            </div>
                            <div v-else class="mt-1.5 text-red-500 text-sm font-medium">
                                <ui-icon name="alert-triangle"></ui-icon>
                                Insufficient budget.
                            </div>
                        </div>
                    </div>
                </ui-async>

                <div class="text-center text-sm text-gray-600 mt-4">
                    We will show you a preview of the applied tags before you spend your credits.
                </div>

                <div class="flex justify-center mt-6 mb-2">
                    <ui-button type="submit" color="blue" :disabled="! hasBudgetAvailable || submitting">
                        Preview
                    </ui-button>
                </div>
            </template>

            <template v-else>
                <div class="h-[50vh] overflow-y-scroll mt-4">
                    <div v-if="previewTask.state == 'completed' && content.data" class="space-y-2">
                        <simple-content-card v-for="content in content.data" :key="content.id" :content="content">
                            <template v-slot:bottom>
                                <div class="pt-2 flex flex-wrap gap-x-1 gap-y-1">
                                    <div v-for="tag in content.tags" class="flex items-center text-lg font-medium text-gray-800" :key="tag.id">
                                        <ui-icon name="user-tag" :class="`text-${tag.color}`"></ui-icon>
                                        <div class="ml-1 text-xs">{{ tag.name }}</div>
                                    </div>
                                </div>
                            </template>
                        </simple-content-card>

                        <div v-if="! content.data.length" class="h-[50vh] flex items-center justify-center text-gray-700">
                            <span>No tags have been applied.</span>
                        </div>
                    </div>

                    <div class="h-full flex flex-col items-center justify-center space-y-3" v-else>
                        <ui-spinner class="text-3xl" type="clip"></ui-spinner>
                        <div>Loading Smart Tag preview...</div>
                    </div>
                </div>

                <div class="text-center text-sm text-gray-600 mt-6">
                    Applying the Smart Tag might take a few minutes.
                </div>

                <div class="flex justify-center mt-6 mb-2 space-x-5">
                    <ui-button type="button" color="gray" :disabled="submitting" @click="back">
                        Back
                    </ui-button>

                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Apply
                    </ui-button>
                </div>
            </template>
        </ui-form>
    </ui-modal>
</template>

<script>
import SimpleContentCard from '@/components/content/stream-cards/simple-content'
import SmartTagYesNo from '@/components/content/modals/partials/smart-tag-yes-no'

import useContentModalsSmartTagStore from '@/stores/content/modals/smart-tag'

import { mapActions, mapState, mapStores } from 'pinia'
import SmartTagMultiCategory from "@/components/content/modals/partials/smart-tag-multi-category.vue";

export default {
    components: {
        SmartTagMultiCategory,
        SimpleContentCard, SmartTagYesNo
    },
    
    computed: {
        ...mapStores(useContentModalsSmartTagStore),
        ...mapState(useContentModalsSmartTagStore, [ 'hasBudgetAvailable', 'itemsCount', 'previewTask', 'content' ])
    },

    data: () => ({
        showAdvanced: false
    }),

    methods: {
        ...mapActions(useContentModalsSmartTagStore, [ 'back' ])
    }
}
</script>
