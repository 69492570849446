import useMyAlertsStore from '@/stores/me/alerts'

import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useAlertDuplicateStore = defineForm({
    id: 'alertsModalsAlertDuplicate',

    inputs: () => ({
        alert: {},
        name: null
    }),

    submitRequest() {
        return api.route('me alerts duplicate', { id: this.inputs.alert.id })
            .json({
                name: this.inputs.name
            })
            .post()
    },

    onResponse(res) {
        useMyAlertsStore().reload()

        useModal().hide('alert-duplicate')
    },

    actions: {
        open(alert) {
            this.reset()

            this.inputs.alert = alert
            this.inputs.name = `${alert.name} (copy)`

            useModal().show('alert-duplicate')
        },

        cancel() {
            useModal().hide('alert-duplicate')
        }
    }
})

export default useAlertDuplicateStore
