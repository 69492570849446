<template>
    <div class="w-64 max-h-96 overflow-y-auto py-2">
        <template v-for="type in types" :key="type.id">
            <ui-dropdown-link v-if="type.id" @click="toggle(type)" :icon="type.icon" checkbox :selected="store.filters.type?.includes(type.id)">
                {{type.name}}
            </ui-dropdown-link>
            <ui-dropdown-separator v-else></ui-dropdown-separator>
        </template>
    </div>
</template>

<script>
export default {
    data: () => ({
        types: [
            { name: 'Facebook Page', id: 'facebook-page', icon: 'badges.channel-facebook-page' },
            { name: 'Facebook Group', id: 'facebook-group', icon: 'badges.channel-facebook-group' },
            { name: 'Facebook Profile', id: 'facebook-profile', icon: 'badges.channel-facebook-profile' },
            { name: 'Instagram Profile', id: 'instagram-profile', icon: 'badges.channel-instagram-profile' },
            { name: 'Reddit Subreddit', id: 'reddit-subreddit', icon: 'badges.channel-reddit-subreddit' },
            { name: 'Reddit User', id: 'reddit-user', icon: 'badges.channel-reddit-user' },
            { name: 'Telegram Channel', id: 'telegram-channel', icon: 'badges.channel-telegram-channel' },
            { name: 'Telegram Group', id: 'telegram-group', icon: 'badges.channel-telegram-group' },
            { name: 'Telegram User', id: 'telegram-user', icon: 'badges.channel-telegram-user' },
            { name: 'TikTok User', id: 'tik-tok-user', icon: 'badges.channel-tik-tok-user' },
            { name: 'VKontakte Community', id: 'vkontakte-community', icon: 'badges.channel-vkontakte-community' },
            { name: 'VKontakte User', id: 'vkontakte-user', icon: 'badges.channel-vkontakte-user' },
            { name: 'Web Feed', id: 'web-feed', icon: 'badges.channel-web-feed' },
            { name: 'Web Site', id: 'web-site', icon: 'badges.channel-web-site' },
            { name: 'X User', id: 'x-user', icon: 'badges.channel-x-user' },
            { name: 'Youtube Channel', id: 'youtube-channel', icon: 'badges.channel-youtube-channel' },
            {},
            { name: '2Ch Board', id: '2ch-board', icon: 'badges.channel-2ch-board' },
            { name: '2Ch User', id: '2ch-user', icon: 'badges.channel-2ch-board' },
            { name: '4Chan Board', id: '4chan-board', icon: 'badges.channel-4chan-board' },
            { name: '4Chan User', id: '4chan-user', icon: 'badges.channel-4chan-board' }
        ]
    }),

    methods: {
        toggle(type) {
            let filterTypes = this.store.filters.type || []

            filterTypes.includes(type.id)
                ? filterTypes.splice(filterTypes.findIndex(v => v == type.id), 1)
                : filterTypes.push(type.id)

            this.store.filterBy(filterTypes.length ? { type: filterTypes } : {})
        }
    }
}
</script>