<template>
    <div :class="wrapperClass">
        <editor-content :class="inputClass" :editor="editor" @keyup.esc="blur"></editor-content>
    </div>
</template>

<script>
import useSuggestionsStore from '@/stores/reusable/suggestions'

import debounce from 'just-debounce-it'
import { mapActions } from 'pinia'
import { Editor, EditorContent, Extension } from '@tiptap/vue-3'
import Paragraph from '@tiptap/extension-paragraph'
import Hardbreak from '@tiptap/extension-hard-break'
import Document from '@tiptap/extension-document'
import History from '@tiptap/extension-history'
import Text from '@tiptap/extension-text'

export default {
    props: {
        'modelValue': { type: String, default: '' },
        'wrapperClasses': { type: String, default: null },
        'focusedWrapperClasses': { type: String, default: null },
        'blurredWrapperClasses': { type: String, default: null },
        'classes': { type: String, default: null },
        'focusedClasses': { type: String, default: null },
        'blurredClasses': { type: String, default: null },
        'isFocused': { type: Boolean, default: false }
    },

    data: () => ({
        editor: null,
        editorValue: null
    }),

    components: {
        EditorContent
    },

    mounted() {
        const shortcuts = Extension.create({
            name: 'shortcuts',

            addKeyboardShortcuts: () => ({
                'Enter': () => {
                    this.$emit('update:modelValue', this.editorValue = this.editor.getText('\n'))
                    this.$emit('submit')
                    return true
                }
            })
        })

        this.editor = new Editor({
            autocomplete: false,
            spellcheck: false,
            content: '',
            extensions: [
                Hardbreak,
                Paragraph,
                Document,
                Text,
                History,
                shortcuts
            ],
            onFocus: () => {
                this.focus()
            },
            onBlur: () => {
                this.blur()
            },
            onUpdate: () => {
                this.update()
            },
            onTransaction: ({ editor, transaction }) => {
                this.transaction({ editor, transaction })
            }
        })
    },

    beforeUnmount() {
        this.editor.destroy()
    },

    methods: {
        ...mapActions(useSuggestionsStore, [ 'suggest' ]),

        focus() {
            this.editor.commands.focus()

            this.$emit('focus')
        },

        blur() {
            if (! this.editor.getText()) {
                this.editor.commands.clearContent()
            }

            this.editor.commands.blur()

            this.$emit('blur')
        },

        update() {
            this.editorValue = this.editor.getText('\n')

            this.model = this.editorValue
        },

        transaction({ editor, transaction }) {
            this.$emit('transaction', { editor, transaction })
        },

        processValue(value) {
            return value
        }
    },

    computed: {
        model: {
            get() { return this.modelValue },
            set: debounce(function (val) { this.$emit('update:modelValue', val) }, 50)
        },

        wrapperClass() {
            const classes = this.wrapperClasses ?? 'bg-gray-100 flex-1 rounded'
            const focusedClasses = this.focusedWrapperClasses ?? 'h-auto'
            const blurredClasses = this.blurredWrapperClasses ?? 'h-10'

            return this.isFocused ? `${classes} ${focusedClasses}` : `${classes} ${blurredClasses}`
        },

        inputClass() {
            const classes = this.classes ?? 'h-full w-full overflow-y-hidden pl-10 pr-32 pt-2 pb-2 rounded-sm align-middle z-30 max-h-48 '
            const focusedClasses = this.focusedClasses ?? 'bg-white ring-2 ring-blue-500'
            const blurredClasses = this.blurredClasses ?? 'resize-none text-gray-900'

            return this.isFocused ? `${classes} ${focusedClasses}` : `${classes} ${blurredClasses}`
        }
    },

    watch: {
        modelValue(value) {
            if (value == this.editorValue) return

            this.editor.commands.setContent(this.processValue(value), false)
        }
    }
}
</script>

<style>
.ProseMirror {
    outline: none;
    overflow-y: hidden;
    min-height: 0;
}

.ProseMirror:focus {
    outline: none;
}

.ProseMirror p {
    @apply font-sans text-[14px] leading-[24px] pb-0;

    /*padding-bottom: 0;*/
    /*display: -webkit-box;*/
    /*-webkit-line-clamp: 1;*/
    /*-webkit-box-orient: vertical;*/
    /*overflow: hidden;*/
}

.ProseMirror-focused p {
    /*display: block;*/
    /*-webkit-line-clamp: none;*/
    /*-webkit-box-orient: vertical;*/
    /*overflow: initial;*/
}
</style>
