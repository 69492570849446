import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyDashboards from '@/stores/me/dashboards'

export const useModalsBoardDuplicateStore = defineForm({
    id: 'dashboardsModalsBoardDuplicate',

    inputs: () => ({
        board: {},
        name: null
    }),

    submitRequest() {
        trackEvent('dashboards', 'duplicated-dashboard-saved', 'board')

        return api.route('me dashboards duplicate', { id: this.inputs.board.id })
            .json({
                type: 'board',
                name: this.inputs.name
            })
            .post()
    },

    onResponse(res) {
        useMyDashboards().reload()

        useModal().hide('dashboards-board-duplicate')
    },

    actions: {
        open(board) {
            this.reset()

            this.inputs.board = board
            this.inputs.name = `${board.name} (copy)`

            useModal().show('dashboards-board-duplicate')
        },

        cancel() {
            useModal().hide('dashboards-board-duplicate')
        }
    }
})

export default useModalsBoardDuplicateStore
