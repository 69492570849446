<template>
    <router-view></router-view>

    <Teleport to="#root">
        <analysis-duplicate-modal></analysis-duplicate-modal>
        <analysis-info-modal></analysis-info-modal>
    </Teleport>
</template>

<script>
import AnalysisDuplicateModal from './modals/analysis-duplicate'
import AnalysisInfoModal from './modals/analysis-info'

export default {
    components: {
        AnalysisDuplicateModal, AnalysisInfoModal
    }
}
</script>
