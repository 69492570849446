import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import { channels } from '@/api'
import { sortAlphabetically } from '@/helpers/sorting'

export default {
    state: () => ({
        selectedChannels: []
    }),

    actions: {
        changeDataset(callback) {
            useQuickSearchStore().show({
                families: [ 'channels', 'lists' ],
                limit: 50,
                onSelect: result => {
                    if (result) {
                        let fn = callback || this.setDataset

                        fn({
                            datasetFamily: result.resultType == 'channel' ? 'channels' : 'lists',
                            datasetId: result.id,
                            datasetName: result.title,
                            label: result.title
                        })
                    }

                    useQuickSearchStore().hide()
                },
                initialResults: [
                    ...sortAlphabetically(useMyBookmarksStore().items, 'title').map(item => ({
                        id: item.id,
                        family: 'channels',
                        resultType: 'channel',
                        type: item.type,
                        title: item.title,
                        avatar: item.avatar,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    })),
                    ...sortAlphabetically(useMyChannelListsStore().items).map(item => ({
                        id: item.id,
                        family: 'lists',
                        resultType: 'list',
                        title: item.name
                    }))
                ]
            })
        },

        setDataset(dataset) {
            if (this.series.length) {
                this.updateSeries(this.series[0], dataset)
            } else {
                this.addSeries(dataset)
            }

            this.loadSelectedChannels()
        },

        addChannel() {
            this.changeDataset(dataset => {
                this.addSeries(dataset)
                this.loadSelectedChannels()
            })
        },

        loadSelectedChannels() {
            let channelIds = this.series.filter(s => s.datasetFamily == 'channels').map(s => s.datasetId)
            let listIds = this.series.filter(s => s.datasetFamily == 'lists').map(s => s.datasetId)

            Promise.all([
                channelIds.length ? channels().ids(channelIds).get() : [],
                useMyChannelListsStore().items.filter(list => listIds.includes(list.id))
            ]).then(([ channels, lists ]) => this.selectedChannels = [ ...lists, ...channels ])
        }
    }
}
