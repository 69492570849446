<template>
    <ui-modal name="channels-audience" :title="title">
        <ui-async :guards="! items.isFetching" class="space-y-2 min-h-80">
            <div v-if="! items.data?.length && ! items.isFetching" class="text-center text-gray-700">
                No items found yet.
            </div>

            <div class="divide-y divide-y-100">
                <router-link :to="{ name: 'channels.channel.information', params: { id: item.channel.id } }" v-for="item, i in items.data" :key="i" href="#" class="flex items-center py-2 gap-x-2">
                    <div class="shrink-0 relative">
                        <ui-avatar :item="item.channel" class="w-7 h-7"></ui-avatar>
                        <ui-icon :name="item.channel.monitored ? `badges.channel-${item.channel.type}` : `badges.channel-${item.channel.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-sm"></ui-icon>
                    </div>
                    <div class="truncate">{{ item.channel.name }}</div>
                    <div class="ml-auto flex items-center gap-x-3">
                        <ui-icon v-if="item.isAdmin" v-tooltip="'Administrator'" name="shield"></ui-icon>
                        <p v-if="item.count"><span class="font-semibold">{{ item.count }}</span> posts</p>
                        <contextual-menu :channel="item.channel" plain class="mt-1"></contextual-menu>
                    </div>
                </router-link>
            </div>

            <ui-infinite-loading v-if="type === 'members' && items?.length" @infinite="loadMore"></ui-infinite-loading>
        </ui-async>
    </ui-modal>
</template>

<script>
import ContextualMenu from '@/components/channels/contextual-menus/channel'

import useAudienceStore from '@/stores/channels/channel/audience'

import { mapActions, mapState } from 'pinia'

export default {
    components: {ContextualMenu},
    methods: {
        ...mapActions(useAudienceStore, [ 'setType', 'loadMore' ])
    },

    computed: {
        ...mapState(useAudienceStore, [ 'type', 'items' ]),

        title() {
            return {
                members: 'Members',
                topSources: 'Top published by..',
                topTargets: 'Top published in..'
            }[this.type]
        }
    }
}
</script>
