<template>
    <ui-modal name="connections-node-details" width="100%" height="auto" :max-width="content ? 800 : 420"
              :max-height="800" unstyled>
        <div class="rounded-xl overflow-hidden bg-white flex flex-col" style="max-height:700px;">
            <ui-async :guards="! isLoading" class="flex flex-col min-h-0">
                <div class="overflow-y-auto min-h-0">
                    <channel-information-details-card :channel="channel" :inline="true"
                                                      v-if="channel"></channel-information-details-card>
                    <feature-information-details-card :feature="feature" :inline="true"
                                                      v-else-if="feature"></feature-information-details-card>
                    <content-information-details-card :content="content" :inline="true"
                                                      v-else-if="content"></content-information-details-card>
                </div>

                <div class="shrink-0">
                    <a :href="detailsUrl" target="_blank"
                       class="border-t border-gray-200 flex justify-center items-center h-10 text-blue-500 hover:text-blue-900"
                       v-if="channel || feature || content">
                        <ui-icon name="info" class="mr-1"></ui-icon>
                        Show details
                    </a>

                    <a href="#" @click.prevent="loadConnections"
                       class="border-t border-gray-200 flex justify-center items-center h-10 text-blue-500 hover:text-blue-900"
                       v-if="graphStore && !content && !graphStore.inspectedNodeIds.includes(nodeId) && graphStore.nodes.length < graphStore.maxNodesLimit">
                        <ui-icon name="share" class="mr-1"></ui-icon>
                        Load connections
                    </a>

                    <a href="#" @click.prevent="hideNode"
                       class="border-t border-gray-200 flex justify-center items-center h-10 text-blue-500 hover:text-blue-900"
                       v-if="graphStore && graphStore.inspectedNodeIds[0] != nodeId">
                        <ui-icon name="eye-off" class="mr-1"></ui-icon>
                        Hide node
                    </a>
                </div>

                <template v-slot:loading>
                    <div class="flex items-center justify-center py-24">
                        <ui-spinner></ui-spinner>
                    </div>
                </template>
            </ui-async>
        </div>
    </ui-modal>
</template>

<script>

import { mapActions, mapState } from 'pinia'
import ChannelInformationDetailsCard from '@/components/channels/channel/info'
import ContentInformationDetailsCard from '@/components/content/details'
import FeatureInformationDetailsCard from '@/components/features/feature/info'
import useConnectionsModalsNodeDetailsStore from '@/stores/connections/modals/node-details'


export default {
    components: {
        ChannelInformationDetailsCard, ContentInformationDetailsCard, FeatureInformationDetailsCard
    },

    computed: {
        ...mapState(useConnectionsModalsNodeDetailsStore, [
            'channel', 'feature', 'isLoading', 'graphStore', 'nodeId', 'content'
        ]),

        detailsUrl() {
            if (this.channel) {
                return this.$router.resolve({
                    name: 'channels.channel.information', params: {id: this.channel.id}
                }).href
            } else if (this.feature) {
                return this.$router.resolve({
                    name: 'features.details', params: {id: this.feature.id}
                }).href
            } else if (this.content) {
                return this.$router.resolve({
                    name: 'content.details', params: {id: this.content.id}
                }).href
            }

            return ''
        }
    },

    methods: {
        ...mapActions(useConnectionsModalsNodeDetailsStore, ['loadConnections', 'hideNode'])
    }
}
</script>
