import defineOverviewKeywordsStore from '@/stores/content/perspective/overview/keywords'
import defineOverviewPlatformsShareStore from '@/stores/content/perspective/overview/platforms-share'
import defineOverviewSentimentShareStore from '@/stores/content/perspective/overview/sentiment-share'
import defineOverviewSentimentTimelineStore from '@/stores/content/perspective/overview/sentiment-timeline'
import defineOverviewTimelineStore from '@/stores/content/perspective/overview/timeline'
import defineOverviewTopChannelsStore from '@/stores/content/perspective/overview/top-channels'
import defineOverviewTopDomainsStore from '@/stores/content/perspective/overview/top-domains'
import defineOverviewTopHashtagsStore from '@/stores/content/perspective/overview/top-hashtags'
import defineOverviewTopLinksStore from '@/stores/content/perspective/overview/top-links'
import defineOverviewTopMediaStore from '@/stores/content/perspective/overview/top-media'

import useMyPerspectivesStore from '@/stores/me/perspectives'
import useQuickSearchStore from '@/stores/me/quick-search'

import {sortAlphabetically} from '@/helpers/sorting'
import colorHash from '@/helpers/color-hash'
import searchFilters from '@/helpers/search-filters'

import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

export const useContentPerspectiveOverviewStore = defineStore({
    id: 'contentPerspectiveOverview',

    state: () => ({
        isInitialized: false,

        mainSeries: null,
        comparedSeries: [],

        widgets: {
            keywords: defineOverviewKeywordsStore({ id: `contentPerspectiveOverviewWidgetsKeywords` }),
            platformsShare: defineOverviewPlatformsShareStore({ id: `contentPerspectiveOverviewWidgetsPlatformsShare` }),
            sentimentShare: defineOverviewSentimentShareStore({ id: `contentPerspectiveOverviewWidgetsSentimentShare` }),
            sentimentTimeline: defineOverviewSentimentTimelineStore({ id: `contentPerspectiveOverviewWidgetsSentimentTimeline` }),
            timeline: defineOverviewTimelineStore({ id: `contentPerspectiveOverviewWidgetsTimeline` }),
            topChannels: defineOverviewTopChannelsStore({ id: `contentPerspectiveOverviewWidgetsTopChannels` }),
            topDomains: defineOverviewTopDomainsStore({ id: `contentPerspectiveOverviewWidgetsTopDomains` }),
            topHashtags: defineOverviewTopHashtagsStore({ id: `contentPerspectiveOverviewWidgetsTopHashtags` }),
            topLinks: defineOverviewTopLinksStore({ id: `contentPerspectiveOverviewWidgetsTopLinks` }),
            topMedia: defineOverviewTopMediaStore({ id: `contentPerspectiveOverviewWidgetsTopMedia` })
        }
    }),

    getters: {
        isComparing: store => store.comparedSeries.length
    },

    actions: {
        initialize(filters, perspective, force = false) {
            if (this.isInitialized && ! force) return

            this.mainSeries = this.prepareSeries({ filters, perspective })

            this.widgets.keywords().initialize(this.mainSeries)
            this.widgets.platformsShare().initialize(this.mainSeries)
            this.widgets.sentimentShare().initialize(this.mainSeries)
            this.widgets.sentimentTimeline().initialize(this.mainSeries)
            this.widgets.timeline().initialize(this.mainSeries)
            this.widgets.topChannels().initialize(this.mainSeries)
            this.widgets.topDomains().initialize(this.mainSeries)
            this.widgets.topHashtags().initialize(this.mainSeries)
            this.widgets.topLinks().initialize(this.mainSeries)
            this.widgets.topMedia().initialize(this.mainSeries)

            this.isInitialized = true
        },

        compareWith() {
            useQuickSearchStore().show({
                families: [ 'perspectives' ],
                limit: 50,
                onSelect: result => {
                    let comparedSeries = this.prepareSeries({ perspective: useMyPerspectivesStore().find(result.id) })

                    this.comparedSeries.push(comparedSeries)

                    this.widgets.keywords().compareWith(comparedSeries)
                    this.widgets.platformsShare().compareWith(comparedSeries)
                    this.widgets.sentimentShare().compareWith(comparedSeries)
                    this.widgets.sentimentTimeline().compareWith(comparedSeries)
                    this.widgets.timeline().compareWith(comparedSeries)
                    this.widgets.topChannels().compareWith(comparedSeries)
                    this.widgets.topDomains().compareWith(comparedSeries)
                    this.widgets.topHashtags().compareWith(comparedSeries)
                    this.widgets.topLinks().compareWith(comparedSeries)
                    this.widgets.topMedia().compareWith(comparedSeries)
                },
                initialResults: [
                    ...sortAlphabetically(useMyPerspectivesStore().items).map(item => ({
                        id: item.id,
                        resultType: 'perspective',
                        title: item.name
                    }))
                ]
            })
        },

        stopComparing(series) {
            this.comparedSeries = this.comparedSeries.filter(s => s.id != series.id)

            this.widgets.keywords().stopComparing(series)
            this.widgets.platformsShare().stopComparing(series)
            this.widgets.sentimentShare().stopComparing(series)
            this.widgets.sentimentTimeline().stopComparing(series)
            this.widgets.timeline().stopComparing(series)
            this.widgets.topChannels().stopComparing(series)
            this.widgets.topDomains().stopComparing(series)
            this.widgets.topHashtags().stopComparing(series)
            this.widgets.topLinks().stopComparing(series)
            this.widgets.topMedia().stopComparing(series)
        },

        prepareSeries({ name, perspective, filters, color }) {
            name = perspective?.name || 'Untitled Perspective'
            filters = filters || searchFilters().fromPerspective(perspective.filters)
            color = perspective ? (perspective.avatar.color || colorHash(perspective.name)) : '#2172f0'

            return { id: uuid(), name, perspective, filters, color }
        }
    }
})

export default useContentPerspectiveOverviewStore
