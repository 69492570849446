import { defineStore } from 'pinia'
import forceAtlas2 from "graphology-layout-forceatlas2"
import { animateNodes } from "sigma/utils"
import noverlap from "graphology-layout-noverlap"
import { circular } from "graphology-layout"


const useGraphLayoutStore = defineStore({
    id: 'graph-layout',

    state: () => ({
        graph: null,
        renderer: null,

        layoutAlgorithm: 'force-atlas',
        animationTimer: null,
        settings: null
    }),

    actions: {
        initialize(graph, renderer) {
            this.graph = graph
            this.renderer = renderer
        },

        layout(type) {
            this.stopLayout()

            switch (type) {
                case 'force-atlas':
                    this.layoutAlgorithm = 'force-atlas'
                    break

                case 'circular':
                    this.layoutAlgorithm = 'circular'
                    break

                case 'random':
                    this.layoutAlgorithm = 'random'
                    break

                case 'noverlap':
                    this.layoutAlgorithm = 'noverlap'
                    break

                default:
                    break
            }
        },

        stopLayout() {
            if (this.animationTimer) {
                clearInterval(this.animationTimer)
                this.animationTimer = null
            }
        },

        runForceAtlas2(step = false, settings = null) {
            this.stopLayout()

            let defaultSettings = forceAtlas2.inferSettings(this.graph)

            if (settings.gravity < 0) {
                settings.gravity = 0
            }

            if (settings) {
                defaultSettings = { ...defaultSettings, ...settings }
            }

            const animateLayout = (duration) => {
                const positions =  forceAtlas2(this.graph, { iterations: 1, settings: defaultSettings })
                animateNodes(this.graph, positions, { duration: duration, easing: 'linear' })
            }

            if (step) {
                animateLayout(500)
            } else {
                this.animationTimer = setInterval(() => { animateLayout(100) }, 100)
            }
        },

        applyForceAtlas(graph, iterations = 10, startRandom = true, noverlapEnabled = true) {
            if (startRandom) {
                this.randomPositionsSeed(graph)
            }

            // TODO same settings as in runForceAtlas2
            const layout_settings = {
                barnesHutOptimize: true,
                barnesHutTheta: 0.5,
                strongGravityMode: false,

                gravity: 1,
                scalingRatio: 5,
                linLogMode: false,

                adjustSizes: false,
                weighted: false,
                outboundAttractionDistribution: false,

                // slowDown: 1,
                // edgeWeightInfluence: 1,
            }

            forceAtlas2.assign(graph, { iterations: iterations, settings: layout_settings })

            if (noverlapEnabled) {
                // noverlap.assign(graph, {
                //     maxIterations: 5,
                //     settings: { ratio: 1, margin: 100, gridSize: 100 },
                //     inputReducer: (key, attr) => ({x: attr.x, y: attr.y, size: attr.size}),
                //     outputReducer: (key, pos) => {
                //         graph.updateNodeAttributes(key, attr => ({...attr, x: pos.x, y: pos.y}))
                //         return { x: pos.x, y: pos.y }
                //     }
                // })
            }
        },

        randomPositionsSeed(graph, animate = false) {
            const minValue = -1000
            const maxValue = 1000

            const uuidToNum = (str) => {
                const hexString = str.replace(/-/g, '')
                const hexValue = BigInt(`0x${hexString}`)
                const maxHexValue = BigInt('0xffffffffffffffffffffffffffffffff');
                const scaledValue = (hexValue * BigInt(maxValue - minValue + 1)) / maxHexValue
                return Number(scaledValue) + minValue
            }

            const positions = {}

            graph.forEachNode((n, a) => {
                if (animate) {
                    positions[n] = { x: uuidToNum(n), y: uuidToNum(n.split('').reverse().join('')) }
                } else {
                    a.x = uuidToNum(n)
                    a.y = uuidToNum(n.split('').reverse().join(''))
                }
            })

            if (animate) {
                animateNodes(graph, positions, { duration: 1000, easing: 'linear' });
            }
        },

        runLayout(settings = null) {
            this.stopLayout()

            switch (this.layoutAlgorithm) {
                case 'force-atlas':
                    this.runForceAtlas2(false, settings)
                    break

                case 'circular':
                    this.applyCircularLayout(this.graph)
                    break

                case 'random':
                    this.randomPositionsSeed(this.graph, true)
                    this.renderer.refresh()
                    break

                case 'noverlap':
                    this.runNoverlap()
                    break
            }
        },

        applyCircularLayout(graph) {
            const circularPositions = circular(graph, { scale: 1000 })
            animateNodes(graph, circularPositions, { duration: 1000, easing: "linear" })
        },

        runNoverlap(step = false) {
            this.stopLayout()

            const settings = { ratio: 1, margin: 100, gridSize: 100 }
            const animateLayout = (duration) => {
                const positions = noverlap(this.graph, { maxIterations: 1, settings: settings,
                    inputReducer: (key, attr) => ({ x: attr.x, y: attr.y, size: attr.size }),
                    outputReducer: (key, pos) => ({ x: pos.x, y: pos.y })
                })

                animateNodes(this.graph, positions, { duration: duration, easing: 'linear' })
            }

            if (step) {
                animateLayout(500)
            } else {
                this.animationTimer = setInterval(() => { animateLayout(100) }, 100)
            }
        },

        stepLayout(settings) {
            this.stopLayout()

            switch (this.layoutAlgorithm) {
                case 'force-atlas':
                    this.runForceAtlas2(true, settings)
                    break

                case 'noverlap':
                    this.runNoverlap(true)
                    break
            }
        },

    }
})

export default useGraphLayoutStore
