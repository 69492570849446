<template>
    <ui-modal name="channel-history" title="History">
        <history-records details></history-records>
    </ui-modal>
</template>

<script>
import HistoryRecords from '@/components/channels/channel/partials/history-records'

export default {
    components: { HistoryRecords }
}
</script>
