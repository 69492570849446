import { defineStore } from 'pinia'
import { content } from '@/helpers/api-three-tables'

export const useSharesStore = defineStore({
    id: 'contentDetailsShares',

    state: () => ({
        content: null,
        shares: [],
        sharesCount: null,

        sorting: 'date-desc',
        sort: null,

        isLoading: false,
        lastReloadTime: +new Date()
    }),

    actions: {
        async initialize(content) {
            this.content = content
            this.shares = []
            
            this.loadCount()

            this.lastReloadTime = +new Date()
        },

        async loadAdditionalShares(infiniteScroll) {
            this.isLoading = true

            await content()
                .sharedFrom(this.content.id)
                .when(this.shares.length && this.sort, c => c.after(this.sort))
                .sorting(this.sorting)
                .limit(30)
                .get((data, res) => {
                    this.shares.push(...data)
                    this.sort = res.sort

                    if (infiniteScroll) data.length ? infiniteScroll.loaded() : infiniteScroll.complete()

                    this.isLoading = false
                })
        },
        
        async loadCount() {
            await content()
                .sharedFrom(this.content.id)
                .count(count => this.sharesCount = count)
        },

        setSorting(sorting) {
            this.sorting = sorting
            this.shares = []

            this.loadAdditionalShares().then(() => this.lastReloadTime = +new Date())
        }
    }
})

export default useSharesStore
