import Grid from '@/components/ui/cards/layouts/grid'

import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyListsStore from '@/stores/me/channel-lists'
import defineCards from '@/stores/reusable/cards'

import { sortNaturally } from '@/helpers/sorting'

import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const useIndexPinnedCardsStore = defineCards({
    id: 'channelsIndexPinnedCards',

    source: defineStore({
        id: 'channelsIndexPinnedCardsSource',

        getters: {
            items(store) {
                return [
                    ...useMyBookmarksStore().items,
                    ...(useMyListsStore().items?.filter(l => l.pinned) ?? []),
                ]
            },

            isInitialized(store) {
                return useMyListsStore().isInitialized || useMyListsStore().isInitialized
            },

            isLoading(store) {
                return useMyListsStore().isLoading || useMyListsStore().isLoading
            }
        },

        actions: {
            initialize() {
                useMyBookmarksStore().initialize()
                useMyListsStore().initialize()
            }
        }
    }),

    layoutOptions: [
        {
            id: 'grid',
            component: markRaw(Grid),
            settings: { rows: 2 }
        }
    ],

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: true,
            apply: items => sortNaturally(items)
        }
    ]
})

export default useIndexPinnedCardsStore
