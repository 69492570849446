<template>
    <div class="ui-icon" :data-name="name" v-html="content"></div>
</template>

<script>
import { error } from '@/helpers/logger'

import icons from '@/icons'

export default {
    name: 'UIIcon',
    props: { name: {}, asImage: Boolean, filled: Boolean },
    computed: {
        content() {
            if (this.asImage) {
                return `<img src="data:image/svg+xml;base64,${btoa(icons[this.name])}">`
            }

            if (! icons[this.name]) {
                return error(`Icon not found "${this.name}".`)
            }

            return this.addStyles(this.makeIdsUnique(icons[this.name]))
        }
    },

    methods: {
        makeIdsUnique(svg) {
            let ids = svg.match(/id=".*?"/g)

            if (! ids) {
                return svg
            }

            ids.forEach(id => {
                let newId = `-svg-${Date.now()}` + Math.floor(Math.random() * 1000)
                let originalId = id.match(/id="(.*?)"/)[1]

                svg = svg.replace(new RegExp(id, 'g'), `id="${newId}"`)
                svg = svg.replace(new RegExp(`#${originalId}`, 'g'), `#${newId}`)
            })

            return svg
        },

        addStyles(svg) {
            return this.filled ? svg.replace(/<svg /, '<svg style="fill: currentColor;" ') : svg
        }
    }
}
</script>

<style>
.ui-icon {
    display: inline-block;
    height: 1em;
    width: 1em;
    vertical-align: -0.125em;
}

.ui-icon svg, .ui-icon img {
    display: block;
    height: 100%;
    width: 100%;
}
</style>
