<template>
    <search-filter name="Channels" :ids="['publishedBy', 'publishedInto']" :filters="filters" @opened="reset" :keep-open="keepOpen" no-header>
        <template v-slot:applied>
            <ui-icon name="navigation.channels" class="mr-1"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} channels
            </span>
            <span class="font-medium max-w-sm truncate" v-else>
                {{value.length ? value[0].name : value.name}}
            </span>
        </template>

        <template v-slot:options="{ close }">
            <div class="flex divide-x divide-gray-200 -mb-2">
                <div class="w-64 max-h-80 overflow-y-auto pb-2">
                    <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium">
                        <p>Published by</p>
                    </div>
                
                    <div v-if="! selectedSourcesToInclude.length && ! selectedSourcesToExclude.length" class="text-xs text-center my-4 mt-2 px-3">
                        <ui-icon name="arrow-up-circle" class="text-3xl text-gray-600"></ui-icon>
                        <p class="mt-1 text-gray-700">Filter content based on who published it, like a Facebook Page or Telegram User.</p>
                    </div>
        
                    <div v-if="selectedSourcesToInclude.length" class="text-xs font-medium text-gray-700 px-4 mb-1">
                        Including only content by
                    </div>
    
                    <ui-dropdown-link @click="deselectSource(source)" keep-open icon="none" :key="`${source.family}-${source.id}`" v-for="source in selectedSourcesToInclude">
                        <template v-slot:icon>
                            <div class="w-4 flex justify-center items-center text-center">
                                <ui-avatar family="lists" :name="source.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-if="source.type == 'list'"></ui-avatar>
                                <ui-avatar :name="source.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-else></ui-avatar>
                            </div>
                        </template>
                        <template v-slot:default>
                            <div class="flex items-center group">
                                {{source.name}}
                                <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                            </div>
                        </template>
                    </ui-dropdown-link>
    
                    <ui-dropdown-link v-if="selectedSourcesToInclude.length" icon="plus-circle" @click="selectSourceToInclude" keep-open>
                        Add channel...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectSourceToInclude" icon="filter" keep-open>
                        Include only content by...
                    </ui-dropdown-link>
    
                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>
    
                    <div v-if="selectedSourcesToExclude.length" class="text-xs font-medium text-gray-700 px-4 mb-1">
                        Excluding content by
                    </div>
    
                    <div class="max-h-80 overflow-y-auto">
                        <ui-dropdown-link @click="deselectSource(source)" keep-open icon="none" :key="`${source.family}-${source.id}`" v-for="source in selectedSourcesToExclude">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <ui-avatar family="lists" :name="source.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-if="source.type == 'list'"></ui-avatar>
                                    <ui-avatar :name="source.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-else></ui-avatar>
                                </div>
                            </template>
                            <template v-slot:default>
                                <div class="flex items-center group">
                                    {{source.name}}
                                    <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                                </div>
                            </template>
                        </ui-dropdown-link>
                    </div>
    
                    <ui-dropdown-link v-if="selectedSourcesToExclude.length" icon="plus-circle" @click="selectSourceToExclude" keep-open>
                        Add channel...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectSourceToExclude" icon="slash" keep-open>
                        Exclude content by...
                    </ui-dropdown-link>
                </div>
                
                <div class="w-64 max-h-80 overflow-y-auto pb-2">
                    <div class="flex items-center justify-between px-4 py-3 pb-2 font-medium">
                        <p>Published in</p>
                        <a href="#" @click.prevent="close">
                            <ui-icon name="x"></ui-icon>
                        </a>    
                    </div>
                    
                    <div v-if="! selectedTargetsToInclude.length && ! selectedTargetsToExclude.length" class="text-xs text-center my-4 mt-2 px-3">
                        <ui-icon name="arrow-down-circle" class="text-3xl text-gray-600"></ui-icon>
                        <p class="mt-1 text-gray-700">Filter content based on where it was published, like a Telegram Group.</p>
                    </div>
    
                    <div v-if="selectedTargetsToInclude.length" class="text-xs font-medium text-gray-700 px-4 mb-1">
                        Including only content in
                    </div>
    
                    <ui-dropdown-link @click="deselectTarget(target)" keep-open icon="none" :key="`${target.family}-${target.id}`" v-for="target in selectedTargetsToInclude">
                        <template v-slot:icon>
                            <div class="w-4 flex justify-center items-center text-center">
                                <ui-avatar family="lists" :name="target.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-if="target.type == 'list'"></ui-avatar>
                                <ui-avatar :name="target.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-else></ui-avatar>
                            </div>
                        </template>
                        <template v-slot:default>
                            <div class="flex items-center group">
                                {{target.name}}
                                <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                            </div>
                        </template>
                    </ui-dropdown-link>
    
                    <ui-dropdown-link v-if="selectedTargetsToInclude.length" icon="plus-circle" @click="selectTargetToInclude" keep-open>
                        Add channel...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectTargetToInclude" icon="filter" keep-open>
                        Include only content in...
                    </ui-dropdown-link>
    
                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>
    
                    <div v-if="selectedTargetsToExclude.length" class="text-xs font-medium text-gray-700 px-4 mb-1">
                        Excluding content in
                    </div>
    
                    <div class="max-h-80 overflow-y-auto">
                        <ui-dropdown-link @click="deselectTarget(target)" keep-open icon="none" :key="`${target.family}-${target.id}`" v-for="target in selectedTargetsToExclude">
                            <template v-slot:icon>
                                <div class="w-4 flex justify-center items-center text-center">
                                    <ui-avatar family="lists" :name="target.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-if="target.type == 'list'"></ui-avatar>
                                    <ui-avatar :name="target.name" class="w-4 h-4 drop-shadow-sm shrink-0" v-else></ui-avatar>
                                </div>
                            </template>
                            <template v-slot:default>
                                <div class="flex items-center group">
                                    {{target.name}}
                                    <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                                </div>
                            </template>
                        </ui-dropdown-link>
                    </div>
    
                    <ui-dropdown-link v-if="selectedTargetsToExclude.length" icon="plus-circle" @click="selectTargetToExclude" keep-open>
                        Add channel...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectTargetToExclude" icon="slash" keep-open>
                        Exclude content in...
                    </ui-dropdown-link>
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="filters.remove('publishedBy'); filters.remove('publishedInto'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-help-button page="channels-filter" class="text-2xl ml-auto mr-3"></ui-help-button>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers/sorting'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        selectedSources: [],
        selectedTargets: [],

        keepOpen: false
    }),

    computed: {
        value() {
            return [ ...(this.filters.value('publishedBy') || []), ...(this.filters.value('publishedInto') || []) ]
        },

        selectedSourcesToInclude() {
            return sortAlphabetically(this.selectedSources.filter(val => ! val.exclude))
        },

        selectedSourcesToExclude() {
            return sortAlphabetically(this.selectedSources.filter(val => val.exclude))
        },
        
        selectedTargetsToInclude() {
            return sortAlphabetically(this.selectedTargets.filter(val => ! val.exclude))
        },

        selectedTargetsToExclude() {
            return sortAlphabetically(this.selectedTargets.filter(val => val.exclude))
        }
    },

    methods: {
        selectSourceToInclude() {
            this.selectSource()
        },

        selectSourceToExclude() {
            this.selectSource(true)
        },

        selectSource(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'channels', 'lists' ],
                limit: 50,
                onSelect: result => {
                    if (! this.selectedSources.find(target => target.id == result.id)) this.selectedSources.push({
                        id: result.id,
                        type: result.resultType == 'list' ? 'list' : 'channel',
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                },
                initialResults: [
                    ...useMyBookmarksStore().items.map(item => ({
                        id: item.id,
                        family: 'channels',
                        resultType: 'channel',
                        type: item.type,
                        title: item.name,
                        image: item.image,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    })),
                    ...useMyChannelListsStore().items.map(item => ({
                        id: item.id,
                        family: 'lists',
                        resultType: 'list',
                        title: item.name
                    }))
                ]
            })
        },
        
        selectTargetToInclude() {
            this.selectTarget()
        },

        selectTargetToExclude() {
            this.selectTarget(true)
        },

        selectTarget(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'channels', 'lists' ],
                limit: 50,
                onSelect: result => {
                    if (! this.selectedTargets.find(target => target.id == result.id)) this.selectedTargets.push({
                        id: result.id,
                        type: result.resultType == 'list' ? 'list' : 'channel',
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                },
                initialResults: [
                    ...useMyBookmarksStore().items.map(item => ({
                        id: item.id,
                        family: 'channels',
                        resultType: 'channel',
                        type: item.type,
                        title: item.title,
                        image: item.image,
                        flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                        description: []
                    })),
                    ...useMyChannelListsStore().items.map(item => ({
                        id: item.id,
                        family: 'lists',
                        resultType: 'list',
                        title: item.name
                    }))
                ]
            })
        },

        deselectSource(source) {
            this.selectedSources = this.selectedSources.filter(f => f.id != source.id || f.type != source.type)
        },

        deselectTarget(target) {
            this.selectedTargets = this.selectedTargets.filter(f => f.id != target.id || f.type != target.type)
        },

        reset() {
            this.selectedSources = (this.filters.value('publishedBy') || []).map(source => ({
                ...source
            }))
            this.selectedTargets = (this.filters.value('publishedInto') || []).map(target => ({
                ...target
            }))
        },

        apply() {
            if (this.selectedSources.length) {
                this.filters.set('publishedBy', this.selectedSources.map(source => ({ ...source })))
            } else {
                this.filters.remove('publishedBy')
            }

            if (this.selectedTargets.length) {
                this.filters.set('publishedInto', this.selectedTargets.map(target => ({ ...target })))
            } else {
                this.filters.remove('publishedInto')
            }
        }
    }
}
</script>
