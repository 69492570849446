<template>
    <ui-modal name="content-hash" title="Content Hash" width="320">
        <div class="flex flex-col items-center text-center">
            <p>
                The hash for this content is:
            </p>

            <a href="#" class="mt-5 px-3 py-1 bg-gray-200 rounded-md font-medium" @click.prevent="copyHash" v-tooltip="'Copy to clipboard'">
                {{ item.hash }}
            </a>

            <p class="mt-5">
                This hash can be used to uniquely identify and verify this content's body.
            </p>
        </div>
    </ui-modal>
</template>

<script>
import useContentHashStore from '@/stores/content/modals/content-hash'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useContentHashStore, [ 'item' ])
    },

    methods: {
        copyHash() {
            navigator.clipboard.writeText(this.item.hash)
        }
    }
}
</script>
