<template>
    <div>
        <ui-cards :store="store">
            <template v-slot:item="{ item }">
                <content-card :content="item" :stream-store="store" :key="item.id"></content-card>
            </template>
        </ui-cards>

        <div v-if="store.items.error">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="frown" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">Ooops, something went wrong!</div>
                <div class="text-center text-sm">
                    <p>Looks like we are experiencing some technical issues (sorry about that).</p>
                    <p>Please try again later, if the problem persists, please <a class="underline" href="mailto:support@gerulata.com">contact us</a> for further assistance.</p>
                </div>
            </div>
        </div>

        <div v-if="! store.items.data?.length && ! store.items.pending && store.isInitialized">
            <div class="h-96 flex flex-col items-center justify-center text-gray-600">
                <ui-icon name="search" class="text-6xl text-gray-500"></ui-icon>
                <div class="text-base font-semibold mt-3 mb-2">We didn't find any results.</div>
                <div class="text-center text-sm">
                    <p>Check your spelling and try again, or try a different set of filters.</p>
                </div>
            </div>
        </div>

        <ui-infinite-loading class="mt-12 mb-8" :identifier="store.lastReloadTime" @infinite="store.loadMore($event)" v-if="store.items.data && store.items.data.length"></ui-infinite-loading>

        <Teleport to="#root">
            <stream-lightbox :store="store.mediaLightbox"></stream-lightbox>
        </Teleport>
    </div>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'
import StreamLightbox from '@/components/content/perspective/stream/lightbox'

export default {
    components: {
        ContentCard, StreamLightbox
    }
}
</script>
