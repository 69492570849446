import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyPerspectivesStore from '@/stores/me/perspectives'

export const useContentModalsPerspectiveDuplicateStore = defineForm({
    id: 'contentModalsPerspectiveDuplicate',

    inputs: () => ({
        perspective: {},
        name: null
    }),

    submitRequest() {
        trackEvent('content', 'duplicated-perspective-saved')

        return api.route('me perspectives duplicate', { id: this.inputs.perspective.id })
            .json({ name: this.inputs.name })
            .post()
    },

    async onResponse(res) {
        useMyPerspectivesStore().reload()

        useModal().hide('content-perspective-duplicate')
    },

    actions: {
        open(perspective = {}) {
            this.reset()

            this.inputs.perspective = perspective
            this.inputs.name = `${perspective.name} (copy)`

            useModal().show('content-perspective-duplicate')
        },

        cancel() {
            useModal().hide('content-perspective-duplicate')
        }
    }
})

export default useContentModalsPerspectiveDuplicateStore
