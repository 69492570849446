import useAuthLoginFormStore from '@/stores/auth/login-form'

import { defineForm } from '@/stores/reusable/form'

import api from '@/api'
import { useRouter } from '@/helpers'

export const useAuthPasswordRecoveryResetFormStore = defineForm({
    id: 'authPasswordRecoveryResetForm',

    inputs: () => ({
        email: null,
        password: null,
        passwordConfirmation: null,
        resetToken: null
    }),

    async submitRequest() {
        return api.route('auth password-recovery reset')
            .formData({
                email: this.inputs.email,
                password: this.inputs.password,
                passwordConfirmation: this.inputs.passwordConfirmation,
                resetToken: this.inputs.resetToken,
                product: 'juno'
            })
            .post()
            .error(400, err => {
                this.errors = { other: [ err.json.message ] }
                this.submitting = false
            })
    },

    async onResponse(res) {
        useAuthLoginFormStore().passwordWasReset = true
        useRouter().push({ name: 'login' })
    },

    actions: {
        setResetToken(token, email) {
            this.inputs.resetToken = token
            this.inputs.email = email
        }
    }
})

export default useAuthPasswordRecoveryResetFormStore
