<template>
    <div class="w-full px-6 mb-4">
        <ui-async :guards="connections.data || connections.error">
            <table class="w-full table-fixed divide-y divide-gray-100 text-gray-900 mb-3" v-if="connections.data && connections.data.length">
                <tr :key="index" v-for="connection, index in connections.data">
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium break-all">
                        <router-link :to="connectionUrl(connection)" class="flex items-center min-w-0">
                            <div class="shrink-0 relative" v-if="connection.channel">
                                <ui-avatar :item="connection.channel" class="w-7 h-7"></ui-avatar>
                                <ui-icon :name="connection.channel.monitored ? `badges.channel-${connection.channel.type}` : `badges.channel-${connection.channel.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-sm"></ui-icon>
                            </div>
                            <div class="shrink-0" v-else-if="connection.feature">
                                <ui-avatar :icon="$nodeType(connection.feature).icon" :color="{ background: 'bg-gray-300', icon: 'text-gray-900 text-xl' }" class="w-7 h-7" v-tooltip="$nodeType(connection.feature).name"></ui-avatar>
                            </div>
                        
                            <div class="truncate px-3" v-tooltip="connectionTitle(connection)">
                                {{ connectionTitle(connection) }}
                            </div>
                        </router-link>
                    </td>
                    <td class="w-12 py-2 font-bold text-right">
                        {{ connection.strength }}
                    </td>
                </tr>
            </table>
        
            <div class="py-8 text-center space-y-2 text-gray-700" v-else-if="connections.error">
                <p>We were unable to load connections for this Channel.</p>
                <p>
                    <a href="#" @click.prevent="connectionsStore.reload()" class="text-blue-500 hover:text-blue-800">Retry</a>
                </p>
            </div>
        
            <div class="py-20 text-gray-700 text-center text-sm" v-else>
                <p>No connections found.</p>
            </div>
            
            <template v-slot:loading>
                <div class="flex items-center justify-center py-14 pt-12 w-full h-full">
                    <ui-spinner v-bind="spinner"></ui-spinner>
                </div>
            </template>
        </ui-async>
    </div>
</template>

<script>
export default {
    props: [ 'connections', 'connectionsStore' ],

    methods: {
        connectionTitle(connection) {
            return connection.channel?.name || connection.feature?.name
        },

        connectionUrl(connection) {
            if (connection.channel) {
                return { name: 'channels.channel.information', params: { id: connection.channel.id } }
            } else if (connection.feature) {
                return { name: 'features.details', params: { id: connection.feature.id } }
            }
        }
    }
}
</script>
