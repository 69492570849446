<template>
    <ui-modal name="new-app" title="Token">
        <ui-form :store="newAppFormStore" v-slot="{ inputs, submitting, errors }">
            <div v-if="isSuccessful" class="flex flex-col items-center text-center py-6">
                <ui-icon name="check-circle" class="text-4xl text-green-500"></ui-icon>

                <h1 class="font-semibold text-lg mt-4">
                    A new Token has been created.
                </h1>

                <div class="mt-4">
                    Please make sure to save your token before closing this dialog, you will not be able to access it again later.
                </div>

                <div class="rounded-md bg-yellow-50 p-3 mt-4">
                    <div class="flex items-center">
                        <div class="shrink-0">
                            <ui-icon name="warning" class="text-yellow-600 text-lg"></ui-icon>
                        </div>
                        <div class="ml-3 flex-1 text-sm text-yellow-800 leading-snug">
                            This token has full access to your workspace's data, be careful to keep it protected.
                        </div>
                    </div>
                </div>

                <p class="mt-8 px-3 py-1 bg-gray-200 rounded-md font-medium text-xl">
                    {{ token }}
                </p>

                <div class="mt-4">
                    <ui-button color="blue" size="lg" :action="close">Done</ui-button>
                </div>
            </div>

            <div v-else>
                <label for="name" class="block text-sm font-medium text-gray-700">
                    Name
                </label>
                <div class="mt-2">
                    <ui-input id="name" name="name" :errors="errors.name" v-model="inputs.name"></ui-input>
                </div>

                <div class="flex justify-end space-x-4 mt-8">
                    <ui-button @click="close">
                        Cancel
                    </ui-button>

                    <ui-button type="submit" color="blue" :disabled="submitting">
                        Create
                    </ui-button>
                </div>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import { mapActions, mapState, mapStores } from 'pinia'

import useNewAppFormStore from "@/stores/settings/new-app-form"

export default {
    computed: {
        ...mapStores(useNewAppFormStore),

        ...mapState(useNewAppFormStore, [
            'isSuccessful',
            'token'
        ])
    },

    methods: {
        ...mapActions(useNewAppFormStore, [
            'close'
        ])
    }
}
</script>
