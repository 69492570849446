const nodeTypes = {
    // Content
    'facebook-group-post': { name: 'Facebook Group Post', icon: 'badges.channel-facebook-group', color: '#4267B2', platform: 'facebook', family: 'content' },
    'facebook-post': { name: 'Facebook Post', icon: 'badges.channel-facebook-page', color: '#4267B2', platform: 'facebook', family: 'content' },
    'facebook-post-comment': { name: 'Facebook Post Comment', icon: 'badges.channel-facebook-profile', color: '#4267B2', platform: 'facebook', family: 'content', isReply: true },
    'facebook-ad': { name: 'Facebook Ad', icon: 'badges.channel-facebook-page', color: '#4267B2', platform: 'facebook', family: 'content', isReply: false },
    '4chan-post': { name: '4Chan Post', icon: 'badges.channel-4chan-board', color: '#45C159', platform: '4chan', family: 'content' },
    'instagram-post': { name: 'Instagram Post', icon: 'badges.channel-instagram-profile', color: '#C13584', platform: 'instagram', family: 'content' },
    'instagram-comment': { name: 'Instagram Comment', icon: 'badges.channel-instagram-profile', color: '#C13584', platform: 'instagram', family: 'content', isReply: true },
    'reddit-comment': { name: 'Reddit Comment', icon: 'badges.channel-reddit-user', color: '#FF4500', platform: 'reddit', family: 'content', isReply: true },
    'reddit-post': { name: 'Reddit Post', icon: 'badges.channel-reddit-user', color: '#FF4500', platform: 'reddit', family: 'content' },
    'telegram-channel-post': { name: 'Telegram Channel Post', icon: 'badges.channel-telegram-channel', color: '#4798BB', platform: 'telegram', family: 'content' },
    'telegram-message': { name: 'Telegram Message', icon: 'badges.channel-telegram-user', color: '#4798BB', platform: 'telegram', family: 'content' },
    'tik-tok-video': { name: 'TikTok Video', icon: 'badges.channel-tik-tok-user', color: '#FF0050', platform: 'tik-tok', family: 'content' },
    'tik-tok-comment': { name: 'TikTok Comment', icon: 'badges.channel-tik-tok-user', color: '#FF0050', platform: 'tik-tok', family: 'content', isReply: true },
    'vkontakte-post': { name: 'VKontakte Post', icon: 'badges.channel-vkontakte-user', color: '#CD0000', platform: 'vkontakte', family: 'content' },
    'web-feed-article': { name: 'Web Feed Article', icon: 'badges.channel-web-feed', color: '#F49E28', platform: 'web-feeds', family: 'content' },
    'web-site-article': { name: 'Web Site Article', icon: 'badges.channel-web-site', color: '#12D31F', platform: 'web-sites', family: 'content' },
    'x-post': { name: 'X Post', icon: 'badges.channel-x-user', color: '#01C5D3', platform: 'x', family: 'content' },
    'youtube-video': { name: 'YouTube Video', icon: 'badges.channel-youtube-channel', color: '#F14539', platform: 'youtube', family: 'content' },
    'youtube-video-comment': { name: 'YouTube Video Comment', icon: 'badges.channel-youtube-channel', color: '#F14539', platform: 'youtube', family: 'content', isReply: true },
    '2ch-post': { name: '2Ch Post', icon: 'badges.channel-2ch-board', color: '#45C159', platform: '2ch', family: 'content' },

    // Channels
    'facebook-group': { name: 'Facebook Group', icon: 'badges.channel-facebook-group', color: '#4267B2', defaultRole: 'published-in', platform: 'facebook', family: 'channels', monitorable: true },
    'facebook-page': { name: 'Facebook Page', icon: 'badges.channel-facebook-page', color: '#4267B2', defaultRole: 'published-by', platform: 'facebook', family: 'channels', monitorable: true },
    'facebook-profile': { name: 'Facebook Profile', icon: 'badges.channel-facebook-profile', color: '#4267B2', defaultRole: 'published-by', platform: 'facebook', family: 'channels', monitorable: false },
    '4chan-board': { name: '4Chan Board', icon: 'badges.channel-4chan-board', color: '#45C159', defaultRole: 'published-in', platform: '4chan', family: 'channels', monitorable: true },
    '4chan-user': { name: '4Chan User', icon: 'badges.channel-4chan-board', color: '#45C159', defaultRole: 'published-by', platform: '4chan', family: 'channels', monitorable: false },
    'instagram-profile': { name: 'Instagram Profile', icon: 'badges.channel-instagram-profile', color: '#C13584', defaultRole: 'published-by', platform: 'instagram', family: 'channels', monitorable: true },
    'reddit-subreddit': { name: 'Reddit Subreddit', icon: 'badges.channel-reddit-subreddit', color: '#FF4500', defaultRole: 'published-in', platform: 'reddit', family: 'channels', monitorable: true },
    'reddit-user': { name: 'Reddit User', icon: 'badges.channel-reddit-user', color: '#FF4500', defaultRole: 'published-by', platform: 'reddit', family: 'channels', monitorable: true },
    'telegram-channel': { name: 'Telegram Channel', icon: 'badges.channel-telegram-channel', color: '#4798BB', defaultRole: 'published-by', platform: 'telegram', family: 'channels', monitorable: true },
    'telegram-group': { name: 'Telegram Group', icon: 'badges.channel-telegram-group', color: '#4798BB', defaultRole: 'published-in', platform: 'telegram', family: 'channels', monitorable: true },
    'telegram-user': { name: 'Telegram User', icon: 'badges.channel-telegram-user', color: '#4798BB', defaultRole: 'published-by', platform: 'telegram', family: 'channels', monitorable: false },
    'tik-tok-user': { name: 'TikTok User', icon: 'badges.channel-tik-tok-user', color: '#FF0050', defaultRole: 'published-by', platform: 'tik-tok', family: 'channels', monitorable: true },
    'vkontakte-community': { name: 'VKontakte Community', icon: 'badges.channel-vkontakte-community', color: '#CD0000', defaultRole: 'published-in', platform: 'vkontakte', family: 'channels', monitorable: true },
    'vkontakte-user': { name: 'VKontakte User', icon: 'badges.channel-vkontakte-user', color: '#CD0000', defaultRole: 'published-by', platform: 'vkontakte', family: 'channels', monitorable: true },
    'web-feed': { name: 'Web Feed', icon: 'badges.channel-web-feed', color: '#F49E28', defaultRole: 'published-by', platform: 'web-feeds', family: 'channels', monitorable: true },
    'web-site': { name: 'Web Site', icon: 'badges.channel-web-site', color: '#12D31F', defaultRole: 'published-by', platform: 'web-sites', family: 'channels', monitorable: true },
    'x-user': { name: 'X User', icon: 'badges.channel-x-user', color: '#01C5D3', defaultRole: 'published-by', platform: 'x', family: 'channels', monitorable: true },
    'youtube-channel': { name: 'Youtube Channel', icon: 'badges.channel-youtube-channel', color: '#F14539', defaultRole: 'published-by', platform: 'youtube', family: 'channels', monitorable: true },
    '2ch-board': { name: '2Ch Board', icon: 'badges.channel-2ch-board', color: '#45C159', defaultRole: 'published-in', platform: '2ch', family: 'channels', monitorable: true },
    '2ch-user': { name: '2Ch User', icon: 'badges.channel-2ch-board', color: '#45C159', defaultRole: 'published-by', platform: '2ch', family: 'channels', monitorable: false },

    // Features
    'place': { name: 'Place', icon: 'location', family: 'features' },
    'ahash-fingerprint': { name: 'AHASH Fingerprint', icon: 'finger-print', family: 'features' },
    'crop-resistant-fingerprint': { name: 'Crop Resistant Fingerprint', icon: 'finger-print', family: 'features' },
    'dhash-fingerprint': { name: 'DHASH Fingerprint', icon: 'finger-print', family: 'features' },
    'pdq-fingerprint': { name: 'PDQ Fingerprint', icon: 'finger-print', family: 'features' },
    'phash-fingerprint': { name: 'PHASH Fingerprint', icon: 'finger-print', family: 'features' },
    'tmk-pdqf-fingerprint': { name: 'TMK PDQF Fingerprint', icon: 'finger-print', family: 'features' },
    'whash-fingerprint': { name: 'WHASH Fingerprint', icon: 'finger-print', family: 'features' },
    'bank-account': { name: 'Bank Account', icon: 'euro', family: 'features' },
    'domain': { name: 'Domain', icon: 'compass', family: 'features' },
    'email': { name: 'Email', icon: 'email', family: 'features' },
    'hashtag': { name: 'Hashtag', icon: 'hash', family: 'features' },
    'hostname': { name: 'Hostname', icon: 'compass', family: 'features' },
    'person-name': { name: 'Person Name', icon: 'person', family: 'features' },
    'phone-number': { name: 'Phone Number', icon: 'phone', family: 'features' },
    'url': { name: 'URL', icon: 'link', family: 'features' },
    'username': { name: 'Username', icon: 'user', family: 'features' },

    // Media
    'image': { name: 'Image', family: 'media' },
    'video': { name: 'Video', family: 'media' },
    'audio': { name: 'Audio', family: 'media' },
    'document': { name: 'Document', family: 'media' }
}

export function nodeType(type) {
    return nodeTypes[type?.type || type] || {}
}

export function nodeTypeIds(filter) {
    return Object.entries(nodeTypes).filter(([ id, type ]) => filter(type)).map(([ id ]) => id)
}
