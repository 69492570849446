import defineCards from '@/stores/reusable/cards'
import useMyAlertsStore from '@/stores/me/alerts'

import { asyncResource } from '@/api'
import { sortByDate } from '@/helpers/sorting'

import { defineStore } from 'pinia'

export const useAlertsIndexCardsAlertStore = defineCards({
    id: 'alertsIndexCardsAlert',

    source: defineStore({
        id: 'alertsIndexCardsAlertSource',

        state: () => ({
            params: {},
            paginated: true,

            resource: asyncResource({
                request: (api, store, payload) => api.route('me alerts triggers', { alertId: store.params.id })
                    .query(payload)
            })
        }),

        getters: {
            items(store) {
                return store.resource.data || []
            },

            isLoading(store) {
                return store.resource.isFetchingFirst
            }
        },

        actions: {
            initialize(params) {
                this.params = params
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            reload() {
                this.resource.reset()
                this.resource.fetchFirst(this)
            },

            async loadMore(infiniteScroll) {
                let items = await this.resource.fetchNext(this)

                items.length ? infiniteScroll.loaded() : infiniteScroll.complete()
            }
        }
    }),

    layout: 'table',
    searchable: true,

    sortingOptions: [
        {
            id: 'latest', name: 'Latest', icon: 'calendar', default: true,
            apply: items => sortByDate(items, 'createdAt').reverse()
        }
    ],

    getters: {
        alert: store => useMyAlertsStore().find(store.params.id)
    }
})

export default useAlertsIndexCardsAlertStore
