import defineCards from '@/stores/reusable/cards'
import useMyAlertsStore from '@/stores/me/alerts'

import { sortAlphabetically, sortByDate } from '@/helpers/sorting'

export const useAlertsIndexCardsAlertsStore = defineCards({
    id: 'alertsIndexCardsAlerts',

    source: useMyAlertsStore,
    layout: 'table',
    searchable: true,
    filterable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => sortByDate(items, 'createdAt').reverse()
        }
    ]
})

export default useAlertsIndexCardsAlertsStore
