<template>
    <div>
        <h1 class="text-gray-700 text-xl font-medium leading-none px-1 my-6 mb-4">Account</h1>

        <div v-if="loading" class="h-72 flex flex-col items-center justify-center text-gray-600">
            <ui-spinner class="mb-2"></ui-spinner>
            <p>Loading your settings...</p>
        </div>

        <div v-if="accountSettingsFormStore.submitted" class="border border-green-300 bg-green-50 text-green-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
            Profile successfully updated.
        </div>

        <div v-if="accountSettingsFormStore.errors.current_password" class="border border-red-300 bg-red-50 text-red-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
            The current confirmation password is not correct.
        </div>

        <div v-if="socialLoginError" class="border border-red-300 bg-red-50 text-red-900 px-4 py-2 text-sm mx-auto mb-2 rounded-sm">
            {{socialLoginError}}
        </div>

        <ui-form :store="accountSettingsFormStore" v-slot="{ inputs, submitting, errors }" class="rounded-lg bg-white border border-gray-200 shadow-sm divide-y divide-gray-200">
            <div class="md:grid md:grid-cols-3 md:gap-6 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Profile</h3>
                    <p class="mt-1 text-gray-700">
                        Change your public information like a name, email or set a profile photo.
                    </p>
                </div>
                <div class="flex mt-5 md:mt-0 md:col-span-2 space-x-8">
                    <div class="flex-1 space-y-8">
                        <div>
                            <label for="name" class="block text-sm font-medium text-gray-700">
                                Name
                            </label>
                            <div class="mt-1">
                                <ui-input type="text" name="name" id="name" v-model="inputs.name" :errors="errors.name"></ui-input>
                            </div>
                        </div>

                        <div>
                            <label for="email" class="block text-sm font-medium text-gray-700">
                                E-mail
                            </label>
                            <div class="mt-1">
                                <ui-input type="email" name="email" id="email" v-model="inputs.email" :errors="errors.email" @change="requirePasswordConfirmation"></ui-input>
                            </div>
                        </div>

                        <div>
                            <label for="phoneNumber" class="block text-sm font-medium text-gray-700">
                                Phone Number
                            </label>
                            <div class="mt-1">
                                <ui-input type="tel" name="phoneNumber" id="phoneNumber" v-model="inputs.phoneNumber" :errors="errors.phoneNumber"></ui-input>
                            </div>
                        </div>
                    </div>

                    <div class="w-32">
                        <label class="block text-sm font-medium text-gray-700 text-center">
                            Photo
                        </label>
                        <div class="mt-3">
                            <ui-avatar-upload name="profile_photo" :src="currentProfilePhotoUrl" v-model="inputs.profile_photo"></ui-avatar-upload>
                        </div>
                    </div>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 pt-8 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Password</h3>
                    <p class="mt-1 text-gray-700">
                        Change your password, or not.
                    </p>
                </div>

                <div class="mt-5 md:mt-0 md:col-span-2 space-y-8">
                    <div>
                        <label for="password" class="block text-sm font-medium text-gray-700">New Password</label>
                        <div class="mt-1">
                            <ui-input type="password" name="password" id="password" autocomplete="new-password" v-model="inputs.password" :errors="errors.password" @change="requirePasswordConfirmation"></ui-input>
                        </div>
                    </div>

                    <div v-if="inputs.password">
                        <label for="password_confirmation" class="block text-sm font-medium text-gray-700">Confirm New Password</label>
                        <div class="mt-1">
                            <ui-input type="password" name="password_confirmation" id="password_confirmation" v-model="inputs.password_confirmation" :errors="errors.password_confirmation"></ui-input>
                        </div>
                    </div>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 pt-8 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Two-factor authentication</h3>
                    <p class="mt-1 text-gray-700">
                        Additional layer of security using an authenticator app on your phone.
                    </p>
                </div>

                <div class="mt-5 md:mt-0 md:col-span-2">
                    <ui-button v-if="! hasTwoFactorAuth" color="blue" size="lg" @click="openTwoFactorAuthModal">Add two-factor authentication</ui-button>

                    <ui-button v-else color="red" size="lg" @click="openTwoFactorAuthModal">Remove two-factor authentication</ui-button>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 pt-8 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Gerulata Connect</h3>
                    <p class="mt-1 text-gray-700">
                        Quick and secure sign-in via Facebook.
                    </p>
                </div>

                <div class="mt-5 md:mt-0 md:col-span-2 space-y-8">
                    <div class="mx-auto max-w-md">
                        <button v-if="hasFacebookLogin" @click.prevent="disconnectSocial" class="w-full inline-flex items-center justify-center py-3 px-4 rounded-md shadow-sm bg-gerulata-blue-600 font-medium text-gerulata-blue-50 hover:bg-gerulata-blue-700 shadow-md disabled:opacity-50" :disabled="! $hasFeatureTo(['register-via-email'])">
                            <ui-icon name="g" class="mr-2 text-2xl"></ui-icon>
                            <span>Disconnect Gerulata Connect</span>
                        </button>
                        <button v-else @click.prevent="connectSocial" class="w-full inline-flex items-center justify-center py-3 px-4 rounded-md shadow-sm bg-gerulata-blue-600 font-medium text-gerulata-blue-50 hover:bg-gerulata-blue-700 shadow-md">
                            <ui-icon name="g" class="mr-2 text-2xl"></ui-icon>
                            <span>Sign in with Gerulata Connect</span>
                        </button>
                    </div>
                </div>
            </div>
            
            <div class="md:grid md:grid-cols-3 md:gap-6 pt-8 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Newsletter</h3>
                    <p class="mt-1 text-gray-700">
                        Be the first to know about new features and products.
                    </p>
                </div>

                <div class="mt-5 md:mt-0 md:col-span-2 space-y-4">
                    <div>
                        <div class="flex items-center space-x-5">
                            <input v-if="! loadingSubscriptions && inputs.newsletter_subscription !== null" type="checkbox" id="newsletter_subscription" class="h-4 w-4 text-blue-600 border-gray-300" v-model="inputs.newsletter_subscription">
                            <ui-spinner v-else type="clip" class="mr-2"></ui-spinner>
                            <label for="newsletter_subscription" class="block text-gray-700">
                                I want to receive short email updates about new releases, features, improvements, fixes, new products or research projects and general news. (Highly recommended.) 
                            </label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 pt-8 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Preferences</h3>
                    <p class="mt-1 text-gray-700">
                        Change your platform settings.
                    </p>
                </div>

                <div class="mt-5 md:mt-0 md:col-span-2 space-y-8">
                    <div>
                        <label for="timezone" class="block text-sm font-medium text-gray-700">Timezone</label>
                        <ui-select :options="timezones" v-model="inputs.timezone" width="w-80" form></ui-select>
                    </div>

                    <div>
                        <label for="preferred_content_language" class="block text-sm font-medium text-gray-700">Preferred search language</label>
                        <ui-select :options="languages" v-model="inputs.preferred_content_language" form></ui-select>
                    </div>

                    <div>
                        <ui-button :color="hasNativeNotificationsEnabled ? 'green' : 'blue'" size="lg" :disabled="hasNativeNotificationsEnabled" @click="enableNativeNotifications">
                            {{ hasNativeNotificationsEnabled ? 'Desktop notifications allowed' : 'Allow desktop notifications' }}
                        </ui-button>
                    </div>
                </div>
            </div>

            <div class="md:grid md:grid-cols-3 md:gap-6 pt-8 px-4 py-5 sm:p-8">
                <div class="md:col-span-1">
                    <h3 class="text-lg font-medium leading-6">Sessions</h3>
                    <p class="mt-1 text-gray-700">
                        These are your currently active Juno sessions.
                    </p>
                    <p class="mt-1 text-gray-700">
                        Old sessions expire on their own or can be closed manually here.
                    </p>
                </div>

                <ui-async :guards="sessions.data" class="mt-5 md:mt-0 md:col-span-2 space-y-2">
                    <div v-for="token in sessions.data" :key="token.id" class="flex items-center gap-x-3">
                        <div class="shrink-0">
                            <ui-icon :name="token.mobile ? 'smartphone' : 'monitor'" class="text-xl"></ui-icon>
                        </div>
                        <div class="flex-1">
                            <div>{{token.name}}</div>
                        </div>
                        <div class="text-xs text-gray-500">
                            <span v-tooltip="`Logged in at ${$dateTime(token.createdAt)}.`">
                                <span v-if="token.current" class="font-semibold">Active session</span>
                                <span v-else-if="token.lastUsedAt">Last used at {{$dateTime(token.lastUsedAt)}}</span>
                                <span v-else>Not used yet</span>
                            </span>
                        </div>
                        <div v-if="! token.current">
                            <a href="#" @click.prevent="closeSession(token.id)">
                                <ui-icon name="remove"></ui-icon>
                            </a>
                        </div>
                    </div>
                </ui-async>
            </div>

            <div class="flex justify-end px-4 py-5 sm:p-8 rounded-b-lg border-t border-gray-100">
                <ui-button type="submit" size="lg" color="blue" :action="save" :disabled="submitting">
                    Save changes
                </ui-button>
            </div>

            <Teleport to="#root">
                <password-confirmation-modal></password-confirmation-modal>
            </Teleport>
        </ui-form>

        <Teleport to="#root">
            <two-factor-auth-modal></two-factor-auth-modal>
        </Teleport>
    </div>
</template>

<script>
import { mapStores, mapState, mapActions } from 'pinia'

import PasswordConfirmationModal from '../modals/password-confirmation'
import TwoFactorAuthModal from '@/components/settings/modals/two-factor-auth'

import useAccountSettingsStore from '@/stores/settings/account'
import useAccountSettingsFormStore from '@/stores/settings/account-form'
import useAccountSettingsSocialLoginStore from '@/stores/settings/account-social-login'
import useTwoFactorAuthFormStore from '@/stores/settings/two-factor-auth-form'

export default {
    components: { PasswordConfirmationModal, TwoFactorAuthModal },

    methods: {
        ...mapActions(useAccountSettingsStore, [ 'closeSession', 'enableNativeNotifications' ]),
        ...mapActions(useAccountSettingsFormStore, [ 'requirePasswordConfirmation', 'save' ]),
        ...mapActions(useAccountSettingsSocialLoginStore, {
            connectSocial: 'connect',
            disconnectSocial: 'disconnect'
        }),
        ...mapActions(useTwoFactorAuthFormStore, {
            openTwoFactorAuthModal: 'open'
        })
    },

    computed: {
        ...mapStores(useAccountSettingsFormStore),
        ...mapState(useAccountSettingsStore, [
            'loading',
            'loadingSubscriptions',
            'currentProfilePhotoUrl',
            'hasTwoFactorAuth',
            'hasFacebookLogin',
            'hasNativeNotificationsEnabled',
            'timezones',
            'languages',
            'sessions'
        ]),
        ...mapState(useAccountSettingsSocialLoginStore, {
            socialLoginError: 'error'
        })
    }
}
</script>
