<template>
    <ui-modal name="channel-details-text" :title="label">
        <p>{{value}}</p>
    </ui-modal>
</template>

<script>
import useChannelDetailsText from '@/stores/channels/modals/channel-details-text'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useChannelDetailsText, [
            'label', 'value'
        ])
    }
}
</script>
