<template>
    <div class="min-h-48 flex items-center justify-center pt-2">
        <settings-digest v-if="store.alert.type == 'digest'" :store="store"></settings-digest>
        <settings-spike-detection v-else-if="store.alert.type == 'spike-detection'" :store="store"></settings-spike-detection>
        <settings-new-publishers v-else-if="store.alert.type == 'new-publishers'" :store="store"></settings-new-publishers>
        <settings-sentiment-shift v-else-if="store.alert.type == 'sentiment-shift'" :store="store"></settings-sentiment-shift>
        <settings-audience-change v-else-if="store.alert.type == 'audience-change'" :store="store"></settings-audience-change>
        <settings-follow-content v-else-if="store.alert.type == 'follow-content'" :store="store"></settings-follow-content>

        <p v-else class="text-gray-600">
            This alert type does not have any additional settings.
        </p>
    </div>
</template>

<script>
import SettingsDigest from './edit-settings-digest'
import SettingsSpikeDetection from './edit-settings-spike-detection'
import SettingsNewPublishers from './edit-settings-new-publishers'
import SettingsSentimentShift from './edit-settings-sentiment-shift'
import SettingsAudienceChange from './edit-settings-audience-change'
import SettingsFollowContent from './edit-settings-follow-content'

export default {
    components: {
        SettingsDigest, SettingsSpikeDetection, SettingsNewPublishers, SettingsSentimentShift, SettingsAudienceChange,
        SettingsFollowContent
    }
}
</script>
