<template>
    <div class="flex h-12 group relative">
        <div class="pl-2 flex items-center flex-1 min-w-0">
            <div class="bg-gray-400 rounded-full w-7 h-7 drop-shadow-sm shrink-0"></div>

            <div class="min-w-0 px-2">
                <div class="bg-gray-400 rounded-full h-5 w-32"></div>
            </div>
        </div>

        <div class="flex items-center space-x-3 px-3">
            <div class="w-20">
                <div class="bg-gray-400 rounded-full h-3 w-16"></div>
            </div>

            <div class="w-20">
                <div class="bg-gray-400 rounded-full h-3 w-16"></div>
            </div>

            <div class="w-20">
                <div class="bg-gray-400 rounded-full h-3 w-16"></div>
            </div>

            <div class="w-20">
                <div class="bg-gray-400 rounded-full h-3 w-16"></div>
            </div>
        </div>

        <div class="flex items-center px-3">
        </div>
    </div>
</template>
