import useAnalysisAnalysesChannelAudienceStore from "@/stores/analysis/analyses/channel-audience"
import useAnalysisAnalysesChannelPublishTimesStore from "@/stores/analysis/analyses/channel-publish-times"
import useAnalysisAnalysesContentPerformanceStore from '@/stores/analysis/analyses/content-performance'
import useAnalysisAnalysesContentPublishTimesStore from "@/stores/analysis/analyses/content-publish-times"
import useAnalysisAnalysesContentPullPushStore from "@/stores/analysis/analyses/content-pull-push"
import useAnalysisAnalysesContentSentimentStore from "@/stores/analysis/analyses/content-sentiment"
import useAnalysisAnalysesContentTopChannelsStore from '@/stores/analysis/analyses/content-top-channels'
import useAnalysisAnalysesContentTopPlatformsStore from '@/stores/analysis/analyses/content-top-platforms'
import useAnalysisAnalysesContentKeywordsStore from "@/stores/analysis/analyses/content-keywords"
import useAnalysisDatasetCardsStore from '@/stores/analysis/dataset-cards'
import useAnalysisTypes from "@/stores/analysis/analysis-types"
import useMyAnalysesStore from '@/stores/me/analyses'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyTopicsStore from '@/stores/me/topics'

import { defineStore } from 'pinia'

export const useAnalysisMyAnalysesAnalysisPage = defineStore({
    id: 'analysisMyAnalysesAnalysisPage',

    state: () => ({
        type: null,
        analysis: null,
        isNew: false
    }),

    getters: {
        title: store => store.analysis ? `${store.analysis.name} | Analysis` : '',

        store(store) {
            if (! store.type) return

            if (store.type.id == 'content-performance') {
                return useAnalysisAnalysesContentPerformanceStore()
            } else if (store.type.id == 'content-publish-times') {
                return useAnalysisAnalysesContentPublishTimesStore()
            } else if (store.type.id == 'content-pull-push') {
                return useAnalysisAnalysesContentPullPushStore()
            } else if (store.type.id == 'content-sentiment') {
                return useAnalysisAnalysesContentSentimentStore()
            } else if (store.type.id == 'content-top-channels') {
                return useAnalysisAnalysesContentTopChannelsStore()
            } else if (store.type.id == 'content-top-platforms') {
                return useAnalysisAnalysesContentTopPlatformsStore()
            } else if (store.type.id == 'content-keywords') {
                return useAnalysisAnalysesContentKeywordsStore()
            } else if (store.type.id == 'channel-audience') {
                return useAnalysisAnalysesChannelAudienceStore()
            } else if (store.type.id == 'channel-publish-times') {
                return useAnalysisAnalysesChannelPublishTimesStore()
            }
        }
    },

    actions: {
        async beforeEnter(to) {
            this.type = useAnalysisTypes().find(to.params.type)

            this.store.analysisSeries = [] // If we don't reset this first we crash in some getter...
            this.store.$reset()
            this.store.fromQuery(to.query)

            this.isNew = to.params.id == 'new'

            useMyAnalysesStore().initialize().then(() => this.initializeAnalysis(to))
            useMyChannelListsStore().load()
            useMyPerspectivesStore().load()
            useMyTopicsStore().load()
        },

        beforeLeave() {
            this.store.abort()

//            useMyNotificationsStore().removeOnToast('analysis-series')
        },

        initializeAnalysis(to) {
            useAnalysisDatasetCardsStore().setAnalysisStore(this.store)

            if (to.params.id == 'new') return

            this.analysis = useMyAnalysesStore().find(to.params.id)
            if (this.analysis?.lastUsedAt) { this.analysis.lastUsedAt = new Date }

            // we don't really need to load more details here, but this updates the last used date
            useMyAnalysesStore().loadDetails(to.params.id)

            this.store.initialize(this.analysis)

//            useMyNotificationsStore().onToast('analysis-series', toast => toast.meta?.analysisId != this.analysis.id)
        }
    }
})

export default useAnalysisMyAnalysesAnalysisPage
