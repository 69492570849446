import useAudienceStore from '@/stores/channels/channel/audience'
import useChannelStreamStore from '@/stores/channels/channel/stream'
import useConnectionsStore from '@/stores/channels/channel/connections'
import useHistoryStore from '@/stores/channels/channel/history'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useTaggingStore from '@/stores/tagging/tagging'

import defineChannelMetricsStore from '@/stores/channels/channel/metrics'
import defineContentMetricsStore from '@/stores/content/perspective/overview/timeline'
import defineHeatmapStore from '@/stores/channels/channel/heatmap'
import defineKeywordsStore from '@/stores/content/perspective/overview/keywords'
import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox'
import defineTopDomainsStore from '@/stores/content/perspective/overview/top-domains'
import defineTopHashtagsStore from '@/stores/content/perspective/overview/top-hashtags'
import defineTopLinksStore from '@/stores/content/perspective/overview/top-links'
import defineTopMediaStore from '@/stores/content/perspective/overview/top-media'

import { channels } from '@/api'
import searchFilters from '@/helpers/search-filters'
import { nodeType } from '@/helpers/types'

import { defineStore } from 'pinia'

export const useChannelsChannelPage = defineStore({
    id: 'channelsChannelPage',

    state: () => ({
        channel: null,

        selectedTab: 'inspect',
        selectedMode: 'published-by',

        filters: searchFilters({ values: { date: { type: 'past', date: { past: 1, unit: 'years' } } } }),

        channelMetricsWidget: defineChannelMetricsStore({ id: 'channelChannelMetricsWidget' }),
        heatmapWidget: defineHeatmapStore({ id: 'channelHeatmapWidget' }),
        keywordsWidget: defineKeywordsStore({ id: `channelKeywordsWidget` }),
        contentMetricsWidget: defineContentMetricsStore({ id: `channelContentMetricsWidget` }),
        topDomainsWidget: defineTopDomainsStore({ id: `channelTopDomainsWidget` }),
        topHashtagsWidget: defineTopHashtagsStore({ id: `channelTopHashtagsWidget` }),
        topLinksWidget: defineTopLinksStore({ id: `channelTopLinksWidget` }),
        topMediaWidget: defineTopMediaStore({ id: `channelTopMediaWidget` }),

        lightbox: defineStreamMediaLightbox({ id: 'channelMediaLightbox', name: 'channelMediaLightbox' })
    }),

    getters: {
        title: store => store.channel ? `${store.channel.name} | Channels` : '',

        newPerspectiveLink: store => ({
            name: 'content.perspectives.perspective',
            params: { perspectiveId: 'new' },
            query: { 'filter[date]': 'past:1|months', [`filter[${store.selectedMode == 'published-in' ? 'publishedInto' : 'publishedBy'}]`]: `channel|${store.channel.id}|${store.channel.name}` }
        })
    },

    actions: {
        setup() {
            this.filters.onChange = () => this.loadWidgets()
        },

        async afterEnter(to) {
            useMyBookmarksStore().initialize()
            useMyChannelListsStore().initialize()
            useMyCuratedListsStore().initialize()
            useTaggingStore().initialize()

            await this.load(to.params.id)

            useConnectionsStore().load(this.channel)
            useAudienceStore().load(this.channel)
            useHistoryStore().load(this.channel)

            this.loadWidgets()
        },

        async afterLeave() {
            this.$reset()
            this.setup()
        },

        async load(id, force) {
            this.channel = await channels().include([ 'lists', 'monitoringOptions', 'media', 'notes', 'alerts' ]).find(id)

            this.selectedMode = nodeType(this.channel).defaultRole
        },

        loadWidgets() {
            this.contentMetricsWidget().initialize(this.channelSeries())
            this.channelMetricsWidget().initialize(this.channelSeries())
            this.keywordsWidget().initialize(this.channelSeries())
            this.heatmapWidget().initialize(this.channelSeries())
            this.topDomainsWidget().initialize(this.channelSeries())
            this.topLinksWidget().initialize(this.channelSeries())
            this.topHashtagsWidget().initialize(this.channelSeries())
            this.topMediaWidget().initialize(this.channelSeries())

            useChannelStreamStore().initialize(this.channel)
            useChannelStreamStore().filters.clear().set({
                date: this.filters.value('date'),
                [this.selectedMode == 'published-in' ? 'publishedInto' : 'publishedBy']: [ { type: 'channel', id: this.channel.id } ]
            })
        },

        selectTab(tab) {
            this.selectedTab = tab
        },

        selectMode(mode) {
            this.selectedMode = mode
            this.loadWidgets()
        },

        channelSeries() {
            return {
                name: this.channel.name,
                channel: this.channel,
                filters: searchFilters().set({
                    date: this.filters.value('date'),
                    [this.selectedMode == 'published-in' ? 'publishedInto' : 'publishedBy']: [{ type: 'channel', id: this.channel.id }]
                }),
                color: nodeType(this.channel).color || '#2172f0'
            }
        }
    }
})

export default useChannelsChannelPage
