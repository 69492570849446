<template>
    <ui-modal name="channels-my-channels-resource-unavailable" title="Resource Unavailable" width="320px">
        <div class="text-center">
            You have reached the maximum limit of monitored Channels.
        </div>

        <div class="text-center mt-8">
            Please disable monitoring on some of your Channels or contact support for more options.
        </div>

        <div class="flex justify-center mt-8">
            <ui-button @click="$modal.hide('channels-my-channels-resource-unavailable')">
                Close
            </ui-button>
        </div>
    </ui-modal>
</template>
