<template>
    <div :class="{ 'bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-lg': ! inline }">
        <div class="px-4">
            <div class="flex space-x-3 mb-3 mt-6 items-center">
                <div class="group relative">
                    <ui-avatar :icon="$nodeType(feature.type).icon" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" class="w-12 h-12 rounded-full shrink-0"></ui-avatar>
                </div>
                <div class="flex-1 min-w-0">
                    <h1 class="text-3xl font-bold leading-none truncate pb-0.5" v-tooltip="feature.name">{{feature.name}}</h1>
                    <h2 class="text-sm leading-none">
                        {{$nodeType(feature.type).name}}
                    </h2>
                </div>
                <contextual-menu :feature="feature"></contextual-menu>
            </div>

            <table class="w-full table-fixed divide-y divide-gray-100 text-sm mb-3">
                <tr :key="label" v-for="value, label in details">
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium w-1/4">
                        {{label}}
                    </td>
                    <td class="pr-1 pl-3 py-3 whitespace-pre-line break-words">
                        {{$strLimit(value)}}
                        <a href="#" v-if="value.length > 250" class="text-blue-600" @click.prevent="showFullText(label, value)">Read more</a>
                    </td>
                </tr>
            </table>
        </div>
    </div>
    
    <Teleport to="#root">
        <stream-lightbox :store="$page.lightbox()"></stream-lightbox>
    </Teleport>    
</template>

<script>
import ContextualMenu from '@/components/features/contextual-menus/feature'
import StreamLightbox from '@/components/content/perspective/stream/lightbox'

import useFeatureDetailsText from '@/stores/features/modals/feature-details-text'

import strLimit from '@/helpers/str-limit'

import filter from 'just-filter'
import { mapActions } from 'pinia'

export default {
    props: [ 'feature', 'inline' ],

    components: {
        ContextualMenu, StreamLightbox
    },

    computed: {
        details() {
            return filter({
                'Created At': this.feature.createdAt ? this.$dateTime(this.feature.createdAt) : null,
                'First Seen At': this.feature.firstSeenAt ? this.$dateTime(this.feature.firstSeenAt) : null
            }, (k, v) => v)
        }
    },

    methods: {
        ...mapActions(useFeatureDetailsText, { showFullText: 'open' }),
        
        $strLimit: strLimit
    }
}
</script>
