import useMyAppsStore from '@/stores/me/apps'

import defineCards from '@/stores/reusable/cards'

import { sortAlphabetically } from '@/helpers/sorting'

export const useAppsCardsStore = defineCards({
    id: 'appsCards',

    source: useMyAppsStore,

    layout: 'table',

    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        }
    ]
})

export default useAppsCardsStore
