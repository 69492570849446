import { scaleLinear, scaleOrdinal, scalePow } from 'd3-scale'
import { interpolateCool } from "d3-scale-chromatic"

// TODO Refactor this
export const palette = [
    '#ffcb00', '#309975',  '#f55951', '#54d2d2',
    '#8a00d4', '#d527b7', '#f782c2', '#f9c46b',
    '#e3e3e3', '#e74645', '#fb7756', '#facd60',
    '#fdfa66', '#1ac0c6', '#454d66', '#309975',
    '#58b368', '#dad873', '#efeeb4', '#272643',
    '#ffffff', '#e3f6f5', '#bae8e8', '#2c698d',
    '#361d32', '#543c52', '#edd2cb', '#f1e8e6',
    '#072448', '#ff6150', '#f8aa4b',
]

const sigmoid = (z) => {
    const k = 0.33666
    return 1 / (1 + Math.exp(- (z - 0.5)/k))
}

export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n)
}

export const interpolateColorByAttr = (graph, attr) => {
    let domain = [Number.MAX_VALUE, Number.MIN_VALUE]
    let numeric = false
    const data = []

    graph.forEachNode((key, node) => {
        if (!data.includes(node[attr])) {
            if ((!numeric && node[attr] && isNumeric(node[attr])) || (node[attr] === 0)) numeric = true

            if (numeric && node[attr] !== undefined && node[attr] !== null) {
                if (node[attr] < domain[0]) domain[0] = node[attr]
                if (node[attr] > domain[1]) domain[1] = node[attr]
            }

            data.push(node[attr])
        }
    })

    let colorFunction = null

    if (numeric) {
        const colorScale = scaleLinear().domain(domain).range([0, 1])
        colorFunction = (value) => interpolateCool(colorScale(value))
    } else {
        colorFunction = scaleOrdinal().domain(data).range(palette)
        domain = data
    }

    return {
        colorFunction: colorFunction,
        domain: domain,
        numeric: numeric
    }
}

export const interpolateSizeByAttr = (graph, attr) => {
    const range = [8, 24]
    const data = []
    let numeric = false
    const domain = [Number.MAX_VALUE, Number.MIN_VALUE]

    graph.forEachNode((key, node) => {
        if (!data.includes(node[attr])) {
            if ((!numeric && node[attr] && isNumeric(node[attr])) || (node[attr] === 0)) numeric = true

            if (numeric && node[attr] !== undefined && node[attr] !== null) {
                if (node[attr] < domain[0]) domain[0] = node[attr]
                if (node[attr] > domain[1]) domain[1] = node[attr]
            }

            data.push(node[attr])
        }
    })

    let sizeFunction = null

    if (numeric) {
        sizeFunction = scalePow().domain(domain).range(range)
    } else {
        sizeFunction = scaleOrdinal().domain(data).range(range)
    }

    return {
        sizeFunction: sizeFunction,
        domain: domain,
        numeric: numeric
    }
}

export const interpolateEdgeColorByAttr = (edgesInGraph) => {
    return scaleOrdinal().domain(edgesInGraph).range(palette)
}

export const interpolateEdgeColorByWeight = (graph) => {
    const domain = [ Number.MAX_VALUE, Number.MIN_VALUE ]

    graph.forEachEdge((e, a) => {
        if (isNumeric(a.weight)) {
            if (a.weight < domain[0]) domain[0] = a.weight
            if (a.weight > domain[1]) domain[1] = a.weight
        }
    })

    const colorScale = scaleLinear().domain(domain).range([0, 1])
    // colorFunction = (value) => interpolateCool(sigmoid(colorScale(value)))
    return (value) => interpolateCool(colorScale(value))
}

export const interpolateEdgeSizeByWeight = (graph) => {
    const domain = [ Number.MAX_VALUE, Number.MIN_VALUE ]

    graph.forEachEdge((e, a) => {
        if (isNumeric(a.weight)) {
            if (a.weight < domain[0]) domain[0] = a.weight
            if (a.weight > domain[1]) domain[1] = a.weight
        }
    })

    return scaleLinear().domain(domain).range([ 1, 7 ]).clamp(true)
}

export const hexToRgba = (hex, opacity) => {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

    if (result) {
        const r = parseInt(result[1], 16)
        const g = parseInt(result[2], 16)
        const b = parseInt(result[3], 16)

        return `rgba(${r}, ${g}, ${b}, ${opacity})`
    }

    return hex
}