<template>
    <div class="space-y-4">
        <div class="flex items-center gap-x-3">
            <ui-switch v-model="store.inputs.settings.triggerOnReplies" size="sm"></ui-switch>

            <div>
                <label for="metric" class="block text-sm text-gray-700 font-medium mb-1">
                    Trigger on replies
                </label>

                <div class="text-xs text-gray-600">
                    An alert will be triggered anytime someone replies to this content.
                </div>
            </div>
        </div>

        <div class="flex items-center gap-x-3">
            <ui-switch v-model="store.inputs.settings.triggerOnShares" size="sm"></ui-switch>

            <div>
                <label for="metric" class="block text-sm text-gray-700 font-medium mb-1">
                    Trigger on shares
                </label>

                <div class="text-xs text-gray-600">
                    An alert will be triggered anytime someone shares this content.
                </div>
            </div>
        </div>

        <div class="flex items-center gap-x-3">
            <ui-switch v-model="store.inputs.settings.triggerOnEdit" size="sm"></ui-switch>

            <div>
                <label for="metric" class="block text-sm text-gray-700 font-medium mb-1">
                    Trigger on edits
                </label>

                <div class="text-xs text-gray-600">
                    An alert will be triggered anytime this content is edited.
                </div>
            </div>
        </div>

        <div>
            <label for="frequency" class="block text-sm text-gray-700 font-medium mb-1">
                Frequency
            </label>

            <div>
                <ui-select id="frequency" :options="frequency" :reduce="v => v.id" v-model="store.inputs.settings.frequency"></ui-select>
                <div class="mt-1 text-xs text-gray-600">
                    You can choose to check for spikes every hour or once a day.
                </div>
                <div class="mt-1 text-xs text-gray-600">
                    If you choose hourly, we will always check the last hour of content, compared with previous 12 hours.
                </div>
                <div class="mt-1 text-xs text-gray-600">
                    If you choose daily, we will always check the last day of content, compared with previous 7 days.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        frequency: [
            { id: 'hourly', label: 'Hourly' },
            { id: 'daily', label: 'Daily' }
        ],

        metric: [
            { id: 'results', label: 'Results Count' },
            { id: 'interactions', label: 'Interactions' },
            { id: 'views', label: 'Views' }
        ]
    })
}
</script>