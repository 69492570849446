import defineCards from '@/stores/reusable/cards'
import useMyMonitoredChannelsStore from '@/stores/me/monitored-channels'

export const useIndexCardsMonitoredStore = defineCards({
    id: 'channelsIndexCardsMonitored',

    source: useMyMonitoredChannelsStore,
    layout: 'table',

    paginated: true,
    searchable: true,
    filterable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', api: 'name-asc', default: true
        },
        {
            id: 'followers', name: 'Followers', icon: 'users', api: 'followers-desc'
        }
    ]
})

export default useIndexCardsMonitoredStore
