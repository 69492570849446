<template>
    <ui-dropdown width="w-48">
        <template v-slot:trigger>
            <div class="inline-flex items-center px-3 pr-2 h-9 text-sm hover:bg-gray-50 text-gray-900 rounded cursor-pointer bg-white border border-gray-300 rounded shadow-sm w-full">
                <div class="w-4 flex justify-center items-center text-center mr-1.5" v-if="model">
                    <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${model.color}`"></span>
                </div>    
                <span class="flex-1" :class="{ 'text-gray-400': ! model }">
                    {{model?.name || "Don't apply a tag"}}
                </span>
                <ui-icon name="select" class="ml-2"></ui-icon>
            </div>
        </template>

        <template v-slot:content>
            <div class="max-h-80 my-2 overflow-y-auto">
                <div class="text-xs font-medium text-gray-700 px-4 mb-1.5 cursor-pointer" @click="isSelectingGroup = ! isSelectingGroup">
                    {{ selectedGroup ? selectedGroup.name : 'All Tags' }}
                    <ui-icon :name="isSelectingGroup ? 'chevron-up' : 'chevron-down'"></ui-icon>
                </div>
        
                <template v-if="isSelectingGroup">
                    <ui-dropdown-link @click="selectGroup(null)" keep-open :selected="! selectedGroup">
                        All Tags
                    </ui-dropdown-link>
                    <ui-dropdown-link @click="selectGroup(group)" keep-open :selected="!! (selectedGroup && selectedGroup.id == group.id)" :key="group.id" v-for="group in tagGroups">
                        {{group.name}}
                    </ui-dropdown-link>
                </template>
        
                <template v-else>
                    <div class="px-3 py-1 mb-1">
                        <div class="relative w-full h-7">
                            <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                                <ui-icon name="search" class="text-sm"></ui-icon>
                            </div>
                            <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
                        </div>
                    </div>
        
                    <ui-dropdown-link @click="model = tag" :icon="true" :key="tag.id" v-for="tag in tags">
                        <template v-slot:icon>
                            <div class="w-4 flex justify-center items-center text-center">
                                <span :class="`inline-block w-3 h-3 text-xs leading-4 rounded-full bg-${tag.color}`"></span>
                            </div>
                        </template>
                        <template v-slot:default>
                            {{tag.name}}
                        </template>
                    </ui-dropdown-link>
        
                    <div class="text-xs text-gray-700 text-center py-1" v-if="! tags.length">
                        No tags have been found.
                    </div>
                </template>
            </div>    
        
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="clear" @click="model = null">
                Don't apply a tag
            </ui-dropdown-link>

            <ui-dropdown-link icon="settings" @click="manageTags">
                Manage Tags
            </ui-dropdown-link>
        </template>
    </ui-dropdown>   
</template>

<script>
import useMyModalsTagsManageStore from '@/stores/me/modals/tags-manage'
import useTaggingStore from '@/stores/tagging/tagging'

import textSearch from '@/helpers/text-search'

import { mapActions, mapState } from 'pinia'

export default {
    props: { modelValue: {} },
    
    data: () => ({
        search: '',
        isSelectingGroup: false
    }),

    computed: {
        ...mapState(useTaggingStore, {
            tagGroups: 'tagGroups',
            selectedGroup: 'selectedGroup',

            tags(store) {
                let tags = this.search ? textSearch(this.search, store.tags, t => t.name) : store.tags

                return tags.sort((a, b) => a.name.localeCompare(b.name))
            }
        }),
        
        model: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        }
    },

    methods: {
        ...mapActions(useMyModalsTagsManageStore, { manageTags: 'open' }),

        selectGroup(group) {
            useTaggingStore().selectGroup(group)
            this.isSelectingGroup = false
        }
    }
}
</script>
