import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyTopicsStore from '@/stores/me/topics'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers/sorting'

export default {
    actions: {
        changeDataset() {
            useQuickSearchStore().show({
                families: [ 'perspectives', 'topics', 'lists' ],
                limit: 50,
                onSelect: result => {
                    if (result) {
                        this.setDataset({
                            datasetType: result.resultType,
                            datasetId: result.id,
                            label: result.title
                        })
                    }

                    useQuickSearchStore().hide()
                },
                initialResults: [
                    ...sortAlphabetically(useMyPerspectivesStore().items).map(item => ({
                        id: item.id,
                        family: 'perspectives',
                        resultType: 'perspective',
                        title: item.name
                    })),
                    ...sortAlphabetically(useMyTopicsStore().items).map(item => ({
                        id: item.id,
                        family: 'topics',
                        resultType: 'topic',
                        title: item.name
                    })),
                    ...sortAlphabetically(useMyChannelListsStore().items).map(item => ({
                        id: item.id,
                        family: 'lists',
                        resultType: 'list',
                        title: item.name
                    }))
                ]
            })
        },

        setDataset(dataset) {
            if (this.series.length) {
                this.updateSeries(this.series[0], dataset)
            } else {
                this.addSeries(dataset)
            }
        }
    }
}
