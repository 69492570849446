import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyTopicsStore from '@/stores/me/topics'
import useQuickSearchStore from '@/stores/me/quick-search'

import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

export const useAnalysisModalsContentPerformanceSeriesStore = defineForm({
    id: 'analysisModalsContentPerformanceSeries',

    inputs: () => ({
        label: null,
        color: null,

        datasetType: null,
        datasetId: null,

        meta: {},

        series: null,
        analysisStore: null
    }),

    getters: {
        isValid: store => {
            return store.inputs.analysisStore ? store.inputs.analysisStore.isValidSeries(store.inputs) : false
        }
    },

    actions: {
        open(analysisStore, series) {
            this.reset()

            this.inputs.label = series?.label
            this.inputs.color = series?.color

            this.inputs.datasetType = series?.datasetType
            this.inputs.datasetId = series?.datasetId

            this.inputs.meta = series?.meta || {}

            this.inputs.series = series
            this.inputs.analysisStore = analysisStore

            useMyPerspectivesStore().initialize()
            useMyChannelListsStore().initialize()
            useMyTopicsStore().initialize()

            useModal().show('analyses-content-performance-series')

            return this.makePromise()
        },

        cancel() {
            useModal().hide('analyses-content-performance-series')

            this.fulfillPromise()
        },

        submit() {
            if (! this.isValid) return

            if (this.inputs.series) {
                this.inputs.analysisStore.updateSeries(this.inputs.series, this.inputs)
            } else {
                this.inputs.analysisStore.addSeries(this.inputs)
            }

            useModal().hide('analyses-content-performance-series')

            this.fulfillPromise()
        },

        delete() {
            this.inputs.analysisStore.deleteSeries(this.inputs.series)

            useModal().hide('analyses-content-performance-series')
        },

        changeDataset() {
            useQuickSearchStore().show({
                families: [ 'perspectives', 'topics', 'lists' ],
                limit: 50,
                perspectiveStarters: false,
                onSelect: result => {
                    if (result) {
                        this.inputs.datasetType = result.resultType
                        this.inputs.datasetId = result.id

                        if (! this.inputs.label) this.inputs.label = result.title
                    }

                    useQuickSearchStore().hide()
                },
                initialResults: [
                    ...useMyPerspectivesStore().items.map(item => ({
                        id: item.id,
                        family: 'perspectives',
                        resultType: 'perspective',
                        title: item.name
                    })),
                    ...useMyTopicsStore().items.map(item => ({
                        id: item.id,
                        family: 'topics',
                        resultType: 'topic',
                        title: item.name
                    })),
                    ...useMyChannelListsStore().items.map(item => ({
                        id: item.id,
                        family: 'lists',
                        resultType: 'list',
                        title: item.name
                    }))
                ]
            })
        }
    }
})

export default useAnalysisModalsContentPerformanceSeriesStore
