<template>
    <ui-icon v-if="workspace.type == 'personal'" name="user" v-tooltip="'Visible only by you'"></ui-icon>
    <ui-icon v-else-if="workspace.type == 'default'" name="users" v-tooltip="'Visible by the current workspace'"></ui-icon>
    <ui-icon v-else-if="workspace.type == 'global'" name="globe-simple" v-tooltip="'Visible by the whole organization'"></ui-icon>
</template>

<script>
export default {
    props: [ 'workspace' ]
}
</script>
