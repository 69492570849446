<template>
    <ui-async :guards="$page.channel">
        <div class="px-6 xl:px-16 py-8 flex justify-center space-x-4 max-w-12xl mx-auto">
            <div class="w-1/3 space-y-4">
                <channel-info :channel="$page.channel"></channel-info>
                <channel-connections></channel-connections>
                <channel-audience></channel-audience>
                <channel-history></channel-history>
            </div>

            <div class="w-2/3 space-y-4">
                <div class="h-8 flex items-center mb-2 pt-px">
                    <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                        <a @click.prevent="$page.selectTab('inspect')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedTab == 'inspect' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Inspect
                        </a>
                        <a @click.prevent="$page.selectTab('stream')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedTab == 'stream' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Stream
                        </a>
                        <a @click.prevent="$page.selectTab('connections')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedTab == 'connections' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Connections
                        </a>
                    </div>

                    <toolbar-date v-if="[ 'inspect', 'stream' ].includes($page.selectedTab)" :filters="$page.filters" class="ml-4"></toolbar-date>

                    <div v-if="[ 'inspect', 'stream' ].includes($page.selectedTab)" class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full ml-4">
                        <a @click.prevent="$page.selectMode('published-by')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedMode == 'published-by' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'" v-tooltip="{ content: '<b>Published by mode</b><br>Show content published by this channel in any channel.', html: true }">
                            <ui-icon name="arrow-up-circle" class="text-xl"></ui-icon>
                        </a>
                        <a @click.prevent="$page.selectMode('published-in')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedMode == 'published-in' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'" v-tooltip="{ content: '<b>Published in mode</b><br>Show content published by any channel into this channel.', html: true }">
                            <ui-icon name="arrow-down-circle" class="text-xl"></ui-icon>
                        </a>
                    </div>

                    <div class="ml-auto"></div>

                    <router-link :to="$page.newPerspectiveLink" class="flex items-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 ml-4">
                        <ui-icon name="navigation.content"></ui-icon>
                        <span>New perspective</span>
                    </router-link>

                    <!--<div class="flex items-center gap-x-2 h-full ml-4">-->
                    <!--    <a href="#" class="border border-gray-400 border-dashed text-gray-700 hover:text-gray-900 rounded-full font-medium flex items-center px-3 pl-2.5 gap-x-1.5 h-full">-->
                    <!--        <ui-icon name="plus-circle"></ui-icon> <span>Compare with...</span>-->
                    <!--    </a>-->
                    <!--</div>-->
                </div>

                <template v-if="$page.selectedTab == 'inspect'">
                    <content-metrics :store="$page.contentMetricsWidget()" class="w-full"></content-metrics>
                    <channel-metrics :store="$page.channelMetricsWidget()" class="w-full"></channel-metrics>
                    <channel-keywords :store="$page.keywordsWidget()" class="w-full"></channel-keywords>
                    <channel-heatmap :store="$page.heatmapWidget()" class="w-full"></channel-heatmap>

                    <div class="flex gap-x-4">
                        <channel-top-links :store="$page.topLinksWidget()" class="w-1/3"></channel-top-links>
                        <channel-top-domains :store="$page.topDomainsWidget()" class="w-1/3"></channel-top-domains>
                        <channel-top-hashtags :store="$page.topHashtagsWidget()" class="w-1/3"></channel-top-hashtags>
                    </div>

                    <channel-top-media :store="$page.topMediaWidget()"></channel-top-media>
                </template>

                <template v-else-if="$page.selectedTab == 'stream'">
                    <channel-stream :store="channelStreamStore()"></channel-stream>
                </template>

                <template v-else-if="$page.selectedTab == 'connections'">
                    <channel-connections-details></channel-connections-details>
                </template>
            </div>
        </div>
    </ui-async>

    <Teleport to="#root">
        <channel-details-text-modal></channel-details-text-modal>
        <channel-history-modal></channel-history-modal>
        <channel-audience-modal></channel-audience-modal>
    </Teleport>
</template>

<script>
import ChannelAudience from '@/components/channels/channel/audience'
import ChannelAudienceModal from '@/components/channels/modals/audience'
import ChannelConnections from './channel/connections'
import ChannelConnectionsDetails from './channel/connections-details'
import ChannelDetailsTextModal from './modals/channel-details-text'
import ChannelHeatmap from './channel/heatmap'
import ChannelHistory from './channel/history'
import ChannelHistoryModal from './modals/channel-history'
import ChannelInfo from './channel/info'
import ChannelKeywords from '@/components/content/perspective/overview/widgets/keywords'
import ChannelMetrics from './channel/channel-metrics'
import ChannelStream from './channel/stream'
import ChannelTopDomains from '@/components/content/perspective/overview/widgets/top-domains'
import ChannelTopHashtags from '@/components/content/perspective/overview/widgets/top-hashtags'
import ChannelTopLinks from '@/components/content/perspective/overview/widgets/top-links'
import ChannelTopMedia from '@/components/content/perspective/overview/widgets/top-media'
import ContentMetrics from '@/components/content/perspective/overview/widgets/timeline'
import ToolbarDate from '@/components/content/perspective/toolbar/date'

import useStreamStore from '@/stores/channels/channel/stream'

import { mapStores } from 'pinia'

export default {
    components: {
        ChannelAudience, ChannelAudienceModal, ChannelConnections, ChannelConnectionsDetails, ChannelDetailsTextModal,
        ChannelHeatmap, ChannelHistory, ChannelHistoryModal, ChannelInfo, ChannelKeywords, ChannelMetrics, ChannelStream,
        ChannelTopDomains, ChannelTopHashtags, ChannelTopLinks, ChannelTopMedia, ToolbarDate, ContentMetrics
    },

    methods: {
        ...mapStores(useStreamStore)
    }
}
</script>
