<template>
    <ui-contextual-menu v-bind="$attrs" z-index="50">
        <template v-if="list && ! list.curated">
            <ui-dropdown-link icon="plus" @click="addToList(list, channel)" v-if="! isIncludedIn(list, channel)">
                Add channel
            </ui-dropdown-link>
            <ui-dropdown-link icon="remove" @click="removeFromList(list, channel)" v-else>
                Remove channel
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>
        </template>

        <template v-if="streamStore && streamStore.items.data && streamStore.items.data.length">
            <ui-dropdown-link icon="filter" @click="excludeChannel(type)">
                Exclude from search
            </ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>
        </template>

        <ui-dropdown-link icon="pin-off" @click="pin(channel)" v-if="! isPinned(channel)">
            Pin
        </ui-dropdown-link>
        <ui-dropdown-link icon="pin-off" @click="unpin(channel)" v-else>
            Un-pin
        </ui-dropdown-link>

        <ui-dropdown-link icon="navigation.channels" :disabled="! $nodeType(channel).monitorable" v-tooltip="! $nodeType(channel).monitorable ? 'This Channel is not monitorable' : ''" submenu>
            Monitoring...

            <template v-slot:submenu>
                <ui-dropdown-link :selected="isMonitored(channel)" @click="isMonitored(channel) ? disableMonitoring(channel) : enableMonitoring(channel)" :disabled="channel.requestedMonitoring" keep-open>
                    {{ channel.monitoringRequested ? 'Monitoring requested..' : 'Enable monitoring' }}
                </ui-dropdown-link>

                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-dropdown-link :selected="channel.monitoringOptions.includes('imageTextExtraction')" :disabled="! isMonitored(channel)" @click="toggleMonitoringOption(channel, 'imageTextExtraction')" keep-open>
                    Image Text Extraction
                </ui-dropdown-link>

                <ui-dropdown-link :selected="channel.monitoringOptions.includes('videoDownload')" :disabled="! isMonitored(channel)" @click="toggleMonitoringOption(channel, 'videoDownload')" keep-open>
                    Video Retention
                </ui-dropdown-link>

                <ui-dropdown-link :selected="channel.monitoringOptions.includes('videoTranscription')" :disabled="! isMonitored(channel)" @click="toggleMonitoringOption(channel, 'videoTranscription')" keep-open>
                    Video Transcription
                </ui-dropdown-link>

                <ui-dropdown-separator></ui-dropdown-separator>

                <ui-dropdown-link icon="time" @click="openHistoryDownloadModal(channel)">
                    Download Full History
                </ui-dropdown-link>
            </template>
        </ui-dropdown-link>

        <lists-section align="right" :channel="channel" :list="list"></lists-section>
        <alerts-submenu :subject="channel"></alerts-submenu>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'channels.channel.information', params: { id: channel.id } }).href">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-link icon="external-link" :href="channel.link" target="_blank" rel="noreferrer noopener" :disabled="! channel.link">
            Open original
        </ui-dropdown-link>
        
        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="edit" @click="() => createNote(channel)">
            Add note...
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="warning" @click="() => report('channel', channel.id)">
            Report
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="$inspect(channel)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import AlertsSubmenu from '@/components/me/alerts/alerts-submenu'
import ListsSection from './channel-lists'

import useChannelsStore from '@/stores/me/channels'
import useChannelsModalsHistoryDownloadStore from '@/stores/channels/modals/history-download'
import useMeModalsNoteStore from '@/stores/me/modals/note'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyMonitoredChannelsStore from '@/stores/me/monitored-channels'
import useSupportReportStore from '@/stores/support/report'

import { mapActions } from 'pinia'

export default {
    components: {
        AlertsSubmenu, ListsSection
    },

    props: [ 'streamStore', 'channel', 'list', 'type' ],

    methods: {
        ...mapActions(useChannelsStore, [ 'isPinned', 'pin', 'unpin' ]),
        ...mapActions(useChannelsModalsHistoryDownloadStore, { openHistoryDownloadModal: 'open' }),
        ...mapActions(useMeModalsNoteStore, { createNote: 'create' }),
        ...mapActions(useMyChannelListsStore, [ 'addToList', 'removeFromList', 'isIncludedIn' ]),
        ...mapActions(useMyMonitoredChannelsStore, [ 'disableMonitoring', 'enableMonitoring', 'isMonitored', 'toggleMonitoringOption' ]),
        ...mapActions(useSupportReportStore, [ 'report' ]),

        excludeChannel(filter) {
            this.streamStore.filters.set(filter, [
                ...(this.streamStore.filters.value(filter) || []),
                {
                    id: this.channel.id,
                    type: 'channel',
                    name: this.channel.name,
                    exclude: true
                }
            ])
        }
    }
}
</script>
