<template>
    <ui-modal name="content-perspective-edit" title="Perspective" :max-width="520" :max-height="680" @opened="opened" @closed="cancel">
        <ui-form :store="contentModalsPerspectiveEditStore" v-slot="{ inputs, submitting, errors }">
            <div class="flex gap-x-8">
                <div class="flex-1">
                    <div>
                        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                            Name
                        </label>

                        <div>
                            <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                            Description
                        </label>

                        <div>
                            <ui-input type="textarea" rows="2" name="description" id="description" placeholder="e.g. Paramilitary Groups" v-model="inputs.description" :errors="errors.description"></ui-input>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="pt-1">
                        <ui-avatar-input family="perspectives" :inputs="inputs"></ui-avatar-input>
                    </div>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deletePerspective" :disabled="submitting" v-if="perspective && perspective.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="perspective?.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ perspective?.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useContentModalsPerspectiveEditStore),

        perspective() { return this.contentModalsPerspectiveEditStore.inputs?.perspective }
    },

    methods: {
        ...mapActions(useContentModalsPerspectiveEditStore, {
            'cancel': 'cancel', 'deletePerspective': 'delete', 'submit': 'submit'
        }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
