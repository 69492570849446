<template>
    <router-link :to="to" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }">
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar :icon="$nodeType(result.type).icon" :color="{ background: 'bg-gray-300', icon: 'text-gray-900' }" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="leading-5 font-medium text-gray-900">
                    <div class="truncate">{{result.title}}</div>
                </div>
                <div class="text-xs leading-4 text-gray-700 truncate">
                    {{$nodeType(result.type).name}}
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: 'features.details', params: { id: this.result.id } }
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
