import { defineContentPublishTimesAnalysisStore } from '@/stores/analysis/analyses/content-publish-times'

import determineGranularity from '@/helpers/determine-granularity'

import { defineStore } from 'pinia'

export const defineHeatmapStore = settings => defineStore({
    id: settings.id,

    state: () => ({
        type: 'daily',
        
        series: [],

        isInitialized: false,
        isLoading: false,

        analysisStore: defineContentPublishTimesAnalysisStore({ id: `${settings.id}Analysis` })
    }),

    getters: {
        heatmapOptions: store => store.analysisStore().heatmapOptions,
        
        isComparing: store => store.series.length > 1,
        isRenderable: store => store.analysisStore().isRenderable
    },

    actions: {
        async initialize(series) {
            this.series = [ { ...series } ]

            await this.load()

            this.isInitialized = true
        },

        async compareWith(series) {
            this.series.push({ ...series })

            this.isLoading = true

            await this.load()
        },

        stopComparing(series) {
            this.series = this.series.filter(s => s.id != series.id)

            this.load()
        },

        async load() {
            this.isLoading = true

            await this.loadAnalysis()

            this.isLoading = false
        },

        loadAnalysis() {
            let store = this.analysisStore()
            
            store.series = this.series.flatMap(series => [
                {
                    label: 'Content',
                    color: '#000',
                    datasetType: 'inline-perspective',
                    datasetFilters: {
                        mentions: [{ id: series.feature.id, type: series.feature.type, name: series.feature.name, exclude: false }]
                    },
                    meta: { type: this.type }
                }
            ])

            if (this.series[0].filters.value('date')) {
                store.date = this.series[0].filters.value('date')
                store.granularity = determineGranularity(store.date)
            } else {
                store.date = { type: 'past', date: { past: 5, unit: 'years' } }
                store.granularity = 'month'
            }
            
            return store.loadSeries()
        },
        
        toggleType() {
            this.type = this.type == 'hourly' ? 'daily' : 'hourly'
            this.load()
        }
    }
})

export default defineHeatmapStore
