<template>
    <ui-button :light="! isSubscribedByParent(list)" color="blue" :action="() => isSubscribedByParent(list) ? unsubscribeFromList(list) : subscribeToList(list)">
        {{ isSubscribedByParent(list) ? 'Unsubscribe' : 'Subscribe' }}
    </ui-button>
</template>

<script>
import useMyChannelListsStore from '@/stores/me/channel-lists'

import { mapActions } from 'pinia'

export default {
    props: [ 'list' ],

    methods: {
        ...mapActions(useMyChannelListsStore, [ 'subscribeToList', 'unsubscribeFromList', 'isSubscribedByParent' ])
    }
}
</script>
