<!-- This filter does not extends generic search-filter as it has a more "custom" UI instead of the standard dropdown -->

<template>
    <div>
        <a href="#" class="block" @click.prevent="filters.value('deleted') ? filters.remove('deleted') : filters.set('deleted', true)">
            <div class="inline-flex items-center space-x-1 px-3 h-7 text-xs font-medium leading-4 bg-blue-50 text-blue-600 rounded max-w-full" v-if="filters.value('deleted')">
                <span class="inline-block w-3 h-3 text-xs rounded-full bg-red-500"></span>
                <span>Deleted</span>
            </div>

            <div class="inline-flex items-center px-3 h-7 text-sm font-medium leading-4 hover:bg-gray-50 text-gray-900 rounded max-w-full" v-else>
                Deleted
            </div>
        </a>
    </div>
</template>

<script>
export default {
    props: [ 'filters' ]
}
</script>