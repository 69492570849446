import { defineStore } from 'pinia'
import defineConnectionsGraph from "@/stores/connections/connections-graph.js"
import NodesTableActions from "@/components/connections/partials/nodes-table-actions.vue"
import EdgesTableActions from "@/components/connections/partials/edges-table-actions.vue"
import { nodeType } from "@/helpers/types.js"


const useConnectionsDataStore = defineStore({
    id: 'connections-data',

    state: () => ({
        graphStore: null,

        graph: null,
        renderer: null,

        nodesGridApi: null,
        edgesGridApi: null,

        nodeHeaders: [
            { field: "label", headerName: 'Name', minWidth: 180, flex: 1, filter: "agTextColumnFilter" },
            { field: "class", headerName: 'Class', width: 100, filter: "agTextColumnFilter" },
            { field: "subclass", headerName: 'Family', width: 100, filter: "agTextColumnFilter" },
            { field: "object_type", headerName: 'Type', filter: "agTextColumnFilter", valueFormatter: p => nodeType(p.value).name || '' },
            { field: "followers",  headerName: 'Followers', width: 100, type: "number", filter: "agNumberColumnFilter" },
            { field: "nodeDegree", headerName: 'Degree', width: 100, type: "number", filter: "agNumberColumnFilter" },
            { field: "actions", headerName: 'Actions', cellRenderer: NodesTableActions, pinned: 'right', width: 85, resizable: false, sortable: false }
        ],

        edgeHeaders: [
            { field: "source", headerName: 'Source',  filter: "agTextColumnFilter", flex: 1 },
            { field: "target", headerName: 'Target',  filter: "agTextColumnFilter", flex: 1 },
            { field: "label", headerName: 'Type', filter: "agTextColumnFilter" },
            { field: "weight", headerName: 'Weight',  filter: "agNumberColumnFilter", type: "number" },
            { field: "actions", headerName: 'Actions', cellRenderer: EdgesTableActions, pinned: 'right', width: 85, resizable: false, sortable: false }
        ]
    }),

    actions: {
        initialize() {
            this.graphStore = defineConnectionsGraph({ id: 'connectionsGraph' })()
        },

        onNodesGridReady(params) {
            this.nodesGridApi = params.api
        },

        onEdgesGridReady(params) {
            this.edgesGridApi = params.api
        },

        addNodesMetricsColumn(metrics) {
            const nodeColumns = this.nodesGridApi.getGridOption('columnDefs')
            let field = null

            switch (metrics) {
                case 'centrality':
                    field = nodeColumns.find(c => c.field === 'centrality')
                    if (!field) {
                        nodeColumns.push({ field: "centrality", width: 100, headerName: 'Betweenness centrality', type: "number", filter: "agNumberColumnFilter" })
                    }
                    break
                case 'pagerank':
                    field = nodeColumns.find(c => c.field === 'pagerank')
                    if (!field) {
                        nodeColumns.push({ field: "pagerank", width: 100, headerName: 'Pagerank', type: "number", filter: "agNumberColumnFilter" })
                    }
                    break
                case 'eigenvector':
                    field = nodeColumns.find(c => c.field === 'eigenvector')
                    if (!field) {
                        nodeColumns.push({ field: "eigenvector", width: 100, headerName: 'Eigenvector centrality', type: "number", filter: "agNumberColumnFilter" })
                    }
                    break
            }

            this.nodesGridApi.setGridOption('columnDefs', nodeColumns)
            this.nodeHeaders = nodeColumns
        },

        onRowSelected(e) {
            if (e.source === 'checkboxSelected') {
                this.graphStore.selectNodes([e.data.node_id], false, false)
            }
        },

        selectionChanged(e) {
            if (e.source === 'uiSelectAllFiltered') {
                const ids = e.api.getSelectedNodes().map(n => n.data.node_id)
                this.graphStore.selectNodes(ids, true, false)
            }
        },

        exportAs(format) {
            switch (format) {
                case 'nodes-csv':
                    this.exportNodesAsCsv()
                    break

                case 'edges-csv':
                    this.exportEdgesAsCsv()
                    break

                case 'gexf':
                    this.graphStore.export()
                    break
            }
        },

        exportNodesAsCsv() {
            const blacklist = ['actions']
            const columnKeys = this.nodeHeaders.filter(c => !blacklist.includes(c.field)).map(c => c.field)
            this.nodesGridApi.exportDataAsCsv({ columnKeys })
        },

        exportEdgesAsCsv() {
            const blacklist = ['actions']
            const columnKeys = this.edgeHeaders.filter(c => !blacklist.includes(c.field)).map(c => c.field)
            this.edgesGridApi.exportDataAsCsv({ columnKeys })
        },

        checkSelectedNodes(selectedNodes) {
            const selected = []
            const deselected = []

            this.nodesGridApi.forEachNode((node) => {
                if (selectedNodes.includes(node.data.node_id)) {
                    selected.push(node)
                } else {
                    deselected.push(node)
                }
            })

            this.nodesGridApi.setNodesSelected({ nodes: selected, newValue: true })
            this.nodesGridApi.setNodesSelected({ nodes: deselected, newValue: false })
        }
    }
})

export default useConnectionsDataStore
