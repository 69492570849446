<template>
    <ui-modal name="content-topic-edit" title="Topic" :max-width="520" :max-height="680" @opened="opened" @closed="cancel">
        <ui-form :store="contentModalsTopicEditStore" v-slot="{ inputs, submitting, errors }">
            <div class="flex gap-x-8">
                <div class="flex-1">
                    <div>
                        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                            Name
                        </label>

                        <div>
                            <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                            Description
                        </label>

                        <div>
                            <ui-input type="textarea" rows="2" name="description" id="description" placeholder="e.g. Paramilitary Groups" v-model="inputs.description" :errors="errors.description"></ui-input>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="pt-1">
                        <ui-avatar-input family="topics" :inputs="inputs"></ui-avatar-input>
                    </div>
                </div>
            </div>

            <div class="mt-6">
                <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                    Query
                </label>

                <div>
                    <search-input
                        v-model="inputs.query"
                        @focus="() => isQueryFocused = true"
                        @blur="() => isQueryFocused = false"
                        :is-focused="isQueryFocused"
                        :allow-multi-line="true"
                        :allow-highlighting="true"
                        :allow-suggesting-topics="true"
                        :allow-unwrapping-topics="true"
                        classes="h-full w-full overflow-y-auto px-3 py-1.5 rounded-sm align-middle z-30 max-h-32"
                        wrapper-classes="bg-white ring-2 ring-gray-100 flex-1 rounded h-9"
                    ></search-input>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deleteTopic" :disabled="submitting" v-if="inputs.topic.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="inputs.topic.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ inputs.topic.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import SearchInput from '@/components/content/perspective/stream/search-input-input'

import useContentModalsTopicEditStore from '@/stores/content/modals/topic-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    data: () => ({
        isQueryFocused: false
    }),

    components: {
        SearchInput
    },

    computed: {
        ...mapStores(useContentModalsTopicEditStore),

        topic() { return this.contentModalsTopicEditStore.inputs.topic }
    },

    methods: {
        ...mapActions(useContentModalsTopicEditStore, { 'cancel': 'cancel', 'deleteTopic': 'delete' }),

        opened() {
            this.$refs.nameInput.focus()
        }
    }
}
</script>
