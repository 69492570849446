import defineCards from '@/stores/reusable/cards'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { sortAlphabetically, sortByDate } from '@/helpers/sorting'

export const useIndexCardsPerspectivesStore = defineCards({
    id: 'channelsIndexCardsPerspectives',

    source: useMyPerspectivesStore,
    layout: 'table',

    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => sortByDate(items, 'lastUsedAt').reverse()
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => sortByDate(items, 'updatedAt').reverse()
        }
    ]
})

export default useIndexCardsPerspectivesStore
