<template>
    <ui-modal name="content-edits" title="Edits" width="100%" height="auto" :max-width="760" :max-height="800">
        <ui-async class="h-full overflow-y-auto" :guards="! isLoading">
            <div class="flow-root" v-if="items">
                <ul>
                    <li>
                        <div class="relative" :class="{ 'pb-8': index != items.length - 1 }" :key="index" v-for="record, index in items">
                            <span class="absolute top-4 -ml-px h-full w-0.5 bg-gray-100 left-4" aria-hidden="true" v-if="index != items.length - 1"></span>
                            <div class="relative flex space-x-3">
                                <div>
                                    <span class="flex items-center justify-center h-8 w-8">
                                        <span class="h-2.5 w-2.5 rounded-full bg-gray-100 border border-gray-400 flex ring-4 ring-white"></span>
                                    </span>
                                </div>
                                <div class="min-w-0 flex-1 flex items-center justify-between space-x-4 text-gray-800">
                                    <div class="leading-tight">
                                        <div class="whitespace-nowrap text-gray-700" :class="details ? 'text-xs' : 'text-2xs'">
                                            <time>{{ $dateTime(record.at) }}</time>
                                        </div>
                                        <div v-for="change, i in record.data" :key="i">
                                            <content-change :change="change"></content-change>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </ui-async>
    </ui-modal>
</template>

<script>
import useContentEditsStore from '@/stores/content/modals/content-edits'

import ContentChange from '@/components/content/modals/partials/content-change'

import { mapState } from 'pinia'

export default {
    components: {
        ContentChange
    },

    computed: {
        ...mapState(useContentEditsStore, [ 'isLoading', 'items' ])
    },
}
</script>
