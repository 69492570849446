<template>
    <div v-if="store.search && store.items.length" class="mt-3 max-h-96 overflow-y-scroll -mx-3">
        <channel-result v-for="result, index in store.items" :key="index" :result="result" :ref="`result-${index}`" @click="selectResult(result)"></channel-result>
    </div>
</template>

<script>
import ChannelResult from '@/components/quick-search/results/channel-result'

export default {
    components: {ChannelResult},

    methods: {
        selectResult(result) {
            if (! result) return

            if (this.onSelect && ! result.alwaysOpen) {
                this.onSelect(result)
            } else {
                this.$refs[`result-${this.store.items.indexOf(result)}`][0].open()
            }
        }
    }
}
</script>