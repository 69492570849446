<template>
    <div class="w-64 max-h-96 overflow-y-auto">
        <div class="text-xs font-medium text-gray-700 px-4 my-1.5 cursor-pointer">
            Status
        </div>

        <ui-dropdown-link @click="setStatus('active')" :selected="store.filters.status == 'active'" keep-open>
            Active
        </ui-dropdown-link>
        <ui-dropdown-link @click="setStatus('paused')" :selected="store.filters.status == 'paused'" keep-open>
            Paused
        </ui-dropdown-link>
        <ui-dropdown-link @click="setStatus('muted')" :selected="store.filters.status == 'muted'" keep-open>
            Muted
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <div class="text-xs font-medium text-gray-700 px-4 my-1.5 cursor-pointer">
            Type
        </div>

        <ui-dropdown-link v-for="type in types" @click="toggleType(type)" :icon="type.icon" checkbox :selected="store.filters.type?.includes(type.id)" :key="type.id">
            {{type.name}}
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <div class="text-xs font-medium text-gray-700 px-4 my-1.5 cursor-pointer">
            Delivery
        </div>

        <ui-dropdown-link v-for="option in deliveryOptions" @click="toggleDelivery(option)" :icon="option.icon" checkbox :selected="store.filters.delivery?.includes(option.id)" :key="option.id">
            {{option.name}}
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link @click="clear()" icon="clear">
            Clear
        </ui-dropdown-link>
    </div>
</template>

<script>
import useAlertEditStore from '@/stores/alerts/modals/alert-edit'
import useMyAlertsStore from '@/stores/me/alerts'

import { mapState } from 'pinia'
import { toRaw } from 'vue'

export default {
    computed: {
        ...mapState(useAlertEditStore, [ 'deliveryOptions' ]),
        ...mapState(useMyAlertsStore, [ 'types' ])
    },

    methods: {
        setStatus(status) {
            let filters = structuredClone(toRaw(this.store.filters))

            filters.status = filters.status == status ? null : status

            this.store.filterBy(filters)
        },

        toggleType(type) {
            let filters = structuredClone(toRaw(this.store.filters))

            if (! filters.type) filters.type = []

            filters.type.includes(type.id)
                ? filters.type.splice(filters.type.findIndex(v => v == type.id), 1)
                : filters.type.push(type.id)

            this.store.filterBy(filters)
        },

        toggleDelivery(option) {
            let filters = structuredClone(toRaw(this.store.filters))

            if (! filters.delivery) filters.delivery = []

            filters.delivery.includes(option.id)
                ? filters.delivery.splice(filters.delivery.findIndex(v => v == option.id), 1)
                : filters.delivery.push(option.id)

            this.store.filterBy(filters)
        },

        clear() {
            this.store.filterBy({})
        }
    }
}
</script>
