<template>
    <div class="px-6 xl:px-16 max-w-7xl mx-auto my-8 pb-6">
        <ui-async :guards="content" v-slot:default="{ isLoaded }">
            <div class="max-w-3xl mx-auto">
                <content-card :content="content" :stream-store="streamStore" mode="details"></content-card>
            </div>

            <Teleport to="#root">
                <stream-lightbox :store="streamStore.mediaLightbox"></stream-lightbox>
            </Teleport>
        </ui-async>
    </div>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'
import StreamLightbox from './perspective/stream/lightbox'

import useContentDetailsStore from '@/stores/content/details'

import { mapState } from 'pinia'

export default {
    components: {
        ContentCard, StreamLightbox
    },

    computed: {
        ...mapState(useContentDetailsStore, [ 'content', 'streamStore' ])
    }
}
</script>
