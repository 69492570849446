<template>
    <ui-dropdown-link icon="bell" submenu submenu-width="w-60" v-if="subject.alerts">
        Alerts...

        <template v-slot:submenu>
            <alerts-submenu-content :subject="subject"></alerts-submenu-content>
        </template>
    </ui-dropdown-link>
</template>

<script>
import AlertsSubmenuContent from '@/components/me/alerts/alerts-submenu-content.vue'

export default {
    props: [ 'subject' ],

    components: {
        AlertsSubmenuContent
    }
}
</script>
