<template>
    <ui-dropdown class="h-full flex" align="left" width="w-auto">
        <template v-slot:trigger>
            <a href="#" class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-center h-full px-3">
                <span class="font-medium capitalize">By {{ store.granularity }}</span>

                <ui-icon name="select" class="ml-2"></ui-icon>
            </a>
        </template>

        <template v-slot:content>
            <div class="w-24">
                <ui-dropdown-link v-for="granularity in store.granularities ?? [ 'hour', 'day', 'week', 'month', 'year' ]" :key="granularity" @click="store.setGranularity(granularity)">
                    By {{ granularity }}
                </ui-dropdown-link>
            </div>
        </template>
    </ui-dropdown>
</template>
