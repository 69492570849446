<template>
    <router-link :to="to" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }">
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-icon :name="result.icon" class="text-xl"></ui-icon>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 text-gray-700">
                    <div class="truncate">Go to <span class="text-gray-900 font-medium">{{result.title}}</span></div>
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: this.result.route }
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        }
    }
}
</script>
