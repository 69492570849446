<template>
    <div class="overflow-y-auto px-4 py-2 max-h-120">
        <div class="mb-2">
            <div class="font-bold">
                <div class="float-right">{{ store.nodes?.length }}</div>
                Nodes
            </div>
            <div v-for="(value, key) in store.stats.classes" :key="key" v-if="stats().classes">
                <div class="float-right">{{ value }}</div>
                <div class="capitalize">{{ key.replace('-', ' ') }}</div>
            </div>
        </div>

        <div class="mb-2">
            <div class="font-bold">Node types</div>
            <div v-for="(value, key) in store.stats.object_types" :key="key" v-if="store.stats?.object_types">
                <div class="float-right">{{ value }}</div>
                <div class="capitalize">{{ key.replace('-', ' ') }}</div>
            </div>
        </div>

        <div class="mb-2">
            <div class="font-bold">
                <div class="float-right">{{ store.graph?.edges()?.length }}</div>
                Edges
            </div>
            <div v-for="(value, key) in store.stats.edges" :key="key" v-if="store.stats?.edges">
                <div class="float-right">{{ value }}</div>
                <div class="capitalize">{{ key.replace('-', ' ') }}</div>
            </div>
        </div>
    </div>
</template>


<script>

export default {
    props: {
        store: Object
    },

    methods: {
        stats() {
            return this.store.stats
        }
    }
}
</script>
