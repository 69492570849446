import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsWorkspaceSwitchConfirmationStore = defineStore({
    id: 'modalsSwitchWorkspaceConfirmation',

    state: () => ({
        fromWorkspace: {},
        toWorkspace: {},
        location: null,

        accept: null,
        reject: null,
        submitting: false
    }),

    actions: {
        async open(fromWorkspace, toWorkspace, location) {
            this.submitting = false

            this.fromWorkspace = fromWorkspace
            this.toWorkspace = toWorkspace
            this.location = location

            useModal().show('workspace-switch-confirmation')

            return new Promise((accept, reject) => {
                this.accept = accept
                this.reject = reject
            })
        },

        async confirm() {
            this.submitting = true

            this.accept()
            useModal().hide('workspace-switch-confirmation')
        },

        async cancel() {
            this.submitting = true

            this.reject('cancelled')
            useModal().hide('workspace-switch-confirmation')
        }
    }
})

export default useModalsWorkspaceSwitchConfirmationStore
