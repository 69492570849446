<template>
    <div class="p-4 overflow-auto" style="max-height: 440px">
        <ui-button @click="resetFilters" class="mb-1 w-full" v-if="filtersActive()">
            Reset filters
        </ui-button>

        <div>
            <div class="text-xl mb-2">General</div>
            <a href="#" class="flex items-center font-medium hover:text-blue-600 group" @click.prevent="hideLeafs" :class="{ 'text-blue-600': leafsHidden }">
                <ui-icon name="feather" class="mr-2"></ui-icon>
                <div>Hide leaf nodes</div>
                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! leafsHidden }"></ui-icon>
            </a>

            <a href="#" class="flex items-center font-medium hover:text-blue-600 group" @click.prevent="hideIsolatedNodes" :class="{ 'text-blue-600': isolatedHidden }">
                <ui-icon name="circle" class="mr-2"></ui-icon>
                <div>Hide isolated nodes</div>
                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! isolatedHidden }"></ui-icon>
            </a>

        </div>

        <div class="mt-4">
            <div class="float-right cursor-pointer">
                <ui-icon  name="eye" class="ml-2" @click="toggleShownChannel(true)"></ui-icon>
                <ui-icon name="eye-off" class="ml-2" @click="toggleShownChannel(false)"></ui-icon>
            </div>

            <div class="text-xl mb-2">Channels</div>
            <a href="#" class="flex items-center font-medium hover:text-blue-600 group" @click.prevent="toggleShownChannel(channel.type)" :class="{ 'text-blue-600': shownChannels.includes(channel.type) }" :key="channel.type" v-for="channel in channels">
                <ui-icon :name="channel.icon" class="mr-2"></ui-icon>
                <div>{{ channel.name }}</div>
                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! shownChannels.includes(channel.type) }"></ui-icon>
            </a>
        </div>

        <div class="mt-4">
            <div class="float-right cursor-pointer">
                <ui-icon name="eye" class="ml-2" @click="toggleShownFeature(true)"></ui-icon>
                <ui-icon name="eye-off" class="ml-2" @click="toggleShownFeature(false)"></ui-icon>
            </div>

            <div class="text-xl mb-2">Features</div>
            <a href="#" class="flex items-center font-medium hover:text-blue-600 group" :class="{ 'text-blue-600': shownFeatures.includes(feature.type) }" @click.prevent="toggleShownFeature(feature.type)" :key="feature.type" v-for="feature in features">
                <ui-icon :name="feature.icon" class="mr-2"></ui-icon>
                <div>{{feature.name}}</div>
                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! shownFeatures.includes(feature.type) }"></ui-icon>
            </a>
        </div>

        <div class="mt-4">
            <div class="float-right cursor-pointer">
                <ui-icon name="eye" class="ml-2" @click="toggleShownEdges(true)"></ui-icon>
                <ui-icon name="eye-off" class="ml-2" @click="toggleShownEdges(false)"></ui-icon>
            </div>

            <div class="text-xl mb-2">Edge types</div>
            <a href="#" class="flex items-center font-medium hover:text-blue-600 group" :class="{ 'text-blue-600': shownEdges.includes(edge) }" @click.prevent="toggleShownEdges(edge)" :key="edge" v-for="edge in edgesInGraph">
                <ui-icon name="corner-right-up" class="mr-2"></ui-icon>
                <div>{{ edge }}</div>
                <ui-icon name="check" class="ml-auto shrink-0" :class="{ 'text-gray-400': ! shownEdges.includes(edge) }"></ui-icon>
            </a>
        </div>

        <div class="mt-4">
            <div class="float-right cursor-pointer">
                {{ "< " +  weightSlider }}
            </div>
            <div class="text-xl mb-2">Edge weight</div>
            <input type="range" min="1" :max="store.maxEdgeWeight > 2000 ? 2000 : store.maxEdgeWeight" step="1" class="w-full h-1 my-4" v-model="weightSlider">
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "pinia"
import { sidebar_channels, sidebar_features } from "@/components/connections/node_styles"
import useGraphFiltersStore from "@/stores/connections/graph-filters"
import UiButton from "@/components/ui/button.vue"


export default {
    components: { UiButton },

    props: {
        store: Object
    },

    computed: {
        ...mapState(useGraphFiltersStore, [
            'shownChannels', 'channelsInGraph', 'shownFeatures', 'featuresInGraph', 'shownEdges', 'edgesInGraph',
            'leafsHidden', 'weightFilter', 'isolatedHidden'
        ]),

        channels() {
            return sidebar_channels.filter(ch => this.channelsInGraph.includes(ch.type))
        },

        features() {
            return sidebar_features.filter(f => this.featuresInGraph.includes(f.type))
        },

        weightSlider: {
            get() { return this.weightFilter },
            set(value) { this.setEdgeWeightFilter(value) }
        },
    },

    methods: {
        ...mapActions(useGraphFiltersStore, [
            'setShownChannels', 'setShownFeatures', 'setShownEdges', 'hideLeafs', 'filtersActive', 'resetFilters',
            'setEdgeWeightFilter', 'hideIsolatedNodes'
        ]),

        toggleShownChannel(channelType) {
            let shownChannels = [ ...this.shownChannels ]

            if (channelType === true) {
                shownChannels = [ ...this.channelsInGraph ]
            } else if (channelType === false) {
                shownChannels = []
            } else if (channelType) {
                if (shownChannels.includes(channelType)) {
                    shownChannels = shownChannels.filter(t => t !== channelType)
                } else {
                    shownChannels = [ ...shownChannels, channelType ]
                }
            }

            this.setShownChannels(shownChannels)
        },

        toggleShownFeature(featureType) {
            let shownFeatures = [ ...this.shownFeatures ]

            if (featureType === true) {
                shownFeatures = [ ...this.featuresInGraph ]
            } else if (featureType === false) {
                shownFeatures = []
            } else if (featureType) {
                if (shownFeatures.includes(featureType)) {
                    shownFeatures = shownFeatures.filter(t => t !== featureType)
                } else {
                    shownFeatures = [ ...shownFeatures, featureType ]
                }
            }

            this.setShownFeatures(shownFeatures)
        },

        toggleShownEdges(edge) {
            let shownEdges = [ ...this.shownEdges ]

            if (edge === true) {
                shownEdges = [ ...this.edgesInGraph ]
            } else if (edge === false) {
                shownEdges = []
            } else if (edge) {
                if (shownEdges.includes(edge)) {
                    shownEdges = shownEdges.filter(t => t !== edge)
                } else {
                    shownEdges = [ ...shownEdges, edge ]
                }
            }

            this.setShownEdges(shownEdges)
        },
    },

    watch: {
        weightSlider(value) {
            this.setEdgeWeightFilter(value)
        },
    }
}
</script>
