<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg w-full p-4 pt-0 flex flex-col min-w-0 relative h-80">
        <div class="flex justify-between">
            <div class="flex items-start gap-x-3">
                <div class="flex flex-col justify-center min-h-0 mx-3 cursor-pointer relative pt-4 overflow-hidden" :class="{ 'pt-5 pb-5': isComparing }" v-for="metric in metrics()" :key="metric.id" @click="selectedMetric = metric.id">
                    <div v-if="selectedMetric == metric.id" class="absolute -top-0.5 left-0 h-1.5 w-full rounded-full bg-blue-700"></div>
                    <div class="flex items-center gap-x-1 font-medium" :class="{ 'text-blue-600': selectedMetric == metric.id, 'text-gray-700': selectedMetric != metric.id, 'text-sm': ! isComparing }">
                        <ui-icon :name="metric.icon" class="text-lg"></ui-icon>
                        <h2>{{metric.name}}</h2>
                    </div>
                    <h1 class="text-3xl font-semibold" v-if="! isComparing">
                        {{$number(metric.current)}}
                    </h1>
                    <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="metric.previous && ! isComparing">
                        <div class="text-sm border rounded leading-tight whitespace-nowrap px-1" :class="metric.previous < metric.current ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                            <span :class="metric.previous < metric.current ? 'text-green-600' : 'text-red-600'">
                                <ui-icon :name="metric.previous < metric.current ? 'arrow-up' : 'arrow-down'"></ui-icon>
                                {{metric.change}}%
                            </span>
                        </div>
                        <div class="text-sm">
                            {{$number(metric.previous)}}
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex items-top gap-x-2 pt-4">
                <ui-data-quality-icon :quality="activeMetric().dataQuality"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex-1 min-h-0">
            <chart :options="chartOptions" ref="chart" class="h-full rounded-2xl"></chart>
        </div>

        <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20 bg-gray-100': isInitialized, 'bg-white': ! isInitialized}" v-if="isLoading">
            <ui-spinner></ui-spinner>
        </div>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

import useMetricsStore from '@/stores/content/details/metrics'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        Chart
    },

    computed: {
        ...mapState(useMetricsStore, [ 'chartOptions', 'isComparing', 'isInitialized', 'isLoading', 'series' ]),
        ...mapWritableState(useMetricsStore, [ 'selectedMetric' ])
    },

    methods: {
        ...mapActions(useMetricsStore, [ 'activeMetric', 'metrics' ])
    }
}
</script>
