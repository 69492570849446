<template>
    <div class="flex-1 min-h-0">
        <div class="divide-y divide-gray-100">
            <details-reply :reply="reply" v-for="reply in effectiveReplies" :replies-component="_.type" :key="reply.id"></details-reply>
        </div>
        
        <div v-if="! isLoading && ! effectiveReplies.length" class="py-8 pb-6 text-center text-gray-700 text-sm">
            No replies have been found.
        </div>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadAdditionalReplies(parent, $event)"></ui-infinite-loading>
    </div>
</template>

<script>
import DetailsReply from './replies-reply'

import useRepliesStore from '@/stores/content/details/replies'

import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'parent', 'replies' ],

    components: { DetailsReply },

    computed: {
        ...mapState(useRepliesStore, [
            'isLoading', 'lastReloadTime'
        ]),
        
        effectiveReplies() {
            return this.replies || useRepliesStore().replies
        }
    },

    methods: {
        ...mapActions(useRepliesStore, [ 'loadAdditionalReplies' ])
    }
}
</script>
