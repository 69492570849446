import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useChannelsModalsListDetachConfirmationStore = defineStore({
    id: 'channelsModalsListDetachConfirmation',

    state: () => ({
        accept: null,
        reject: null,
        submitting: false
    }),

    actions: {
        async open() {
            this.submitting = false

            useModal().show('channels-list-detach-confirmation')

            return new Promise((accept, reject) => {
                this.accept = accept
                this.reject = reject
            })
        },

        async confirm() {
            this.submitting = true

            this.accept()
            useModal().hide('channels-list-detach-confirmation')
        },

        async cancel() {
            this.submitting = true

            this.reject('cancelled')
            useModal().hide('channels-list-detach-confirmation')
        }
    }
})

export default useChannelsModalsListDetachConfirmationStore
