<template>
    <div class="flex flex-col h-full bg-gray-75" ref="pageContainer">
        <template v-if="$page.graph.inspectedNodeIds.length">
            <ui-header
                v-if="$page.graph.nodes.length"
                class="fixed"
                :title="$page.graph.graphId === 'new' ? 'New graph' : $page.graph.graphName"
                avatar-type="perspectives"
                :back="{ name: 'connections.graphs' }"
                :no-bottom-margin="true"
            >
                <template v-slot:actions>
                    <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-8">
                        <a @click.prevent="() => { $page.graph.switchView('graph') }" href="#" class="flex items-center justify-center px-3" :class="$page.graph.shownComponent === 'graph' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Graph view
                        </a>
                        <a @click.prevent="() => { $page.graph.switchView('data') }" href="#" class="flex items-center justify-center px-3" :class="$page.graph.shownComponent === 'data' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Data view
                        </a>
                    </div>

                    <ui-button color="blue" :icon="!$page.graph.isSaving ? 'check-circle' : ''" @click="$page.graph.saveGraph()" :disabled="$page.graph.isSaving || $page.graph.nodes.length > 10000">
                        <span v-if="!$page.graph.isSaving">Save</span>
                        <span v-else class="flex items-center">
                            <ui-spinner type="clip" class="mr-2 float-left"></ui-spinner> Saving
                        </span>
                    </ui-button>
                </template>
            </ui-header>
        </template>

        <template v-else-if="$page.graph.graphId === 'new' && !$page.graph.isLoading">
            <div class="w-full h-full flex-1 flex items-center justify-center">
                <div class="bg-white border border-gray-200 shadow-sm rounded-md px-8 py-12 flex flex-col items-center text-center space-y-6 w-96">
                    <h1 class="text-xl font-semibold">
                        Choose a starting point
                    </h1>
                    <div>To start your exploration, you will need to choose a Channel as a starting point.</div>
                    <div>You will be able to load additional connections and add more Channels later.</div>
                    <ui-button @click="$page.chooseChannel" color="blue" size="lg">Choose a channel</ui-button>
                </div>
            </div>
        </template>

        <ui-async v-show="$page.graph.inspectedNodeIds.length && $page.graph.shownComponent === 'data'" class="bg-white" :guards="$page.graph">
            <graph-data-table :store="$page.graph"></graph-data-table>
        </ui-async>

        <ui-async v-show="$page.graph.inspectedNodeIds.length && $page.graph.shownComponent === 'graph'" :guards="$page.graph" class="relative">
            <div class="w-full h-full cursor-grabbing" id="connections-graph-container"></div>

            <div class="absolute top-4 left-1/2 transform -translate-x-1/2 z-10 flex bg-white rounded-lg p-1 opacity-80" v-if="$page.graph.isProcessing">
                <ui-spinner type="clip" class="text-3xl mr-2"></ui-spinner> {{ $page.graph.processingMessage }}
            </div>

            <div class="w-72 absolute top-4 left-4 z-20" v-if="$page.graph.nodes.length">
                <ui-input icon="search" type="search" class="pl-8 mb-2 shadow-sm" placeholder="Search in graph" v-model="searchQuery"></ui-input>
                <selected-nodes :store="$page.graph"></selected-nodes>
            </div>

            <div class="absolute bottom-4 left-4 flex flex-col items-start gap-y-3" v-if="$page.graph.nodes.length">
                <graph-view-panel :store="$page.graph" :pageContainer="$refs.pageContainer"></graph-view-panel>
                <color-legend></color-legend>
                <size-legend></size-legend>
            </div>

            <template v-if="$page.graph.nodes.length">
                <tools-panel class="absolute top-4 right-4" :store="$page.graph"></tools-panel>
                <context-menu :store="$page.graph"></context-menu>
            </template>

            <div class="fixed top-96 z-10 flex justify-center w-full" v-if="!$page.graph.isLoading && !$page.graph.nodes.length && $page.graph.inspectedNodeIds.length">
                <ui-icon name="slash" class="pt-1" style="height: 2em; width: 2em"></ui-icon>
                <div class="text-2xl mt-0.5 ml-0.5">There's no data for this channel yet</div>
            </div>
        </ui-async>
    </div>

    <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10" v-if="$page.graph.isLoading">
        <div class="bg-gray-200/80 font-medium backdrop-blur shadow-sm rounded-lg flex flex-col items-center px-6 py-4 gap-y-0.5">
            <ui-spinner type="clip" class="text-3xl"></ui-spinner>
            <div>{{ $page.graph.loadingMessage }}</div>
        </div>
    </div>

    <div v-if="$page.graph.notFound" class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 z-10">
        <div class="text-2xl text-gray-700 text-center">
            Graph not found :(
        </div>
    </div>

    <Teleport to="#root">
        <edge-explain></edge-explain>
        <connections-modals-node-details></connections-modals-node-details>
        <create-graph></create-graph>
    </Teleport>

    <div id="selection-rectangle"></div>
</template>

<script>
import ConnectionsModalsNodeDetails from '@/components/connections/modals/node-details'
import ToolsPanel from "@/components/connections/partials/tools-panel.vue"
import ContextMenu from "@/components/connections/partials/context-menu.vue"
import UiButton from "@/components/ui/button.vue"
import CreateGraph from "@/components/connections/modals/graph-name.vue"
import SelectedNodes from "@/components/connections/partials/selected-nodes.vue"
import ColorLegend from "@/components/connections/partials/color-legend.vue"
import SizeLegend from "@/components/connections/partials/size-legend.vue"
import GraphDataTable from "@/components/connections/graph-data-table.vue"
import GraphViewPanel from "@/components/connections/partials/graph-view-panel.vue"
import EdgeExplain from "@/components/connections/modals/edge-explain.vue"

export default {
    components: {
        GraphDataTable,
        SizeLegend,
        ColorLegend,
        SelectedNodes,
        UiButton,
        ContextMenu,
        ToolsPanel,
        ConnectionsModalsNodeDetails,
        CreateGraph,
        GraphViewPanel,
        EdgeExplain
    },

    data: () => ({
        searchQuery: ""
    }),

    watch: {
        searchQuery: function (newVal) {
            this.$page.graph.searchNodes(newVal)
        }
    },
}
</script>

<style>
#selection-rectangle {
    position: absolute;
    border: 2px solid rgba(0, 0, 255, 0.5);
    background-color: rgba(0, 0, 255, 0.1);
    display: none;
}
</style>
