<template>
    <div class="py-6 px-6 xl:px-16 max-w-12xl mx-auto min-h-full flex flex-col">
        <div v-if="!isLoading && !graphs.length" class="flex-1 flex items-center">
            <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                <ui-icon name="share" class="text-6xl text-gray-400"></ui-icon>

                <h1 class="text-3xl font-semibold mt-6">Welcome to the Connections section!</h1>
                <div class="space-y-4 my-6">
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">
                            <span class="font-medium">Explore</span> Connections between channels in full featured graph visualizer.
                        </p>
                    </div>

                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">
                            <span class="font-medium">Analyze</span> the network of interconnected Channels in a visual way.
                        </p>
                    </div>

                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">
                            <span class="font-medium">Use</span> advanced graph algorithms to find patterns in the data.
                        </p>
                    </div>
                </div>

                <div>
                    <ui-button color="blue" size="lg" :action="newGraph">Create a graph</ui-button>
                </div>
            </div>
        </div>

        <template v-else>
            <div class="flex justify-between items-center mb-6">
                <h1 class="text-[24px] font-semibold">Connections</h1>
                <ui-button color="blue" size="" :action="newGraph">New Graph</ui-button>
            </div>

            <h1 class="text-gray-700 text-xl font-medium leading-none mb-5">Graphs</h1>

            <div v-if="!isLoading" class="w-full divide-y divide-gray-300 border-t border-b border-gray-300">
                <router-link v-for="graph in graphs" :key="graph.id" :to="{ name: 'connections.graphs.graph-detail', params: { id: graph.id } }" class="block hover:bg-gradient-to-r from-transparent via-gray-50 to-transparent transition">
                    <div class="flex h-12 group relative">
                        <div class="pl-2 flex items-center flex-1 min-w-0">
                            <div class="shrink-0 relative">
                                <ui-avatar :item="graph" class="w-7 h-7 drop-shadow-sm shrink-0"></ui-avatar>
                            </div>

                            <div class="min-w-0 px-3 space-x-2">
                                <span class="text-xl font-semibold truncate leading-tight" v-tooltip="graph.name">{{ graph.name }}</span>
                            </div>
                        </div>

<!--                        <div class="w-20 pt-2">-->
<!--                            <div class="text-2xs text-gray-700 leading-tight">Nodes</div>-->
<!--                            <div class="font-semibold text-sm">{{ graph.nodes }}</div>-->
<!--                        </div>-->

<!--                        <div class="w-20 pt-2">-->
<!--                            <div class="text-2xs text-gray-700 leading-tight">Edges</div>-->
<!--                            <div class="font-semibold text-sm">{{ graph.edges }}</div>-->
<!--                        </div>-->

                        <div class="w-40 pt-2">
                            <div class="text-2xs text-gray-700 leading-tight">Created</div>
                            <div class="font-semibold text-sm">{{ $dateTime(graph.created_at) }}</div>
                        </div>

                        <div class="w-40 pt-2">
                            <div class="text-2xs text-gray-700 leading-tight">Updated</div>
                            <div class="font-semibold text-sm">{{ $dateTime(graph.updated_at) }}</div>
                        </div>

                        <div class="w-10">
                            <ui-dropdown width="w-auto">
                                <template v-slot:trigger>
                                    <div class="flex items-center justify-center">
                                        <button aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                                            <ui-icon name="more-horizontal" class="h-10"></ui-icon>
                                        </button>
                                    </div>
                                </template>

                                <template v-slot:content>
<!--                                    <div class="flex items-center text-lg font-medium cursor-default py-1 px-2 rounded">-->
<!--                                        <div class="ml-1 text-xs">Graph actions</div>-->
<!--                                    </div>-->

                                    <ui-dropdown-link icon="edit" @click="editGraph(graph)">Rename</ui-dropdown-link>
                                    <ui-dropdown-link icon="trash" @click="deleteGraph(graph)">Delete</ui-dropdown-link>
                                </template>
                            </ui-dropdown>
                        </div>
                    </div>
                </router-link>
            </div>

            <perspective-placeholder-row v-else v-for="i in [...Array(10).keys()]" :key="i"></perspective-placeholder-row>
        </template>

        <Teleport to="#root">
            <create-graph :graph="editingGraph"></create-graph>
        </Teleport>
    </div>
</template>

<script>

import useGraphsStore from "@/stores/connections/graphs.js"
import { mapActions, mapState } from "pinia"
import CreateGraph from "@/components/connections/modals/graph-name.vue"
import { useModal, useRouter } from "@/helpers.js"
import PerspectivePlaceholderRow from "@/components/content/stream-cards/perspective-placeholder-row.vue";


export default {
    components: {
        PerspectivePlaceholderRow,
        CreateGraph
    },

    data: () => ({ }),

    computed: {
        ...mapState(useGraphsStore, [ 'graphs', 'isLoading', 'editingGraph' ]),
    },

    methods: {
        ...mapActions(useGraphsStore, [ 'deleteGraph', 'setEditingGraph' ]),

        newGraph() {
            useRouter().push({ name: 'connections.graphs.graph-detail', params: { id: 'new' } })
        },

        editGraph(graph) {
            this.setEditingGraph(graph)
            useModal().show('graph-name')
        }
    }
}
</script>
