<template>
    <div class="content-card bg-white max-w-4xl w-full overflow-visible shadow rounded-lg relative flex flex-col">
        <div class="content-card-content shrink-0 px-5 py-4">
        <svg
            role="img"
            :width="large ? '400' : '400'"
            :height="large ? '364' : '106'"
            aria-labelledby="loading-aria"
            :viewBox="large ? '0 0 400 364' : '0 0 400 106'"
            preserveAspectRatio="none"
        >
            <title id="loading-aria">Loading...</title>
            <rect
                x="0"
                y="0"
                width="100%"
                height="100%"
                clip-path="url(#clip-path)"
                style='fill: #eaedef;'
            ></rect>
            <defs>
                <clipPath id="clip-path">
                    <circle cx="16" cy="16" r="16" />
                    <rect x="44" y="4" rx="4" ry="4" width="160" height="11" />
                    <rect x="44" y="21" rx="4" ry="4" width="80" height="8" />
                    <rect x="1" y="55" rx="3" ry="3" width="420" height="8" />
                    <rect x="1" y="77" rx="3" ry="3" width="380" height="8" />
                    <rect x="1" y="98" rx="3" ry="3" width="180" height="8" />
                    <rect x="1" y="127" rx="4" ry="4" width="508" height="240" />
                </clipPath>
            </defs>
        </svg>
    </div>
    </div>
</template>

<script>
export default {
    props: { large: Boolean }
}
</script>
