import { defineStore } from 'pinia'
import { useModal } from '@/helpers'
import { channels, content, features } from '@/helpers/api-three-tables'

import useContentDetailsStore from '@/stores/content/details'
import useContentPerspectiveStreamStore from '@/stores/content/perspective/stream'
import defineConnectionsGraph from "@/stores/connections/connections-graph.js"

export const useConnectionsModalsNodeDetailsStore = defineStore({
    id: 'connections-modals-node-details',

    state: () => ({
        nodeId: null,
        graphStore: null,

        channel: null,
        content: null,
        feature: null,

        isLoading: false
    }),

    actions: {
        open(family, nodeId) {
            this.$reset()

            this.nodeId = nodeId
            this.graphStore = defineConnectionsGraph({ id: 'connectionsGraph' })()

            useModal().show('connections-node-details')

            this.isLoading = true

            if (family === 'Feature') {
                features().find(nodeId, feature => {
                    this.feature = feature
                    this.isLoading = false
                })
            } else if (family === 'Content') {
                content().find(nodeId, content => {
                    this.content = content
                    this.isLoading = false
                    useContentDetailsStore().load(this.content, useContentPerspectiveStreamStore())
                })
            } else {
                channels().include([ 'lists', 'monitoringOptions', 'media' ]).find(nodeId, channel => {
                    this.channel = channel
                    this.isLoading = false
                })
            }
        },

        close() {
            useModal().hide('connections-node-details')
        },

        loadConnections() {
            this.graphStore.inspectNode([this.nodeId])
            this.close()
        },

        hideNode() {
            this.graphStore.hideNode([this.nodeId])
            this.close()
        }
    }
})

export default useConnectionsModalsNodeDetailsStore
