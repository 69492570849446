import { defineStore } from 'pinia'
import api from "@/api.js";
import { useModal, useRouter } from "@/helpers.js"
import useDeleteConfirmationModal from "@/stores/modals/delete-confirmation.js"


const useGraphsStore = defineStore({
    id: 'connections',

    state: () => ({
        isLoading: false,
        graphs: [],
        editingGraph: null,

        newGraphData: null,
        newGraphSettings: null,

        isSaving: false
    }),

    actions: {
        init() {
            this.isLoading = true

            api.route('connections graph index', true)
                .get()
                .json(res => {
                    this.graphs = res
                    this.isLoading = false
                })
        },

        setEditingGraph(graph) {
            this.editingGraph = graph
        },

        submit(name) {
            if (this.editingGraph) {
                this.renameGraph(name)
            } else {
                this.createNewGraph(name)
            }
        },

        createNewGraph(name) {
            this.isSaving = true

            api.route('connections graph store', true)
                .json({ name: name, data: this.newGraphData, settings: this.newGraphSettings })
                .post()
                .json(res => {
                    this.newGraphData = null
                    this.newGraphSettings = null
                    this.isSaving = false

                    useModal().hide('graph-name')
                    useRouter().push({ name: 'connections.graphs.graph-detail', params: { id: res.graph.id } })
                })
        },

        renameGraph(name) {
            this.isSaving = true
            api.route('connections graph update', { id: this.editingGraph.id })
                .json({ name: name } )
                .put()
                .json(res => {
                    this.isSaving = false
                    useModal().hide('graph-name')
                    this.init()
                })
        },

        deleteGraph(graph) {
            return useDeleteConfirmationModal().open(graph.name + " graph")
                .then(() => {
                    this.isLoading = true
                    api.route('connections graph delete', {id: graph.id})
                        .delete()
                        .json(res => { this.init() })
                })
        },

        setNewGraphFields(data, settings) {
            this.newGraphData = data
            this.newGraphSettings = settings
        }
    }
})

export default useGraphsStore
