<template>
    <div class="flex h-12 group relative">
        <div class="pl-2 flex items-center flex-1 min-w-0" v-tooltip="app.description">
            <ui-avatar :item="app" class="w-7 h-7 shrink-0"></ui-avatar>

            <div class="min-w-0 px-2">
                <h1 class="text-xl font-semibold truncate leading-tight">
                    {{app.name}}
                </h1>
            </div>
        </div>

        <div class="flex items-center w-48 text-gray-700">
            {{app.token}}
        </div>

        <div class="flex items-center w-48 text-gray-700">
            {{$dateTime(app.createdAt)}}
        </div>

        <div class="flex items-center px-3">
            <contextual-menu :app="app"></contextual-menu>
        </div>
    </div>
</template>

<script>
import ContextualMenu from '@/components/settings/contextual-menus/app'

export default {
    props: {app: {}, cardsStore: {} },

    components: { ContextualMenu }
}
</script>
