<template>
    <div class="bg-gray-50 rounded-lg flex items-center cursor-default shrink-0">
        <ui-dropdown align="right">
            <template v-slot:trigger>
                <div class="px-5 py-3 group">
                    <div class="text-blue-700 group-hover:text-blue-800 group-hover:font-medium">
                        <ui-icon :name="store.selectedType.icon"></ui-icon>
                        {{store.selectedType.name}}
                        <ui-icon name="select" class="ml-1"></ui-icon>
                    </div>
                    <div class="text-xs text-gray-800 font-semibold">Type</div>
                </div>
            </template>
            <template v-slot:content>
                <template :key="index" v-for="type, index in store.types">
                    <ui-dropdown-link v-if="type" :icon="type.icon" @click="store.setType(type.id)">{{type.name}}</ui-dropdown-link>
                    <ui-dropdown-separator v-else></ui-dropdown-separator>
                </template>
            </template>
        </ui-dropdown>
    </div>
</template>
