import useContentPerspectiveOverviewStore from '@/stores/content/perspective/overview'
import useContentPerspectiveStreamStore from '@/stores/content/perspective/stream'
import useMyBookmarksStore from '@/stores/me/bookmarks'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyTopicsStore from '@/stores/me/topics'
import useTaggingStore from '@/stores/tagging/tagging'

import { defineStore } from 'pinia'

export const useContentPerspectivesPerspectivePage = defineStore({
    id: 'contentPerspectivesPerspectivePage',

    state: () => ({
        perspective: {},
        selectedMode: null,
        showFilters: true,
        isSearchFocused: false
    }),

    getters: {
        title: store => store.perspective?.name ? `${store.perspective.name} | Content` : '',

        streamStore: store => useContentPerspectiveStreamStore(),

        mode: store => store.selectedMode || (store.prespective ? 'overview' : 'stream')
    },

    actions: {
        async afterEnter(to, from) {
            useMyBookmarksStore().initialize()

            await Promise.all([
                useMyChannelListsStore().initialize(),
                useMyPerspectivesStore().initialize(),
                useMyTopicsStore().initialize(),
                useTaggingStore().initialize()
            ])

            this.perspective = useMyPerspectivesStore().find(to.params.perspectiveId)
            if (this.perspective?.lastUsedAt) { this.perspective.lastUsedAt = new Date }

            this.streamStore.initialize(to)

            if (! this.perspective) this.showFilters = true

            if (this.mode == 'overview') useContentPerspectiveOverviewStore().initialize(this.streamStore.filters, this.perspective, true)
        },

        async afterLeave(to, from) {
            useContentPerspectiveOverviewStore().isInitialized = false

            if (this.streamStore.triggeredNavigation) {
                return this.streamStore.triggeredNavigation--
            }

            this.streamStore.whilePaused(store => store.reset())
            this.selectedMode = null
        },

        selectMode(mode) {
            this.selectedMode = mode

            if (mode == 'overview') useContentPerspectiveOverviewStore().initialize(this.streamStore.filters, this.perspective)

            // update perspective if selected
        }
    }
})

export default useContentPerspectivesPerspectivePage
