<template>
    <radial-progress
        v-if="type === 'radial'"
        :total-steps="effectiveTotalSteps"
        :completed-steps="effectiveCompletedSteps"
        innerStrokeColor="#e0e5e8"
        start-color="#2172f0"
        stop-color="#5d96f4"
        :diameter="effectiveDiameter"
        :strokeWidth="effectiveStroke"
        :innerStrokeWidth="effectiveStroke"
    >
        <slot></slot>
    </radial-progress>
</template>
<script>
import RadialProgress from "vue3-radial-progress"

export default {
    components: { RadialProgress },

    props: {
        type: { default: 'radial' },
        totalSteps: { default: 100 },
        completedSteps: { default: 0 },
        diameter: null,
        stroke: null,
        size: null,
        task: {}
    },

    computed: {
        effectiveTotalSteps() {
            return this.task?.totalUnits || this.totalSteps
        },

        effectiveCompletedSteps() {
            return this.task?.completedUnits || this.completedSteps
        },

        effectiveDiameter() {
            return this.diameter ?? (this.size == 'lg' ? 64 : 24)
        },

        effectiveStroke() {
            return this.stroke ?? (this.size == 'lg' ? 10 : 5)
        }
    }
}
</script>
