// import useListSuggestionsStore from '@/stores/channels/list-suggestions'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useQuickSearchStore from '@/stores/me/quick-search'

import { defineStore } from 'pinia'

export const useChannelsModalsListAddChannelStore = defineStore({
    id: 'channelsModalsListAddChannel',

    state: () => ({
        list: {}
    }),

    actions: {
        open(list, addedCallback = null) {
            this.$reset()

            this.list = list

            useQuickSearchStore().show({
                families: [ 'channels' ],
                limit: 50,
                onSelect: result => {
                    this.add(result).then(() => {
                        if (addedCallback) { addedCallback(result) }
                    })
                    useQuickSearchStore().hide()
                },
                // initialResults: useListSuggestionsStore().suggestedChannels.map(item => ({
                //     id: item.id,
                //     resultType: 'channel',
                //     type: item.type,
                //     title: item.title,
                //     avatar: item.avatar,
                //     flags: [ item.monitored ? 'monitored' : null, item.verified ? 'verified' : null ].filter(v => v),
                //     description: []
                // })),
            })
        },

        add(result, callback = null) {
            return useMyChannelListsStore().addToList(this.list, result)
        },
    }
})

export default useChannelsModalsListAddChannelStore
