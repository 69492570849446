<template>
    <div :key="node.id" v-for="node in nodes" class="flex items-center justify-between rounded transition hover:bg-blue-400 hover:text-white group">
        <a href="#" class="py-1 px-2 truncate" @click.prevent="store.focusNode(node.id)">
            <ui-icon :name="node.icon" class="shrink-0 mr-1"></ui-icon>
            <span>{{ node.name }}</span>
        </a>

        <div class="flex">
            <a href="#" class="py-1 hidden group-hover:block" @click.prevent="store.selectNodes([node.id])" v-if="!store.selectedNodes.includes(node.id)" title="Select node">
                <ui-icon name="check-square" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
            </a>

            <a href="#" class="py-1 hidden group-hover:block" @click.prevent="store.selectNodes([node.id])" v-if="store.selectedNodes.includes(node.id)" title="Deselect node">
                <ui-icon name="square" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
            </a>

            <a href="#" class="py-1 hidden group-hover:block" @click.prevent="store.deleteNode([node.id])" title="Delete node">
                <ui-icon name="trash" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
            </a>

            <a href="#" class="py-1 hidden group-hover:block" @click.prevent="store.hideNode([node.id])" title="Hide node" v-if="!store.hiddenNodes.includes(node.id) && store.inspectedNodeIds[0] != node.id">
                <ui-icon name="eye-off" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
            </a>
            <a href="#" class="py-1" @click.prevent="store.showNode([node.id])" title="Show node" v-if="store.hiddenNodes.includes(node.id)">
                <ui-icon name="eye" class="mr-1 text-gray-700 group-hover:text-white"></ui-icon>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        nodes: Array,
        store: Object,
    }
}
</script>
