const uiComponents = import.meta.glob('@/components/ui/**/*.vue', { eager: true, import: 'default' })

export default function registerComponents(app) {
    app.component('ui-access-icon', uiComponents['/src/components/ui/access-icon.vue'])
    app.component('ui-access-input', uiComponents['/src/components/ui/access-input.vue'])
    app.component('ui-async', uiComponents['/src/components/ui/async.vue'])
    app.component('ui-avatar', uiComponents['/src/components/ui/avatar.vue'])
    app.component('ui-avatar-input', uiComponents['/src/components/ui/avatar-input.vue'])
    app.component('ui-avatar-upload', uiComponents['/src/components/ui/avatar-upload.vue'])
    app.component('ui-button', uiComponents['/src/components/ui/button.vue'])
    app.component('ui-cards', uiComponents['/src/components/ui/cards/cards.vue'])
    app.component('ui-cards-section', uiComponents['/src/components/ui/cards/cards-section.vue'])
    app.component('ui-cards-toolbar', uiComponents['/src/components/ui/cards/toolbar/results.vue'])
    app.component('ui-analysis', uiComponents['/src/components/ui/analysis.vue'])
    app.component('ui-contextual-menu', uiComponents['/src/components/ui/contextual-menu.vue'])
    app.component('ui-data-quality-icon', uiComponents['/src/components/ui/data-quality-icon.vue'])
    app.component('ui-date-picker', uiComponents['/src/components/ui/date-picker.vue'])
    app.component('ui-dropdown', uiComponents['/src/components/ui/dropdown.vue'])
    app.component('ui-dropdown-link', uiComponents['/src/components/ui/dropdown-link.vue'])
    app.component('ui-dropdown-separator', uiComponents['/src/components/ui/dropdown-separator.vue'])
    app.component('ui-editor', uiComponents['/src/components/ui/editor.vue'])
    app.component('ui-form', uiComponents['/src/components/ui/form.vue'])
    app.component('ui-header', uiComponents['/src/components/ui/header.vue'])
    app.component('ui-help-button', uiComponents['/src/components/ui/help-button.vue'])
    app.component('ui-icon', uiComponents['/src/components/ui/icon.vue'])
    app.component('ui-infinite-loading', uiComponents['/src/components/ui/infinite-loading.vue'])
    app.component('ui-input', uiComponents['/src/components/ui/input.vue'])
    app.component('ui-modal', uiComponents['/src/components/ui/modal.vue'])
    app.component('ui-navigation-section', uiComponents['/src/components/ui/navigation-section.vue'])
    app.component('ui-progress', uiComponents['/src/components/ui/progress.vue'])
    app.component('ui-select', uiComponents['/src/components/ui/select.vue'])
    app.component('ui-spark-analysis', uiComponents['/src/components/ui/spark-analysis.vue'])
    app.component('ui-spinner', uiComponents['/src/components/ui/spinner.vue'])
    app.component('ui-switch', uiComponents['/src/components/ui/switch.vue'])
    app.component('ui-table', uiComponents['/src/components/ui/table.vue'])
    app.component('ui-channel-link', uiComponents['/src/components/ui/links/channel.vue'])
}
