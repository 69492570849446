<template>
    <ui-modal name="graph-name" title="Graph" :max-width="520" :max-height="680">
        <div class="flex gap-x-8">
            <div class="flex-1">
                <div>
                    <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                        Name
                    </label>

                    <div>
                        <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="name"></ui-input>
                    </div>
                </div>
            </div>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="submit" color="blue" :action="createGraph">
                {{ graph?.name ? 'Save' : 'Create' }}
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>

import useGraphsStore from "@/stores/connections/graphs";
import { mapActions, mapState } from "pinia";

export default {
    props: {
        graph: { type: Object, required: false }
    },

    data: () => ({
        name: ''
    }),

    computed: {
        ...mapState(useGraphsStore, [
            'graphs', 'editingGraph', 'isSaving'
        ]),
    },

    methods: {
        ...mapActions(useGraphsStore, ['submit']),

        createGraph() {
            this.submit(this.name);
        }
    },

    watch: {
        graph() {
            this.name = this.graph?.name || '';
        }
    }
}
</script>
