<template>
    <div class="p-4 overflow-x-auto">
        <div class="text-xl">Nodes</div>
        <div class="block text-sm font-medium leading-6 text-gray-900">Size</div>
        <ui-select :options="nodeSizeByOptions" :value="nodeSizeBy" @change="setNodeSize" form class="w-full"></ui-select>

        <input v-if="nodeSizeBy == 'fixed'" type="range" min="3" max="22" step="1" class="w-full h-1 my-4" v-model="sizeSlider">

        <div class="mt-2"  v-if="nodeSizeBy == 'attribute'">
            <div class="block text-sm font-medium leading-6 text-gray-900s">Size by attribute:</div>

            <ui-select :options="nodeSizeByAttrOptions" :value="nodeSizeByAttr" @change="setNodeSize('attribute', $event)" form class="w-full"></ui-select>
        </div>

        <div class="block text-sm font-medium leading-6 text-gray-900s mt-2">Style</div>
        <ui-select :options="nodeStyleOptions" :value="nodeStyle" @change="setNodeStyle" form class="w-full"></ui-select>

        <div class="mt-2" v-if="nodeStyle == 'color'">
            <div class="block text-sm font-medium leading-6 text-gray-900s">Color by attribute:</div>

            <ui-select :options="nodeColorAttrOptions" :value="nodeColorAttr" @change="setNodeStyle('color', $event)" form class="w-full"></ui-select>
        </div>

        <ui-dropdown-separator class="my-4"></ui-dropdown-separator>

        <div class="text-xl">Edges</div>
        <div class="block text-sm font-medium leading-6 text-gray-900">Thickness</div>
        <ui-select :options="edgeThicknessStyleOptions" :value="edgeThicknessStyle" @change="setEdgeThickness" form class="w-full"></ui-select>

        <input v-if="edgeThicknessStyle == 'fixed'" type="range" min="0" max="5" step="0.5" class="w-full h-1 my-4" v-model="thicknessSlider">

        <div class="block text-sm font-medium leading-6 text-gray-900 mt-2">Color</div>
        <ui-select :options="edgeColorStyleOptions" :value="edgeColorStyle" @change="setEdgeStyle" form class="w-full"></ui-select>

        <div class="mt-3" v-if="edgeColorStyle == 'fixed'">
            Pick a color:
            <div class="float-right">
                <color-picker v-model:pureColor="edgeColorPicker" />
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions, mapState } from "pinia"
import "vue3-colorpicker/style.css"
import { ColorPicker } from "vue3-colorpicker"
import useGraphStyleStore from "@/stores/connections/graph-style"
import useGraphMetricsStore from "@/stores/connections/graph-metrics.js"

export default {
    components: {
        ColorPicker
    },

    data: () => ({
        nodeSizeByOptions: [
            { id: 'fixed', name: 'Fixed' },
            { id: 'attribute', name: 'By attribute' }
        ],

        nodeStyleOptions: [
            { id: 'icons', name: 'Channel icons' },
            { id: 'pictures', name: 'Profile pictures' },
            { id: 'color', name: 'Color by attribute' }
        ],

        edgeThicknessStyleOptions: [
            { id: 'fixed', name: 'Fixed'},
            { id: 'weight', name: 'By weight'}
        ],

        edgeColorStyleOptions: [
            { id: 'fixed', name: 'Fixed' },
            { id: 'type', name: 'By type' },
            { id: 'weight', name: 'By weight' }
        ]
    }),

    computed: {
        nodeSizeByAttrOptions() {
            return [
                { id: 'nodeDegree', name: 'Node degree' },
                { id: 'followers', name: 'Followers' },
                this.computed && this.computed['pagerank'] ? { id: 'pagerank', name: 'Pagerank' } : null,
                this.computed && this.computed['centrality'] ? { id: 'centrality', name: 'Betweenness centrality' } : null,
                this.computed && this.computed['eigenvector'] ? { id: 'eigenvector', name: 'Eigenvector centrality' } : null
            ].filter(Boolean)
        },

        nodeColorAttrOptions() {
            return [
                { id: 'nodeDegree', name: 'Node degree'},
                { id: 'followers', name: 'Followers'},
                { id: 'subclass', name: 'Subclass'},
                this.computed && this.computed['pagerank'] ? { id: 'pagerank', name: 'Pagerank'} : null,
                this.computed && this.computed['centrality'] ? { id: 'centrality', name: 'Betweenness centrality'} : null,
                this.computed && this.computed['eigenvector'] ? { id: 'eigenvector', name: 'Eigenvector centrality'} : null
            ].filter(Boolean)
        },

        ...mapState(useGraphStyleStore, [
            'nodeStyle', 'nodeColorAttr', 'nodeSizeBy', 'nodeSize', 'nodeSizeByAttr' ,
            'edgeColor', 'edgeThickness', 'edgeColorStyle', 'edgeThicknessStyle',
        ]),

        ...mapState(useGraphMetricsStore, [ 'computed' ]),

        sizeSlider: {
            get() { return this.nodeSize },
            set(value) { this.changeNodeSize(value) }
        },

        thicknessSlider: {
            get() { return this.edgeThickness },
            set(value) { this.setEdgeThickness('fixed', value) }
        },

        edgeColorPicker: {
            get() { return this.edgeColor },
            set(value) { this.setEdgeStyle('fixed', value) }
        },
    },

    methods: {
        ...mapActions(useGraphStyleStore, [
            'setNodeStyle', 'setNodeSize', 'setEdgeStyle', 'setEdgeThickness', 'changeNodeSize'
        ])
    },

    watch: {
        sizeSlider(value) {
            this.changeNodeSize(value)
        },

        thicknessSlider(value) {
            this.setEdgeThickness('fixed', value)
        },

        edgeColorPicker(value) {
            this.setEdgeStyle('fixed', value)
        }
    }
}
</script>

<style>
.vc-color-wrap {
    border-radius: 10px;
    margin-right: 0!important;
}
</style>
