import useMyAnalysesStore from '@/stores/me/analyses'
import useQuickSearchStore from '@/stores/me/quick-search'

import { defineChannelAudienceAnalysisStore } from '@/stores/analysis/analyses/channel-audience'
import { defineChannelPublishTimesAnalysisStore } from '@/stores/analysis/analyses/channel-publish-times'
import { defineContentKeywordsAnalysisStore } from '@/stores/analysis/analyses/content-keywords'
import { defineContentPerformanceAnalysisStore } from '@/stores/analysis/analyses/content-performance'
import { defineContentPublishTimesAnalysisStore } from '@/stores/analysis/analyses/content-publish-times'
import { defineContentPullPushAnalysisStore } from '@/stores/analysis/analyses/content-pull-push'
import { defineContentSentimentAnalysisStore } from '@/stores/analysis/analyses/content-sentiment'
import { defineContentTopChannelsAnalysisStore } from '@/stores/analysis/analyses/content-top-channels'
import { defineContentTopPlatformsAnalysisStore } from '@/stores/analysis/analyses/content-top-platforms'

import { sortAlphabetically } from '@/helpers/sorting'
import searchFilters from '@/helpers/search-filters'

import clone from 'just-clone'
import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const defineAnalysisWidget = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            wid: settings.id,

            type: settings.type,

            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,

            analysisId: settings.analysisId,
            view: settings.view || 'timeline',
            options: settings.options || {},

            analysisStore: null,

            viewOptions: [
                {
                    id: 'table', name: 'Table', icon: 'table',
                    availableFor: [ 'content-top-channels', 'content-top-platforms' ]
                },
                {
                    id: 'timeline', name: 'Timeline', icon: 'chart',
                    availableFor: [ 'content-top-channels', 'content-performance', 'content-pull-push', 'content-sentiment', 'content-top-platforms', 'channel-audience' ]
                },
                {
                    id: 'share', name: 'Share', icon: 'chart',
                    availableFor: [ 'content-top-channels', 'content-sentiment', 'content-top-platforms' ]
                },
                {
                    id: 'daily-heatmap', name: 'Daily Heatmap', icon: 'chart',
                    availableFor: [ 'content-publish-times', 'channel-publish-times' ]
                },
                {
                    id: 'hourly-heatmap', name: 'Hourly Heatmap', icon: 'chart',
                    availableFor: [ 'content-publish-times', 'channel-publish-times' ]
                },
                {
                    id: 'keywords', name: 'Keywords', icon: 'word',
                    availableFor: [ 'content-keywords' ]
                }
            ],

            supportedAnalyses: markRaw({
                'channel-audience': {
                    defineStore: defineChannelAudienceAnalysisStore,
                    defaults: { view: 'timeline', options: {} }
                },
                'channel-publish-times': {
                    defineStore: defineChannelPublishTimesAnalysisStore,
                    defaults: { view: 'daily', options: { type: 'daily' } }
                },
                'content-top-channels': {
                    defineStore: defineContentTopChannelsAnalysisStore,
                    defaults: { view: 'timeline', options: {} }
                },
                'content-keywords': {
                    defineStore: defineContentKeywordsAnalysisStore,
                    defaults: { view: 'keywords', options: {} }
                },
                'content-performance': {
                    defineStore: defineContentPerformanceAnalysisStore,
                    defaults: { view: 'timeline', options: {} }
                },
                'content-publish-times': {
                    defineStore: defineContentPublishTimesAnalysisStore,
                    defaults: { view: 'daily', options: { type: 'daily' } }
                },
                'content-pull-push': {
                    defineStore: defineContentPullPushAnalysisStore,
                    defaults: { view: 'timeline', options: {} }
                },
                'content-sentiment': {
                    defineStore: defineContentSentimentAnalysisStore,
                    defaults: { view: 'timeline', options: { type: 'content', 'analysis.style': 'absolute' } }
                },
                'content-top-platforms': {
                    defineStore: defineContentTopPlatformsAnalysisStore,
                    defaults: { view: 'timeline', options: {} }
                }
            }),

            globalFilters: searchFilters(),
            refreshInterval: 5,
            lastRefresh: null,
            lastLayoutUpdate: +new Date()
        }),

        getters: {
            analysis(store) {
                return useMyAnalysesStore().items.find(l => l.id == store.analysisId)
            },
            
            availableViewOptions(store) {
                return store.viewOptions.filter(o => o.availableFor.includes(store.analysis?.type))
            },

            defaultOptions(store) {
                return store.supportedAnalyses[store.analysis?.type]?.defaults.options
            },
            
            defaultView(store) {
                return store.supportedAnalyses[store.analysis?.type]?.defaults.view
            },

            defineStore(store) {
                return store.supportedAnalyses[store.analysis?.type]?.defineStore
            },

            title(store) {
                return store.analysis?.name || 'Analysis'
            }
        },

        actions: {
            initialize(filters) {
                this.globalFilters = filters
                
                if (! this.analysis) return this
                
                this.analysisStore = this.defineStore({
                    id: `${this.wid}analysisWidget`,
                })
                
                this.load(true)

                return this
            },

            load(fresh = false) {
                if (! this.analysis) return

                if (fresh) {
                    let analysis = clone(this.analysis)
                    analysis.configuration.series[0].meta = { ...(analysis.configuration.series[0].meta || {}), ...this.options }
                    analysis.configuration.styles = { widget: { h: this.h, w: this.w }, ...(analysis.configuration.styles || {}), ...this.options }

                    this.analysisStore().initialize(analysis)
                    this.analysisStore().date = this.globalFilters.value('date')
                    this.analysisStore().loadSeries()
                } else {
                    this.analysisStore().date = this.globalFilters.value('date')
                    this.analysisStore().loadSeries()
                }

                this.lastRefresh = +new Date
            },

            destroy() {
                clearTimeout(this.updateTimeout)

                if (this.analysisStore) this.analysisStore().$dispose()

                this.$dispose()
            },

            refresh() {
                this.load()
                this.lastRefresh = +new Date
            },
            
            added() {
                this.selectAnalysis()
            },
            
            filtersUpdated() {
                this.load()
            },
            
            layoutUpdated() {
            },
            
            serialize() {
                return {
                    analysisId: this.analysis?.id,
                    view: this.view,
                    options: this.options
                }
            },

            dependencies() {
                return {
                    analyses: [ this.analysis?.id ]
                }
            },
            
            selectAnalysis() {
                useQuickSearchStore().show({
                    families: [ 'analyses' ],
                    limit: 50,
                    onSelect: result => {
                        this.analysisId = result.id

                        if (this.analysisStore) this.analysisStore().$dispose()

                        this.analysisStore = this.defineStore({
                            id: `${this.wid}analysisWidget`
                        })

                        this.view = { ...this.defaultView }
                        this.options = { ...this.defaultOptions }

                        this.load(true)
                        
                        useQuickSearchStore().hide()
                    },
                    initialResults: sortAlphabetically(useMyAnalysesStore().items).map(item => ({
                        id: item.id, resultType: 'analysis', title: item.name, type: item.type
                    }))
                })
            },
            
            toggleView(view) {
                this.view = view
            },

            setOption(name, value) {
                this.options[name] = value

                this.load(true)
            }
        }
    })
}

export default defineAnalysisWidget
