import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useContentHashStore = defineStore({
    id: 'contentHash',

    state: () => ({
        item: {}
    }),

    actions: {
        show(item) {
            this.item = item
            useModal().show('content-hash')
        }
    }
})

export default useContentHashStore
