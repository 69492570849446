import api from '@/api'

import useMyNotificationsStore from '@/stores/me/notifications'

import { defineForm } from '@/stores/reusable/form'

import { useModal } from '@/helpers'

export const useContentModalsSentimentEditStore = defineForm({
    id: 'contentModalsSentimentEdit',

    inputs: () => ({
        content: null,
        sentiment: null
    }),

    submitRequest() {
        return api.route('monitor content sentiment store', { id: this.inputs.content.id })
            .formData({
                sentiment: this.inputs.sentiment
            })
            .post()
    },

    async onResponse(res) {
        if (res.success) {
            useModal().hide('content-sentiment-edit')

            this.inputs.content.labels = this.inputs.content.labels?.filter(l => l !== `sentiment_polarity:${this.originalSentiment}`) || []
            this.inputs.content.labels.push(`sentiment_polarity:${this.inputs.sentiment}`)

            useMyNotificationsStore().pushToast({
                title: 'Sentiment has been updated!',
                body: "We've updated the sentiment of this content. Thanks for your help!",
                level: 'success'
            })
        }
    },

    getters: {
        originalSentiment(state) {
            if (! state.inputs.content) return

            if (state.inputs.content.labels?.includes('sentiment_polarity:positive')) {
                return 'positive'
            } else if (state.inputs.content.labels.includes('sentiment_polarity:neutral')) {
                return 'neutral'
            } else if (state.inputs.content.labels.includes('sentiment_polarity:negative')) {
                return 'negative'
            } else {
                return null
            }
        }
    },

    actions: {
        open(content = {}) {
            this.reset()

            this.inputs.content = content
            this.inputs.sentiment = this.originalSentiment

            useModal().show('content-sentiment-edit')
        }
    }
})

export default useContentModalsSentimentEditStore
