import useIndexCardsMonitoredStore from './index-cards-monitored'
import useIndexCardsRequestedStore from './index-cards-requested'
import useIndexCardsListsStore from './index-cards-lists'
import useIndexCardsListsListStore from './index-cards-lists-list'
import useIndexCardsCuratedCategoryStore from './index-cards-curated-category'

import defineMultiCards from '@/stores/reusable/multi-cards'

export const useChannelsIndexCardsStore = defineMultiCards({
    id: 'channelsIndexCards',

    contexts: {
        'monitored': useIndexCardsMonitoredStore,
        'requested': useIndexCardsRequestedStore,
        'lists': useIndexCardsListsStore,
        'list': useIndexCardsListsListStore,
        'curated-category': useIndexCardsCuratedCategoryStore
    },

    mapGetters: [
        'list'
    ]
})

export default useChannelsIndexCardsStore
