<template>
    <ui-async :guards="$page.feature">
        <div class="px-6 xl:px-16 py-8 flex justify-center space-x-4 max-w-12xl mx-auto">
            <div class="w-1/3 space-y-4">
                <feature-info :feature="$page.feature"></feature-info>
                <feature-place-map v-if="$page.feature.type == 'place'"></feature-place-map>
                <feature-connections></feature-connections>
            </div>

            <div class="w-2/3 space-y-4">
                <div class="h-8 flex items-center mb-2">
                    <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                        <a @click.prevent="$page.selectTab('inspect')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedTab == 'inspect' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Inspect
                        </a>
                        <a @click.prevent="$page.selectTab('stream')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedTab == 'stream' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Stream
                        </a>
                        <a @click.prevent="$page.selectTab('connections')" href="#" class="flex items-center justify-center px-3" :class="$page.selectedTab == 'connections' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                            Connections
                        </a>
                    </div>

                    <toolbar-date v-if="[ 'inspect', 'stream' ].includes($page.selectedTab)" :filters="$page.filters" class="ml-4"></toolbar-date>

                    <div class="ml-auto"></div>

                    <router-link :to="{ name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': 'past:1|months', 'filter[mentions]': `${$page.feature.type}|${$page.feature.id}|${$page.feature.name}` } }" class="flex items-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 ml-4">
                        <ui-icon name="navigation.content"></ui-icon>
                        <span>New perspective</span>
                    </router-link>

                    <!--<div class="flex items-center gap-x-2 h-full ml-4">-->
                    <!--    <a href="#" class="border border-gray-400 border-dashed text-gray-700 hover:text-gray-900 rounded-full font-medium flex items-center px-3 pl-2.5 gap-x-1.5 h-full">-->
                    <!--        <ui-icon name="plus-circle"></ui-icon> <span>Compare with...</span>-->
                    <!--    </a>-->
                    <!--</div>-->
                </div>

                <template v-if="$page.selectedTab == 'inspect'">
                    <content-metrics :store="$page.contentMetricsWidget()" class="w-full"></content-metrics>
                    <feature-keywords :store="$page.keywordsWidget()" class="w-full"></feature-keywords>
                    <feature-heatmap :store="$page.heatmapWidget()" class="w-full"></feature-heatmap>

                    <div class="flex gap-x-4">
                        <feature-top-links :store="$page.topLinksWidget()" class="w-1/3"></feature-top-links>
                        <feature-top-domains :store="$page.topDomainsWidget()" class="w-1/3"></feature-top-domains>
                        <feature-top-hashtags :store="$page.topHashtagsWidget()" class="w-1/3"></feature-top-hashtags>
                    </div>

                    <feature-top-media :store="$page.topMediaWidget()"></feature-top-media>
                </template>

                <template v-else-if="$page.selectedTab == 'stream'">
                    <feature-stream :store="featureStreamStore()"></feature-stream>
                </template>

                <template v-else-if="$page.selectedTab == 'connections'">
                    <feature-connections-details></feature-connections-details>
                </template>
            </div>
        </div>
    </ui-async>

    <Teleport to="#root">
        <feature-details-text-modal></feature-details-text-modal>
    </Teleport>
</template>

<script>
import ContentMetrics from '@/components/content/perspective/overview/widgets/timeline'
import FeatureConnections from './feature/connections'
import FeatureConnectionsDetails from './feature/connections-details'
import FeatureDetailsTextModal from './modals/feature-details-text'
import FeatureHeatmap from './feature/heatmap'
import FeatureInfo from './feature/info'
import FeatureKeywords from '@/components/content/perspective/overview/widgets/keywords'
import FeaturePlaceMap from './feature/place/map'
import FeatureStream from './feature/stream'
import FeatureTopDomains from '@/components/content/perspective/overview/widgets/top-domains'
import FeatureTopHashtags from '@/components/content/perspective/overview/widgets/top-hashtags'
import FeatureTopLinks from '@/components/content/perspective/overview/widgets/top-links'
import FeatureTopMedia from '@/components/content/perspective/overview/widgets/top-media'
import ToolbarDate from '@/components/content/perspective/toolbar/date'

import useStreamStore from '@/stores/features/feature/stream'

import { mapStores } from 'pinia'

export default {
    components: {
        ContentMetrics, FeatureConnections, FeatureConnectionsDetails, FeatureDetailsTextModal, FeatureHeatmap,
        FeatureInfo, FeatureKeywords, FeaturePlaceMap, FeatureStream, FeatureTopDomains, FeatureTopHashtags,
        FeatureTopLinks, FeatureTopMedia, ToolbarDate
    },

    methods: {
        ...mapStores(useStreamStore)
    }
}
</script>
