import Table from '@/components/ui/cards/layouts/table'

import defineCards from '@/stores/reusable/cards'
import useMyDashboardsStore from '@/stores/me/dashboards'

import { sortAlphabetically, sortByDate } from '@/helpers/sorting'

import { markRaw } from 'vue'

export const useDashboardsIndexCardsStore = defineCards({
    id: 'dashboardsIndexCards',

    source: useMyDashboardsStore,

    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => sortAlphabetically(items)
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => sortByDate(items, 'lastUsedAt').reverse()
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => sortByDate(items, 'updatedAt').reverse()
        }
    ],
    
    layoutOptions: [
        {
            id: 'table',
            component: markRaw(Table)
        }
    ]
})

export default useDashboardsIndexCardsStore
