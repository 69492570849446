<template>
    <ui-dropdown width="w-auto">
        <template v-slot:trigger>
            <div class="ml-3">
                <button aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                    <ui-icon class="text-3xl" name="more-horizontal"></ui-icon>
                </button>
            </div>
        </template>

        <template v-slot:content>
            <ui-dropdown-link icon="info" @click="showExplainModal">Explain edge</ui-dropdown-link>
         </template>
    </ui-dropdown>
</template>

<script>

import useGraphEventsStore from "@/stores/connections/graph-events.js"

export default {
    data: () => ({
        edge: {},
    }),

    methods: {
        showExplainModal() {
            useGraphEventsStore().explainEdge({ edge: this.edge.id })
        }
    },

    beforeMount() {
        this.edge = this.params.node.data
    },
}
</script>
