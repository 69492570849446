<template>
    <div class="border-2 border-gray-50 rounded-lg min-w-0 overflow-hidden flex-1">
        <template v-if="store.selectedChannels[0]">
            <list-card :cards-store="analysisDatasetCardsStore" :list="store.selectedChannels[0]" mode="row" v-if="store.selectedChannels[0].family == 'lists'">
            </list-card>
            <channel-card :cards-store="analysisDatasetCardsStore" :channel="store.selectedChannels[0]" mode="row" v-else>
            </channel-card>
        </template>
    </div>
</template>

<script>
import ChannelCard from '@/components/channels/stream-cards/channel'
import ListCard from '@/components/channels/stream-cards/list'

import useAnalysisDatasetCardsStore from '@/stores/analysis/dataset-cards'

import { mapStores } from 'pinia'

export default {
    components: {
        ChannelCard, ListCard
    },

    computed: {
        ...mapStores(useAnalysisDatasetCardsStore)
    }
}
</script>
