<template>
    <ui-modal name="content-detail" title="Conversation" width="100%" height="auto" :max-width="760" :max-height="800" unstyled @opened="opened">
        <div class="h-full overflow-y-auto rounded-md" style="max-height:800px;" ref="scrollContainer">
            <content-card :content="content" mode="details" :stream-store="streamStore" :key="content.id" v-if="content"></content-card>
        </div>
    </ui-modal>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'

import useContentDetailsStore from '@/stores/content/details'

import { mapState } from 'pinia'

export default {
    components: {
        ContentCard
    },

    computed: {
        ...mapState(useContentDetailsStore, [ 'content', 'streamStore' ])
    },

    methods: {
        opened() {
            this.$refs.scrollContainer?.scrollTo({ top: 0 })
        }
    }
}
</script>
