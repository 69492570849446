<template>
    <ui-dropdown class="h-full flex" width="w-auto" :keep-open="true" @opened="reset">
        <template v-slot:trigger>
            <a href="#" class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-center h-full px-3">
                <ui-icon name="calendar" class="text-xl mr-2"></ui-icon>

                <span v-if="! value">
                    All time
                </span>

                <span v-else-if="value.type == 'before'">
                    <span class="text-gray-500 mr-1">Before</span>
                    <span class="font-medium">
                        {{ hasTime(value.date.lte) ? $dateTime(value.date.lte) : $date(value.date.lte) }}
                    </span>
                </span>

                <span v-else-if="value.type == 'after'">
                    <span class="text-gray-500 mr-1">After</span>
                    <span class="font-medium">
                        {{ hasTime(value.date.gte) ? $dateTime(value.date.gte) : $date(value.date.gte) }}
                    </span>
                </span>

                <span v-else-if="value.type == 'in-range'">
                    <span class="font-medium">
                        {{ hasTime(value.date.gte) ? $dateTime(value.date.gte) : $date(value.date.gte) }} - {{ hasTime(value.date.lte) ? $dateTime(value.date.lte) : $date(value.date.lte) }}
                    </span>
                </span>

                <template v-else-if="value.type == 'past'">
                    <span class="" v-if="value.date.past == 1 && value.date.unit == 'hours'">Past Hour</span>
                    <span class="" v-else-if="value.date.past == 6 && value.date.unit == 'hours'">Past 6 Hours</span>
                    <span class="" v-else-if="value.date.past == 12 && value.date.unit == 'hours'">Past 12 Hours</span>
                    <span class="" v-else-if="value.date.unit == 'hours'">Past {{ value.date.past }} Hours</span>

                    <span class="" v-else-if="value.date.past == 1 && value.date.unit == 'days'">Past Day</span>
                    <span class="" v-else-if="value.date.past == 1 && value.date.unit == 'weeks'">Past Week</span>
                    <span class="" v-else-if="value.date.past == 1 && value.date.unit == 'months'">Past Month</span>
                    <span class="" v-else-if="value.date.past == 1 && value.date.unit == 'years'">Past Year</span>
                    <span class="" v-else-if="value.date.unit == 'days'">Past {{ value.date.past }} Days</span>
                </template>

                <ui-icon name="select" class="ml-2"></ui-icon>
            </a>
        </template>
        <template v-slot:content="{ close }">
            <div class="w-64" v-if="selectedType == 'past-hours'">
                <label class="text-xs font-medium text-gray-700 px-4 mb-2">
                    Past X hours
                </label>

                <div class="w-64 px-4">
                    <ui-input class="flex-1" type="number" min="0" v-model="selectedDateValue"></ui-input>
                </div>
            </div>

            <div class="w-64" v-else-if="selectedType == 'past-days'">
                <label class="text-xs font-medium text-gray-700 px-4 mb-2">
                    Past X days
                </label>

                <div class="w-64 px-4">
                    <ui-input class="flex-1" type="number" min="0" v-model="selectedDateValue"></ui-input>
                </div>
            </div>

            <div class="w-64" v-else-if="selectedType == 'before'">
                <label class="text-xs font-medium text-gray-700 px-4 mb-2">
                    Before
                </label>

                <div class="flex items-center">
                    <ui-date-picker inline :show-second="false" type="datetime" v-model="selectedDateValue"></ui-date-picker>
                </div>
            </div>

            <div class="w-64" v-else-if="selectedType == 'after'">
                <label class="text-xs font-medium text-gray-700 px-4 mb-2">
                    After
                </label>

                <div class="flex items-center">
                    <ui-date-picker inline :show-second="false" type="datetime" v-model="selectedDateValue"></ui-date-picker>
                </div>
            </div>

            <div class="w-full" v-else-if="selectedType == 'in-range'">
                <label class="text-xs font-medium text-gray-700 px-4 mb-2">
                    In range
                </label>

                <div class="flex items-center">
                    <ui-date-picker inline range :show-second="false" type="datetime" v-model="selectedDateValue"></ui-date-picker>
                </div>
            </div>

            <div class="w-52" v-else>
                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '1' && value.date.unit == 'hours'" @click="filters.set('date', { type: 'past', date: { past: 1, unit: 'hours' } })">
                    Past hour
                </ui-dropdown-link>

                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '6' && value.date.unit == 'hours'" @click="filters.set('date', { type: 'past', date: { past: 6, unit: 'hours' } })">
                    Past 6 hours
                </ui-dropdown-link>

                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '12' && value.date.unit == 'hours'" @click="filters.set('date', { type: 'past', date: { past: 12, unit: 'hours' } })">
                    Past 12 hours
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '1' && value.date.unit == 'days'" @click="filters.set('date', { type: 'past', date: { past: 1, unit: 'days' } })">
                    Past day
                </ui-dropdown-link>

                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '1' && value.date.unit == 'weeks'" @click="filters.set('date', { type: 'past', date: { past: 1, unit: 'weeks' } })">
                    Past week
                </ui-dropdown-link>

                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '1' && value.date.unit == 'months'" @click="filters.set('date', { type: 'past', date: { past: 1, unit: 'months' } })">
                    Past month
                </ui-dropdown-link>

                <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past == '1' && value.date.unit == 'years'" @click="filters.set('date', { type: 'past', date: { past: 1, unit: 'years' } })">
                    Past year
                </ui-dropdown-link>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link submenu submenu-align="left" :selected="false">
                    Custom...

                    <template v-slot:submenu>
                        <ui-dropdown-link :selected="!!value && value.type == 'past' && ! ['1', '6', '12'].includes(value.date.past) && value.date.unit == 'hours'" @click="selectedType = 'past-hours'" keep-open>
                            Past X hours...
                        </ui-dropdown-link>

                        <ui-dropdown-link :selected="!!value && value.type == 'past' && value.date.past != '1' && value.date.unit == 'days'" @click="selectedType = 'past-days'" keep-open>
                            Past X days...
                        </ui-dropdown-link>

                        <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                        <ui-dropdown-link :selected="!!value && value.type == 'before'" @click="selectedType = 'before'" keep-open>
                            Before...
                        </ui-dropdown-link>

                        <ui-dropdown-link :selected="!!value && value.type == 'after'" @click="selectedType = 'after'" keep-open>
                            After...
                        </ui-dropdown-link>

                        <ui-dropdown-link :selected="!!value && value.type == 'in-range'" @click="selectedType = 'in-range'" keep-open>
                            In range...
                        </ui-dropdown-link>
                    </template>
                </ui-dropdown-link>
            </div>

            <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

            <div class="pt-1">
                <slot name="controls" :close="close"></slot>
            </div>

            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center hover:font-medium" @click.prevent="filters.remove('date'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-sm">Reset</span>
                </a>

                <ui-button v-if="selectedType == 'past-hours'" @click="filters.set('date', { type: 'past', date: { past: selectedDateValue, unit: 'hours' } }); close()" :disabled="! selectedDateValue" color="blue" size="sm">
                    Apply
                </ui-button>

                <ui-button v-if="selectedType == 'past-days'" @click="filters.set('date', { type: 'past', date: { past: selectedDateValue, unit: 'days' } }); close()" :disabled="! selectedDateValue" color="blue" size="sm">
                    Apply
                </ui-button>

                <ui-button v-if="selectedType == 'before'" @click="filters.set('date', { type: 'before', date: { lte: selectedDateValue } }); close()" :disabled="! selectedDateValue" color="blue" size="sm">
                    Apply
                </ui-button>

                <ui-button v-if="selectedType == 'after'" @click="filters.set('date', { type: 'after', date: { gte: selectedDateValue } }); close()" :disabled="! selectedDateValue" color="blue" size="sm">
                    Apply
                </ui-button>

                <ui-button v-if="selectedType == 'in-range'" @click="filters.set('date', { type: 'in-range', date: { gte: selectedDateValue[0], lte: selectedDateValue[1] } }); close()" :disabled="! selectedDateValue" color="blue" size="sm">
                    Apply
                </ui-button>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
import { UTCToZoned } from '@/helpers/datetime'

export default {
    props: [ 'filters' ],

    data: () => ({
        selectedType: null,
        selectedDateValue: null
    }),

    computed: {
        value() {
            return this.filters.value('date')
        }
    },

    methods: {
        reset() {
            this.selectedType = null
            this.selectedDateValue = null
        },

        hasTime(value) {
            let zonedValue = UTCToZoned(value)

            return zonedValue.getHours() || zonedValue.getMinutes();
        }
    }
}
</script>
