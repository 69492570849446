<template>
    <search-filter name="Features" :ids="['mentions', 'media']" :filters="filters" @opened="refresh" :keep-open="keepOpen">
        <template v-slot:applied>
            <div class="flex items-center space-x-1">
                <!-- Mentions -->
                <div v-if="filters.value('mentions')" class="inline-flex items-center">
                    <div v-if="filters.value('mentions').length > 1" class="inline-flex items-center leading-none space-x-1">
                        <ui-icon name="info"></ui-icon>
                        <span class="font-medium">{{ filters.value('mentions').length }} Mentions</span>
                    </div>

                    <div v-else class="inline-flex items-center leading-none space-x-1 max-w-sm">
                        <ui-icon class="shrink-0" :name="$nodeType(filters.value('mentions')[0].type).icon" v-tooltip="$nodeType(filters.value('mentions')[0].type).name"></ui-icon>
                        <span class="font-medium truncate">{{filters.value('mentions')[0].name}}</span>
                    </div>
                </div>

                <!-- Media -->
                <div v-if="filters.value('media')" class="inline-flex space-x-1">
                    <template v-if="filters.value('media').type == 'contains-media' || filters.value('media').type == 'contains-image-reverse'">
                        <ui-icon name="image"></ui-icon>
                        <span>Contains Media</span>
                    </template>

                    <template v-else-if="filters.value('media').type == 'contains-video'">
                        <ui-icon name="video"></ui-icon>
                        <span>Contains Video</span>
                    </template>
                </div>
            </div>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <div class="max-h-80 overflow-y-auto">
                    <ui-dropdown-link icon="image" :selected="containsMedia == 'contains-media' || containsMedia == 'contains-image-reverse'" @click="setContainsMedia('contains-media')" keep-open>
                        Contains Media
                    </ui-dropdown-link>

                    <div :key="`images-${image.id}`" v-for="image in uploadedImages" class="flex items-center gap-x-1.5 px-3 py-2 cursor-default">
                        <div class="w-14 h-10 flex justify-center items-center shrink-0 relative group">
                            <ui-spinner v-if="image.uploading" type="clip"></ui-spinner>
                            <img v-else :src="image.url" class="max-h-full max-w-full drop-shadow-sm rounded">
                            <a href="#" @click.prevent="previewImage(image)" class="absolute inset-0 hidden group-hover:flex items-center justify-center bg-gray-500/40 rounded backdrop-blur-sm text-white text-sm">
                                <ui-icon name="search"></ui-icon>
                            </a>
                        </div>
                        <div class="line-clamp-2 break-all text-xs min-w-0">{{image.name}}</div>
                        <ui-icon name="trash" class="text-gray-700 hover:text-gray-900 shrink-0 ml-auto" @click="removeImage(image)"></ui-icon>
                    </div>

                    <ui-dropdown-link icon="upload" :selected="false" :disabled="uploadedImages.length >= 10" @click="() => $refs.imageUpload.click()" keep-open>
                        Upload Image...
                    </ui-dropdown-link>
                </div>

                <input ref="imageUpload" type="file" accept="image/*" @change="uploadImages($event.target.files)" multiple hidden>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <ui-dropdown-link icon="video" :selected="containsMedia == 'contains-video'" @click="setContainsMedia('contains-video')" keep-open>
                    Contains Video
                </ui-dropdown-link>

                <div class="max-h-80 overflow-y-auto">
                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                    <template v-if="! selectedMentionsToInclude.length && ! selectedMentionsToExclude.length">
                        <p class="text-xs text-center my-3">Showing content with all Features.</p>

                        <ui-dropdown-separator class="my-2"></ui-dropdown-separator>
                    </template>

                    <div v-if="selectedMentionsToInclude.length" class="text-xs font-medium text-gray-700 px-4 mb-2">
                        Show only content with
                    </div>

                    <ui-dropdown-link @click="deselectFeature(feature)" keep-open :icon="`badges.feature-${feature.type}`" :key="`${feature.family}-${feature.id}`" v-for="feature in selectedMentionsToInclude">
                        <template v-slot:default>
                            <div class="flex items-center group">
                                {{feature.name}}
                                <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                            </div>
                        </template>
                    </ui-dropdown-link>

                    <ui-dropdown-link v-if="selectedMentionsToInclude.length" icon="plus-circle" @click="selectFeatureToInclude" keep-open>
                        Add Feature...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectFeatureToInclude" keep-open>
                        Show only content with...
                    </ui-dropdown-link>

                    <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                    <div v-if="selectedMentionsToExclude.length" class="text-xs font-medium text-gray-700 px-4 mb-2">
                        Exclude content with
                    </div>

                    <div class="max-h-80 overflow-y-auto">
                        <ui-dropdown-link @click="deselectFeature(feature)" keep-open :icon="`badges.feature-${feature.type}`" :key="`${feature.family}-${feature.id}`" v-for="feature in selectedMentionsToExclude">
                            <template v-slot:default>
                                <div class="flex items-center group">
                                    {{feature.name}}
                                    <ui-icon name="trash" class="text-white hidden group-hover:block ml-auto"></ui-icon>
                                </div>
                            </template>
                        </ui-dropdown-link>
                    </div>

                    <ui-dropdown-link v-if="selectedMentionsToExclude.length" icon="plus-circle" @click="selectFeatureToExclude" keep-open>
                        Add Feature...
                    </ui-dropdown-link>
                    <ui-dropdown-link v-else @click="selectFeatureToExclude" keep-open>
                        Exclude content with...
                    </ui-dropdown-link>
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="reset(); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">
                    Apply
                </ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import useQuickSearchStore from '@/stores/me/quick-search'

import api from '@/api'
import { sortAlphabetically } from '@/helpers/sorting'

export default {
    props: [ 'filters', 'streamStore' ],

    components: {
        SearchFilter
    },

    data: () => ({
        containsMedia: null,
        selectedMentions: [],

        uploadedImages: [],

        keepOpen: false
    }),

    computed: {
        selectedMentionsToInclude() {
            return sortAlphabetically(this.selectedMentions.filter(val => ! val.exclude))
        },

        selectedMentionsToExclude() {
            return sortAlphabetically(this.selectedMentions.filter(val => val.exclude))
        }
    },

    methods: {
        selectFeatureToInclude() {
            this.selectFeature()
        },

        selectFeatureToExclude() {
            this.selectFeature(true)
        },

        selectFeature(exclude = false) {
            this.keepOpen = true

            useQuickSearchStore().show({
                families: [ 'features' ],
                onSelect: result => {
                    this.selectedMentions.push({
                        id: result.id,
                        type: result.type,
                        name: result.title,
                        exclude
                    })

                    useQuickSearchStore().hide()
                },
                onHide: () => {
                    setTimeout(() => this.keepOpen = false, 500)
                }
            })
        },

        deselectFeature(feature) {
            this.selectedMentions = this.selectedMentions.filter(f => f.id != feature.id || f.type != feature.type)
        },

        setContainsMedia(value) {
            this.containsMedia = this.containsMedia == value ? null : value
        },

        uploadImages(files) {
            Array.from(files)
                .slice(0, 10 - this.uploadedImages.length)
                .forEach(file => {
                    this.uploadedImages.push({ id: null, name: file.name, url: null, uploading: true })

                    let uploadedFile = this.uploadedImages[this.uploadedImages.length - 1]

                    api.route('me files store', { collection: 'reverse-image-search' })
                        .formData({ file })
                        .post()
                        .json(res => {
                            uploadedFile.id = res.data.id
                            uploadedFile.name = res.data.name
                            uploadedFile.url = res.data.imageUrl
                            uploadedFile.uploading = false
                        })
                })

            this.containsMedia = 'contains-image-reverse'
        },

        removeImage(image) {
            this.uploadedImages = this.uploadedImages.filter(i => i != image)
        },

        previewImage(image) {
            this.keepOpen = true

            this.streamStore.mediaLightbox.open(
                this.uploadedImages.map(i => ({ family: 'images', name: i.name, src: i.url })),
                this.uploadedImages.indexOf(image),
                null,
                { onClose: () => this.keepOpen = false }
            )
        },

        reset() {
            this.filters.remove([ 'mentions', 'media' ])
        },

        refresh() {
            this.selectedMentions = this.filters.value('mentions') || []

            this.containsMedia = this.filters.value('media')?.type
            this.uploadedImages = []

            if (this.containsMedia == 'contains-image-reverse') {
                this.filters.value('media').id.forEach(id => api.route('me files details', { id }).get()
                    .json(res => { this.uploadedImages.push({ id, name: res.data.name, url: res.data.url, uploading: false }) }))
            }
        },

        apply() {
            if (this.selectedMentions.length) {
                this.filters.set('mentions', this.selectedMentions)
            } else {
                this.filters.remove('mentions')
            }

            if (this.containsMedia) {
                this.filters.set('media', { type: this.containsMedia, id: this.uploadedImages.map(i => i.id).filter(Boolean) })
            } else {
                this.filters.remove('media')
            }
        }
    }
}
</script>
