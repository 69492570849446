<template>
    <div class="spaxe-y-3">
        <div class="flex gap-x-1 bg-yellow-100 border border-yellow-400 rounded-md text-zinc-900 py-3 px-5 cursor-pointer" v-for="note in content.notes" :key="note.id" @click.prevent="open(content, note)">
            <div class="flex-1">
                <span class="italic">
                    {{render(note.body)}}
                </span>
                <span class="text-sm pl-1 whitespace-nowrap">
                    &mdash; {{note.author.name}}
                </span>
            </div>
            <div>
                <ui-access-icon :workspace="note.workspace" class="text-sm text-zinc-500"></ui-access-icon>
            </div>
        </div>
    </div>
</template>

<script>
import useDetailsStore from '@/stores/content/details'
import useMeModalsNoteStore from '@/stores/me/modals/note'

import { removeHTML } from '@/helpers/html'

import { marked } from 'marked'
import { mapActions, mapState } from 'pinia'

export default {
    props: [ 'item' ],
    
    computed: {
        ...mapState(useDetailsStore, [ 'content' ])
    },

    methods: {
        ...mapActions(useMeModalsNoteStore, [ 'open' ]),

        render(body) {
            let rendered = removeHTML(marked.parse(body))

            return rendered.length > 300 ? `${rendered.substring(0, 300)}...` : rendered 
        }
    }
}
</script>