<template>
    <ui-dropdown>
        <template v-slot:trigger>
            <span class="text-gray-800 cursor-default hover:text-gray-900 rounded-full">
                <ui-icon name="lock" class="mr-0.5"></ui-icon> {{selectedOption.name}} <ui-icon name="select" class="ml-0.5"></ui-icon>
            </span>
        </template>

        <template v-slot:content>
            <ui-dropdown-link v-for="option in options" :key="option.id" :icon="option.icon" @click="select(option)">
                {{option.name}}
            </ui-dropdown-link>
        </template>
    </ui-dropdown>
</template>

<script>
import useMyStore from '@/stores/me/my'

export default {
    props: [ 'modelValue' ],
    
    data: () => ({
        options: [
            { id: 'personal', name: 'Only me', icon: 'user' },
            { id: 'workspace', name: 'Current workspace', icon: 'users' },
            { id: 'global', name: 'Whole organization', icon: 'globe-simple' }
        ]
    }),

    computed: {
        model: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        },
        
        selectedOption() {
            return this.options.find(o => o.id == this.model) || this.options.find(o => o.id == 'workspace')
        }
    },
    
    methods: {
        select(option) {
            this.model = option.id == 'workspace' ? useMyStore().currentWorkspace.id : option.id
        }
    },
    
    watch: {
        model(val) {
            if (! val) this.model = useMyStore().currentWorkspace.id
        }
    }
}
</script>
