<template>
    <div class="space-y-4">
        <div>
            <label for="frequency" class="block text-sm text-gray-700 font-medium mb-1">
                Frequency
            </label>

            <div>
                <ui-select id="frequency" :options="frequency" v-model="store.inputs.settings.frequency" form></ui-select>
                <div class="mt-1 text-xs text-gray-600">
                    You will receive your digest at the start of the next day, week or month.
                </div>
            </div>
        </div>

        <div>
            <label for="followersThreshold" class="block text-sm text-gray-700 font-medium mb-1">
                Followers Threshold
            </label>

            <div>
                <ui-input id="followersThreshold" v-model="store.inputs.settings.followersThreshold"></ui-input>
                <div class="mt-1 text-xs text-gray-600">
                    Only report on Channels with more followers than set threshold.
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: () => ({
        frequency: [
            { id: 'daily', name: 'Daily' },
            { id: 'hourly', name: 'Hourly' }
        ]
    })
}
</script>
