<template>
    <div class="space-y-3">
        <simple-content-card :content="item" :key="item.id" v-for="item in shares"></simple-content-card>

        <div v-if="! isLoading && ! shares.length" class="py-8 pb-6 text-center text-gray-700 text-sm">
            No shares have been found.
        </div>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadAdditionalShares($event)"></ui-infinite-loading>
    </div>
</template>

<script>
import SimpleContentCard from '@/components/content/stream-cards/simple-content'

import useSharesStore from '@/stores/content/details/shares'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        SimpleContentCard
    },
    
    computed: {
        ...mapState(useSharesStore, [
            'isLoading', 'lastReloadTime', 'shares'
        ])
    },
    
    methods: {
        ...mapActions(useSharesStore, [ 'loadAdditionalShares' ])
    }
}
</script>
