<template>
    <div class="mb-8">
        <results-toolbar :title="title" :store="store">
            <template v-slot:title>
                <slot name="title"></slot>
            </template>

            <template v-slot:actions>
                <slot name="actions"></slot>
            </template>

            <template v-slot:filter>
                <slot name="filter"></slot>
            </template>
        </results-toolbar>

        <ui-cards :store="store">
            <template v-slot:item="{ item, cardsStore }">
                <slot name="item" :item="item" :cards-store="cardsStore"></slot>
            </template>

            <template v-slot:empty>
                <slot name="empty"></slot>
            </template>
        </ui-cards>
    </div>
</template>

<script>
import ResultsToolbar from '@/components/ui/cards/toolbar/results'

export default {
    components: {
        ResultsToolbar
    },

    props: [ 'title' ]
}
</script>
