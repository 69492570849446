<template>
    <div class="py-2 border-b border-gray-200 px-2">
        <div class="align-center flex py-2 px-2" v-if="title">
            <h3 class="uppercase leading-none font-medium text-gray-700 text-xs inline-flex items-center" id="content-lists-headline">
                <ui-icon :name="icon" v-if="icon" class="mb-0.5 mr-1"></ui-icon>
                {{title}}
            </h3>
            <a href="#" class="ml-auto text-gray-700" @click.prevent="collapsed = ! collapsed">
                <ui-icon :name="collapsed ? 'chevron-up' : 'chevron-down'"></ui-icon>
            </a>
        </div>
        <div class="pt-1 pb-2" role="group" aria-labelledby="content-lists-headline" v-if="! collapsed">
            <ui-async :guards="waitUntil">
                <component :is="asLinks ? 'a' : 'router-link'" :to="asLinks ? undefined : resolveLink(item)" :href="asLinks ? item.url : ''" class="group flex items-center px-2 py-0.5 mt-px leading-7 rounded-sm transition ease-in-out duration-150 hover:bg-gray-75" :class="isActive(item) ? 'bg-gray-100' : ''" :exact-active-class="isActive(item) !== false ? 'bg-gray-100' : ''" exact :key="index" v-for="item, index in shownItems">
                    <slot name="image" :item="item">
                        <span class="inline-flex mr-2 h-5 w-5 shrink-0 items-center justify-center leading-4 transition ease-in-out duration-150 text-blue-600">
                            <ui-icon :name="item.icon" class="text-xl" v-if="item.icon"></ui-icon>
                            <ui-avatar :item="item" class="h-full w-full" v-else></ui-avatar>
                        </span>
                    </slot>
                    <slot name="name" :item="item">
                        <span class="tracking-tight truncate">{{item.name || item.title}}</span>
                    </slot>
                    <span class="ml-auto inline-block py-0.5 px-2 text-2xs font-semibold leading-4 rounded-full transition ease-in-out duration-150 text-gray-900 bg-gray-200 group-hover:bg-blue-500 group-hover:text-white" v-if="showCount && item.count">
                        {{item.count}}
                    </span>
                </component>

                <a href="#" class="flex p-2 text-blue-700 text-sm" @click.prevent="showAll = true" v-if="hiddenItemsCount">
                    Show {{hiddenItemsCount}} more
                </a>

                <div class="p-1 text-center text-gray-600 text-xs" v-if="! shownItems.length">
                    {{placeholder}}
                </div>
            </ui-async>
            <div class="flex flex-col text-blue-700 text-sm py-2 px-2 space-y-2 mt-2" v-if="$slots.actions">
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import { useRoute } from '@/router'

export default {
    props: {
        'active': {}, 'asLinks': Boolean, 'avatarType': {}, 'icon': {}, 'items': {}, 'maxShown': {}, 'placeholder': {},
        'showCount': Boolean, 'title': {}, 'to': {}, 'waitUntil': {}
    },

    data: () => ({
        showAll: false,
        collapsed: false
    }),

    computed: {
        shownItems() {
            return (this.showAll || ! this.maxShown) ? this.items : this.items.slice(0, this.maxShown)
        },

        hiddenItemsCount() {
            return this.showAll ? 0 : Math.max(this.items.length - this.maxShown, 0)
        }
    },

    methods: {
        isActive(item) {
            if (item.active) return item.active(useRoute())
            if (this.active) return this.active(item, useRoute())

            return null
        },

        resolveLink(item) {
            if (item.to) return item.to
            if (this.to) return this.to(item)

            return '#'
        }
    }
}
</script>
