import defineCards from '@/stores/reusable/cards'

// Used to render the selected dataset cards
export const useAnalysisDatasetCardsStore = defineCards({
    id: 'analysisDatasetCards',

    choose: (item, store) => store.analysisStore.changeDataset(),

    state: () => ({
        analysisStore: null
    }),

    actions: {
        setAnalysisStore(analysisStore) {
            this.analysisStore = analysisStore
        }
    }
})

export default useAnalysisDatasetCardsStore
