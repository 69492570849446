<template>
    <div>
        <component :is="store.layout.component" :store="store">
            <template v-slot:item="{ item }">
                <slot name="item" :item="item" :cards-store="store">
                    <alert-card :alert="item" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'alerts'"></alert-card>
                    <alert-trigger-card :trigger="item" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'alert-triggers'"></alert-trigger-card>

                    <channel-card :channel="item" :list="store.list" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'channels'"></channel-card>

                    <monitoring-request-card :request="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'monitoring-requests'"></monitoring-request-card>

                    <perspective-card :perspective="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'perspectives'"></perspective-card>

                    <topic-card :topic="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'topics'"></topic-card>

                    <list-card :list="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'lists'"></list-card>

                    <list-curated-card :list="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'lists-curated'"></list-curated-card>

                    <curated-category-card :category="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'curated-category'"></curated-category-card>
                    <curated-list-card :list="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'curated-list'"></curated-list-card>

                    <analysis-card :analysis="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'analyses'"></analysis-card>

                    <board-card :board="item" :cards-store="store" :mode="store.layout.id == 'table' ? 'row' : 'card'" v-if="item.family == 'dashboards'"></board-card>
                </slot>
            </template>

            <template v-slot:placeholder="{ item }">
                <slot name="placeholder" :item="item" :cards-store="store">
                    <perspective-placeholder-card v-if="store.layout.id == 'grid'"></perspective-placeholder-card>
                    <perspective-placeholder-row v-if="store.layout.id == 'table'"></perspective-placeholder-row>
                </slot>
            </template>

            <template v-slot:empty>
                <slot name="empty"></slot>
            </template>
        </component>
    </div>
</template>

<script>
import AlertCard from '@/components/alerts/cards/alert'
import AlertTriggerCard from '@/components/alerts/cards/trigger'
import AnalysisCard from '@/components/analysis/stream-cards/analysis-card'
import BoardCard from '@/components/dashboards/stream-cards/board-card'
import ChannelCard from '@/components/channels/stream-cards/channel'
import CuratedCategoryCard from '@/components/channels/stream-cards/curated-category'
import CuratedListCard from '@/components/channels/stream-cards/curated-list'
import ListCard from '@/components/channels/stream-cards/list'
import ListCuratedCard from '@/components/channels/stream-cards/list-curated'
import MonitoringRequestCard from '@/components/channels/stream-cards/monitoring-request'
import PerspectiveCard from '@/components/content/stream-cards/perspective'
import TopicCard from '@/components/content/stream-cards/topic'
import PerspectivePlaceholderCard from '@/components/content/stream-cards/perspective-placeholder'
import PerspectivePlaceholderRow from '@/components/content/stream-cards/perspective-placeholder-row'

import breakpoint from '@/helpers/breakpoint'

export default {
    components: {
        AlertCard, AlertTriggerCard, AnalysisCard, BoardCard, ChannelCard, CuratedCategoryCard, CuratedListCard,
        ListCard, ListCuratedCard, MonitoringRequestCard, PerspectiveCard, TopicCard, PerspectivePlaceholderCard,
        PerspectivePlaceholderRow
    },

    data: () => ({
        breakpoint: breakpoint,
        layoutWidth: null
    }),

    methods: {
        updateLayouts() {
            this.store.refreshLayouts && this.store.refreshLayouts(this.$el.getBoundingClientRect().width)
        }
    },

    watch: {
        'breakpoint.w'() { this.updateLayouts() }
    },

    mounted() {
        this.updateLayouts()
    }
}
</script>
