export function mapLocalActions(keysOrMapper) {
    return Array.isArray(keysOrMapper)
        ? keysOrMapper.reduce((reduced, key) => {
            reduced[key] = function (...args) { return this.store[key](...args) }
            return reduced
        }, {})
        : Object.keys(keysOrMapper).reduce((reduced, key) => {
            reduced[key] = function (...args) {
                return this.store[keysOrMapper[key]](...args)
            }
            return reduced
        }, {})
}

export function mapLocalState(keysOrMapper) {
    return Array.isArray(keysOrMapper)
        ? keysOrMapper.reduce((reduced, key) => {
            reduced[key] = function () { return this.store[key] }
            return reduced
        }, {})
        : Object.keys(keysOrMapper).reduce((reduced, key) => {
            reduced[key] = function () {
                const store = this.store
                const storeKey = keysOrMapper[key]

                return typeof storeKey === 'function'
                    ? (storeKey).call(this, store)
                    : store[storeKey]
            }
            return reduced
        }, {})
}

export function mapLocalWritableState(keysOrMapper) {
    return Array.isArray(keysOrMapper)
        ? keysOrMapper.reduce((reduced, key) => {
            reduced[key] = {
                get() {
                    return this.store[key]
                },
                set(value) {
                    return this.store[key] = value
                }
            }
            return reduced
        }, {})
        : Object.keys(keysOrMapper).reduce((reduced, key) => {
            reduced[key] = {
                get() {
                    return this.store[keysOrMapper[key]]
                },
                set(value) {
                    return this.store[keysOrMapper[key]] = value
                }
            }
            return reduced
        }, {})
}
