import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'
import { trackEvent } from '@/analytics'

import useMyChannelListsStore from "@/stores/me/channel-lists"

export const useChannelsModalsListMergeStore = defineForm({
    id: 'channelsModalsListMerge',

    state: () => ({
        cardsStore: false,
    }),

    inputs: () => ({
        deleteSelected: false,
        sourceList: {},
        targetList: {}
    }),

    submitRequest() {
        trackEvent('channels', 'channel-lists-merged')

        return api.route('me lists merge', { id: this.inputs.targetList.id })
            .formData({
                _method: 'post',
                remove: this.inputs.deleteSelected ? 1 : 0,
                sourceList: this.inputs.sourceList.id
            })
            .post()
    },

    async onResponse(res) {
        await useMyChannelListsStore().reload()

        if (this.cardsStore) this.cardsStore.source().reload()

        useModal().hide('channels-list-merge')
    },

    actions: {
        open(list = null, cardsStore = null) {
            this.reset()
            this.cardsStore = cardsStore
            this.inputs.targetList = list
            useModal().show('channels-list-merge')
        },

        cancel() {
            useModal().hide('channels-list-merge')
        },

        select(list) {
            this.inputs.sourceList = list
        }
    }
})

export default useChannelsModalsListMergeStore
