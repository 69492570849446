<template>
    <ui-async :guards="shownEvents">
        <div class="flow-root" v-if="shownEvents && shownEvents.length">
            <ul>
                <li>
                    <div class="relative" :class="{ 'pb-8': index != shownEvents.length - 1 }" :key="index" v-for="record, index in shownEvents">
                        <span class="absolute top-4 -ml-px h-full w-0.5 bg-gray-100" :class="details ? 'left-4' : 'left-3'" aria-hidden="true" v-if="index != shownEvents.length - 1"></span>
                        <div class="relative flex space-x-3">
                            <div>
                                <span v-if="record.type == 'updated'" class="flex items-center justify-center" :class="details ? 'h-8 w-8' : 'h-6 w-6'">
                                    <span class="h-2.5 w-2.5 rounded-full bg-gray-100 border border-gray-400 flex ring-4 ring-white"></span>
                                </span>
                                <span v-else class="rounded-full bg-blue-500 text-white text-lg flex items-center justify-center ring-4 ring-white" :class="details ? 'h-8 w-8' : 'h-6 w-6'">
                                    <ui-icon name="flag" v-if="record.type == 'published'"></ui-icon>
                                    <ui-icon name="eye" v-else-if="record.type == 'discovered'"></ui-icon>
                                    <ui-icon name="check" v-else-if="record.type == 'verified' || record.type == 'unverified'"></ui-icon>
                                    <ui-icon name="info" v-else></ui-icon>
                                </span>
                            </div>
                            <div class="min-w-0 flex-1 flex items-center justify-between space-x-4 text-gray-800">
                                <div class="leading-tight">
                                    <div class="whitespace-nowrap text-gray-700" :class="details ? 'text-xs' : 'text-2xs'">
                                        <time>{{ $dateTime(record.date) }}</time>
                                    </div>
                                    <p v-if="record.type == 'published'">
                                        Channel <span class="font-medium text-gray-900">created</span>
                                    </p>
                                    <p v-else-if="record.type == 'discovered'">
                                        Channel <span class="font-medium text-gray-900">first discovered</span>
                                    </p>
                                    <p v-else-if="record.type == 'verified'">
                                        Channel <span class="font-medium text-gray-900">gained verified</span> status
                                    </p>
                                    <p v-else-if="record.type == 'unverified'">
                                        Channel <span class="font-medium text-gray-900">lost verified</span> status
                                    </p>
                                    <template v-else>
                                        <p v-for="change in record.changes" :key="change.field">
                                            Changed <span class="font-medium text-gray-900">{{change.field}}</span> from <span class="font-medium">{{ formatValue(change.old) }}</span> to <span class="font-medium text-blue-600">{{ formatValue(change.new) }}</span>
                                        </p>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div v-if="! details" class="text-center text-sm mt-4">
            <a @click.prevent="$modal.show('channel-history')" href="#" class="text-blue-500 hover:text-blue-800">
                Show More
            </a>
        </div>

        <div class="py-12 text-gray-600 text-center text-sm" v-if="! shownEvents || ! shownEvents.length">
            <p>We have found no history for this Channel.</p>
        </div>
    </ui-async>
</template>

<script>
import useHistoryStore from '@/stores/channels/channel/history'

import { mapState } from 'pinia'

export default {
    props: { details: Boolean },

    computed: {
        ...mapState(useHistoryStore, [ 'events' ]),

        shownEvents() {
            if (! this.events) return

            return this.details ? this.events : [ ...this.events ].slice(0, 3)
        }
    },

    methods: {
        formatValue(value) {
            if (value instanceof Array) return value.join(', ')
            return value || 'none'
        }
    }
}
</script>
