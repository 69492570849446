import useChannelSearchStream from '@/stores/channels/search/stream'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyCuratedListsStore from '@/stores/me/curated-lists'
import useMyMonitoredChannelsStore from '@/stores/me/monitored-channels'
import useMyMonitoredStatsStore from '@/stores/channels/monitored-stats'
import useMyMonitoringRequestsStore from '@/stores/me/monitoring-requests'
import useIndexCardsStore from '@/stores/channels/index-cards'
import useIndexPinnedCardsStore from '@/stores/channels/index-pinned-cards'

import { defineStore } from 'pinia'

export const useChannelsIndexPage = defineStore({
    id: 'channelsIndexPage',

    getters: {
        title: () => 'Channels',

        streamStore: store => useChannelSearchStream(),

        showFilters: () => true
    },

    actions: {
        afterEnter(to) {
            this.$reset()

            this.streamStore.reset()

            useIndexPinnedCardsStore().initialize()
            useIndexCardsStore().initialize(to.query.show)

            useMyChannelListsStore().initialize()
            useMyCuratedListsStore().initialize()
            useMyMonitoringRequestsStore().initialize()
            useMyMonitoredChannelsStore().initialize()
            useMyMonitoredStatsStore().initialize()
        }
    }
})

export default useChannelsIndexPage
