<template>
    <router-view></router-view>

    <Teleport to="#root">
        <channels-library-add-channel-modal></channels-library-add-channel-modal>
        <channels-list-duplicate-modal></channels-list-duplicate-modal>
        <channels-list-edit-modal></channels-list-edit-modal>
        <content-hash-modal></content-hash-modal>
        <list-import-modal></list-import-modal>
    </Teleport>
</template>

<script>
import ChannelsLibraryAddChannelModal from './modals/library-add-channel'
import ChannelsListDuplicateModal from './modals/list-duplicate'
import ChannelsListEditModal from './modals/list-edit'
import ContentHashModal from '@/components/content/modals/content-hash'
import ListImportModal from '@/components/channels/modals/list-import-channels'

export default {
    components: {
        ChannelsLibraryAddChannelModal, ChannelsListDuplicateModal, ChannelsListEditModal, ContentHashModal,
        ListImportModal
    }
}
</script>
