<template>
    <dashboard-widget v-bind="$attrs" :store="store" unstyled>
        <div class="flex flex-col h-full">
            <div class="flex justify-between mb-1 px-2">
                <h1 class="text-lg font-medium">{{store.title}}</h1>
            </div>

            <overview-metrics :store="store.metricStore()" style="width:100% !important; flex: 1 !important;"></overview-metrics>
        </div>
        
        <template v-slot:actions>
            <div class="text-xs font-medium text-gray-700 px-4 my-2 cursor-pointer">Metrics</div>

            <ui-dropdown-link @click="store.selectPerspective()" icon="perspective">Select perspective...</ui-dropdown-link>

            <ui-dropdown-separator></ui-dropdown-separator>
        
            <ui-dropdown-link v-for="option in store.metricOptions" :key="option.id" @click="store.toggleMetric(option.id)" checkbox :selected="store.metrics[option.id]" :icon="option.icon">{{option.name}}</ui-dropdown-link>
            
            <ui-dropdown-separator></ui-dropdown-separator>
        </template>
    </dashboard-widget>
</template>

<script>
import DashboardWidget from './widget'

import OverviewMetrics from '@/components/content/perspective/overview/widgets/metrics'

export default {
    components: { DashboardWidget, OverviewMetrics }
}
</script>
