import Grid from '@/components/ui/cards/layouts/grid'

import defineCards from '@/stores/reusable/cards'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { sortAlphabetically } from '@/helpers/sorting'

import { markRaw } from 'vue'

export const useContentIndexPinnedCardsStore = defineCards({
    id: 'contentIndexPinnedCards',

    source: useMyPerspectivesStore,

    filter: items => items.filter(item => item.pinned),

    layoutOptions: [
        {
            id: 'grid',
            component: markRaw(Grid),
            settings: { rows: 2 }
        }
    ],

    sortingOptions: [
        {
            id: 'default', name: '', icon: '', default: true,
            apply: items => sortAlphabetically(items)
        }
    ]
})

export default useContentIndexPinnedCardsStore
