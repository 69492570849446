<template>
    <div class="flex items-center py-2 px-3 w-full">
        <div class="text-gray-700 text-sm text-right w-32 mr-4">
            {{$dateTime(request.createdAt)}}
        </div>
        <div class="flex items-center space-x-2">
            <ui-icon :name="platformInfo(request.platform).icon" v-tooltip="platformInfo(request.platform).name" class="text-xl"></ui-icon>
            <span>{{request.url}}</span>
        </div>
        <div class="ml-auto">
            <span class="flex items-center space-x-2 text-green-600" v-if="request.state == 'added'">
                <ui-icon name="check-circle"></ui-icon>
                <span>Success</span>
            </span>
            <span class="flex items-center space-x-2 text-red-600" v-else-if="request.state == 'failed'">
                <ui-icon name="error-full-circle"></ui-icon>
                <span>Failed</span>
            </span>
            <span class="flex items-center space-x-2 text-yellow-600" v-else>
                <ui-spinner type="clip" color="#ca8a04"></ui-spinner>
                <span>Pending</span>
            </span>
        </div>
        <div class="ml-3 text-xl leading-none">
            <a href="#" @click.prevent="showChannels = ! showChannels" :class="{'text-gray-300': ! request.channels.length}">
                <ui-icon :name="showChannels ? 'chevron-down' : 'chevron-right'"></ui-icon>
            </a>
        </div>
    </div>

    <div v-if="showChannels">
        <channel-card :channel="channel" mode="row" :cards-store="channelsIndexCardsMonitoredStore" :key="channel.id" v-for="channel in request.channels"></channel-card>
    </div>
</template>

<script>
import ChannelCard from '@/components/channels/stream-cards/channel'

import useIndexCardsMonitoredStore from '@/stores/channels/index-cards-monitored'

import { mapStores } from 'pinia'

export default {
    components: {
        ChannelCard
    },

    props: [
        'request'
    ],

    data: () => ({
        platforms: [
            { name: 'Facebook Page', id: 'facebook', icon: 'badges.channel-facebook-page' },
            { name: 'Facebook Group', id: 'facebook-group', icon: 'badges.channel-facebook-group' },
            { name: 'Imageboard Board', id: 'imageboard', icon: 'badges.channel-imageboard-board' },
            { name: 'Instagram Profile', id: 'instagram-profile', icon: 'badges.channel-instagram-profile' },
            { name: 'Reddit User or Subreddit', id: 'reddit', icon: 'badges.channel-reddit-subreddit' },
            { name: 'Telegram Channel or Group', id: 'telegram', icon: 'badges.channel-telegram-channel' },
            { name: 'TikTok User', id: 'tiktok', icon: 'badges.channel-tik-tok-user' },
            { name: 'X User', id: 'x', icon: 'badges.channel-x-user' },
            { name: 'VKontakte User, Page or Community', id: 'vkontakte', icon: 'badges.channel-vkontakte-community' },
            { name: 'Web Feed', id: 'web-feeds', icon: 'badges.channel-web-feed' },
            { name: 'Youtube Channel', id: 'youtube', icon: 'badges.channel-youtube-channel' }
        ],

        showChannels: false
    }),

    computed: {
        ...mapStores(useIndexCardsMonitoredStore)
    },

    methods: {
        platformInfo(platform) {
            return this.platforms.find(p => p.id == platform) || {}
        }
    }
}
</script>
