<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg w-1/3 h-40 flex flex-col divide-y divide-gray-200 justify-center relative">
        <div class="flex-1 flex items-center min-w-0" v-for="metric, index in metrics" :key="index">
            <div class="flex flex-col justify-center flex-1 min-h-0 px-3">
                <div class="flex items-center gap-x-1 text-gray-700">
                    <ui-icon :name="icon(metric)" class="text-lg"></ui-icon>
                    <h2 class="text-sm">
                        {{title(metric)}}
                    </h2>
                </div>
                <h1 class="text-3xl font-semibold">
                    {{$number(current[index])}}
                </h1>
                <div class="flex items-center gap-x-2 text-base text-gray-700 leading-tight" v-if="previous[index]">
                    <div class="text-sm border rounded leading-tight whitespace-nowrap px-1" :class="previous[index] < current[index] ? 'bg-green-100 border-green-300' : 'bg-red-100 border-red-300'">
                        <span :class="previous[index] < current[index] ? 'text-green-600' : 'text-red-600'">
                            <ui-icon :name="previous[index] < current[index] ? 'arrow-up' : 'arrow-down'"></ui-icon>
                            {{change[index]}}%
                        </span>
                    </div>
                    <div class="text-sm">
                        {{$number(previous[index])}}
                    </div>
               </div>
            </div>

            <div class="h-[4.5rem] w-3/5 min-w-0 px-2">
                <chart :options="chartOptions[index]" ref="chart" class="h-full rounded-lg"></chart>
            </div>
        </div>
        <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20 bg-gray-100': isInitialized, 'bg-white': ! isInitialized}" v-if="isLoading">
            <ui-spinner></ui-spinner>
        </div>
    </div>
</template>

<script>
import { Chart } from 'highcharts-vue'

import { mapLocalState } from '@/helpers/pinia'

export default {
    components: {
        Chart
    },

    computed: {
        ...mapLocalState([ 'change', 'chartOptions', 'current', 'isInitialized', 'isLoading', 'metrics', 'previous' ]),
    },

    methods: {
        title(metric) {
            if (metric.aggregatesQuery == 'interactions:sum') {
                return 'Interactions'
            } else if (metric.aggregatesQuery == 'views:sum') {
                return 'Views'
            } else {
                return 'Results'
            }
        },
        
        icon(metric) {
            if (metric.aggregatesQuery == 'interactions:sum') {
                return 'interactions'
            } else if (metric.aggregatesQuery == 'views:sum') {
                return 'views'
            } else {
                return 'hash'
            }
        }
    }
}
</script>
