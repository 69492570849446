 <template>
    <div class="flex justify-between items-center mb-5 mx-auto px-1">
        <div class="flex items-center gap-x-1.5 grow">
            <slot name="title">
                <h1 class="text-gray-700 text-xl font-medium leading-none" v-if="title">
                    {{title}}
                </h1>
            </slot>
        </div>

        <div class="flex items-center space-x-4">
            <slot name="actions"></slot>
            <results-search :store="store" v-if="store.searchable">
                <template v-slot:filter>
                    <slot name="filter"></slot>
                </template>
            </results-search>
            <results-sorting :store="store" v-if="store.availableSortingOptions.length > 1"></results-sorting>
            <results-view :store="store" v-if="store.availableLayoutOptions.length > 1"></results-view>
        </div>
    </div>
</template>

<script>
import ResultsSearch from './results-search'
import ResultsSorting from './results-sorting'
import ResultsView from './results-view'

export default {
    props: [ 'title' ],

    components: {
        ResultsSearch, ResultsSorting, ResultsView
    }
}
</script>
