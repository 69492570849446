<template>
    <ui-modal name="alert-trigger" max-width="800" unstyled content-class="flex flex-col w-full">
        <alert-trigger></alert-trigger>
    </ui-modal>
</template>

<script>
import AlertTrigger from '@/components/alerts/trigger/trigger'

export default {
    components: {
        AlertTrigger
    }
}
</script>
