<template>
    <ui-dropdown width="w-64">
        <template v-slot:trigger>
            <ui-icon v-if="quality == 'observed'" name="data-solid" class="text-3xl text-green-600 cursor-help"></ui-icon>
            <ui-icon v-else-if="quality == 'processed'" name="magic" class="text-3xl text-blue-600 cursor-help"></ui-icon>
            <ui-icon v-else-if="quality == 'ai-processed'" name="ai" class="text-3xl text-red-600 cursor-help"></ui-icon>
        </template>
        <template v-slot:content>
            <div v-if="quality == 'observed'" class="px-4 py-3 text-gray-700">
                <div class="flex items-center gap-x-1.5 text-lg">
                    <ui-icon name="data-solid" class="text-green-600 text-3xl"></ui-icon>
                    <span>Observed Data</span>
                </div>
                <div class="leading-tight text-sm mt-2">This analysis is based on actual data harvested from the native platforms, without any additional processing.</div>
            </div>
            <div v-else-if="quality == 'processed'" class="px-4 py-3 text-gray-700">
                <div class="flex items-center gap-x-1.5 text-lg">
                    <ui-icon name="magic" class="text-blue-600 text-3xl"></ui-icon>
                    <span>Processed Data</span>
                </div>
                <div class="leading-tight text-sm mt-2">This analysis is based on data computed using regression or other models, estimated data or combination of hard and estimated data.</div>
            </div>
            <div v-else-if="quality == 'ai-processed'" class="px-4 py-3 text-gray-700">
                <div class="flex items-center gap-x-1.5 text-lg">
                    <ui-icon name="ai" class="text-red-600 text-3xl"></ui-icon>
                    <span>AI-Processed Data</span>
                </div>
                <div class="leading-tight text-sm mt-2">This categorization is based on the results of an Al language model with imperfect accuracy, and can include a few misclassified samples.</div>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
export default {
    props: [ 'quality' ]
}
</script>