<template>
    <ui-icon :class="cls" v-if="type === 'Facebook'" name="badges.channel-facebook-page"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'Telegram'" name="badges.channel-telegram-channel"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'Instagram'" name="badges.channel-instagram-profile"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'X'" name="badges.channel-x-user"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'VKontakte'" name="badges.channel-vkontakte-community"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'Reddit'" name="badges.channel-reddit-subreddit"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'Youtube'" name="badges.channel-youtube-channel"></ui-icon>
    <ui-icon :class="cls" v-if="type === 'Tik-Tok'" name="badges.channel-tik-tok-user"></ui-icon>
    <ui-icon :class="cls" v-if="type === '4Chan'" name="badges.channel-4chan-board"></ui-icon>
    <ui-icon :class="cls" v-if="type === '2Ch'" name="badges.channel-2ch-board"></ui-icon>
</template>

<script>
export default {
    props: {
        type: {type: String, required: true},
        size: {type: String, default: '1.15em'},
    },
    data: () => ({
        cls: "ring-1 ring-white rounded-full"
    })
}
</script>
