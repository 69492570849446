import useAuthPasswordRecoveryRequestPage from '@/pages/auth-password-recovery-request'
import { defineForm } from '@/stores/reusable/form'

import api from '@/api'

export const useAuthPasswordRecoveryRequestFormStore = defineForm({
    id: 'authPasswordRecoveryRequestForm',

    inputs: () => ({
        email: null
    }),

    async submitRequest() {
        return api.route('auth password-recovery request')
            .formData({
                email: this.inputs.email,
                product: 'juno'
            })
            .post()
            .error(400, err => {
                this.errors = { other: [ err.json.message ] }
                this.submitting = false
            })
    },

    async onResponse(res) {
        useAuthPasswordRecoveryRequestPage().submitted = true
    }
})

export default useAuthPasswordRecoveryRequestFormStore
