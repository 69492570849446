<template>
    <!-- eslint-disable vue/no-mutating-props -->
    <div class="text-center">
        This feature uses AI to apply tags to content based on categories defined in natural language.
    </div>

    <div class="text-center text-xs text-red-600 mt-2" v-if="Object.keys(errors).length">
        Please enter at least one category name and tag.
    </div>

    <div v-for="category, index in inputs.multiCategory.categories" class="border border-gray-100 shadow-sm rounded-lg p-4 mt-4" :key="index">
        <div class="flex space-x-6">
            <div class="flex-1">
                <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" :placeholder="category.placeholder.name" v-model="category.name" :errors="errors.multiCategory?.[index]?.name"></ui-input>
                </div>
            </div>

            <div class="flex-1">
                <label for="tag" class="block text-sm text-gray-700 font-medium mb-1">
                    Tag
                </label>

                <div>
                    <tags-dropdown v-model="category.tag"></tags-dropdown>
                </div>
            </div>
        </div>

        <div class="mt-4">
            <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                Description
            </label>

            <div>
                <ui-input type="text" name="description" id="description" :placeholder="category.placeholder.description" v-model="category.description" :errors="errors.multiCategory?.[index]?.description"></ui-input>
            </div>
        </div>
    </div>

    <div v-if="inputs.multiCategory.categories.length < 5" class="mt-3 flex justify-center">
        <a @click.prevent="addCategory" class="text-gray-600 hover:text-gray-900" href="#">
            <ui-icon name="plus"></ui-icon> Add category
        </a>
    </div>

    <div class="mt-4">
        <div class="mt-2">
            <label for="context" class="block text-sm text-gray-700 font-medium mb-1">
                Context
            </label>

            <div>
                <ui-input type="textarea" rows="2" name="context" id="context" placeholder="Military conflict refers to any use of military forces." v-model="inputs.yesOrNo.context" :errors="errors.context"></ui-input>
            </div>

            <div class="text-sm text-gray-600 mt-1">
                Optionally specify additional context to your question. This can help to make the answers more accurate.
            </div>
        </div>
    </div>
</template>

<script>
import TagsDropdown from './tags-dropdown'

import useContentModalsSmartTagStore from '@/stores/content/modals/smart-tag'

import { mapActions } from 'pinia'

export default {
    props: [ 'errors', 'inputs' ],

    components: {
        TagsDropdown
    },

    methods: {
        ...mapActions(useContentModalsSmartTagStore, [ 'addCategory' ])
    }
}
</script>
