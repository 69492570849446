import { defineContentItemPerformanceAnalysisStore } from '@/stores/analysis/analyses/content-item-performance'

import number from '@/helpers/number'

import { defineStore } from 'pinia'

export const useMetricsStore = defineStore({
    id: 'contentDetailsMetrics',

    state: () => ({
        content: {},

        isInitialized: false,
        isLoading: false,

        selectedMetric: 'interactions',

        supportedMetrics: [
            { id: 'interactions', name: 'Interactions', icon: 'interactions', aggregate: 'interactions:sum', dataQuality: 'observed' },
            { id: 'reactions', name: 'Reactions', icon: 'thumbs-up', aggregate: 'reactions:sum', dataQuality: 'observed' },
            { id: 'comments', name: 'Comments', icon: 'comment', aggregate: 'comments:sum', dataQuality: 'observed' },
            { id: 'shares', name: 'Shares', icon: 'share', aggregate: 'shares:sum', dataQuality: 'observed' },
            { id: 'views', name: 'Views', icon: 'views', aggregate: 'views:sum', dataQuality: 'observed' }
        ],

        analysisStore: defineContentItemPerformanceAnalysisStore({ id: 'contentDetailsMetricsAnalysis' })
    }),

    getters: {
        chartOptions: store => ({
            chart: {
                backgroundColor: false,
                type: 'line',
                zoomType: 'xy',
                resetZoomButton: {
                    position: {
                        x: -40,
                        y: -5,
                    }
                },
                spacing: [0, 0, 0, 0]
            },
            boost: {
                useGPUTranslations: true
            },
            title: {
                text: '',
            },
            plotOptions: {
                series: {
                    lineWidth: 3,
                    marker: {
                        enabled: false
                    }
                }
            },
            xAxis: {
                type: 'datetime',
                labels: {
                    align: 'center',
                    style: { color: '#8599a3' }
                }
            },
            yAxis: [
                {
                    id: 'values',
                    title: { text: '' },
                    labels: { enabled: false },
                    gridLineColor: 'transparent'
                }
            ],
            tooltip: {
                backgroundColor: 'rgba(107, 114, 128, 0.8)',
                borderColor: 'rgb(156, 163, 175)',
                borderRadius: 7,
                hideDelay: 100,
                padding: 8,
                pointFormatter: function () {
                    return `<strong>${this.series.name}: ${number(this.y)}</strong><br/>`
                },
                shadow: false,
                shared: true,
                style: { color: '#fff', textAlign: 'center' }
            },
            legend: {
                enabled: false
            },
            series: [{
                color: '#2172f0',
                data: store.activeMetric().data,
                name: store.activeMetric().name,
                type: 'spline',
                yAxis: 0
            }],
            credits: {
                enabled: false
            }
        }),
        
        lastUpdatedAt() {
            return this.analysisStore().analysisSeries[0]?.values[this.analysisStore().analysisSeries[0]?.values.length - 1]?.value
                || this.content.firstSeenAt
        }
    },

    actions: {
        async initialize(content) {
            this.content = content
            
            await this.loadAnalysis()

            this.isInitialized = true
        },

        async loadAnalysis() {
            this.isLoading = true

            let store = this.analysisStore()
        
            store.series = [
                {
                    label: 'Interactions',
                    color: '#2172f0',
                    meta: {
                        aggregator: 'sum',
                        metric: 'interactions'
                    },
                    datasetType: 'content',
                    datasetId: this.content.id
                },
                {
                    label: 'Reactions',
                    color: '#2172f0',
                    meta: {
                        aggregator: 'sum',
                        metric: 'reactions'
                    },
                    datasetType: 'content',
                    datasetId: this.content.id
                },
                {
                    label: 'Comments',
                    color: '#2172f0',
                    meta: {
                        aggregator: 'sum',
                        metric: 'comments'
                    },
                    datasetType: 'content',
                    datasetId: this.content.id
                },
                {
                    label: 'Shares',
                    color: '#2172f0',
                    meta: {
                        aggregator: 'sum',
                        metric: 'shares'
                    },
                    datasetType: 'content',
                    datasetId: this.content.id
                },
                {
                    label: 'Views',
                    color: '#2172f0',
                    meta: {
                        aggregator: 'sum',
                        metric: 'views'
                    },
                    datasetType: 'content',
                    datasetId: this.content.id
                }
            ]

            store.date = null
            store.granularity = null
            
            await store.loadSeries()
        
            this.isLoading = false
        },

        metrics() {
            return this.supportedMetrics.map((metric, index) => ({
                ...metric,
                current: this.content.metrics?.[metric.id],
                data: this.analysisStore().analysisSeries[index]?.values || []
            }))
        },

        activeMetric(series) {
            return this.metrics(series).find(metric => metric.id == this.selectedMetric)
        }
    }
})

export default useMetricsStore
