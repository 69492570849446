<template>
    <ui-modal name="workspaces" unstyled max-width="560px" width="100%" @opened="opened" content-class="flex flex-col">
        <div class="w-full max-w-2xl mx-auto bg-white rounded-md shadow-md flex flex-col overflow-hidden p-4">
            <div class="flex gap-x-5 items-center px-1">
                <div>
                    <span class="text-lg font-medium">Workspaces</span>
                </div>

                <a @click.prevent="newWorkspace()" href="#" class="text-blue-600 hover:text-blue-900 ml-auto">
                    <ui-icon name="plus"></ui-icon> Create
                </a>

                <a @click.prevent="$my.logOut" href="#" class="text-blue-600 hover:text-blue-900">
                    <ui-icon name="power"></ui-icon> Logout
                </a>

                <a @click="$modal.hide('workspaces')" href="#">
                    <ui-icon name="x" class="text-lg"></ui-icon>
                </a>
            </div>
            
            <div class="relative mt-4">
                <div class="absolute top-0 left-4 h-9 flex items-center pointer-events-none text-xl z-40">
                    <ui-icon name="search" class="text-gray-500"></ui-icon>
                </div>

                <input type="text" ref="input" v-model="search" autocomplete="off" spellcheck="false" class="block w-full h-9 pl-10 pr-28 py-3 rounded-t-md placeholder-gray-500 focus:outline-none focus:ring-0 focus:placeholder-gray-500 sm:text-sm border-0 z-30 max-h-48 resize-none rounded-b-md text-gray-800 bg-gray-100" placeholder="Search...">
            </div>

            <div class="overflow-y-auto divide-y divide-gray-200 mt-3 -mx-4 -mb-4" ref="results">
                <workspace-card :workspace="workspace" :key="workspace.id" v-for="workspace in workspaces"></workspace-card>
            </div>
        </div>
    </ui-modal>
</template>

<script>
import WorkspaceCard from './partials/workspace-card'

import useMyWorkspacesStore from '@/stores/me/workspaces'
import useWorkspaceInfoStore from '@/stores/me/modals/workspace-info'

import { sortNaturally } from '@/helpers/sorting'
import textSearch from '@/helpers/text-search'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        WorkspaceCard
    },
    
    data: () => ({
        search: ''
    }),

    computed: {
        ...mapState(useMyWorkspacesStore, {
            workspaces(store) {
                if (! store.items.length) return []
                
                let workspaces = sortNaturally(textSearch(this.search, store.items, v => v.name))
                
                let globalWorkspace = workspaces.find(w => w.type == 'global')
                let personalWorkspace = workspaces.find(w => w.type == 'personal')
                
                return [ globalWorkspace, personalWorkspace, ...workspaces.filter(w => w.type == 'default') ].filter(Boolean)
            }
        })
    },
    
    methods: {
        ...mapActions(useWorkspaceInfoStore, { newWorkspace: 'open' }),
        
        opened() {
            this.$refs.input.focus()
        }
    }
}
</script>
