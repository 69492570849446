<template>
    <ui-dropdown align="right">
        <template v-slot:trigger>
            <a href="#" class="border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-center h-8 px-3 whitespace-nowrap">
                <span class="text-sm font-medium">Ordered by <span class="lowercase">{{store.sorting.name}}</span></span>
                <ui-icon name="select" class="ml-2"></ui-icon>
            </a>
        </template>

        <template v-slot:content>
            <template v-for="option, index in store.availableSortingOptions">
                <ui-dropdown-link :icon="option.icon" @click="store.setSorting(option.id)" :key="`ordering-${option.id}`" v-if="option.id">
                    {{option.name}}
                </ui-dropdown-link>
                <ui-dropdown-separator :key="`separator-${index}`" v-else></ui-dropdown-separator>
            </template>
        </template>
    </ui-dropdown>
</template>
