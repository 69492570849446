<template>
    <div class="block flex items-center my-6 cursor-default" @click="store.toggleRecipient('*')">
        <ui-switch :value="inputs.recipients['*']" size="lg" class="mr-3"></ui-switch>
        <span class="font-medium text-lg">Notify all members of this workspace</span>
    </div>

    <div class="columns-2 gap-x-3 space-y-2">
        <div class="flex items-center break-inside-avoid cursor-default" @click="store.toggleRecipient(user.id)" :key="user.id" v-for="user in currentWorkspace.users">
            <div class="shrink-0 pr-3">
                <ui-switch  :value="inputs.recipients[user.id]" size="sm" :disabled="inputs.recipients['*']"></ui-switch>
            </div>

            <div class="shrink-0">
                <ui-avatar :item="user" class="w-6 h-6"></ui-avatar>
            </div>

            <div class="flex-1 min-w-0 px-2">
                <div class="truncate" v-tooltip="user.name">{{user.name}}</div>
                <div class="text-gray-600 text-xs leading-none  truncate">{{user.email}}</div>
            </div>
        </div>
    </div>

    <div class="flex items-center gap-x-2 mt-6">
        <ui-icon name="info" class="text-gray-600 text-lg shrink-0"></ui-icon>
        <div class="leading-tight text-gray-600">
            You can change your email address and phone number in the account settings.
        </div>
    </div>
</template>

<script>
import useMyStore from '@/stores/me/my'

import { mapState } from 'pinia'

export default {
    props: [ 'channel', 'store' ],

    computed: {
        ...mapState(useMyStore, [ 'currentWorkspace' ]),

        inputs() { return this.store.inputs }
    }
}
</script>
