<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg w-full p-4 min-w-0">
        <h1 class="text-lg font-medium text-gray-700 pb-2">Top Media</h1>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div v-for="series in series" :key="series.id" class="flex-1 min-w-0 flex flex-col relative">
                <div v-if="isComparing" class="mb-3">
                    <span class="rounded px-1.5 py-1 font-medium" :style="{ 'background-color': `${series.color}20`, 'color': series.color }">{{series.name}}</span>
                </div>

                <div class="flex flex-wrap items-center justify-center gap-1 mt-2">
                    <div v-if="! media(series).length" class="text-gray-600 text-sm mb-4">
                        No media have been found.
                    </div>

                    <a v-else href="#" @click.prevent="store.showContent(media.content)" class="w-28 h-28 relative" :key="media.id" v-for="media in media(series)">
                        <img class="w-full h-full object-cover" :src="media.mediaType == 'video' ? media.thumbnailUrl : media.url" v-if="media.mediaType == 'video' ? media.thumbnailUrl : media.url">
                        <div class="w-full h-full bg-gray-500" v-else></div>

                        <div class="absolute inset-0 flex items-center justify-center text-3xl text-white" v-if="media.mediaType == 'video'">
                            <div class="w-16 h-16 flex items-center justify-center bg-gray-900 bg-opacity-50 rounded-full" v-if="media.url">
                                <ui-icon name="play"></ui-icon>
                            </div>
                            <div class="h-12 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 rounded-2xl px-2" v-else>
                                <ui-icon name="warning" class="text-xl"></ui-icon>
                                <span class="text-2xs">Video unavailable</span>
                            </div>
                        </div>
                    </a>
                </div>

                <div class="absolute inset-0 backdrop-blur-sm rounded-2xl flex items-center justify-center" :class="{'bg-opacity-20': isInitialized, 'bg-white': ! isInitialized}" v-if="series.isLoading">
                    <ui-spinner></ui-spinner>
                </div>
            </div>
        </div>
    </div>

    <Teleport to="#root">
        <stream-lightbox :store="store.mediaLightbox"></stream-lightbox>
    </Teleport>
</template>

<script>
import StreamLightbox from '@/components/content/perspective/stream/lightbox'

import { mapLocalState } from '@/helpers/pinia'

export default {
    components: { StreamLightbox },

    computed: {
        ...mapLocalState([ 'isComparing', 'isInitialized', 'series' ])
    },

    methods: {
        media(series) {
            return series.items
                .map(content => ({ ...content, media: this.resolveMedia(content) }))
                .flatMap(content => content.media.map((media, index) => ({ ...media, content, index })))
                .slice(0, 24)
        },

        resolveMedia(content) {
            let thumbnails = content.images.filter(i => i.parent)
            let images = content.images.filter(i => ! i.parent)

            let videos = content.videos.map(v => ({ ...v, thumbnailUrl: thumbnails.find(t => t.parent == v.id)?.url }))

            return [ ...images, ...videos ].sort((a, b) => a.order - b.order)
        }
    }
}
</script>
