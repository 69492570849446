<template>
    <ui-dropdown>
        <template v-slot:trigger>
            <a href="#" :class="triggerClass">
                <span :class="{ 'font-normal text-gray-600': ! model }">{{model ? model?.name : placeholder}}</span>
                <ui-icon name="select" class="ml-2"></ui-icon>
            </a>
        </template>

        <template v-slot:content>
            <div class="max-h-96 overflow-y-auto">
                <template v-for="option, index in options">
                    <ui-dropdown-link :icon="option.icon" @click="model = option" :key="option?.id" v-if="option?.id !== undefined">
                        {{option.name}}
                    </ui-dropdown-link>
                    <ui-dropdown-separator :key="`separator-${index}`" v-else></ui-dropdown-separator>
                </template>
                <template v-if="clearable">
                    <ui-dropdown-separator></ui-dropdown-separator>
                    <ui-dropdown-link icon="clear" @click="model = undefined">
                        Clear
                    </ui-dropdown-link>
                </template>
            </div>
        </template>
    </ui-dropdown>
</template>

<script>
export default {
    props: {
        clearable: Boolean,
        form: Boolean,
        modelValue: {},
        options: {},
        placeholder: {},
        value: {}
    },

    computed: {
        model: {
            get() {
                return this.modelValue
                    ? this.options.find(o => o?.id == this.modelValue)
                    : this.options.find(o => o?.id == this.value)
            },
            set(val) {
                this.$emit('update:modelValue', val?.id)
                this.$emit('change', val?.id)
            }
        },

        triggerClass() {
            return {
                'border border-gray-300 hover:border-gray-400 text-gray-700 hover:text-gray-900 rounded-md font-medium flex items-center justify-between px-3 w-full' : true,
                'h-8': ! this.form,
                'h-9 bg-white': this.form
            }
        }
    }
}
</script>
