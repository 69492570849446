<template>
    <div class="grid grid-cols-1 gap-10 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 sm:px-6 lg:px-8">
        <template v-if="store.items.data && store.items.data.length">
            <template v-for="item in store.items.data">
                <media-card :content="item" :media="media" :stream-store="store" :key="media.id" v-for="media in resolveMedia(item)"></media-card>
            </template>
        </template>

        <template v-else-if="store.items.pending">
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
            <stream-pending-placeholder></stream-pending-placeholder>
        </template>
    </div>
</template>

<script>
import MediaCard from '@/components/content/stream-cards/media'

import StreamPendingPlaceholder from '../cards/pending-media-placeholder'

export default {
    components: { MediaCard, StreamPendingPlaceholder },

    methods: {
        resolveMedia(content) {
            let thumbnails = content.images.filter(i => i.parent)
            let images = content.images.filter(i => ! i.parent)

            let videos = content.videos.map(v => ({ ...v, thumbnailUrl: thumbnails.find(t => t.parent = v.id)?.url }))

            return [ ...images, ...videos ].sort((a, b) => a.order - b.order)
        }
    }
}
</script>
