import sanitizeHtml from 'sanitize-html'

export function sanitizeHTML(html) {
    return sanitizeHtml(html, {
        allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'ul', 'ol', 'li', 'a' ]),
        allowedAttributes: {
            'h1': ['style'],
            'h2': ['style'],
            'h3': ['style'],
            'p': ['style'],
            'span': ['style'],
            'ul': ['style'],
            'ol': ['style'],
            'a': ['href', 'target']
        },
        allowedStyles: {
            '*': {
                'color': [/^#(0x)?[0-9a-f]+$/i, /^rgb\(\s*(\d{1,3})\s*,\s*(\d{1,3})\s*,\s*(\d{1,3})\s*\)$/],
                'text-align': [/^left$/, /^right$/, /^center$/, /^justify$/]
            }
        },
        transformTags: {
            'a': sanitizeHtml.simpleTransform('a', { target: '_blank' })
        }
    })
}

export function removeHTML(html) {
    return sanitizeHtml(html, {
        allowedTags: []
    })
}
