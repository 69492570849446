<template>
    <div class="rounded-lg bg-white border border-gray-200 shadow-sm hover:shadow-lg cursor-pointer relative group">
        <div class="flex items-center relative p-4">
            <div class="bg-gray-100 rounded-full w-8 h-8 drop-shadow-sm shrink-0"></div>

            <div class="flex-1 min-w-0 flex gap-x-2 px-2">
                <div class="bg-gray-100 rounded-full h-5 w-32"></div>
            </div>

            <div class="shrink-0">
            </div>
        </div>

        <div class="relative h-16 flex items-center justify-center">
        </div>

        <div class="flex items-center relative px-4 h-14 border-t border-gray-100 rounded-b">
            <div class="flex-1">
                <div class="bg-gray-100 rounded-full h-2 w-8"></div>
            </div>

            <div class="flex-1">
                <div class="bg-gray-100 rounded-full h-2 w-8"></div>
            </div>

            <div class="flex-1">
                <div class="bg-gray-100 rounded-full h-2 w-8"></div>
            </div>

            <div class="flex-1">
                <div class="bg-gray-100 rounded-full h-2 w-8"></div>
            </div>
        </div>
    </div>
</template>
