<template>
    <ui-contextual-menu v-bind="$attrs" z-index="50">
        <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'features.details', params: { id: feature.id } }).href">
            Show details
        </ui-dropdown-link>

        <ui-dropdown-separator></ui-dropdown-separator>

        <ui-dropdown-link icon="warning" @click="() => report('feature', feature.id)">
            Report
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="$inspect(feature)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useSupportReportStore from '@/stores/support/report'

import {mapActions} from 'pinia'

export default {
    props: [ 'feature' ],

    methods: {
        ...mapActions(useSupportReportStore, [ 'report' ])
    }
}
</script>
