<template>
    <router-link :to="{ name: 'dashboard.boards.board', params: { id: board.id } }" class="block" v-if="mode == 'card'">
        <div class="rounded-lg bg-white border border-gray-200 shadow-sm hover:shadow-lg cursor-pointer relative group">
            <div class="flex items-center relative p-4">
                <ui-avatar :item="board" class="w-8 h-8 shrink-0"></ui-avatar>

                <div class="flex-1 min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight" v-tooltip="tooltip">
                        {{board.name}}
                    </h1>
                </div>

                <div class="shrink-0">
                    <contextual-menu :board="board" plain></contextual-menu>
                </div>
            </div>
        </div>
    </router-link>

    <component :is="cardsStore?.chooseCallback ? 'div' : 'router-link'" :to="{ name: 'dashboard.boards.board', params: { id: board.id } }" class="block hover:bg-gradient-to-r from-transparent via-gray-50 to-transparent transition" v-else-if="mode == 'row'">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <ui-avatar :item="board" class="w-7 h-7 shrink-0"></ui-avatar>

                <div class="min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight" v-tooltip="tooltip">
                        {{board.name}}
                    </h1>
                </div>

                <div class="flex items-center gap-x-2">
                    <span class="text-gray-500 text-sm" v-tooltip="'Recently used'" v-if="board.recent">
                        <ui-icon name="time"></ui-icon>
                    </span>

                    <span class="text-gray-500 text-sm" v-tooltip="'Pinned'" v-if="board.pinned">
                        <ui-icon name="pin-off"></ui-icon>
                    </span>
                </div>
            </div>

            <div class="flex items-center px-3">
                <slot name="actions">
                    <contextual-menu :board="board" plain></contextual-menu>
                </slot>
            </div>

            <div class="absolute inset-0 bg-gray-900 bg-opacity-50 rounded hidden group-hover:flex shadow-lg cursor-pointer items-center justify-center z-20" @click.prevent.stop="cardsStore.choose(board)" v-if="cardsStore?.chooseCallback">
                <div class="rounded border border-white px-3 py-1 text-white font-medium">Choose</div>
            </div>
        </div>
    </component>
</template>

<script>
import ContextualMenu from '@/components/dashboards/contextual-menus/board'

import useMyDashboards from '@/stores/me/dashboards'

import { removeHTML } from '@/helpers/html'

import { mapActions } from 'pinia'

export default {
    props: { board: {}, cardsStore: {}, mode: { default: 'card' } },

    components: { ContextualMenu },

    computed: {
        tooltip() {
            return { content: `<b>${this.board.name}</b><br>${removeHTML(this.board.description)}`, html: true }
        }
    },

    methods: {
        ...mapActions(useMyDashboards, {
            updateBoard: 'update'
        })
    }
}
</script>
