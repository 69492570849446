<template>
    <a href="#" class="text-gray-700 hover:text-gray-900">
        <ui-icon name="help-circle" @click.prevent="() => show(page, section)"></ui-icon>
    </a>
</template>

<script>
import useSupportHelpStore from '@/stores/support/help'

import { mapActions } from 'pinia'

export default {
    props: [ 'page', 'section' ],

    methods: {
        ...mapActions(useSupportHelpStore, [ 'show' ])
    }
}
</script>