<template>
    <div :class="{ 'bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-lg': ! inline }">
        <div v-if="channel.unavailable" class="bg-red-700 text-white py-2 px-3 flex items-center gap-x-3 border-b border-red-600">
            <ui-icon name="clear" class="text-lg flex-0"></ui-icon>
            <div class="text-sm">
                <h1 class="font-medium">This Channel is no longer available.</h1>
                <p class="text-xs">It might have been banned, removed by the owner or privated.</p>
            </div>
        </div>
        <div v-else-if="! $nodeType(channel).monitorable" class="bg-gray-300 text-gray-900 py-2 px-3 flex items-center gap-x-3 border-b border-gray-400">
            <ui-icon name="eye-off" class="text-lg flex-0"></ui-icon>
            <div class="text-sm">
                <h1 class="font-medium">This Channel is not monitorable.</h1>
                <p class="text-xs">Content published in other Channels will still be collected.</p>
            </div>
        </div>
        <div v-else-if="! channel.monitored" class="bg-red-700 text-white py-2 px-3 flex items-center gap-x-3 border-b border-red-600">
            <ui-icon name="eye-off" class="text-lg flex-0"></ui-icon>
            <div class="text-sm">
                <h1 class="font-medium">This Channel is currently not monitored.</h1>
                <p class="text-xs">To start monitoring this Channel, use the contextual menu below.</p>
            </div>
        </div>
        <div v-else-if="isExplicitlyMonitored" class="bg-green-700 text-white py-2 px-3 flex items-center gap-x-3 border-b border-green-600">
            <ui-icon name="check" class="text-lg flex-0"></ui-icon>
            <div class="text-sm">
                <h1 class="font-medium">You are monitoring this channel.</h1>
                <p class="text-xs">To stop monitoring this Channel, use the contextual menu below.</p>
            </div>
        </div>
        <div v-else-if="channel.monitored" class="bg-blue-700 text-white py-2 px-3 flex items-center gap-x-3 border-b border-blue-600">
            <ui-icon name="check" class="text-lg flex-0"></ui-icon>
            <div class="text-sm">
                <h1 class="font-medium">We are monitoring this Channel.</h1>
                <p class="text-xs">This Channel is monitored by Gerulata or some other organization.</p>
            </div>
        </div>

        <div v-if="coverUrl" class="w-full h-32 group relative">
            <img :src="coverUrl" class="w-full h-full object-cover">
            <div v-if="coverPictures.length" @click.prevent="$page.lightbox().open(coverPictures, 0)" class="absolute top-2 right-2 w-8 h-8 rounded-full hidden group-hover:flex items-center justify-center bg-gray-900/50 backdrop-blur-sm text-white text-xl">
                <ui-icon name="search"></ui-icon>
            </div>
        </div>

        <div class="px-4">
            <div class="flex space-x-3 mb-3" :class="coverUrl ? 'mt-2 items-center' : 'mt-6 items-center'">
                <div class="group relative" :class="{ '-mt-4': coverUrl }">
                    <ui-avatar :item="channel" class="w-12 h-12 rounded-full shrink-0" :class="{ 'ring-2 ring-white shrink-0': coverUrl }"></ui-avatar>
                    <div v-if="avatarPictures.length" @click.prevent="$page.lightbox().open(avatarPictures, 0)" class="absolute top-0 left-0 w-12 h-12 rounded-full hidden group-hover:flex items-center justify-center bg-gray-900/50 backdrop-blur-sm text-white text-xl">
                        <ui-icon name="search"></ui-icon>
                    </div>
                </div>
                <div class="flex-1 min-w-0">
                    <h1 class="text-3xl font-bold leading-none truncate pb-0.5" v-tooltip="channel.name">{{channel.name}}</h1>
                    <h2 class="text-sm leading-none">
                        <ui-icon :name="$nodeType(channel).icon"></ui-icon>
                        {{$nodeType(channel).name}}
                    </h2>
                </div>
                <alerts-menu :subject="channel"></alerts-menu>
                <contextual-menu :channel="channel"></contextual-menu>
            </div>

            <table class="w-full table-fixed divide-y divide-gray-100 text-sm mb-3">
                <tr :key="label" v-for="value, label in details">
                    <td class="pl-1 pr-3 py-3 whitespace-nowrap font-medium w-1/4">
                        {{label}}
                    </td>
                    <td class="pr-1 pl-3 py-3 whitespace-pre-line break-words">
                        <template v-if="label == 'Language'">
                            <div class="flex items-center space-x-0.5">
                                <template v-for="language in languages" :key="language.code">
                                    <ui-icon v-if="language.icon" :name="language.icon" class="text-3xl rounded-full" v-tooltip="language.name"></ui-icon>
                                    <div v-else class="flex items-center justify-center uppercase text-2xs font-medium h-5 w-5 rounded-full bg-gray-700 text-white cursor-default" v-tooltip="language.name">
                                        {{language.code}}
                                    </div>
                                </template>
                            </div>
                        </template>
                        <template v-else>
                            {{$strLimit(value)}}
                            <a href="#" v-if="value.length > 250" class="text-blue-600" @click.prevent="showFullText(label, value)">Read more</a>
                        </template>
                    </td>
                </tr>
            </table>
        </div>

        <div class="divide-y divide-gray-100 bg-gray-50 border-t border-gray-100 last:rounded-b-sm">
            <div class="py-2 px-5 flex space-x-2" v-if="lists && lists.length">
                <ui-avatar class="w-6 h-6" :item="list" :key="list.id" v-tooltip="list.name" v-for="list in lists"></ui-avatar>
            </div>

            <card-notes :item="channel"></card-notes>
        </div>
    </div>
    
    <Teleport to="#root">
        <stream-lightbox :store="$page.lightbox()"></stream-lightbox>
    </Teleport>    
</template>

<script>
import AlertsMenu from '@/components/me/alerts/alerts-menu'
import CardNotes from '@/components/me/notes/card-notes'
import ContextualMenu from '@/components/channels/contextual-menus/channel'
import StreamLightbox from '@/components/content/perspective/stream/lightbox'

import useChannelDetailsText from '@/stores/channels/modals/channel-details-text'

import { sortByDate } from '@/helpers/sorting'
import language from '@/helpers/language'
import strLimit from '@/helpers/str-limit'

import filter from 'just-filter'
import { mapActions } from 'pinia'

export default {
    props: [ 'channel', 'inline' ],

    components: {
        AlertsMenu, CardNotes, ContextualMenu, StreamLightbox
    },

    computed: {
        avatarPictures() {
            return sortByDate(this.channel?.media?.filter(i => i.type == 'avatar') || [], 'firstSeenAt').reverse()
        },

        coverPictures() {
            return sortByDate(this.channel?.media?.filter(i => i.type == 'cover') || [], 'firstSeenAt').reverse()
        },
        
        coverUrl() {
            return this.coverPictures[0]?.url
        },
        
        details() {
            return filter({
                'About': this.channel.description,
                'Username': this.channel.username,
                'Language': this.channel.language.filter(c => c != '00').join(', '),
                'Website': this.channel.website,
                'URL': this.channel.link,
                'Created At': this.channel.createdAt ? this.$dateTime(this.channel.createdAt) : null,
                'First Seen At': this.channel.firstSeenAt ? this.$dateTime(this.channel.firstSeenAt) : null
            }, (k, v) => v)
        },
        
        isExplicitlyMonitored() {
            return !! this.channel.lists?.find(l => l.type == 'monitored')
        },

        languages() {
            return this.channel.language.map(code => language(code) || { code, name: `Other (${code})` })
        },

        lists() {
            return this.channel.lists.filter(l => l.type == 'list')
        }
    },

    methods: {
        ...mapActions(useChannelDetailsText, { showFullText: 'open' }),
        
        $strLimit: strLimit
    }
}
</script>
