<template>
    <VMenu theme="link" placement="top" :delay="{ show: 350, hide: 100 }">
        <slot name="text">
            <span :class="textClass">{{name}}</span>
        </slot>
        <template #popper>
            <a :href="$router.resolve({ name: 'channels.channel.information', params: { id } }).href" target="_blank" class="block">
                <div class="flex items-center w-64 p-1">
                    <div class="shrink-0 relative">
                        <ui-avatar :item="{ family, name, avatar }" class="w-8 h-8"></ui-avatar>
                        <ui-icon :name="monitored ? `badges.channel-${type}` : `badges.channel-${type}-inactive`" style="height: 1.15em; width: 1.15em" class="absolute -right-1 -bottom-0.5 text-xs ring-2 ring-white rounded-full"></ui-icon>
                    </div>

                    <div class="flex-1 text-left min-w-0 px-3">
                        <h1 class="text-lg font-semibold truncate leading-tight">
                            {{ name }}
                        </h1>
                        <div class="text-gray-800 text-xs leading-tight truncate">
                            {{$nodeType(type).name}}
                        </div>
                    </div>

                    <ui-icon name="chevron-right" class="text-xl text-gray-800 ml-auto"></ui-icon>
                </div>
            </a>
        </template>
    </VMenu>
</template>

<script>
export default {
    props: [ 'avatar', 'family', 'id', 'monitored', 'name', 'textClass', 'type' ]
}
</script>
