import useMyWorkspacesStore from '@/stores/me/workspaces'
import useMyNotificationsStore from '@/stores/me/notifications'
import useSupportChatStore from '@/stores/support/chat'
import useSupportHelpStore from '@/stores/support/help'
import useMyWarningsStore from '@/stores/me/warnings'
import useMyBroadcasterStore from '@/stores/me/broadcaster'
import useMyPushStore from '@/stores/me/push'

import api from '@/api'
import { isAnalyticsReady } from '@/analytics'
import { useModal, useRouter } from '@/helpers'

import { isBefore } from 'date-fns'
import { defineStore } from 'pinia'

export const useMyStore = defineStore({
    id: 'my',

    state: () => ({
        isAuthenticated: false,
        isInMaintenance: false,

        isInitialized: false,
        loadingPromise: false,

        user: {},

        preferences: {},
        permissions: [],
        budgets: null,

        intendedPath: null,

        accessibleFeatures: [],

        currentWorkspace: null,

        authToken: null
    }),

    getters: {
        isImpersonating() {
            return !! localStorage.getItem('impersonationToken')
        },

        preferredLanguage() {
            return this.preferences.preferredContentLanguage || 'en'
        },

        timezone() {
            return this.preferences.timezone || 'UTC'
        }
    },

    actions: {
        async initialize() {
            if (this.isInitialized) return
            if (this.loadingPromise) return this.loadingPromise

            this.handleImpersonation()

            this.authToken = this.authToken || localStorage.getItem('authToken')

            if (this.authToken && ! isNaN(Number(this.authToken.split('|')[0]))) {
                this.authToken = null
                localStorage.removeItem('authToken')
            }

            return this.loadingPromise = new Promise(resolve => {
                api.route('me').get()
                    .error(401, () => {
                        this.isAuthenticated = false
                        this.isInitialized = true
                        this.loadingPromise = null

                        resolve()
                    })
                    .json(async res => {
                        this.isAuthenticated = true

                        this.user = res.data

                        this.preferences = res.data.preferences
                        this.permissions = res.data.permissions

                        this.accessibleFeatures = res.data.features

                        this.currentWorkspace = res.data.workspace

                        await Promise.all([
                            useMyPushStore().initialize(),
                            useMyBroadcasterStore().initialize(),
                            useMyNotificationsStore().initialize(),
                            useMyWorkspacesStore().initialize(),
                            useSupportChatStore().initialize(),
                            useMyWarningsStore().initialize()
                        ])

                        this.isInitialized = true
                        this.loadingPromise = null

                        this.setAnalyticsUser()
                        this.notifyOnNewRelease()

                        resolve()
                    })
            })
        },

        async reinitialize() {
            this.isInitialized = false
            this.loadingPromise = null

            return this.initialize()
        },

        async loggedIn(token) {
            this.authToken = token

            localStorage.setItem('authToken', token)
            localStorage.removeItem('impersonationToken')

            await this.reinitialize()

            useRouter().push(this.intendedPath || { name: 'content' })

            this.intendedPath = null
        },

        async logOut() {
            await api.route('auth logout').post().res()

            localStorage.removeItem('authToken')

            window.location.reload()
        },

        sessionLost() {
            useModal().show('auth-session-lost')
        },

        inMaintenance() {
            this.isInMaintenance = true
        },

        handleImpersonation() {
            const impersonationToken = window.location.search.match(/impersonation-token=(.+?)(&|$)/)?.[1]
                || localStorage.getItem('impersonationToken')

            if (impersonationToken) {
                localStorage.setItem('impersonationToken', decodeURIComponent(impersonationToken))
                this.authToken = decodeURIComponent(impersonationToken)
            }
        },

        stopImpersonating() {
            localStorage.removeItem('impersonationToken')
            this.logOut()
        },

        updatePreferences(preferences) {
            return api.route('me preferences update')
                .formData({ _method: 'put', preferences: JSON.stringify(preferences) }, true)
                .post()
                .json(res => this.preferences = res.data)
        },

        can(...permissions) {
            return permissions.every(p => this.permissions.includes(p))
        },

        canAny(...permissions) {
            return permissions.some(p => this.permissions.includes(p))
        },

        hasFeatureTo(...features) {
            return features.every(f => this.accessibleFeatures.includes(f))
        },

        loadBudgets() {
            return api.route('me budgets')
                .get()
                .json(res => this.budgets = res)
        },

        async switchWorkspace(workspace) {
            this.currentWorkspace = workspace

            await this.updatePreferences({ lastWorkspace: workspace.id })

            document.location = '/'
        },

        setAnalyticsUser() {
            isAnalyticsReady().then(analytics => {
                analytics.setUserId(`${this.user.email} (${this.user.id})`)
            })
        },

        notifyOnNewRelease() {
            let myNotifications = useMyNotificationsStore()
            let lastSeenReleaseTimestamp = this.preferences.lastSeenReleaseTimestamp

            // already seen release notes for this release
            if (lastSeenReleaseTimestamp && ! isBefore(parseInt(lastSeenReleaseTimestamp), parseInt(import.meta.env.VITE_RELEASE_TIMESTAMP))) return

            setTimeout(() => {
                myNotifications.pushToast({
                    title: 'Platform just got updated!',
                    body: 'Take a quick look at the release notes to learn about new features.',
                    actions: [{
                        label: 'show',
                        onAction: () => {
                            this.markReleaseAsSeen()
                            useSupportHelpStore().showOverlay()
                            useSupportHelpStore().loadReleaseNotes(import.meta.env.VITE_RELEASE_NOTES)
                        }
                    }],
                    onDismiss: () => {
                        this.markReleaseAsSeen()
                    }
                })
            }, 1000)
        },

        markReleaseAsSeen() {
            this.updatePreferences({ lastSeenReleaseTimestamp: import.meta.env.VITE_RELEASE_TIMESTAMP })
        }
    }
})

export default useMyStore
