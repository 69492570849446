<template>
    <div class="relative">
        <slot :attrs="$attrs" :effective-class="effectiveClass" :model="model">
            <textarea v-bind="$attrs" :class="effectiveClass" ref="input" :rows="rows || 2" @focus="$emit('focus')" v-model="model" v-if="type == 'textarea'"></textarea>
            <input v-bind="$attrs" :type="type" :class="effectiveClass" ref="input" @focus="$emit('focus')" v-model="model" v-else>
            <div :class="effectiveIconClass" v-if="icon">
                <ui-icon :name="icon"></ui-icon>
            </div>
        </slot>
        <div class="text-xs text-red-600 mt-0.5" v-if="errors">
            <div :key="index" v-for="error, index in errors">{{error}}</div>
        </div>
    </div>
</template>

<script>
export default {
    inheritAttrs: false,

    props: { type: { default: 'text' }, errors: {}, icon: {}, rows: {}, size: {}, modelValue: {}, unstyled: Boolean },

    computed: {
        effectiveClass() {
            if (this.unstyled) return

            return {
                'shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm rounded-md text-gray-900 placeholder-gray-400': true,
                'h-9': this.type != 'textarea' && this.size != 'sm',
                'h-7 px-3': this.type != 'textarea' && this.size == 'sm',
                'border-red-500 has-errors': this.errors,
                'border-gray-300': ! this.errors,
                'pl-8': this.icon
            }
        },

        effectiveIconClass() {
            return 'absolute flex items-center justify-center h-9 w-8 left-0 top-0 text-sm text-gray-700'
        },

        model: {
            get() { return this.modelValue },
            set(val) { this.$emit('update:modelValue', val) }
        }
    },

    methods: {
        focus() {
            this.$refs.input.focus()
        }
    }
}
</script>
