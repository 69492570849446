<template>
    <div>
        <div class="pb-20 px-6 xl:px-16 max-w-12xl mx-auto">
            <div class="flex items-center justify-between my-6">
                <h1 class="text-[24px] font-semibold px-1">
                    Settings
                </h1>
            </div>

            <div class="space-y-8">
                <account-section></account-section>
                <organization-section></organization-section>
                <apps-section></apps-section>
            </div>
        </div>
    </div>
</template>

<script>
import AccountSection from './sections/account'
import AppsSection from './sections/apps'
import OrganizationSection from './sections/organization'

export default {
    components: {
        AccountSection, AppsSection, OrganizationSection
    }
}
</script>
