<template>
	<router-view></router-view>

	<Teleport to="#root">
		<board-edit-modal></board-edit-modal>
		<board-duplicate-modal></board-duplicate-modal>
	</Teleport>
</template>

<script>
import BoardEditModal from './modals/board-edit'
import BoardDuplicateModal from './modals/board-duplicate'

export default {
	components: {
		BoardDuplicateModal, BoardEditModal
	}
}
</script>
