<template>
    <div class="flex items-center justify-center gap-x-1.5 text-3xl font-semibold text-center mb-1">
        Sentiment changed to {{contentSentiment}} in
        <span class="flex items-center gap-x-1">
            <ui-avatar :item="trigger.meta.subject" class="w-7 h-7"></ui-avatar> {{trigger.meta.subject.name}}
        </span>
    </div>

    <div class="flex items-center justify-center gap-x-1.5 mb-10 text-gray-700">
        {{$dateTime(trigger.meta.dateRange.from)}} - {{$dateTime(trigger.meta.dateRange.to)}}
    </div>

    <p class="mb-2">The content sentiment has changed to <span class="font-semibold">{{contentSentiment}}</span> and the replies sentiment changed to <span class="font-semibold">{{repliesSentiment}}</span> between <span class="font-semibold">{{$dateTime(trigger.meta.dateRange.from)}}</span> and <span class="font-semibold">{{$dateTime(trigger.meta.dateRange.to)}}</span> as compared to the previous date range.</p>

    <div class="h-60 --timeline">
        <ui-analysis :store="{ analysisOptions, isRenderable: true }" class="max-w-2xl mx-auto mb-8"></ui-analysis>
    </div>

    <h2 class="text-center font-semibold mt-12 mb-4">Sample of the results</h2>

    <ui-async :guards="content.data" class="space-y-6 max-w-2xl mx-auto">
        <content-card v-for="content in content.data" :content="content" :key="content.id"></content-card>

        <ui-infinite-loading class="my-6" :identifier="lastReloadTime" @infinite="loadMore($event)"></ui-infinite-loading>

        <template v-slot:loading>
            <div class="flex items-center justify-center py-4 w-full h-60">
                <ui-spinner v-bind="spinner"></ui-spinner>
            </div>
        </template>
    </ui-async>
</template>

<script>
import ContentCard from '@/components/content/stream-cards/content'

import useTriggerSentimentShiftStore from '@/stores/alerts/trigger-sentiment-shift'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        ContentCard
    },

    computed: {
        ...mapState(useTriggerSentimentShiftStore, [ 'analysisOptions', 'content', 'lastReloadTime', 'trigger' ]),

        contentSentiment() {
            return this.trigger.meta.snapshots[0].content.average < 0 ? 'negative' : 'positive'
        },

        repliesSentiment() {
            return this.trigger.meta.snapshots[0].replies.average < 0 ? 'negative' : 'positive'
        }
    },

    methods: {
        ...mapActions(useTriggerSentimentShiftStore, [ 'loadMore' ])
    }
}
</script>
