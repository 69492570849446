<template>
    <div class="bg-white border border-gray-200 shadow-sm rounded-lg w-full p-4 flex flex-col min-w-0 relative">
        <div class="flex justify-between pb-2">
            <h1 class="text-lg font-medium text-gray-700">Heatmap</h1>
            <div class="flex items-center space-x-3">
                <a @click.prevent="store.toggleType()" href="#" class="flex items-center font-medium text-gray-700">
                    {{ store.type == 'hourly' ? 'Hourly' : 'Daily' }} <ui-icon name="select" class="text-xs"></ui-icon>
                </a>            
                <ui-data-quality-icon quality="observed"></ui-data-quality-icon>
            </div>
        </div>

        <div class="flex flex-1 min-h-0 gap-x-4">
            <div class="flex-1 min-w-0 flex flex-col relative">
                <hourly-heatmap :store="store.heatmapOptions" v-if="store.type == 'hourly'"></hourly-heatmap>
                <daily-heatmap :store="store.heatmapOptions" v-if="store.type == 'daily'"></daily-heatmap>
            </div>
        </div>
    </div>
</template>

<script>
import HourlyHeatmap from '@/components/analysis/analyses/heatmaps/hourly'
import DailyHeatmap from '@/components/analysis/analyses/heatmaps/daily'

export default {
    components: {
        DailyHeatmap, HourlyHeatmap
    }
}
</script>
