<template>
    <search-filter name="Language" :ids="['language']" :filters="filters" align="right" @opened="reset">
        <template v-slot:applied>
            <ui-icon :name="value[0].icon" class="mr-1" :class="value[0].iconClass" v-if="value.length === 1 && value[0].icon"></ui-icon>

            <span class="font-medium" v-if="value.length > 1">
                {{value.length}} languages
            </span>
            <span class="font-medium" v-else>
                {{value.length ? value[0].name : value.name}}
            </span>
        </template>

        <template v-slot:options>
            <div class="w-64">
                <div class="px-4">
                    <div class="relative w-full h-8">
                        <div class="absolute inset-y-0 left-2 text-gray-700 flex items-center pointer-events-none">
                            <ui-icon name="search" class="text-sm"></ui-icon>
                        </div>
                        <input placeholder="Search..." type="search" autocomplete="off" class="block border-0 h-full w-full pl-7 rounded-sm bg-gray-100 placeholder-gray-700 text-gray-900 focus:outline-none focus:ring-0 focus:placeholder-gray-700 text-sm" ref="searchInput" v-model="search">
                    </div>
                </div>

                <ui-dropdown-separator class="my-2"></ui-dropdown-separator>

                <div class="max-h-80 overflow-y-auto">
                    <div v-for="option, i in options" :key="i">
                        <ui-dropdown-link :icon="option.icon" checkbox :selected="localItems.includes(option.code)" @click="toggleLocalItem(option)" :key="`link-${index}`" v-if="option">
                            {{option.name}}
                        </ui-dropdown-link>

                        <ui-dropdown-separator class="my-2" :key="`separator-${index}`" v-else></ui-dropdown-separator>
                    </div>

                    <div class="text-gray-700 text-sm text-center" v-if='! options.length'>
                        No results
                    </div>
                </div>
            </div>
        </template>

        <template v-slot:controls="{ close }">
            <div class="flex items-center justify-between px-4 pb-3">
                <a href="#" class="flex items-center" @click.prevent="filters.remove('language'); close()">
                    <div class="flex items-center shrink-0 w-6">
                        <ui-icon name="x-rounded"></ui-icon>
                    </div>
                    <span class="text-gray-900 text-sm">Reset</span>
                </a>

                <ui-button @click="apply(); close()" color="blue" size="sm">Apply</ui-button>
            </div>
        </template>
    </search-filter>
</template>

<script>
import SearchFilter from './filter'

import language from '@/helpers/language'
import textSearch from '@/helpers/text-search'

export default {
    props: [ 'filters' ],

    components: { SearchFilter },

    data: () => ({
        localItems: [],
        search: ''
    }),

    computed: {
        allOptions() {
            let languages = [
                'ar', 'be', 'bg', 'zh', 'cs', 'da', 'nl', 'en', 'et', 'fi', 'fr', 'de', 'el', 'he', 'hi', 'hu', 'ga', 'it',
                'ko', 'lv', 'ja', 'lt', 'no', 'pl', 'pt', 'ro', 'ru', 'sr', 'sk', 'sl', 'es', 'sv', 'tr', 'uk',
                null,
                'af', 'sq', 'hy', 'az', 'ba', 'eu', 'bs', 'br', 'my', 'ca', 'km', 'cv', 'co', 'hr', 'eo', 'gl', 'lg', 'ka',
                'gu', 'id', 'is', 'ia', 'ie', 'kk', 'ky', 'ku', 'la', 'lb', 'mk', 'ms', 'mr', 'mn', 'nb', 'nn', 'oc', 'os',
                'pa', 'ps', 'fa', 'si', 'sn', 'so', 'st', 'sw', 'tl', 'ta', 'tt', 'te', 'th', 'bo', 'ts', 'tn', 'ur', 'uz',
                'vi', 'cy', 'fy', 'xh', 'yi', 'yo', 'za',
                null,
                '00'
            ]

            return languages.map(code => language(code))
        },

        options() {
            return textSearch(this.search, this.allOptions.filter(l => l), o => o?.name || '')
        },

        value() {
            return (this.filters.value('language') || []).map(v => this.allOptions.find(o => o?.code == v))
        }
    },

    methods: {
        toggleLocalItem(item) {
            this.localItems.includes(item.code) ? this.localItems.splice(this.localItems.findIndex(v => v === item.code), 1) : this.localItems.push(item.code)
        },

        reset() {
            this.search = ''

            this.localItems = [ ...(this.filters.value('language') || []) ]

            this.$nextTick(() => this.$refs.searchInput.focus())
        },

        apply() {
            this.localItems.length ? this.filters.set('language', this.localItems) : this.filters.remove('language')
        }
    }
}
</script>
