<template>
    <div class="border-2 border-gray-50 rounded-lg min-w-0 overflow-hidden flex-1">
        <list-card v-if="datasetList" :cards-store="analysisDatasetCardsStore" :list="datasetList" mode="row">
        </list-card>
        <perspective-card v-if="datasetPerspective" :cards-store="analysisDatasetCardsStore" :perspective="datasetPerspective" mode="row">
        </perspective-card>
        <topic-card v-if="datasetTopic" :cards-store="analysisDatasetCardsStore" :topic="datasetTopic" mode="row">
        </topic-card>
    </div>
</template>

<script>
import ListCard from '@/components/channels/stream-cards/list'
import PerspectiveCard from '@/components/content/stream-cards/perspective'
import TopicCard from '@/components/content/stream-cards/topic'

import useAnalysisDatasetCardsStore from '@/stores/analysis/dataset-cards'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useMyTopicsStore from '@/stores/me/topics'

import { mapStores } from 'pinia'

export default {
    components: {
        ListCard, PerspectiveCard, TopicCard
    },

    computed: {
        ...mapStores(useAnalysisDatasetCardsStore),

        datasetList() {
            return this.store.series[0].datasetType == 'list'
                ? useMyChannelListsStore().find(this.store.series[0].datasetId) : null
        },

        datasetPerspective() {
            return this.store.series[0].datasetType == 'perspective'
                ? useMyPerspectivesStore().find(this.store.series[0].datasetId) : null
        },

        datasetTopic() {
            return this.store.series[0].datasetType == 'topic'
                ? useMyTopicsStore().find(this.store.series[0].datasetId) : null
        },
    }
}
</script>
