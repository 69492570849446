<template>
    <!-- eslint-disable vue/no-mutating-props -->
    <div class="text-center">
        This feature uses AI to apply tags to content based on an answer to a "yes" or "no" question.
    </div>

    <div class="text-center text-xs text-red-600 mt-2" v-if="Object.keys(errors).length">
        Please enter a question and select at least one tag.
    </div>

    <div class="mt-4">
        <label for="question" class="block text-sm text-gray-700 font-medium mb-1">
            Question
        </label>

        <div>
            <ui-input type="text" name="question" id="name" placeholder="Does this content mention a military conflict?" v-model="inputs.yesOrNo.question"></ui-input>
        </div>
    </div>

    <div class="flex space-x-6 mt-4">
        <div class="flex-1">
            <label for="question" class="block text-sm text-gray-700 font-medium mb-1">
                Yes answer
            </label>

            <div>
                <tags-dropdown v-model="inputs.yesOrNo.yesTag"></tags-dropdown>
            </div>
        </div>

        <div class="flex-1">
            <label for="question" class="block text-sm text-gray-700 font-medium mb-1">
                No answer
            </label>

            <div>
                <tags-dropdown v-model="inputs.yesOrNo.noTag"></tags-dropdown>
            </div>
        </div>
    </div>

    <div class="mt-4">
        <div class="mt-2">
            <label for="context" class="block text-sm text-gray-700 font-medium mb-1">
                Context
            </label>

            <div>
                <ui-input type="textarea" rows="2" name="context" id="context" placeholder="Military conflict refers to any use of military forces." v-model="inputs.yesOrNo.context"></ui-input>
            </div>

            <div class="text-sm text-gray-600 mt-1">
                Optionally specify additional context to your question. This can help to make the answers more accurate.
            </div>
        </div>
    </div>
</template>

<script>
import TagsDropdown from './tags-dropdown'

export default {
    props: [ 'errors', 'inputs' ],

    components: {
        TagsDropdown
    }
}
</script>
