import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useContentEditsStore = defineStore({
    id: 'contentEdits',

    state: () => ({
        items: [],

        isLoading: false
    }),

    actions: {
        show(id) {
            this.items = []
            this.isLoading = true

            useModal().show('content-edits')

            return this.loadingPromise = api.route('monitor content edits index', { id }).get().json(res => {
                this.items = res.data

                this.isLoading = false
            })
        }
    }
})

export default useContentEditsStore
