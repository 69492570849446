<template>
    <ui-dropdown-link v-for="alert in subject.alerts" @click="editAlert(alert, subject)" :avatar="alert" :key="alert.id">
        {{alert.name}}
    </ui-dropdown-link>

    <ui-dropdown-separator v-if="subject.alerts.length"></ui-dropdown-separator>

    <ui-dropdown-link @click="createAlert(subject)" icon="plus">
        New alert
    </ui-dropdown-link>
</template>

<script>
import useAlertCreateStore from '@/stores/alerts/modals/alert-create'
import useAlertEditStore from '@/stores/alerts/modals/alert-edit'

import { mapActions } from 'pinia'

export default {
    props: [ 'subject' ],

    methods: {
        ...mapActions(useAlertCreateStore, { createAlert: 'open' }),
        ...mapActions(useAlertEditStore, { editAlert: 'open' })
    }
}
</script>
