import api from '@/api'
import { defineForm } from '@/stores/reusable/form'
import { useModal } from '@/helpers'

import useMyAnalysesStore from '@/stores/me/analyses'

export const useAnalysisModalsAnalysisDuplicateStore = defineForm({
    id: 'analysisModalsAnalysisDuplicate',

    inputs: () => ({
        analysis: {},
        name: null
    }),

    submitRequest() {
        return api.route('me analyses duplicate', { id: this.inputs.analysis.id })
            .json({ name: this.inputs.name })
            .post()
    },

    async onResponse(res) {
        useMyAnalysesStore().reload()

        useModal().hide('analysis-analysis-duplicate')
    },

    actions: {
        open(analysis = {}) {
            this.reset()

            this.inputs.analysis = analysis
            this.inputs.name = `${analysis.name} (copy)`

            useModal().show('analysis-analysis-duplicate')
        },

        cancel() {
            useModal().hide('analysis-analysis-duplicate')
        }
    }
})

export default useAnalysisModalsAnalysisDuplicateStore
