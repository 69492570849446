<template>
    <ui-modal name="board-edit" title="Dashboard" :max-width="520" :max-height="680" @opened="opened">
        <ui-form :store="modalsBoardEditStore" v-slot="{ inputs, submitting, errors }">
            <div class="flex gap-x-8">
                <div class="flex-1">
                    <div>
                        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                            Name
                        </label>

                        <div>
                            <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                            Description
                        </label>

                        <div>
                            <ui-input type="textarea" rows="2" name="description" id="description" placeholder="e.g. Paramilitary Groups" v-model="inputs.description" :errors="errors.description"></ui-input>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="pt-1">
                        <ui-avatar-input :inputs="inputs"></ui-avatar-input>
                    </div>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="deleteBoard" v-if="inputs.board.id">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="inputs.board.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ inputs.board.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useModalsBoardEditStore from '@/stores/dashboards/modals/board-edit'

import { mapActions, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useModalsBoardEditStore),

        board() { return this.modalsBoardEditStore.inputs?.board }
    },

    methods: {
        ...mapActions(useModalsBoardEditStore, {
            deleteBoard: 'delete'
        }),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
