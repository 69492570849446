<template>
    <div class="bg-white border border-gray-200 w-full overflow-hidden shadow-sm rounded-lg mb-4 divide-y divide-gray-200 px-4">
        <div class="pt-4">
            <div class="flex items-center justify-between pb-2">
                <h3 class="text-lg font-medium text-gray-700">
                    <ui-icon name="navigation.channels"></ui-icon>
                    Connected Channels
                </h3>

                <ui-help-button size="small" v-tooltip="'Channels connected to this feature directly or via published content.'"></ui-help-button>
            </div>

            <ui-async :guards="connectedChannels.data || connectedChannels.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3 mt-1" v-if="shownConnectedChannels.length">
                    <tr :key="index" v-for="connection, index in shownConnectedChannels">
                        <td class="py-1 whitespace-nowrap flex items-center break-all">
                            <div class="shrink-0 relative">
                                <ui-avatar :item="connection.channel" class="w-6 h-6"></ui-avatar>
                                <ui-icon :name="connection.channel.monitored ? `badges.channel-${connection.channel.type}` : `badges.channel-${connection.channel.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-xs"></ui-icon>
                            </div>

                            <div class="truncate px-2">
                                <router-link :to="connectionUrl(connection)" v-tooltip="connection.channel.name">{{ connection.channel.name }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 font-semibold text-right">
                            {{ connection.strength }}
                        </td>
                    </tr>
                </table>

                <div class="pt-4 pb-6 text-center space-y-1 text-sm text-gray-700" v-else-if="connectedChannels.error">
                    <p>We were unable to load connections for this Feature.</p>
                    <p>
                        <a href="#" @click.prevent="reload()" class="text-blue-500 hover:text-blue-800">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No sources found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showConnectedChannelsShowMoreLink">
                    <a @click.prevent="showConnections('connected-channels')" href="#" class="text-blue-500 hover:text-blue-800">
                        Show More
                    </a>
                </div>

                <template v-slot:loading>
                    <div class="flex items-center justify-center py-10 pt-8 w-full h-full">
                        <ui-spinner v-bind="spinner"></ui-spinner>
                    </div>
                </template>
            </ui-async>
        </div>

        <div class="pt-4">
            <div class="flex items-center justify-between pb-2">
                <h3 class="text-lg font-medium text-gray-700">
                    <ui-icon name="feature"></ui-icon>
                    Coexisting Features
                </h3>

                <ui-help-button size="small" v-tooltip="'Features co-existing on the same content or channels.'"></ui-help-button>
            </div>

            <ui-async :guards="coexistingFeatures.data || coexistingFeatures.error">
                <table class="min-w-full w-full table-fixed text-sm mb-3 mt-1" v-if="shownCoexistingFeatures.length">
                    <tr :key="index" v-for="connection, index in shownCoexistingFeatures">
                        <td class="py-1 whitespace-nowrap flex items-center break-all">
                            <ui-avatar :icon="$nodeType(connection.feature).icon" :color="{ background: 'bg-gray-300', icon: 'text-gray-900 text-lg' }" class="w-6 h-6 shrink-0" v-tooltip="$nodeType(connection.feature).name"></ui-avatar>

                            <div class="truncate px-2">
                                <router-link :to="connectionUrl(connection)" v-tooltip="connection.feature.name">{{ connection.feature.name }}</router-link>
                            </div>
                        </td>
                        <td class="w-12 font-semibold text-right">
                            {{ connection.strength }}
                        </td>
                    </tr>
                </table>

                <div class="pt-4 pb-6 text-center space-y-1 text-sm text-gray-700" v-else-if="coexistingFeatures.error">
                    <p>We were unable to load connections for this Feature.</p>
                    <p>
                        <a href="#" @click.prevent="reload()" class="text-blue-500 hover:text-blue-700">Retry</a>
                    </p>
                </div>

                <div class="text-sm text-gray-600 text-center mb-4" v-else>
                    No features found yet.
                </div>

                <div class="text-center text-sm mb-4" v-if="showCoexistingFeaturesShowMoreLink">
                    <a @click.prevent="showConnections('coexisting-features')" href="#" class="text-blue-500 hover:text-blue-800">
                        Show More
                    </a>
                </div>

                <template v-slot:loading>
                    <div class="flex items-center justify-center pb-10 pt-8 w-full h-full">
                        <ui-spinner v-bind="spinner"></ui-spinner>
                    </div>
                </template>
            </ui-async>
        </div>
    </div>
</template>

<script>
import useConnectionsStore from '@/stores/features/feature/connections'

import { mapActions, mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useConnectionsStore, [
            'connectedChannels', 'coexistingFeatures'
        ]),

        shownConnectedChannels() {
            return this.connectedChannels.data ? [ ...this.connectedChannels.data ].slice(0, 5) : []
        },

        showConnectedChannelsShowMoreLink() {
            return this.connectedChannels.data?.length > 3
        },

        shownCoexistingFeatures() {
            return this.coexistingFeatures.data ? [ ...this.coexistingFeatures.data ].slice(0, 5) : []
        },

        showCoexistingFeaturesShowMoreLink() {
            return this.coexistingFeatures.data?.length > 3
        }
    },

    methods: {
        ...mapActions(useConnectionsStore, [ 'reload' ]),

        connectionUrl(connection) {
            if (connection.channel) {
                return { name: 'channels.channel.information', params: { id: connection.channel.id } }
            } else if (connection.feature) {
                return { name: 'features.details', params: { id: connection.feature.id } }
            }
        },

        showConnections(type) {
            this.$page.selectedTab = 'connections'
            useConnectionsStore().selectedType = type
        }
    }
}
</script>
