import { defineStore } from 'pinia'
import defineConnectionsGraph from "@/stores/connections/connections-graph.js"
import useConnectionsDataStore from "@/stores/connections/connections-data.js"
import betweennessCentrality from 'graphology-metrics/centrality/betweenness'
import pagerank from 'graphology-metrics/centrality/pagerank'
import eigenvectorCentrality from 'graphology-metrics/centrality/eigenvector'


const useGraphMetricsStore = defineStore({
    id: 'graph-metrics',

    state: () => ({
        graphStore: null,

        graph: null,
        renderer: null,

        algorithm: 'centrality',
        processing: false,
        computed: {}
    }),

    actions: {
        initialize(graph, renderer) {
            this.graph = graph
            this.renderer = renderer

            this.graphStore = defineConnectionsGraph({id: 'connectionsGraph'})()
        },

        resetComputed() {
            this.computed = {}
        },

        setSettings(settings) {
            this.algorithm = settings.algorithm || this.algorithm
            this.computed = settings.computed || this.computed

            if (this.computed['centrality']) {
                useConnectionsDataStore().addNodesMetricsColumn('centrality')
            }

            if (this.computed['pagerank']) {
                useConnectionsDataStore().addNodesMetricsColumn('pagerank')
            }

            if (this.computed['eigenvector']) {
                useConnectionsDataStore().addNodesMetricsColumn('eigenvector')
            }
        },

        getSettings() {
            return {
                algorithm: this.algorithm,
                computed: this.computed
            }
        },

        selectMetrics(algorithm) {
            this.algorithm = algorithm
        },

        async centrality() {
            this.processing = true
            const centralities = betweennessCentrality(this.graph);

            const nodes = []

            this.graphStore.nodes.forEach(n => {
                nodes.push({
                    ...n,
                    attributes: {
                        ...n.attributes,
                        centrality: centralities[n.key]
                    }
                })

                this.graph.setNodeAttribute(n.key, 'centrality', centralities[n.key])
            })

            this.graphStore.nodes = nodes
            if (!this.computed['centrality']) {
                useConnectionsDataStore().addNodesMetricsColumn('centrality')
                this.computed['centrality'] = true
            }

            setTimeout(() => { this.processing = false }, 1000)
        },

        async pagerankAlg() {
            this.processing = true
            const pagerankValues = pagerank(this.graph)

            const nodes = []

            this.graphStore.nodes.forEach(n => {
                nodes.push({
                    ...n,
                    attributes: {
                        ...n.attributes,
                        pagerank: pagerankValues[n.key]
                    }
                })

                this.graph.setNodeAttribute(n.key, 'pagerank', pagerankValues[n.key])
            })

            this.graphStore.nodes = nodes
            if (!this.computed['pagerank']) {
                useConnectionsDataStore().addNodesMetricsColumn('pagerank')
                this.computed['pagerank'] = true
            }

            setTimeout(() => { this.processing = false }, 1000)
        },

        async eigenvector() {
            try {
                this.processing = true
                const scores = eigenvectorCentrality(this.graph, {
                    maxIterations: 1000, tolerance: 1e-9
                })

                const nodes = []

                this.graphStore.nodes.forEach(n => {
                    nodes.push({
                        ...n,
                        attributes: {
                            ...n.attributes,
                            eigenvector: scores[n.key]
                        }
                    })

                    this.graph.setNodeAttribute(n.key, "eigenvector", scores[n.key])
                })

                this.graphStore.nodes = nodes
                if (!this.computed['eigenvector']) {
                    useConnectionsDataStore().addNodesMetricsColumn('eigenvector')
                    this.computed['eigenvector'] = true
                }

                setTimeout(() => { this.processing = false }, 1000)
            } catch (e) {
                this.processing = false
                console.log(e)
            }
        },

        async runMetrics() {
            switch (this.algorithm) {
                case 'centrality':
                    this.centrality()
                    break

                case 'pagerank':
                    this.pagerankAlg()
                    break

                case 'eigenvector':
                    this.eigenvector()
                    break
            }
        },

        async runAllMetrics() {
            await this.centrality()
            await this.pagerankAlg()

            try {
                await this.eigenvector()
            } catch (e) {
                console.log(e)
            }
        }
    }
})

export default useGraphMetricsStore
