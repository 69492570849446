import { markRaw } from 'vue'
import { defineStore } from 'pinia'
import Graphology from 'graphology'
import Sigma from 'sigma'

import { downloadAsImage } from "@sigma/export-image"
import { NodeImageProgram } from "@sigma/node-image"
import { EdgeCurvedArrowProgram } from "@sigma/edge-curve"
import gexf from 'graphology-gexf/browser'

import api from '@/api'
import { useModal } from "@/helpers.js"

import useGraphsStore from "@/stores/connections/graphs"
import useGraphLayoutStore from "@/stores/connections/graph-layout"
import useGraphStyleStore from "@/stores/connections/graph-style"
import useGraphEventsStore from "@/stores/connections/graph-events"
import useGraphFiltersStore from "@/stores/connections/graph-filters"
import useGraphMetricsStore from "@/stores/connections/graph-metrics"
import useConnectionsDataStore from "@/stores/connections/connections-data"
import useDeleteConfirmationModal from "@/stores/modals/delete-confirmation.js"


const defineConnectionsGraph = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            type: 'actor-feature',

            graph: null,
            renderer: null,

            isLoading: false,
            loadingMessage: 'Loading graph...',

            isProcessing: false,
            processingMessage: '',

            selectedNodes: [],
            inspectedNodeIds: [],
            nodes: [],
            edges: [],

            hiddenNodes: [],
            hiddenEdges: [],
            frozenNodes: [],

            hoveredNode: null,
            hoveredNeighbors: null,
            highlightedNode: null,

            stats: { classes: {}, subclasses: {}, object_types: {}, edges: {} },

            graphId: null,
            graphName: null,

            isSaving: false,
            notFound: false,

            shownComponent: 'graph',
            maxEdgeWeight: 0,

            maxNodesLimit: 10000,
            maxInspectedLimit: 15
        }),

        actions: {
            switchView(view) {
                this.shownComponent = view
                this.renderer.scheduleRefresh()
            },

            initialize(graphId = 'new') {
                this.graph = markRaw(new Graphology({ multi: true }))
                this.hiddenNodes = []
                this.notFound = false

                this.graphId = graphId

                let container = document.getElementById('connections-graph-container')

                this.renderer =  markRaw(new Sigma(this.graph, container, {
                    renderEdgeLabels: true,
                    enableEdgeEvents: true,
                    edgeProgramClasses: { curved: EdgeCurvedArrowProgram },
                    nodeProgramClasses: { image: NodeImageProgram },
                    labelFont: 'Roboto, sans-serif',
                    labelSize: 11,
                    labelWeight: '500',
                    labelColor: { color: '#677f8c' },
                    edgeLabelFont: 'Roboto, sans-serif',
                    edgeLabelSize: 10,
                    edgeLabelWeight: '400',
                    edgeLabelColor: { color: '#677f8c' },
                    zIndex: true,
                    allowInvalidContainer: true,
                    minEdgeThickness: 1,
                }))

                this.renderer.on('enterNode', event => this.setHoveredNode(event.node))
                this.renderer.on('leaveNode', () => this.setHoveredNode())

                this.renderer.setSetting('nodeReducer', (node, data) => this.nodeReduces(node, data))
                this.renderer.setSetting('edgeReducer', (edge, data) => this.edgeReducer(edge, data))

                useGraphEventsStore().initialize(this.graph, this.renderer, container)
                useGraphFiltersStore().initialize(this.graph, this.renderer)
                useGraphStyleStore().initialize(this.graph, this.renderer)
                useGraphLayoutStore().initialize(this.graph, this.renderer)
                useGraphMetricsStore().initialize(this.graph, this.renderer)
                useConnectionsDataStore().initialize()
            },

            reset() {
                if (this.renderer) {
                    this.renderer.removeAllListeners()
                    this.renderer.clear()
                }
                if (this.graph) this.graph.clear()
                this.$reset()
            },

            async processDataset(dataset) {
                const nodes = [ ...this.nodes ]
                const edges = [ ...this.edges ]
                let maxEdgeWeight = 1

                dataset.nodes.forEach(n => {
                    if (!this.graph.hasNode(n.key)) {
                        this.processNode(n)
                        nodes.push(n)

                        this.graph.mergeNode(n.key, n.attributes)
                    }
                })

                dataset.edges.forEach(e => {
                    if (!this.graph.hasEdge(e.key)) {
                        this.processEdge(e)

                        this.graph.mergeEdgeWithKey(e.key, e.source, e.target, e.attributes)
                        edges.push({
                            id: e.key,
                            label: e.attributes.label,
                            source_id: e.source,
                            target_id: e.target,
                            weight: e.attributes.weight,
                            // attributes: e.attributes
                        })

                        if (e.attributes.weight > maxEdgeWeight) {
                            maxEdgeWeight = e.attributes.weight
                        }
                    }
                })

                this.nodes = nodes
                this.maxEdgeWeight = maxEdgeWeight

                this.edges = edges.map(e => ({
                    ...e,
                    source: this.graph.getNodeAttributes(e.source_id).label || 'N/A',
                    target: this.graph.getNodeAttributes(e.target_id).label || 'N/A',
                }))
            },

            async computeStatsFiltersDegrees(resetFilters = true) {
                const channelsInGraph = []
                const featuresInGraph = []
                const edgesInGraph = []
                const degrees = {}

                const stats = { classes: {}, subclasses: {}, object_types: {}, edges: {} }

                this.graph.forEachNode((n, attrs) => {
                    attrs.nodeDegree = this.graph.degree(n)
                    degrees[n] = attrs.nodeDegree

                    if (attrs.class === 'Channel' && !channelsInGraph.includes(attrs.object_type)) {
                        channelsInGraph.push(attrs.object_type)
                    }

                    if (attrs.class === 'Feature' && !channelsInGraph.includes(attrs.object_type)) {
                        featuresInGraph.push(attrs.object_type)
                    }

                    stats['classes'][attrs.class] = stats['classes'][attrs.class] ? stats['classes'][attrs.class] + 1 : 1
                    stats['object_types'][attrs.object_type] = stats['object_types'][attrs.object_type] ? stats['object_types'][attrs.object_type] + 1 : 1
                })

                this.graph.forEachEdge((e, attrs) => {
                    if (!edgesInGraph.includes(attrs.label)) {
                        edgesInGraph.push(attrs.label)
                    }

                    stats['edges'][attrs.label] = stats['edges'][attrs.label] ? stats['edges'][attrs.label] + 1 : 1
                })

                const nodes = [ ...this.nodes ]

                nodes.forEach(n => {
                    n.attributes.nodeDegree = degrees[n.key]
                })

                this.nodes = nodes

                useGraphFiltersStore().setEntitiesInGraph({ channelsInGraph, featuresInGraph, edgesInGraph }, resetFilters)

                stats['edges'] = Object.entries(stats['edges']).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                stats['classes'] = Object.entries(stats['classes']).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                stats['object_types'] = Object.entries(stats['object_types']).sort(([,a],[,b]) => b-a).reduce((r, [k, v]) => ({ ...r, [k]: v }), {})
                
                this.stats = stats
            },

            async inspectNode(nodeIds) {
                this.setHoveredNode()
                this.isLoading = true
                this.loadingMessage = 'Updating graph data...'
                useGraphEventsStore().selectionModeEnabled && useGraphEventsStore().toggleSelectionMode()

                await api.route('connections index', true)
                    .json({ node_ids: nodeIds, type: this.type })
                    .post()
                    .json(async res => {
                        this.notFound = false
                        const dataset = res.subgraph

                        if (!this.graphId) {
                            this.graphId = 'new'
                        }

                        if (this.graphId === 'new') {
                            useGraphStyleStore().reset()
                        }

                        this.inspectedNodeIds = [ ...new Set([ ...this.inspectedNodeIds, ...nodeIds ]) ]
                        this.processDataset(dataset)
                        setTimeout(() => this.computeStatsFiltersDegrees(), 500)

                        useGraphLayoutStore().applyForceAtlas(this.graph)
                        setTimeout(() => { useGraphStyleStore().applyStyles() }, 500)
                        // useGraphMetricsStore().resetComputed()

                        // Enable mouse captor after loading - prevent mouse events on empty graph
                        this.renderer.getMouseCaptor().enabled = true

                        if (this.graphId === 'new') {
                            window.history.pushState({}, "", "/connections/graphs/new/" + this.inspectedNodeIds.join(','))
                        }

                        this.isLoading = false

                        // const ids = this.graph.nodes()
                        const ids = dataset.nodes.map(n => n.key)
                        if (ids.length) {
                            this.getNeighbours(ids).then(async () => {
                                // this.processingMessage = 'Computing graph metrics...'
                                // await useGraphMetricsStore().runAllMetrics()
                                this.isProcessing = false
                            })
                        }

                    })
            },

            async getNeighbours(nodeIds) {
                this.processingMessage = 'Loading connections between neighbors...'
                this.isProcessing = true

                const minWeight = Math.ceil(nodeIds.length / 2)

                await api.route('connections neighbours', true)
                    .json({ node_ids: nodeIds, min_weight: minWeight })
                    .post()
                    .json(async res => {
                        await this.processDataset(res.subgraph)
                        useGraphStyleStore().applyEdgeStyles()
                        this.isProcessing = false
                    })
            },

            async loadGraph(graphId) {
                this.graphId = graphId
                if (graphId === 'new') return

                this.setHoveredNode()
                this.isLoading = true
                this.loadingMessage = 'Loading graph...'

                await api.route('connections graph detail', { id: graphId })
                    .get()
                    .error(404, (res) => {
                        this.isLoading = false
                        this.notFound = true

                        return res
                    })
                    .json( res => {
                        this.notFound = false

                        this.graphId = graphId
                        this.graphName = res.name

                        const dataset = JSON.parse(res.data)
                        const settings = JSON.parse(res.settings)

                        if (!dataset?.nodes?.length) {
                            this.isLoading = false
                            return
                        }

                        this.inspectedNodeIds = settings?.inspectedNodeIds || this.inspectedNodeIds
                        this.processDataset(dataset)
                        setTimeout(() => this.computeStatsFiltersDegrees(false), 1000)

                        useGraphStyleStore().setSettings(settings)
                        useGraphFiltersStore().setSettings(settings)
                        useGraphMetricsStore().setSettings(settings)

                        // Enable mouse captor after loading - prevent mouse events on empty graph
                        this.renderer.getMouseCaptor().enabled = true
                        this.isLoading = false
                    })
            },

            saveGraph() {
                if (this.isSaving) return

                this.isSaving = true
                const nodes = []
                const edges = []

                this.graph.forEachNode((id, attrs) => {
                    nodes.push({ key: id, attributes: attrs })
                })

                this.graph.forEachEdge((e, a) => {
                    edges.push({ key: e, source: this.graph.source(e), target: this.graph.target(e), attributes: a })
                })

                const styleSettings = useGraphStyleStore().getSettings()
                const filterSettings = useGraphFiltersStore().getSettings()
                const metricsSettings = useGraphMetricsStore().getSettings()

                const settings = {
                    stats: this.stats,

                    inspectedNodeIds: this.inspectedNodeIds,
                    graphType: this.type,

                    ...styleSettings,
                    ...filterSettings,
                    ...metricsSettings
                }

                if (this.graphId === 'new') {
                    this.isSaving = false
                    useGraphsStore().setNewGraphFields({ nodes, edges }, settings)
                    useModal().show('graph-name')
                    return
                }

                api.route('connections graph update', { id: this.graphId })
                    .json({ data: { nodes, edges }, settings })
                    .put()
                    .json(res => {
                        this.isSaving = false
                    })
            },

            nodeReduces(node, data) {
                if (this.selectedNodes.includes(node)) {
                    return { ...data, highlighted: true, zIndex: 99999 }
                }

                return data
            },

            edgeReducer(edge, data) {
                if (this.hoveredNode) {
                    let isHovered = this.graph.source(edge) === this.hoveredNode || this.graph.target(edge) === this.hoveredNode

                    return {
                        ...data,
                        // color: isHovered ? data.color : '#e0e5e8',
                        color: isHovered ? '#000' : '#e0e5e8',
                        zIndex: isHovered ? 999 : 0,
                        label: isHovered ? data.label : ''
                    }
                }

                return data
            },

            setType(type) {
                this.type = type
            },

            focusNode(key) {
                this.renderer.getCamera().animate(
                    { ...this.renderer.getNodeDisplayData(key), ratio: 0.2 },
                    { duration: 500 }
                )
            },

            zoomIn() {
                this.renderer.getCamera().animatedZoom()
            },

            zoomOut() {
                this.renderer.getCamera().animatedUnzoom()
            },

            export() {
                if (this.isLoading) return

                let gexfExport = gexf.write(this.graph)

                let anchor = document.createElement('a')
                anchor.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(gexfExport))
                anchor.setAttribute('download', 'export.gexf')
                anchor.style.display = 'none'

                document.body.appendChild(anchor)
                anchor.click()
                document.body.removeChild(anchor)
            },

            processNode(node) {
                const style = useGraphStyleStore().getNodeStyles(node)

                style['icon'] = style['image']

                node.attributes = {
                    x: 0,
                    y: 0,
                    type: 'image',
                    hidden: false,
                    ...style,
                    ...node.attributes
                }
            },

            processEdge(edge) {
                edge.attributes.type = 'arrow'

                edge.attributes.edge_type = edge.attributes.label

                if (!edge.attributes.size) {
                    edge.attributes.size = 1
                }
            },

            setHoveredNode(node) {
                if (node) {
                    document.getElementById('connections-graph-container').style.cursor = this.dragModeEnabled ? 'move' : 'pointer'
                    this.hoveredNode = node
                } else {
                    document.getElementById('connections-graph-container').style.cursor = this.dragModeEnabled ? 'move': 'grab'
                    this.hoveredNode = null
                }

                this.renderer && this.renderer.scheduleRefresh()
            },

            exportAsImage() {
                return downloadAsImage(this.renderer, { backgroundColor: '#fff' })
            },

            deleteNode(nodeIds) {
                return useDeleteConfirmationModal().open(nodeIds.length > 1 ? "nodes" : "node")
                    .then(() => {
                        this.isLoading = true
                        this.loadingMessage = 'Deleting nodes...'

                        let newNodes = [ ...this.nodes ]
                        let newEdges = [ ...this.edges ]
                        let selected = []
                        let frozen = []

                        setTimeout(() => {
                            nodeIds.forEach(nodeId => {
                                this.graph.dropNode(nodeId)
                                newNodes = newNodes.filter(n => n.key != nodeId)
                                newEdges = newEdges.filter(e => e.source_id != nodeId && e.target_id != nodeId)

                                if (this.selectedNodes.includes(nodeId)) {
                                    selected = [ ...this.selectedNodes.filter(n => n != nodeId) ]
                                }

                                if (this.frozenNodes.includes(nodeId)) {
                                    frozen = [ ...this.frozenNodes.filter(n => n != nodeId) ]
                                }
                            })

                            this.nodes = newNodes
                            this.edges = newEdges
                            this.selectedNodes = selected
                            this.frozenNodes = frozen

                            this.renderer.refresh()
                            this.isLoading = false

                            setTimeout(() => this.computeStatsFiltersDegrees(), 500)
                            useGraphMetricsStore().resetComputed()
                        }, 500)
                    })
            },

            selectNodes(nodeIds, clearSelected, updateTableToo = true) {
                let selected = clearSelected ? [] : [ ...this.selectedNodes ]

                nodeIds.forEach(nodeId => {
                    if (selected.includes(nodeId)) {
                        selected = selected.filter(n => n != nodeId)
                    } else {
                        selected = [ ...selected, nodeId ]
                    }
                })

                this.selectedNodes = selected

                if (updateTableToo) {
                    useConnectionsDataStore().checkSelectedNodes(this.selectedNodes)
                }

                this.renderer.refresh()
            },

            hideIsolatedNeighbors(nodeId) {
                this.graph.neighbors(nodeId).forEach((id ) => {
                    if (this.graph.neighbors(id).length < 2) {
                        const node = this.graph.getNodeAttributes(id)
                        node.hidden = true
                        this.hiddenNodes.push(id)
                    }
                })
            },

            freezeNode(nodeIds, clear) {
                let frozen = clear ? [] : [ ...this.frozenNodes ]

                nodeIds.forEach(nodeId => {
                    if (frozen.includes(nodeId)) {
                        frozen = frozen.filter(n => n != nodeId)
                        this.graph.setNodeAttribute(nodeId, "fixed", false)
                    } else {
                        frozen = [ ...frozen, nodeId ]
                        this.graph.setNodeAttribute(nodeId, "fixed", true)
                    }
                })

                this.frozenNodes = frozen
            },

            // Refactor rename to plural
            showNode(nodeIds) {
                nodeIds.forEach(nodeId => {
                    if (this.graph.hasNode(nodeId)) {
                        let node = this.graph.getNodeAttributes(nodeId)
                        node.hidden = false
                        node.fixed = false
                    }
                })

                this.hiddenNodes =  [ ...this.hiddenNodes.filter( x => !nodeIds.includes(x) )]
                this.setHoveredNode()
            },

            hideNode(nodeIds) {
                nodeIds.forEach(nodeId => {
                    if (this.inspectedNodeIds[0] == nodeId) return

                    if (this.graph.hasNode(nodeId)) {
                        const node = this.graph.getNodeAttributes(nodeId)
                        node.hidden = true
                        node.fixed = true

                        // Hide also isolated neighbors - maybe this is not necessary
                        this.hideIsolatedNeighbors(nodeId)
                    }
                })

                this.hiddenNodes =  [ ...this.hiddenNodes.concat(nodeIds) ]
                this.setHoveredNode()
            },

            highlightNode(nodeId) {
                this.highlightedNode = nodeId

                setTimeout(() => {
                    if (this.highlightedNode == nodeId) {
                        const node = this.graph.getNodeAttributes(nodeId)
                        node.highlighted = true
                        this.renderer.refresh()
                    }
                }, 200)
            },

            cancelHighlightedNodes() {
                if (this.highlightedNode) {
                    this.graph.forEachNode((id, attributes) => {
                        attributes.highlighted = false
                    })

                    this.renderer.refresh()
                    this.highlightedNode = null
                }
            },

            showInGraph(node_id) {
                this.switchView('graph')
                setTimeout(() => {
                    this.focusNode(node_id)
                    this.highlightNode(node_id)
                    setTimeout(() => this.cancelHighlightedNodes(), 5000)
                }, 100)
            },

            searchNodes(query) {
                this.graph.forEachNode((id, attributes) => {
                    if (query === '') {
                        attributes.highlighted = false
                        return
                    }

                    if (attributes.label?.toLowerCase().includes(query.toLowerCase())) {
                        attributes.highlighted = true
                    } else {
                        attributes.highlighted = false
                    }
                })

                this.renderer.refresh()
            }
        }
    })
}

export default defineConnectionsGraph
