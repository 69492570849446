export default function textSearch(needle, haystack, retrieveValue) {
    if (! needle) return haystack

    needle = needle.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase()
    retrieveValue = retrieveValue || (v => v)

    return haystack.filter(h => {
        let val = retrieveValue(h)
        return (typeof val == 'string') && val.normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().includes(needle)
    })
}
