<template>
    <l-map ref="map" :zoom="zoom" :bounds="mapBounds" :center="mapCenter" :options="options" class="z-10" @ready="ready">
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

        <slot></slot>

        <l-marker :lat-lng="marker" :key="index" v-for="marker, index in shownMarkers"></l-marker>
    </l-map>
</template>

<script>
import { LMap, LTileLayer, LMarker } from '@vue-leaflet/vue-leaflet'
import { latLng, latLngBounds } from "leaflet"

import 'leaflet/dist/leaflet.css'

import { Icon } from 'leaflet'
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png'
import iconUrl from 'leaflet/dist/images/marker-icon.png'
import shadowUrl from 'leaflet/dist/images/marker-shadow.png'

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
    iconRetinaUrl: iconRetinaUrl.default,
    iconUrl: iconUrl.default,
    shadowUrl: shadowUrl.default
})

export default {
    components: { LMap, LTileLayer, LMarker },

    props: [ 'bounds', 'center', 'controls', 'marker', 'markers', 'zoom' ],

    data: () => ({
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
    }),

    computed: {
        shownMarkers() {
            let markers = this.markers || []

            if (this.marker instanceof Object) markers.push(this.marker)
            if (this.marker) markers.push(this.center)

            return markers.map(marker => latLng(marker.lat, marker.lon))
        },

        options() {
            return {
                dragging: this.controls === true,
                zoomControl: this.controls === true
            }
        },

        mapCenter() {
            return this.center ? latLng(this.center.lat, this.center.lon) : null
        },

        mapBounds() {
            return this.bounds ? latLngBounds(
                latLng(this.bounds.southwest.lat, this.bounds.southwest.lon),
                latLng(this.bounds.northeast.lat, this.bounds.northeast.lon)
            ) : null
        }
    },

    methods: {
        ready() {
            if (this.mapBounds) this.$refs.map.leafletObject.fitBounds(this.mapBounds)
        }
    }
}
</script>