<template>
    <div class="mt-2 text-sm">
        <div class="flex items-center space-x-2" v-if="store.search.query && goodSuggestions.length">
            <h3 class="text-gray-700 text-sm font-medium">
                Suggestions
            </h3>
            <div class="flex flex-wrap">
                <a href="#" class="px-2 py-0.5 text-sm leading-4 bg-gray-100 text-gray-900 hover:bg-gray-700 hover:text-gray-50 rounded-full max-w-32 truncate m-1" @click.prevent.stop="applySuggestion(suggestion)" :key="index" v-for="suggestion, index in goodSuggestions">
                    {{suggestion.word}}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import useSuggestionsStore from '@/stores/reusable/suggestions'

import { mapState } from 'pinia'

export default {
    computed: {
        ...mapState(useSuggestionsStore, [ 'goodSuggestions' ])
    },

    methods: {
        applySuggestion(suggestion) {
            this.store.appendSuggestion(suggestion.word)
        }
    }
}
</script>
