<template>
    <router-link :to="to" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }">
        <div class="flex items-center gap-x-4">
            <div class="shrink-0 relative">
                <ui-avatar family="channels" :name="result.title" :image="avatarImageUrl" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
                <ui-icon :name="result.flags.includes('monitored') ? `badges.channel-${result.type}` : `badges.channel-${result.type}-inactive`" class="absolute rounded-full ring-1 ring-white -right-px -bottom-px text-base"></ui-icon>
            </div>

            <div class="flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-900">
                    <div class="truncate">{{result.title}}</div>
                    <div class="h-3 w-3 rounded-full bg-blue-500 text-white flex items-center justify-center text-2xs inline-flex ml-1"
                         v-if="result.flags.includes('verified')">
                        <ui-icon name="check"></ui-icon>
                    </div>
                </div>

                <div class="text-xs leading-4 text-gray-700 truncate">
                    {{$nodeType(result).name}} · {{result.username ? `@${result.username} · ` : ''}} {{$number(result.followers)}} audience
                </div>
            </div>

            <div class="shrink-0 leading-none">
                <a :href="$router.resolve(toStream)" @click.prevent.stop="openStream">
                    <ui-icon name="stream" class="text-gray-600 hover:text-gray-900 text-3xl" v-tooltip="'Show all content published by this channel'"></ui-icon>
                </a>
            </div>

            <div class="shrink-0 leading-none">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </router-link>
</template>

<script>
export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: 'channels.channel.information', params: { id: this.result.id } }
        },

        toStream() {
            return { name: 'content.perspectives.perspective', params: { perspectiveId: 'new' }, query: { 'filter[date]': 'past:1|months', 'filter[publishedInto]': `channel|${this.result.id}|${this.result.title}` } }
        },

        avatarImageUrl() {
            return this.result.avatarImageUrl ? `${import.meta.env.VITE_IMAGES_CDN_URL}/${this.result.avatarImageUrl}` : null
        }
    },

    methods: {
        open() {
            this.$router.push(this.to)
        },

        openStream() {
            this.$router.push(this.toStream)
            this.store.hide()
        }
    }
}
</script>
