<template>
    <div class="grid grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-4 gap-6">
        <template v-if="store.isInitialized">
            <template v-for="item in items">
                <slot name="item" :item="item"></slot>
            </template>
        </template>

        <template v-else-if="store.isLoading">
            <template v-for="v, i in Array.from({ length: rows * perRow || 25 })">
                <slot name="placeholder" :index="i"></slot>
            </template>
        </template>
    </div>

    <div v-if="store.isInitialized && ! items.length" class="text-center text-gray-700 py-6">
        <slot name="empty">
            No results.
        </slot>
    </div>
</template>

<script>
import breakpoint from '@/helpers/breakpoint'

export default {
    computed: {
        items() {
            if (this.rows) {
                return this.store.items.slice(0, this.rows * this.perRow)
            }

            return this.store.items
        },

        rows() {
            return this.store.layout?.settings?.rows
        },

        perRow() {
            return { 'xs': 2, 'sm': 2, 'md': 2, 'lg': 3, 'xl': 4, '2xl': 4, '3xl': 4 }[breakpoint.is]
        }
    }
}
</script>
