import { defineStore } from 'pinia'
import useSupportChatStore from '@/stores/support/chat'

export const useSupportReportStore = defineStore({
    id: 'supportReport',

    actions: {
        async report(type, id) {
            await useSupportChatStore().createThread(`I'd like to report a problematic or illegal content`)
            useSupportChatStore().message = `I believe the ${type} with ID #${id} contains problematic or illegal material`
            useSupportChatStore().show()
        }
    }
})

export default useSupportReportStore
