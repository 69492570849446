import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useMyMonitoringRequestsStore = defineStore({
    id: 'myMonitoringRequests',

    state: () => ({
        items: [],
        paginated: true,

        isInitialized: false,
        isLoading: false,

        loadingPromise: null,
        lastReloadTime: +new Date(),

        nextPage: null
    }),

    actions: {
        async initialize() {
            await this.load()
        },

        async load(force = false) {
            if (this.isInitialized && ! force) return Promise.resolve()
            if (this.loadingPromise) return this.loadingPromise

            this.isLoading = true

            return api.route('me channels monitoring-requests index').get().json(res => {
                this.items = res.data
                this.nextPage = res.meta.next_cursor

                this.isLoading = false
                this.isInitialized = true

                this.loadingPromise = null
            })
        },

        async loadMore(infiniteScroll) {
            if (! this.nextPage) return infiniteScroll.complete()

            return api.route('me channels monitoring-requests index', { cursor: this.nextPage }).get().json(res => {
                this.items = [ ...this.items,  ...(res.data || []) ]
                this.nextPage = res.meta.next_cursor

                this.nextPage ? infiniteScroll.loaded() : infiniteScroll.complete()
            })
        },

        async reload() {
            this.lastReloadTime = +new Date()
            return this.load(true)
        },

        open() {
            useModal().show('my-channels-monitoring-requests-modal')
        }
    }
})

export default useMyMonitoringRequestsStore
