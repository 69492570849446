import { UTCToZoned } from '@/helpers/datetime'

import Defaults from 'highcharts/es-modules/Core/Defaults.js';
import Utilities from 'highcharts/es-modules/Core/Utilities.js';

export function labelFormatter() {
    const axis = this.axis,
        chart = this.chart,
        { numberFormatter } = chart,
        value = Utilities.isNumber(this.value) ? this.value : NaN,
        time = axis.chart.time,
        categories = axis.categories,
        dateTimeLabelFormat = this.dateTimeLabelFormat,
        lang = Defaults.defaultOptions.lang,
        numericSymbols = lang.numericSymbols,
        numSymMagnitude = lang.numericSymbolMagnitude || 1000,
        // Make sure the same symbol is added for all labels on a linear
        // axis
        numericSymbolDetector = axis.logarithmic ?
            Math.abs(value) :
            axis.tickInterval;

    let i = numericSymbols && numericSymbols.length,
        multi,
        ret;

    if (categories) {
        ret = `${this.value}`;

    } else if (dateTimeLabelFormat) { // Datetime axis
        ret = time.dateFormat(dateTimeLabelFormat, UTCToZoned(value));

    } else if (
        i &&
        numericSymbols &&
        numericSymbolDetector >= 1000
    ) {
        // Decide whether we should add a numeric symbol like k (thousands)
        // or M (millions). If we are to enable this in tooltip or other
        // places as well, we can move this logic to the numberFormatter and
        // enable it by a parameter.
        while (i-- && typeof ret === 'undefined') {
            multi = Math.pow(numSymMagnitude, i + 1);
            if (
                // Only accept a numeric symbol when the distance is more
                // than a full unit. So for example if the symbol is k, we
                // don't accept numbers like 0.5k.
                numericSymbolDetector >= multi &&
                // Accept one decimal before the symbol. Accepts 0.5k but
                // not 0.25k. How does this work with the previous?
                (value * 10) % multi === 0 &&
                numericSymbols[i] !== null &&
                value !== 0
            ) { // #5480
                ret = numberFormatter(
                    value / multi, -1
                ) + numericSymbols[i];
            }
        }
    }

    if (typeof ret === 'undefined') {
        if (Math.abs(value) >= 10000) { // Add thousands separators
            ret = numberFormatter(value, -1);
        } else { // Small numbers
            ret = numberFormatter(value, -1, void 0, ''); // #2466
        }
    }

    return ret;
}
