import { asyncResource, channels, content } from '@/api'

import { defineStore } from 'pinia'

export const useTriggerNewPublishersStore = defineStore({
    id: 'alertTriggerNewPublishers',

    state: () => ({
        trigger: null,

        channels: asyncResource({
            method: 'post',
            request: (api, store, payload) => channels()
                .ids(store.trigger.meta.channels)
                .query(payload)
                .sorting('followers-desc')
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        content: asyncResource({
            method: 'post',
            request: (api, store, payload) => content()
                .ids(store.trigger.meta.content)
                .query(payload)
                .sorting('date-desc')
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        lastReloadTime: +(new Date)
    }),

    actions: {
        async load(trigger) {
            this.trigger = trigger
            this.lastReloadTime = +(new Date)

            this.channels.reset()
            this.channels.fetchFirst(this)

            this.content.reset()
            this.content.fetchFirst(this)
        },

        async loadMore(infiniteScroll, target) {
            if (this[target].isFetching) return infiniteScroll?.loaded()

            let items = await this[target].fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
        }
    }
})

export default useTriggerNewPublishersStore
