import defineOverviewMetricStore from '@/stores/content/perspective/overview/metrics'
import useMyPerspectivesStore from '@/stores/me/perspectives'
import useQuickSearchStore from '@/stores/me/quick-search'

import { sortAlphabetically } from '@/helpers/sorting'
import searchFilters from '@/helpers/search-filters'
import { defineStore } from 'pinia'

export const defineMetricsWidget = settings => {
    return defineStore({
        id: settings.id,

        state: () => ({
            wid: settings.id,

            type: settings.type,

            x: settings.x,
            y: settings.y,
            w: settings.w,
            h: settings.h,
            i: settings.i,

            perspectiveId: settings.perspectiveId,
            metrics: settings.metrics || { results: true, interactions: true, views: true },
            
            metricStore: null,
            filters: searchFilters(),
            
            metricOptions: [
                {
                    id: 'results', name: 'Results', icon: 'hash',
                    settings: { aggregatesQuery: 'results:count', analysisAggregator: 'count', analysisMetric: 'all' }
                },
                {
                    id: 'interactions', name: 'Interactions', icon: 'interactions',
                    settings: { aggregatesQuery: 'interactions:sum', analysisAggregator: 'summary', analysisMetric: 'interactions' }
                },
                {
                    id: 'views', name: 'Views', icon: 'views',
                    settings: { aggregatesQuery: 'views:sum', analysisAggregator: 'summary', analysisMetric: 'views' }
                }
            ],

            globalFilters: searchFilters(),
            refreshInterval: 5,
            lastRefresh: null,
            lastLayoutUpdate: +new Date()
        }),

        getters: {
            perspective(store) {
                return useMyPerspectivesStore().items.find(l => l.id == store.perspectiveId)
            },
            
            enabledMetrics(store) {
                return Object.entries(store.metrics).filter(([ metric, enabled ]) => enabled).map(([ metric ]) => metric)
            },

            title(store) {
                return store.perspective?.name || 'Metrics'
            }
        },

        actions: {
            initialize(filters) {
                this.globalFilters = filters 
                
                this.metricStore = defineOverviewMetricStore({
                    id: `${this.wid}metricWidget`,
                    metrics: this.enabledMetrics.map(m => this.metricOptions.find(o => o.id == m).settings)
                })
                
                this.load()
                this.lastRefresh = +new Date

                return this
            },

            load() {
                this.filters.clear()
                this.filters.fromPerspective(this.perspective?.filters || {})
                this.filters.remove('date')
                this.filters.fromPerspective(this.globalFilters.toPerspective())
                
                this.metricStore().initialize(this.filters)
            },

            destroy() {
                clearTimeout(this.updateTimeout)

                if (this.metricStore) this.metricStore().$dispose()

                this.$dispose()
            },

            refresh() {
                this.load()
                this.lastRefresh =+ new Date
            },
            
            added() {
                this.selectPerspective()
            },
            
            filtersUpdated() {
                this.load()
            },
            
            layoutUpdated() {
            },
            
            serialize() {
                return {
                    perspectiveId: this.perspective?.id,
                    metrics: this.metrics
                }
            },

            dependencies() {
                return {
                    perspectives: [ this.perspective?.id ]
                }
            },
            
            selectPerspective() {
                useQuickSearchStore().show({
                    families: [ 'perspectives' ],
                    limit: 50,
                    onSelect: result => {
                        this.perspectiveId = result.id
                        
                        this.load()
                        
                        useQuickSearchStore().hide()
                    },
                    initialResults: sortAlphabetically(useMyPerspectivesStore().items).map(item => ({
                        id: item.id, resultType: 'perspective', title: item.name
                    }))
                })
            },
            
            toggleMetric(metric) {
                this.metrics[metric] = ! this.metrics[metric]
                
                this.metricStore().metrics = this.enabledMetrics.map(m => this.metricOptions.find(o => o.id == m).settings)
                
                this.load()
            }
        }
    })
}

export default defineMetricsWidget
