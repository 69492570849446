<template>
    <ui-modal name="workspace-info" title="Workspace" :max-width="520" :max-height="680" @opened="opened" @closed="cancel">
        <ui-form :store="myModalsWorkspaceInfoStore" v-slot="{ inputs, submitting, errors }">
            <div class="flex gap-x-8">
                <div class="flex-1">
                    <div>
                        <label for="name" class="block text-sm text-gray-700 font-medium mb-1">
                            Name
                        </label>

                        <div>
                            <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                        </div>
                    </div>

                    <div class="mt-4">
                        <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                            Description
                        </label>

                        <div>
                            <ui-input type="textarea" rows="2" name="description" id="description" placeholder="e.g. Paramilitary Groups" v-model="inputs.description" :errors="errors.description"></ui-input>
                        </div>
                    </div>
                </div>

                <div>
                    <div class="pt-1">
                        <ui-avatar-input :inputs="inputs"></ui-avatar-input>
                    </div>
                </div>
            </div>
            
            <div class="mt-4">
                <label for="description" class="block text-sm text-gray-700 font-medium mb-1">
                    Access
                </label>
                
                <ui-async :guards="! isLoadingUsers">
                    <label class="flex items-center space-x-2 mb-1">
                        <input type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-full" value="everyone" v-model="inputs.access">
                        <span>Everyone</span>
                    </label>
    
                    <label class="flex items-center space-x-2 mb-1">
                        <input type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-full" value="selected-users" v-model="inputs.access">
                        <span>Selected users</span>
                    </label>
    
                    <div class="space-y-2 mt-2 mb-3 pl-6" v-if="inputs.access == 'selected-users'">
                        <div @click="toggleUser(user)" class="flex items-center cursor-pointer" :key="user.id" v-for="user in users">
                            <div class="shrink-0 pr-3">
                                <ui-switch :value="inputs.users[user.id]" size="sm"></ui-switch>
                            </div>
                
                            <div class="shrink-0">
                                <ui-avatar :item="user" class="w-4 h-4"></ui-avatar>
                            </div>
                
                            <div class="flex-1 min-w-0 px-2 space-x-1">
                                <span v-tooltip="user.name">{{user.name}}</span>
                            </div>
                        </div>
                    </div>
                    
                    <label class="flex items-center space-x-2 mb-1">
                        <input type="radio" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded-full" value="only-me" v-model="inputs.access">
                        <span>Only me</span>
                    </label>
                </ui-async>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button color="red" class="mr-auto" :action="remove" :disabled="submitting" v-if="workspace && workspace.id && workspace.owned">
                    Delete
                </ui-button>

                <ui-button type="submit" :color="workspace?.id ? 'blue' : 'green'" :disabled="submitting">
                    {{ workspace?.id ? 'Save' : 'Create' }}
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useMyModalsWorkspaceInfo from '@/stores/me/modals/workspace-info'
import useMyUsersStore from '@/stores/me/users'

import { sortAlphabetically } from '@/helpers/sorting'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useMyModalsWorkspaceInfo),
        ...mapState(useMyModalsWorkspaceInfo, [ 'workspace' ]),
        ...mapState(useMyUsersStore, {
            users: store => sortAlphabetically(store.items),
            isLoadingUsers: 'isLoading'
        })
    },

    methods: {
        ...mapActions(useMyModalsWorkspaceInfo, [ 'cancel', 'remove', 'submit', 'toggleUser' ]),

        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
