import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsImportOptionsStore = defineStore({
    id: 'channelsModalsImportOptions',

    state: () => ({
        list: null,
        cardsStore: null,
        isImporting: false,

        file: null
    }),

    actions: {
        reset() {
            this.$reset()
        },

        open(list, cardsStore) {
            this.reset()

            this.list = list
            this.cardsStore = cardsStore

            useModal().show('channels-list-import')
        },

        cancel() {
            useModal().hide('channels-list-import')
        },

        doImport() {
            this.isImporting = true

            api.route('me lists import-channels', [ this.list.id ]).formData({
                file: this.file
            }).post().res(() => {
                this.cardsStore.source().reload()

                this.cancel()
            })
        }
    }
})

export default useModalsImportOptionsStore
