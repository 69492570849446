<template>
    <router-link :to="to" class="block px-6 py-3 hover:bg-gray-50 hover:ring-1 hover:ring-gray-200 ring-inset" :class="{ 'bg-blue-50 ring-1 ring-blue-200': focused }">
        <div class="flex items-center">
            <div class="shrink-0 relative">
                <ui-avatar family="dashboards" :name="result.title" :color="result.avatarColor" :icon="result.avatarIcon" :image="result.avatarImageUrl" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>
            </div>

            <div class="mx-4 flex-1 min-w-0">
                <div class="flex items-center leading-5 font-medium text-gray-900">
                    <div class="truncate">{{result.title}}</div>
                </div>

                <div class="text-xs leading-4 text-gray-700 truncate">
                    Dashboard
                </div>
            </div>

            <div class="shrink-0">
                <ui-icon name="chevron-right" class="text-2xl text-gray-600"></ui-icon>
            </div>
        </div>
    </router-link>
</template>

<script>
import useMyStore from '@/stores/me/my'
import useMyWorkspacesStore from '@/stores/me/workspaces'
import useModalsWorkspaceSwitchConfirmationStore from '@/stores/modals/workspace-switch-confirmation'

export default {
    props: [ 'focused', 'result' ],

    computed: {
        to() {
            return { name: 'dashboard.boards.board', params: { id: this.result.id } }
        }
    },

    methods: {
        open() {
            if (useMyStore().currentWorkspace.id === this.result.workspaceId) {
                return this.$router.push(this.to)
            }

            const toWorkspace = useMyWorkspacesStore().find(this.result.workspaceId)
            useModalsWorkspaceSwitchConfirmationStore().open(useMyStore().currentWorkspace, toWorkspace, this.to)
                .then(() => {
                    useMyStore().currentWorkspace = toWorkspace
                    useMyStore().updatePreferences({ lastWorkspace: toWorkspace.id }).then(() => {
                        window.location = this.$router.resolve(this.to).href
                    })
                })
        }
    }
}
</script>
