import api from '@/api'

import { defineStore } from 'pinia'

export const useChannelsChannelHistoryStore = defineStore({
    id: 'channelsChannelHistory',

    state: () => ({
        channel: null,
        events: null
    }),

    actions: {
        load(channel) {
            if (this.channel?.id != channel.id) this.$reset()

            this.channel = channel

            return api.route('monitor channels history', { id: this.channel.id })
                .get().json(res => this.events = res.data)
        }
    }
})

export default useChannelsChannelHistoryStore
