<template>
    <router-link :to="{ name: 'channels', query: { show: `list|id:${list.id}` } }" class="block hover:bg-gradient-to-r from-transparent via-gray-50 to-transparent transition">
        <div class="flex h-12 group relative">
            <div class="pl-2 flex items-center flex-1 min-w-0">
                <ui-avatar :item="list" family="lists" class="w-8 h-8 drop-shadow-sm shrink-0"></ui-avatar>

                <div class="min-w-0 px-2">
                    <h1 class="text-xl font-semibold truncate leading-tight">
                        {{list.name}}
                    </h1>
                </div>
            </div>

            <div class="flex items-center space-x-3 px-3">
                <div class="text-sm text-gray-700">
                    {{list.channelsCount}} {{list.channelsCount == 1 ? 'channel' : 'channels'}}
                </div>
                <subscribe-button :list="list"></subscribe-button>
            </div>
        </div>
    </router-link>
</template>

<script>
import SubscribeButton from '@/components/channels/curated-lists/subscribe-button'

export default {
    props: { cardsStore: {}, mode: { default: 'card' }, list: {}, to: {} },

    components: { SubscribeButton }
}
</script>
