import StreamLayoutMasonry from '@/components/content/perspective/stream/layouts/masonry'
import StreamLayoutTimeline from '@/components/content/perspective/stream/layouts/timeline'
import StreamLayoutMedia from '@/components/content/perspective/stream/layouts/media'

import defineStreamMediaLightbox from '@/stores/reusable/stream/media-lightbox'
import useMyStore from '@/stores/me/my'
import useMyPerspectivesStore from '@/stores/me/perspectives'

import { default as api, asyncResource, content } from '@/api'
import { useRoute, useRouter } from '@/helpers'
import searchFilters from '@/helpers/search-filters'
import { trackEvent as _trackEvent } from '@/analytics'

import { addSeconds, parseISO } from 'date-fns'
import filter from 'just-filter'
import { defineStore } from 'pinia'
import { markRaw } from 'vue'

export const useContentPerspectiveStream = defineStore({
    id: 'contentPerspectiveStream',

    state: () => ({
        perspective: null,

        search: { query: '', language: null, flags: [] },

        selectedSorting: null,
        selectedLayout: null,

        filters: searchFilters(),

        items: asyncResource({
            method: 'post',
            request: (api, store, payload) => store.contentQuery()
                .include([ 'publishedBy', 'publishedInto', 'tags', 'notes' ])
                .query({ includeTotal: 1, historize: 1, ...payload })
                .toRequest(),
            paginated: true
        }),
        itemsDetails: asyncResource({
            method: 'post',
            request: (api, store, payload) => store.contentQuery()
                .include([ 'sharedContent', 'conversationParent', 'conversationChildren', 'publishedBy', 'publishedInto', 'tags', 'notes' ])
                .query({ ...payload })
                .toRequest(),
            paginated: true
        }),

        itemsCount: null,
        itemsNewerCount: asyncResource({
            method: 'post',
            request: (api, store) => store.contentQuery().filters('date', { type: 'after', date: { gte: addSeconds(parseISO(store.firstItem?.publishedAt), 1) } }).query({ count: true }).toRequest(),
            collection: false
        }),

        isInitialized: false,
        isSavingPerspective: false,
        paused: false,

        pollTimeout: null,

        lastReloadTime: +new Date(),
        lastLayoutUpdate: { time: +new Date(), items: [] },
        triggeredNavigation: 0,

        sortingOptions: [
            { id: 'latest', value: i => ({ value: i.publishedAt, id: i.id }), api: 'date-desc', type: 'date' },
            { id: 'oldest', value: i => ({ value: i.publishedAt, id: i.id }), api: 'date-asc', type: 'date' },
            { id: 'ids', value: (i, store) => ({ value: store.items.data.indexOf(i), id: i.id }), api: 'ids' },
            { id: 'interactions', value: i => ({ value: i.latestStats.interactions, id: i.id }), api: 'interactions-desc' },
//            { id: 'trending', value: i => ({ value: i.details.metrics.benchmark, id: i.id }), api: 'trending-desc' },
            { id: 'reactions', value: i => ({ value: i.latestStats.reactions, id: i.id }), api: 'reactions-desc' },
            { id: 'comments', value: i => ({ value: i.latestStats.comments, id: i.id }), api: 'comments-desc' },
            { id: 'shares', value: i => ({ value: i.latestStats.shares, id: i.id }), api: 'shares-desc' },
            { id: 'views', value: i => ({ value: i.latestStats.views, id: i.id }), api: 'views-desc' }
        ],

        layoutOptions: [
            { id: 'masonry', component: markRaw(StreamLayoutMasonry) },
            { id: 'timeline', component: markRaw(StreamLayoutTimeline) },
            { id: 'media', component: markRaw(StreamLayoutMedia) }
        ],

        mediaLightbox: defineStreamMediaLightbox({
            id: `contentPerspectiveStreamMediaLightbox`,
            name: `stream-content-lightbox-contentPerspectiveStream`
        })()
    }),

    getters: {
        sorting(store) {
            return store.sortingOptions.find(o => o.id == store.selectedSorting) || store.sortingOptions[0]
        },

        layout(store) {
            return store.layoutOptions.find(o => o.id == store.selectedLayout) || store.layoutOptions[0]
        },

        firstItem(store) {
            if (store.items.data) return store.items.data[0]
        },

        lastItem(store) {
            if (store.items.data) return store.items.data[store.items.data.length - 1]
        },

        hasUnsavedChanges() {
            if (! this.perspective) {
                return this.filters.isNotEmpty() && ! this.filters.value('trigger')
            }

            return JSON.stringify(this.perspective.filters) != this.filters.toJson()
                || this.perspective.meta.sorting?.option != this.sorting.id
                || this.perspective.meta.layout != this.layout.id
        },

        isShowingLatestContent() {
            let dateFilter = this.filters.value('date')

            return this.sorting.id == 'latest' && (! dateFilter || dateFilter.type == 'past' || dateFilter.type == 'after')
        }
    },

    actions: {
        async initialize(to) {
            if (this.isInitialized) return

            this.search.language = useMyStore().preferredLanguage

            this.filters.onChange = (action, payload) => {
                this.reload()
                this.replaceRoute()
                if (action == 'set') this.trackEvent('stream', 'filter-added', payload.filter)
            }

            this.fromQuery(to.query)
            this.applyPerspective(useMyPerspectivesStore().find(to.params.perspectiveId), to.query)

            this.isInitialized = true

            this.load()
        },

        clear() {
            this.items.reset()
            this.itemsDetails.reset()
            this.itemsCount = null
            this.itemsNewerCount.reset()
            clearTimeout(this.pollTimeout)
        },

        reset() {
            this.clear()

            this.perspective = null
            this.search.query = ''
            this.search.language = null
            this.search.flags = []

            this.filters.clear()

            this.isInitialized = false
        },

        abort() {
            this.items.abort()
            this.itemsDetails.abort()
            this.itemsNewerCount.abort()
            clearTimeout(this.pollTimeout)
        },

        async load() {
            if (! this.isInitialized || this.paused) return

            let loadItems = this.items.fetchFirst(this)
            let loadDetails = this.itemsDetails.fetchFirst(this)

            let items = await loadItems

            loadDetails.then(details => {
                this.loadMissingDetails(items)
                this.fillDetails(this.items.data, this.itemsDetails.data)
            })

            this.itemsCount = this.items.res?.total
            this.lastReloadTime = +new Date()

            this.scheduleNewItemsCheck()
            this.triggerLayoutUpdate(items)
        },

        async reload() {
            this.abort()
            this.itemsNewerCount.reset()
            this.load()
        },

        async loadMore(infiniteScroll = null) {
            if (! this.lastItem) return infiniteScroll?.complete()

            if (this.items.isFetching) return infiniteScroll?.loaded()

            let loadItems = this.items.fetchNext(this)
            let loadDetails = this.itemsDetails.fetchNext(this)

            let items = await loadItems

            loadDetails.then(details => {
                this.loadMissingDetails(items)
                this.fillDetails(this.items.data, this.itemsDetails.data)
            })

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()

            this.triggerLayoutUpdate(items)
        },

        contentQuery() {
            return content()
                .filters(this.filters.toPerspective())
                .sorting(this.sorting.api)
                .query({ perspective: this.perspective?.id })
                .limit(25)
        },

        fillDetails(items, details) {
            details?.forEach(item => Object.assign(items.find(i => i.id == item.id) || {}, {
                sharedContent: item.sharedContent,
                conversationParent: item.conversationParent,
                conversationChildren: item.conversationChildren,
                conversationChildrenCount: item.conversationChildrenCount
            }))

            this.itemsDetails.data = this.itemsDetails.data?.filter(item => ! items.find(i => i.id == item.id))

            this.triggerLayoutUpdate(details)
        },

        loadMissingDetails(items) {
            let detailsIds = this.itemsDetails.data?.map(d => d.id) || []

            let missingItems = items.filter(i => ! detailsIds.includes(i.id))

            if (! missingItems.length) return

            this.contentQuery()
                .include([ 'sharedContent', 'conversationParent', 'conversationChildren', 'publishedBy', 'publishedInto', 'tags', 'notes' ])
                .ids(missingItems.map(i => i.id))
                .get(details => this.fillDetails(missingItems, details))
        },

        analyze(type) {
            window.open(useRouter().resolve({
                name: 'analysis.analysis.details',
                query: {
                    date: this.filters.toQuery()['filter[date]'],
                    perspective: this.perspective.id
                },
                params: { type: type, id: 'new' }
            }).href, '_blank')
        },

        setSorting(option) {
            this.selectedSorting = option

            this.load()
            this.replaceRoute()

            this.trackEvent('stream', 'sorting-set', option)
        },

        setLayout(layout) {
            this.selectedLayout = layout

            this.trackEvent('stream', 'layout-set', layout)
        },

        scheduleNewItemsCheck() {
            clearTimeout(this.pollTimeout)

            this.pollTimeout = setTimeout(async () => {
                if (! this.isShowingLatestContent) return

                if (this.items.data?.length) await this.itemsNewerCount.fetch(this)
                this.scheduleNewItemsCheck()
            }, 30000)
        },

        clearSearch() {
            this.search.query = ''

            this.filters.remove('text')
        },

        clearFilters() {
            if (this.filters.isEmpty()) return

            this.clearSearch()
            this.filters.clear()
        },

        appendSuggestion(suggestion) {
            this.search.query = this.search.query + ` OR ${suggestion}`
        },

        applyPerspective(perspective, query = {}) {
            if (! perspective || this.perspective?.id == perspective.id) return

            this.perspective = perspective

            let overrideFilters = Object.entries(query).map(([ key, value ]) => {
                if (key.match(/^override-filter\[(.*?)\]$/)) {
                    return [ key.replace(/^override-filter\[(.*?)\]$/, 'filter[$1]'), value ]
                }
            }).filter(Boolean)

            this.filters.fromPerspective(perspective.filters)
            this.filters.fromQuery(Object.fromEntries(overrideFilters))

            this.search = { ...this.search, ...this.filters.value('text') }

            if (perspective.meta?.sorting) this.setSorting(perspective.meta.sorting?.option)
            if (perspective.meta?.layout) this.setLayout(perspective.meta.layout)

            this.replaceRoute()
        },

        async savePerspective() {
            if (! this.perspective.name || this.isSavingPerspective) return

            this.isSavingPerspective = true

            await api.route(this.perspective.id ? 'me perspectives update' : 'me perspectives store', [ this.perspective.id ])
                .formData({
                    _method: this.perspective.id ? 'put' : 'post',
                    name: this.perspective.name,
                    filters: this.filters.toJson(),
                    sorting: this.sorting.id,
                    layout: this.layout.id
                })
                .post()
                .json(res => this.perspective = res.data)

            this.isSavingPerspective = false

            await useMyPerspectivesStore().reload()

            this.replaceRoute()
        },

        async deletePerspective() {
            try {
                await useMyPerspectivesStore().delete(this.perspective)
            } catch (e) {
                return
            }

            this.perspective = null

            this.replaceRoute()
        },

        applySearchQuery(query) {
            if (query !== undefined) this.search.query = query

            if (this.search.query) {
                this.filters.set('text', { ...this.search })
            } else {
                this.filters.remove('text')
            }
        },

        applySearchLanguage(language) {
            this.search.language = language

            if (this.search.query) this.applySearchQuery()
        },

        toggleSearchFlag(flag) {
            this.search.flags.includes(flag)
                ? this.search.flags = this.search.flags.filter(f => f != flag)
                : this.search.flags.push(flag)

            if (this.search.query) this.applySearchQuery()
        },

        filterDuplicates(items) {
            let loadedIds = (this.items.data || []).map(i => i.id)

            return items.filter(i => ! loadedIds.includes(i.id))
        },

        fromQuery(query, forcedFilters = {}, defaultFilters = {}) {
            this.filters.set(defaultFilters)
            this.filters.fromQuery(query)
            this.filters.set(forcedFilters, true)

            this.search = { ...this.search, ...this.filters.value('text') }

            this.setSorting(query.sorting)
        },

        toQuery() {
            return {
                ...(this.perspective ? { perspective: this.perspective.id.toString() } : {}),

                ...this.filters.toQuery(),

                sorting: this.sorting.id
            }
        },

        replaceRoute() {
            if (! this.isInitialized || this.paused) return

            let query = filter(useRoute().query, key => {
                return ! (key.match(/filter\[.+?\]/) || [ 'perspective', 'sorting' ].includes(key))
            })

            this.triggeredNavigation++

            useRouter().replace(
                {
                    name: 'content.perspectives.perspective',
                    params: { perspectiveId: this.perspective?.id || 'new' },
                    query: { ...query, ...this.toQuery() }
                },
                null,
                error => { if (error.name != 'NavigationDuplicated') throw error }
            )
        },

        whilePaused(callback) {
            this.paused = true
            callback(this)
            this.paused = false
        },

        triggerLayoutUpdate(items = []) {
            this.lastLayoutUpdate = { time: +new Date(), items: [ ...(items || []) ] }
        },

        trackEvent(category, action, name = null, value = null) {
            if (! this.isInitialized) return

            if (name instanceof Object) return Object.entries(name).forEach(v => this.trackEvent(category, action, ...v))

            _trackEvent(category, action, name, value)
        }
    }
})

export default useContentPerspectiveStream
