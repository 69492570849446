<!-- EdgeLabel.vue -->
<template>
    <div class="edge-label rounded-xl bg-gray-200 text-center" :class="{'border': selected, 'border-sky-500': selected }">
        <div class="inline-block mr-2">{{ label }}</div>
    </div>
</template>

<script>

export default {
    props: {
        label: String,
        selected: Boolean
    },

    data() {
        return {
            showMenu: false,
            contextMenuStyle: {},
        };
    }
};
</script>
