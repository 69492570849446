import api from '@/api'
import { useRouter } from '@/helpers'

import { defineStore } from 'pinia'

export const useSettingsAccountSocialLoginStore = defineStore({
    id: 'settingsAccountSocialLogin',

    state: () => ({
        error: null
    }),

    actions: {
        async connect() {
            api.route('auth social login').query({ product: 'juno' }).get().json(res => {
                window.location = res.redirectUrl
            })
        },

        finishConnecting(to) {
            let unknownError = () => {
                this.error = 'Unexpected error, please try again later and contact us if the problem persists.'
                useRouter().push({ name: 'settings.account' })
            }

            api.route('auth social login callback')
                .formData({
                    code: to.query.code,
                    error: to.query.error,
                    state: to.query.state,
                    product: 'juno'
                })
                .post()
                .error(400, unknownError)
                .error(403, err => {
                    this.error = err.json.message
                    useRouter().push({ name: 'settings.account' })
                })
                .error(404, unknownError)
                .error(500, unknownError)
                .res(() => useRouter().push({ name: 'settings.account' }))
                .catch(unknownError)
        },

        disconnect() {
            api.route('auth social login disconnect')
                .formData({ _method: 'delete' })
                .post()
                .res(() => useRouter().go())
        }
    }
})

export default useSettingsAccountSocialLoginStore
