<template>
    <ui-contextual-menu>
        <ui-dropdown-link icon="eye-off" @click="() => markNotSeen(trigger)" v-if="! trigger.unread">
           Mark as unseen
        </ui-dropdown-link>
        <ui-dropdown-link icon="eye" @click="() => markSeen(trigger)" v-if="trigger.unread">
           Mark as seen
        </ui-dropdown-link>

        <template v-if="$can('manage-world')">
            <ui-dropdown-separator></ui-dropdown-separator>

            <ui-dropdown-link icon="wrench" @click="() => $inspect(trigger)">
                Debug
            </ui-dropdown-link>
        </template>
    </ui-contextual-menu>
</template>

<script>
import useMyAlertsStore from '@/stores/me/alerts'

import { mapActions } from 'pinia'

export default {
    props: [ 'trigger' ],

    methods: {
        ...mapActions(useMyAlertsStore, {
            markSeen: 'markSeen',
            markNotSeen: 'markNotSeen'
        })
    }
}
</script>
