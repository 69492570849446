<template>
    <div class="flex flex-1 flex-wrap relative gap-2 max-w-full">
        <a @click.prevent="store.deleteSeries(channel.series)" href="#" class="bg-gray-50 hover:bg-gray-100 rounded-lg cursor-default shrink-0 py-2 px-4" :key="channel.id" v-for="channel in channels">
            <div class="flex items-center space-x-2 h-full mb-1">
                <template v-if="channel.family == 'lists'">
                    <ui-avatar :item="channel" class="h-7 w-7"></ui-avatar>
                    <div>
                        <p class="max-w-xs truncate font-semibold text-lg leading-tight">
                            {{channel.name}}
                        </p>
                        <div class="max-w-xs text-gray-800 text-xs leading-tight truncate">
                            List
                        </div>
                    </div>
                </template>
                <template v-else>
                    <ui-avatar :item="channel" class="h-7 w-7"></ui-avatar>
                    <div>
                        <p class="max-w-xs truncate font-semibold text-lg leading-tight">
                            {{channel.name}}
                        </p>
                        <div class="max-w-xs text-gray-800 text-xs leading-tight truncate">
                            {{$nodeType(channel).name}}
                        </div>
                    </div>
                </template>
            </div>
        </a>

        <a v-if="store.series.length < store.seriesOptions.limit" @click.prevent="store.addChannel(store)" href="#" class="bg-gray-50 hover:bg-gray-100 rounded-lg flex flex-col items-center justify-center cursor-default shrink-0 py-2 px-4 text-gray-800">
            <div class="leading-tight">
                <ui-icon name="plus" class="text-xl"></ui-icon>
            </div>
            <div class="leading-tight">
                Add channel
            </div>
        </a>
    </div>
</template>

<script>
export default {
    computed: {
        channels() {
            return this.store.series.map(series => ({
                ...this.channelForSeries(series), series
            })).filter(t => t.id)
        }
    },

    methods: {
        channelForSeries(series) {
            return (this.store.selectedChannels || []).find(ch => ch.family == series.datasetFamily && ch.id == series.datasetId) || {}
        }
    }
}
</script>
