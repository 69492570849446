import useMySearchHistoryStore from '@/stores/me/search-history'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useModalsSearchHistoryStore = defineStore({
    id: 'modalsSearchHistory',

    actions: {
        open(type = 'content') {
            useModal().show('search-history')

            useMySearchHistoryStore().load(type)
        },

        cancel() {
            useModal().hide('search-history')
        },

        apply(streamStore, item) {
            streamStore.whilePaused(() => {
                streamStore.filters.clear()
                Object.entries(item.filters).forEach(([filter, value]) => streamStore.filters.set(filter, streamStore.filters.unserializeFrom('api', filter, value)))
            })
            streamStore.replaceRoute()

            streamStore.search.query = item.filters.text?.query
            streamStore.search.flags = item.filters.text?.flags
            streamStore.search.language = item.filters.text?.language

            useModal().hide('search-history')
        },

        applyQuery(streamStore, query) {
            streamStore.applySearchQuery(query)

            streamStore.triggerQueryChange()

            useModal().hide('search-history')
        }
    }
})

export default useModalsSearchHistoryStore
