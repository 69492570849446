<template>
    <div class="px-6 xl:px-8 pb-8">
        <div class="h-8 flex items-center mb-2">
            <div class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                <a @click.prevent="$page.selectMode('stream')" href="#" class="flex items-center justify-center h-full px-3">
                    Stream
                </a>
                <a @click.prevent="$page.selectMode('overview')" href="#" class="flex items-center justify-center px-3 border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]">
                    Inspect
                </a>
            </div>

            <toolbar-date :filters="$page.streamStore.filters" v-if="! $page.showFilters" class="ml-4"></toolbar-date>

            <!--<a href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-3 ml-3">-->
            <!--    <ui-icon name="download" class="text-xl"></ui-icon> Export-->
            <!--</a>-->

            <div class="ml-auto"></div>

            <div class="text-sm" v-if="$page.streamStore.itemsCount !== null">
                <span class="font-medium">{{ $number($page.streamStore.itemsCount) }}{{ $page.streamStore.itemsCount == 10000 ? '+' : '' }}</span>
                results
            </div>

            <div class="flex items-center gap-x-2 h-full ml-4">
                <div v-if="isComparing" class="text-gray-700 font-medium text-sm">
                    Comparing with
                </div>

                <div v-for="series, index in comparedSeries" :key="index" class="border border-gray-400 text-gray-700 rounded-full font-medium flex items-center px-3 pl-2.5 gap-x-1.5 h-full">
                    <div class="w-3 h-3 rounded-full" :style="{ 'background-color': series.color }"></div>
                    <span>{{series.name}}</span>
                    <a @click.prevent="stopComparing(series)" href="#">
                        <ui-icon name="x"></ui-icon>
                    </a>
                </div>

                <a v-if="comparedSeries.length < 2" @click.prevent="compareWith" href="#" class="border border-gray-400 border-dashed text-gray-700 hover:text-gray-900 rounded-full font-medium flex items-center px-3 pl-2.5 gap-x-1.5 h-full">
                    <ui-icon name="plus-circle"></ui-icon> <span>Compare with...</span>
                </a>
            </div>
        </div>

        <div class="max-w-6xl mx-auto mt-6 flex flex-wrap">
            <div class="w-full p-2">
                <overview-timeline :store="widgets.timeline()"></overview-timeline>
            </div>

            <div class="p-2" :class="isComparing ? 'w-full' : 'w-2/3'">
                <overview-top-channels :store="widgets.topChannels()"></overview-top-channels>
            </div>
            <div class="p-2" :class="isComparing ? 'w-full' : 'w-1/3'">
                <overview-platforms-share :store="widgets.platformsShare()"></overview-platforms-share>
            </div>

            <div class="p-2" :class="isComparing ? 'w-full' : 'w-2/3'">
                <overview-sentiment-timeline :store="widgets.sentimentTimeline()"></overview-sentiment-timeline>
            </div>
            <div class="p-2" :class="isComparing ? 'w-full' : 'w-1/3'">
                <overview-sentiment-share :store="widgets.sentimentShare()"></overview-sentiment-share>
            </div>

            <div class="p-2" :class="isComparing ? 'w-full' : 'w-2/3'">
                <overview-keywords :store="widgets.keywords()"></overview-keywords>
            </div>
            <div class="p-2" :class="isComparing ? 'w-full' : 'w-1/3'">
                <overview-top-hashtags :store="widgets.topHashtags()"></overview-top-hashtags>
            </div>

            <div class="p-2" :class="isComparing ? 'w-full' : 'w-2/3'">
                <overview-top-links :store="widgets.topLinks()"></overview-top-links>
            </div>
            <div class="p-2" :class="isComparing ? 'w-full' : 'w-1/3'">
                <overview-top-domains :store="widgets.topDomains()"></overview-top-domains>
            </div>

            <div class="w-full p-2">
                <overview-top-media :store="widgets.topMedia()"></overview-top-media>
            </div>
        </div>
    </div>
</template>

<script>
import OverviewKeywords from './overview/widgets/keywords'
import OverviewPlatformsShare from './overview/widgets/platforms-share'
import OverviewSentimentShare from './overview/widgets/sentiment-share'
import OverviewSentimentTimeline from './overview/widgets/sentiment-timeline'
import OverviewTimeline from './overview/widgets/timeline'
import OverviewTopChannels from './overview/widgets/top-channels'
import OverviewTopDomains from './overview/widgets/top-domains'
import OverviewTopHashtags from './overview/widgets/top-hashtags'
import OverviewTopLinks from './overview/widgets/top-links'
import OverviewTopMedia from './overview/widgets/top-media'
import ToolbarDate from './toolbar/date'

import useContentPerspectiveOverviewStore from '@/stores/content/perspective/overview'

import { mapActions, mapState, mapWritableState } from 'pinia'

export default {
    components: {
        OverviewKeywords, OverviewPlatformsShare, OverviewSentimentShare, OverviewSentimentTimeline,
        OverviewTopDomains, OverviewTimeline, OverviewTopHashtags, OverviewTopLinks, OverviewTopMedia,
        OverviewTopChannels, ToolbarDate
    },

    computed: {
        ...mapState(useContentPerspectiveOverviewStore, [ 'comparedSeries', 'isComparing', 'stopComparing', 'showStream', 'stream', 'widgets' ]),
        ...mapWritableState(useContentPerspectiveOverviewStore, [ 'showStream' ])
    },

    methods: {
        ...mapActions(useContentPerspectiveOverviewStore, [ 'compareWith' ])
    },

    watch: {
        showStream() {
            this.$nextTick(() => window.dispatchEvent(new Event('resize')))
        }
    }
}
</script>