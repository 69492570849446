import defineOverviewPlatformsShareStore from '@/stores/content/perspective/overview/platforms-share'
import defineOverviewSentimentShareStore from '@/stores/content/perspective/overview/sentiment-share'
import defineOverviewSentimentTimelineStore from '@/stores/content/perspective/overview/sentiment-timeline'
import defineOverviewTimelineStore from '@/stores/content/perspective/overview/timeline'
import defineOverviewTopChannelsStore from '@/stores/content/perspective/overview/top-channels'
import defineOverviewTopDomainsStore from '@/stores/content/perspective/overview/top-domains'
import defineOverviewTopHashtagsStore from '@/stores/content/perspective/overview/top-hashtags'
import defineOverviewTopLinksStore from '@/stores/content/perspective/overview/top-links'

import { asyncResource, content } from '@/api'
import searchFilters from '@/helpers/search-filters'

import { defineStore } from 'pinia'
import { v4 as uuid } from 'uuid'

export const useTriggerDigestStore = defineStore({
    id: 'alertTriggerDigest',

    state: () => ({
        trigger: null,

        content: asyncResource({
            method: 'post',
            request: (api, store, payload) => content()
                .ids(store.trigger.meta.content)
                .query(payload)
                .sorting(store.trigger.meta.sorting)
                .limit(25)
                .toRequest(),
            paginated: true
        }),

        lastReloadTime: +(new Date),

        widgets: {
            platformsShare: defineOverviewPlatformsShareStore({ id: `alertTriggerDigestWidgetsPlatformsShare` }),
            sentimentShare: defineOverviewSentimentShareStore({ id: `alertTriggerDigestWidgetsSentimentShare` }),
            sentimentTimeline: defineOverviewSentimentTimelineStore({ id: `alertTriggerDigestWidgetsSentimentTimeline` }),
            timeline: defineOverviewTimelineStore({ id: `alertTriggerDigestWidgetsTimeline` }),
            topChannels: defineOverviewTopChannelsStore({ id: `alertTriggerDigestWidgetsTopChannels` }),
            topDomains: defineOverviewTopDomainsStore({ id: `alertTriggerDigestWidgetsTopDomains` }),
            topHashtags: defineOverviewTopHashtagsStore({ id: `alertTriggerDigestWidgetsTopHashtags` }),
            topLinks: defineOverviewTopLinksStore({ id: `alertTriggerDigestWidgetsTopLinks` })
        }
    }),

    actions: {
        async load(trigger) {
            this.trigger = trigger
            this.lastReloadTime = +(new Date)

            this.content.reset()
            this.content.fetchFirst(this)

            let series = { id: uuid(), name: 'Untitled', filters: searchFilters(), color: '#2172f0' }

            this.widgets.platformsShare().initialize({ ...series, data: trigger.meta.platformsShare.data })
            this.widgets.sentimentShare().initialize({ ...series, aggregates: trigger.meta.sentimentShare.aggregates })
            this.widgets.sentimentTimeline().initialize({ ...series, data: trigger.meta.sentimentTimeline.data })
            this.widgets.timeline().initialize({ ...series, currentMetrics: trigger.meta.timeline.currentMetrics, previousMetrics: {} }, trigger.meta.timeline.data)
            this.widgets.topChannels().initialize({ ...series, data: trigger.meta.topChannels.data })
            this.widgets.topDomains().initialize({ ...series, data: [ trigger.meta.topFeatures.domains.data ] })
            this.widgets.topHashtags().initialize({ ...series, data: [ trigger.meta.topFeatures.hashtags.data ] })
            this.widgets.topLinks().initialize({ ...series, data: [ trigger.meta.topFeatures.links.data ] })
        },

        async loadMore(infiniteScroll) {
            if (this.content.isFetching) return infiniteScroll?.loaded()

            let items = await this.content.fetchNext(this)

            items.length ? infiniteScroll?.loaded() : infiniteScroll?.complete()
        }
    }
})

export default useTriggerDigestStore
