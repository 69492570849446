<template>
    <ui-dropdown width="w-48" v-if="! inline">
        <template v-slot:trigger>
            <button :class="classes" aria-label="Options" id="options-menu" aria-haspopup="true" aria-expanded="true">
                <ui-icon name="more-horizontal"></ui-icon>
            </button>
        </template>

        <template v-slot:content>
            <slot></slot>
        </template>
    </ui-dropdown>

    <ui-dropdown-link :submenu="true" submenu-align="right" submenu-width="w-48" v-bind="$attrs" v-else>
        {{ name }}

        <template v-slot:submenu>
            <slot></slot>
        </template>
    </ui-dropdown-link>
</template>

<script>
export default {
    props: {
        color: { default: 'black' },
        inline: Boolean,
        name: {},
        size: { default: 'md' },
        plain: { type: Boolean, default: true }
    },

    computed: {
        classes() {
            return {
                'inline-flex items-center justify-center focus:outline-none rounded-full transition w-6 h-6': true,
                'text-xl border': ! this.plain,
                'text-3xl': this.plain,
                'text-blue-600 hover:text-blue-900 focus:text-blue-800 border-blue-400 hover:border-blue-800 focus:border-blue-800': this.color == 'blue',
                'text-gray-700 hover:text-gray-900 focus:text-black border-gray-900 hover:border-black focus:border-black': this.color == 'black'
            }

//            return {
//                'inline-flex items-center justify-center focus:outline-none rounded-full transition': true,
//                'w-6 h-6': this.size == 'md',
//                'w-8 h-8 text-3xl': this.size == 'lg',
//                'text-xl border border-blue-400 hover:border-blue-800 focus:border-blue-800 text-blue-500 hover:text-blue-800 focus:text-blue-800': this.color == 'blue',
//                'bg-gray-100 hover:bg-gray-600 text-gray-700 hover:text-white': this.color == 'gray'
//            }
        }
    }
}
</script>