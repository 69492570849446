<template>
    <ui-modal name="content-perspective-duplicate" title="Duplicate" :max-width="520" :max-height="680" @opened="opened">
        <ui-form :store="contentModalsPerspectiveDuplicateStore" v-slot="{ inputs, submitting, errors }">
            <div>
                <label for="name" class="block text-sm text-gray-800 font-medium mb-1">
                    Name
                </label>

                <div>
                    <ui-input type="text" name="name" id="name" placeholder="e.g. Paramilitary Groups" ref="nameInput" v-model="inputs.name" :errors="errors.name"></ui-input>
                </div>
            </div>

            <div class="flex justify-end space-x-4 mt-8">
                <ui-button type="submit" color="blue" :disabled="submitting">
                    Duplicate
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useContentModalsPerspectiveDuplicateStore from '@/stores/content/modals/perspective-duplicate'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useContentModalsPerspectiveDuplicateStore)
    },

    methods: {
        opened() { this.$refs.nameInput.focus() }
    }
}
</script>
