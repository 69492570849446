import defineCards from '@/stores/reusable/cards'
import useMyMonitoringRequestsStore from '@/stores/me/monitoring-requests'

import { sortByDate } from '@/helpers/sorting'

export const useIndexCardsRequestedStore = defineCards({
    id: 'channelsIndexCardsRequested',

    source: useMyMonitoringRequestsStore,
    layout: 'table',

    paginated: true,
    searchable: true,

    sortingOptions: [
        {
            id: 'recent', name: 'Recent', icon: 'time', default: true,
            apply: items => sortByDate(items, 'createdAt').reverse()
        }
    ]
})

export default useIndexCardsRequestedStore
