import useMyStore from '@/stores/me/my'
import useTriggerAudienceChangeStore from '@/stores/alerts/trigger-audience-change'
import useTriggerDigestStore from '@/stores/alerts/trigger-digest'
import useTriggerEveryMatchStore from '@/stores/alerts/trigger-every-match'
import useTriggerNewPublishersStore from '@/stores/alerts/trigger-new-publishers'
import useTriggerSentimentShiftStore from '@/stores/alerts/trigger-sentiment-shift'
import useTriggerSpikeDetectionStore from '@/stores/alerts/trigger-spike-detection'

import api from '@/api'
import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useTriggerStore = defineStore({
    id: 'alertTrigger',

    state: () => ({
        trigger: null,

        modal: false
    }),

    actions: {
        async load(trigger, modal = false) {
            this.trigger = await this.fetch(trigger)
            this.modal = modal

            if (this.trigger.alert.type == 'audience-change') useTriggerAudienceChangeStore().load(this.trigger)
            if (this.trigger.alert.type == 'digest') useTriggerDigestStore().load(this.trigger)
            if (this.trigger.alert.type == 'every-match') useTriggerEveryMatchStore().load(this.trigger)
            if (this.trigger.alert.type == 'new-publishers') useTriggerNewPublishersStore().load(this.trigger)
            if (this.trigger.alert.type == 'sentiment-shift') useTriggerSentimentShiftStore().load(this.trigger)
            if (this.trigger.alert.type == 'spike-detection') useTriggerSpikeDetectionStore().load(this.trigger)

            this.markAsSeen()
        },

        open(trigger) {
            this.load(trigger, true)

            useModal().show('alert-trigger')
        },

        async fetch(trigger) {
            if (trigger.family == 'alert-triggers') return trigger

            return api.route('me alerts triggers details', { alertId: trigger.alertId, id: trigger.id })
                .get().json(res => res.data)
        },

        async markAsSeen() {
            if (useMyStore().isImpersonating) return

            await api.route('me alerts triggers mark-seen', { alertId: this.trigger.alert.id, id: this.trigger.id })
                .post().res()

            this.trigger.unread = false
        }
    }
})

export default useTriggerStore
