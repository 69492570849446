<template>
        <div class="w-full bg-white shadow-sm rounded-lg border border-gray-200 mx-auto p-3 mt-4 mb-8" v-click-away="() => $page.isSearchFocused = false">
            <div class="flex flex-1 items-center space-x-2">
                <search-input :store="store"></search-input>
                <search-filters :store="store" v-show="! ($page.isSearchFocused && store.search.query)"></search-filters>
            </div>

            <search-results :store="store"></search-results>
        </div>
</template>

<script>
import SearchFilters from './search-filters'
import SearchInput from './search-input'
import SearchResults from '@/components/channels/search/search-results'

import useContentModalsPerspectiveEditStore from '@/stores/content/modals/perspective-edit'
import useSuggestionsStore from '@/stores/reusable/suggestions'

import { mapActions, mapState } from 'pinia'

export default {
    components: {
        SearchResults, SearchFilters, SearchInput
    },

    computed: {
        ...mapState(useSuggestionsStore, [ 'goodSuggestions' ])
    },

    methods: {
        ...mapActions(useContentModalsPerspectiveEditStore, { _openEditModal: 'open' }),

        async savePerspective() {
            if (this.store.perspective && this.store.perspective.id) {
                await this.store.savePerspective()
            } else {
                await this._openEditModal(this.store.perspective, this.store)
            }
        }
    }
}
</script>
