<template>
    <ui-modal name="workspace-switch-confirmation" :title="`Are you sure you want to switch your workspace?`" @keydown.enter.exact.prevent="confirm">
        <div class="text-sm text-gray-700">
            <div class="text-gray-900">
                The resource you're navigating to is in a different workspace. Would you like to switch to <span class="font-medium">{{ toWorkspace.name }}</span>?
                Any unsaved changes in your current workspace will be lost!
            </div>
        </div>

        <div class="flex justify-end space-x-4 mt-8">
            <ui-button type="button" :action="cancel" :disabled="submitting">
                Cancel
            </ui-button>
            <ui-button type="button" :action="confirm" color="red" :disabled="submitting">
                Confirm
            </ui-button>
        </div>
    </ui-modal>
</template>

<script>
import modalsWorkspaceSwitchConfirmationStore from '@/stores/modals/workspace-switch-confirmation'

import { mapActions, mapState, mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(modalsWorkspaceSwitchConfirmationStore),
        ...mapState(modalsWorkspaceSwitchConfirmationStore, [ 'fromWorkspace', 'toWorkspace', 'location', 'submitting' ])
    },

    methods: {
        ...mapActions(modalsWorkspaceSwitchConfirmationStore, [ 'cancel', 'confirm' ])
    }
}
</script>
