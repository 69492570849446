<template>
    <svg style="overflow: visible" >
        <defs>
            <marker
                id="custom-arrow"
                markerWidth="25"
                markerHeight="6"
                refX="6"
                refY="3"
                orient="auto"
                markerUnits="strokeWidth"
                :fill="edge.color"
            >
                <path d="M0,0 L0,6 L6,3 z" :fill="edge.color" />
            </marker>
        </defs>

        <BezierEdge
            :source-x="sourceX"
            :source-y="sourceY"
            :target-x="targetX"
            :target-y="targetY"
            :source-position="sourcePosition"
            :target-position="targetPosition"

            :style="selected ? { stroke: edge.color, strokeWidth: 2 } : {}"
            :marker-end="'url(#custom-arrow)'"
            :marker-start="'url(#custom-arrow)'"
        />

        <foreignObject
            :x="(sourceX + targetX) / 2 - 65"
            :y="(sourceY + targetY) / 2 - 11"
            width="130"
            height="50"
        >
            <EdgeLabel :label="label" :selected="selected" />
        </foreignObject>
    </svg>
</template>

<script>
import { useEdge } from '@vue-flow/core'
import EdgeLabel from "@/components/connections/edge-explain/edge-label.vue"
import { BezierEdge } from '@vue-flow/core';

export default {
    name: 'CustomEdge',
    data() {
        return {
            edge: useEdge()
        }
    },

    components: {
        EdgeLabel,
        BezierEdge
    },

    props: [
        'sourceX', 'sourceY', 'targetX', 'targetY', 'sourcePosition', 'targetPosition', 'label', 'selected'
    ]
};
</script>
