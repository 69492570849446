let languages = [
    { code: 'aa', name: 'Afar' },
    { code: 'ab', name: 'Abkhazian' },
    { code: 'ae', name: 'Avestan' },
    { code: 'af', name: 'Afrikaans' },
    { code: 'ak', name: 'Akan' },
    { code: 'am', name: 'Amharic' },
    { code: 'an', name: 'Aragonese' },
    { code: 'ar', name: 'Arabic', icon: 'flags.sa' },
    { code: 'as', name: 'Assamese' },
    { code: 'av', name: 'Avaric' },
    { code: 'ay', name: 'Aymara' },
    { code: 'az', name: 'Azerbaijani' },
    { code: 'ba', name: 'Bashkir' },
    { code: 'be', name: 'Belarusian', icon: 'flags.by' },
    { code: 'bg', name: 'Bulgarian',icon: 'flags.bg' },
    { code: 'bh', name: 'Bihari languages' },
    { code: 'bi', name: 'Bislama' },
    { code: 'bm', name: 'Bambara' },
    { code: 'bn', name: 'Bengali' },
    { code: 'bo', name: 'Tibetan' },
    { code: 'br', name: 'Breton' },
    { code: 'bs', name: 'Bosnian' },
    { code: 'ca', name: 'Catalan' },
    { code: 'ce', name: 'Chechen' },
    { code: 'ch', name: 'Chamorro' },
    { code: 'co', name: 'Corsican' },
    { code: 'cr', name: 'Cree' },
    { code: 'cs', name: 'Czech', icon: 'flags.cz' },
    { code: 'cu', name: 'Church Slavic' },
    { code: 'cv', name: 'Chuvash' },
    { code: 'cy', name: 'Welsh' },
    { code: 'da', name: 'Danish', icon: 'flags.dk' },
    { code: 'de', name: 'German', icon: 'flags.de' },
    { code: 'dv', name: 'Divehi' },
    { code: 'dz', name: 'Dzongkha' },
    { code: 'ee', name: 'Ewe' },
    { code: 'el', name: 'Greek', icon: 'flags.gr' },
    { code: 'en', name: 'English', icon: 'flags.uk' },
    { code: 'eo', name: 'Esperanto' },
    { code: 'es', name: 'Spanish', icon: 'flags.es' },
    { code: 'et', name: 'Estonian', icon: 'flags.ee' },
    { code: 'eu', name: 'Basque' },
    { code: 'fa', name: 'Persian' },
    { code: 'ff', name: 'Fulah' },
    { code: 'fi', name: 'Finnish', icon: 'flags.fi' },
    { code: 'fj', name: 'Fijian' },
    { code: 'fo', name: 'Faroese' },
    { code: 'fr', name: 'French', icon: 'flags.fr' },
    { code: 'fy', name: 'Western Frisian' },
    { code: 'ga', name: 'Irish', icon: 'flags.ie' },
    { code: 'gd', name: 'Gaelic' },
    { code: 'gl', name: 'Galician' },
    { code: 'gn', name: 'Guarani' },
    { code: 'gu', name: 'Gujarati' },
    { code: 'gv', name: 'Manx' },
    { code: 'ha', name: 'Hausa' },
    { code: 'he', name: 'Hebrew', icon: 'flags.il' },
    { code: 'hi', name: 'Hindi', icon: 'flags.in' },
    { code: 'ho', name: 'Hiri Motu' },
    { code: 'hr', name: 'Croatian' },
    { code: 'ht', name: 'Haitian' },
    { code: 'hu', name: 'Hungarian', icon: 'flags.hu' },
    { code: 'hy', name: 'Armenian' },
    { code: 'hz', name: 'Herero' },
    { code: 'ia', name: 'Interlingua' },
    { code: 'id', name: 'Indonesian' },
    { code: 'ie', name: 'Interlingue' },
    { code: 'ig', name: 'Igbo' },
    { code: 'ii', name: 'Sichuan Yi' },
    { code: 'ik', name: 'Inupiaq' },
    { code: 'io', name: 'Ido' },
    { code: 'is', name: 'Icelandic' },
    { code: 'it', name: 'Italian', icon: 'flags.it' },
    { code: 'iu', name: 'Inuktitut' },
    { code: 'ja', name: 'Japanese', icon: 'flags.jp' },
    { code: 'jv', name: 'Javanese' },
    { code: 'ka', name: 'Georgian' },
    { code: 'kg', name: 'Kongo' },
    { code: 'ki', name: 'Kikuyu' },
    { code: 'kj', name: 'Kuanyama' },
    { code: 'kk', name: 'Kazakh' },
    { code: 'kl', name: 'Kalaallisut' },
    { code: 'km', name: 'Central Khmer' },
    { code: 'kn', name: 'Kannada' },
    { code: 'ko', name: 'Korean', icon: 'flags.kr' },
    { code: 'kr', name: 'Kanuri' },
    { code: 'ks', name: 'Kashmiri' },
    { code: 'ku', name: 'Kurdish' },
    { code: 'kv', name: 'Komi' },
    { code: 'kw', name: 'Cornish' },
    { code: 'ky', name: 'Kirghiz' },
    { code: 'la', name: 'Latin' },
    { code: 'lb', name: 'Luxembourgish' },
    { code: 'lg', name: 'Ganda' },
    { code: 'li', name: 'Limburgan' },
    { code: 'ln', name: 'Lingala' },
    { code: 'lo', name: 'Lao' },
    { code: 'lt', name: 'Lithuanian', icon: 'flags.lt' },
    { code: 'lu', name: 'Luba-Katanga' },
    { code: 'lv', name: 'Latvian', icon: 'flags.lv' },
    { code: 'mg', name: 'Malagasy' },
    { code: 'mh', name: 'Marshallese' },
    { code: 'mi', name: 'Maori' },
    { code: 'mk', name: 'Macedonian' },
    { code: 'ml', name: 'Malayalam' },
    { code: 'mn', name: 'Mongolian' },
    { code: 'mr', name: 'Marathi' },
    { code: 'ms', name: 'Malay' },
    { code: 'mt', name: 'Maltese' },
    { code: 'my', name: 'Burmese' },
    { code: 'na', name: 'Nauru' },
    { code: 'nb', name: 'Bokmål' },
    { code: 'nd', name: 'Ndebele' },
    { code: 'ne', name: 'Nepali' },
    { code: 'ng', name: 'Ndonga' },
    { code: 'nl', name: 'Dutch', icon: 'flags.nl' },
    { code: 'nn', name: 'Norwegian Nynorsk' },
    { code: 'no', name: 'Norwegian', icon: 'flags.no' },
    { code: 'nr', name: 'Ndebele' },
    { code: 'nv', name: 'Navajo' },
    { code: 'ny', name: 'Chichewa' },
    { code: 'oc', name: 'Occitan' },
    { code: 'oj', name: 'Ojibwa' },
    { code: 'om', name: 'Oromo' },
    { code: 'or', name: 'Oriya' },
    { code: 'os', name: 'Ossetian' },
    { code: 'pa', name: 'Panjabi' },
    { code: 'pi', name: 'Pali' },
    { code: 'pl', name: 'Polish', icon: 'flags.pl' },
    { code: 'ps', name: 'Pushto' },
    { code: 'pt', name: 'Portuguese', icon: 'flags.pt' },
    { code: 'qu', name: 'Quechua' },
    { code: 'rm', name: 'Romansh' },
    { code: 'rn', name: 'Rundi' },
    { code: 'ro', name: 'Romanian', icon: 'flags.ro' },
    { code: 'ru', name: 'Russian', icon: 'flags.ru' },
    { code: 'rw', name: 'Kinyarwanda' },
    { code: 'sa', name: 'Sanskrit' },
    { code: 'sc', name: 'Sardinian' },
    { code: 'sd', name: 'Sindhi' },
    { code: 'se', name: 'Northern Sami' },
    { code: 'sg', name: 'Sango' },
    { code: 'si', name: 'Sinhala' },
    { code: 'sk', name: 'Slovak', icon: 'flags.sk' },
    { code: 'sl', name: 'Slovenian', icon: 'flags.si' },
    { code: 'sm', name: 'Samoan' },
    { code: 'sn', name: 'Shona' },
    { code: 'so', name: 'Somali' },
    { code: 'sq', name: 'Albanian' },
    { code: 'sr', name: 'Serbian', icon: 'flags.rs' },
    { code: 'ss', name: 'Swati' },
    { code: 'st', name: 'Sotho' },
    { code: 'su', name: 'Sundanese' },
    { code: 'sv', name: 'Swedish', icon: 'flags.se' },
    { code: 'sw', name: 'Swahili' },
    { code: 'ta', name: 'Tamil' },
    { code: 'te', name: 'Telugu' },
    { code: 'tg', name: 'Tajik' },
    { code: 'th', name: 'Thai' },
    { code: 'ti', name: 'Tigrinya' },
    { code: 'tk', name: 'Turkmen' },
    { code: 'tl', name: 'Tagalog' },
    { code: 'tn', name: 'Tswana' },
    { code: 'to', name: 'Tonga' },
    { code: 'tr', name: 'Turkish', icon: 'flags.tr' },
    { code: 'ts', name: 'Tsonga' },
    { code: 'tt', name: 'Tatar' },
    { code: 'tw', name: 'Twi' },
    { code: 'ty', name: 'Tahitian' },
    { code: 'ug', name: 'Uighur' },
    { code: 'uk', name: 'Ukrainian', icon: 'flags.ua' },
    { code: 'ur', name: 'Urdu' },
    { code: 'uz', name: 'Uzbek' },
    { code: 've', name: 'Venda' },
    { code: 'vi', name: 'Vietnamese' },
    { code: 'vo', name: 'Volapük' },
    { code: 'wa', name: 'Walloon' },
    { code: 'wo', name: 'Wolof' },
    { code: 'xh', name: 'Xhosa' },
    { code: 'yi', name: 'Yiddish' },
    { code: 'yo', name: 'Yoruba' },
    { code: 'za', name: 'Zhuang' },
    { code: 'zh', name: 'Chinese', icon: 'flags.cn' },
    { code: 'zu', name: 'Zulu' },
    { code: '00', name: 'Unknown' }
]

export default function language(code) {
    return languages.find(l => l.code == code)
}
