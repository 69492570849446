<template>
    <div class="py-6 px-6 xl:px-16 max-w-12xl mx-auto min-h-full flex flex-col">
        <div class="flex items-center justify-between mb-6">
            <h1 class="text-[24px] font-semibold px-1">
                Alerts
            </h1>

            <ui-button icon="plus-square" color="blue" @click="createAlert()">
                New Alert
            </ui-button>
        </div>

        <div v-if="isShowingWelcomeMessage" class="flex-1 flex items-center">
            <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                <ui-icon name="navigation.analysis" class="text-6xl text-gray-400"></ui-icon>
                <h1 class="text-3xl font-semibold mt-6">Welcome to the Alerts section!</h1>
                <div class="space-y-4 my-6">
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2"><span class="font-medium">Set up alerts</span> on your perspectives, lists or channels that trigger and send notifications when certain conditions are met.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Choose from <span class="font-medium">many alert types</span>, like every match, digest, spike detection, new publishers or audience change.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Receive notifications via one of <span class="font-medium">many supported delivery methods</span>, like in-app, email, SMS, Slack, Microsoft Teams, Signal or Mattermost.</p>
                    </div>
                </div>
                <div>
                    <ui-button color="blue" size="lg" @click="createAlert()">
                        Create your first Alert
                    </ui-button>
                </div>
            </div>
        </div>

        <template v-else>
            <ui-cards-section title="Unread Triggers" :store="alertsIndexUnreadTriggersCardsStore" key="indexUnreadTriggersCards">
            </ui-cards-section>

            <ui-cards-section title="Pinned Alerts" :store="alertsIndexPinnedAlertsCardsStore" key="indexPinnedAlertsCards">
            </ui-cards-section>

            <ui-cards-section title="Analyses" :store="alertsIndexCardsStore" key="indexCards">
                <template v-slot:title>
                    <div class="flex items-center grow h-8">
                        <div v-if="alertsIndexCardsStore.isShowing == 'alert'">
                            <div class="flex items-center">
                                <router-link :to="{ name: 'alerts', query: { show: 'alerts' } }" href="#" @click.prevent="alertsIndexCardsStore.back()" class="mr-2">
                                    <ui-icon name="arrow-left" class="text-lg"></ui-icon>
                                </router-link>

                                <div class="flex items-center space-x-1.5 font-medium text-gray-700">
                                    <ui-avatar :item="alertsIndexCardsStore.currentContext.alert" class="w-6 h-6"></ui-avatar>
                                    <span>{{alertsIndexCardsStore.currentContext.alert?.name}}</span>
                                </div>
                            </div>
                        </div>

                        <h1 v-else class="text-gray-700 text-xl font-medium leading-none">
                            Alerts
                        </h1>
                    </div>
                </template>

                <template v-slot:filter>
                    <index-filter :store="alertsIndexCardsStore.currentContext"></index-filter>
                </template>

                <template v-slot:actions>
                    <a @click.prevent="createAlert()" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2.5">
                        <ui-icon name="plus" class="text-xl"></ui-icon> New alert
                    </a>
                </template>
            </ui-cards-section>
        </template>
    </div>
</template>

<script>
import IndexFilter from './menus/index-filter'

import useAlertCreateStore from '@/stores/alerts/modals/alert-create'
import useAlertsIndexCardsStore from '@/stores/alerts/index-cards'
import useAlertsIndexPinnedAlertsCardsStore from '@/stores/alerts/index-pinned-cards'
import useAlertsIndexUnreadTriggersCardsStore from '@/stores/alerts/index-unread-cards'
import useMyAlertsStore from '@/stores/me/alerts'

import { mapActions, mapStores } from 'pinia'

export default {
    components: {
        IndexFilter
    },

    computed: {
        ...mapStores(useAlertsIndexCardsStore, useAlertsIndexPinnedAlertsCardsStore, useAlertsIndexUnreadTriggersCardsStore),

        isShowingWelcomeMessage() {
            return useMyAlertsStore().isInitialized && ! useMyAlertsStore().hasAlerts
        }
    },

    methods: {
        ...mapActions(useAlertCreateStore, { createAlert: 'open' })
    }
}
</script>
