import defineCards from '@/stores/reusable/cards'
import useMyListsStore from '@/stores/me/channel-lists'

import { sortAlphabetically, sortByDate } from '@/helpers/sorting'

import { defineStore } from 'pinia'

export const useIndexCardsListsStore = defineCards({
    id: 'channelsIndexCardsLists',

    source: defineStore({
        id: 'channelsIndexCardsListsSource',

        getters: {
            items(store) {
                return [
                    { family: 'lists-curated', name: 'Curated Lists', id: 'curated-lists' },
                    ...useMyListsStore().items
                ]
            },

            isLoading(store) {
                return useMyListsStore().isLoading
            }
        },

        actions: {
            initialize() {
                useMyListsStore().initialize()
            }
        }
    }),
    layout: 'table',

    searchable: true,

    sortingOptions: [
        {
            id: 'name', name: 'Name', icon: 'text-case', default: true,
            apply: items => [
                ...items.filter(i => i.family == 'lists-curated'),
                ...sortAlphabetically(items.filter(i => i.family != 'lists-curated'), 'name')
            ]
        },
        {
            id: 'recent', name: 'Recent', icon: 'time',
            apply: items => [
                ...items.filter(i => i.family == 'lists-curated'),
                ...sortByDate(items.filter(i => i.family != 'lists-curated'), 'lastUsedAt').reverse()
            ]
        },
        {
            id: 'latest', name: 'Latest', icon: 'calendar',
            apply: items => [
                ...items.filter(i => i.family == 'lists-curated'),
                ...sortByDate(items.filter(i => i.family != 'lists-curated'), 'updatedAt').reverse()
            ]
        }
    ]
})

export default useIndexCardsListsStore
