import useMetricsStore from './details/metrics'
import useRepliesStore from './details/replies'
import useSharesStore from './details/shares'

import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useContentDetailsStore = defineStore({
    id: 'contentDetails',

    state: () => ({
        content: null,
        streamStore: null,
        activeTab: 'metrics'
    }),

    actions: {
        async load(content, streamStore, activeTab = 'metrics') {
            this.content = content
            this.streamStore = streamStore
            this.activeTab = activeTab
            
            useMetricsStore().initialize(this.content)
            useRepliesStore().initialize(this.content)
            useSharesStore().initialize(this.content)
        },
        
        open(content, streamStore, activeTab) {
            this.load(content, streamStore, activeTab)
            
            useModal().show('content-detail')
        }
    }
})

export default useContentDetailsStore
