import { defineStore } from 'pinia'
import useGraphsStore from '@/stores/connections/graphs'

export const useConnectionsGraphsPage = defineStore({
    id: 'connectionsGraphsPage',

    actions: {
        async afterEnter(to) {
            useGraphsStore().init()
        }
    }
})

export default useConnectionsGraphsPage
