<template>
    <div class="py-6 px-6 xl:px-16 max-w-12xl mx-auto min-h-full flex flex-col">
        <h1 class="text-[24px] font-semibold px-1">
            Channels
        </h1>

        <quick-search :store="$page.streamStore"></quick-search>

        <div v-if="isShowingWelcomeMessage" class="flex-1 flex items-center">
            <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                <ui-icon name="navigation.channels" class="text-6xl text-gray-400"></ui-icon>
                <h1 class="text-3xl font-semibold mt-6">Welcome to the Channels section!</h1>
                <div class="space-y-4 my-6">
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2"><span class="font-medium">Explore</span> the currently monitored channels - content publishers, and places where content is published - like Facebook Pages, Telegram Groups or YouTube Channels.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2"><span class="font-medium">Request</span> monitoring of additional channels for your own use-case on top of our base set, and manage which channels are monitored.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Organize channels into <span class="font-medium">lists</span> to make searching for content from multiple channels easier.</p>
                    </div>
                </div>
                <div>
                    <ui-button color="blue" size="lg" :action="requestMonitoring">
                        Request monitoring of a Channel
                    </ui-button>
                    <div class="mt-4">
                        <a @click.prevent="createList" href="#" class="text-gray-700 hover:text-gray-900 font-medium">
                            Create your first List
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <template v-else>
            <ui-cards-section title="Pinned Channels" :store="channelsIndexPinnedCardsStore" key="indexPinnedCards">
                <template v-slot:empty>
                    <div class="py-2 pb-4 text-center text-gray-700">
                        You will see your pinned channels here.
                    </div>
                </template>
            </ui-cards-section>

            <ui-cards-section :store="channelsIndexCardsStore.currentContext" key="indexCards">
                <template v-slot:title>
                    <div class="flex items-center grow h-8">
                        <div v-if="channelsIndexCardsStore.isShowing == 'list'">
                            <div class="flex items-center">
                                <router-link :to="channelsIndexCardsStore.currentContext.list?.curated ? { name: 'channels', query: { show: `curated-category|id:${channelsIndexCardsStore.currentContext.list.category.id}` } } : { name: 'channels', query: { show: 'lists' } }" href="#" @click.prevent="channelsIndexCardsStore.back()" class="mr-2">
                                    <ui-icon name="arrow-left" class="text-lg"></ui-icon>
                                </router-link>

                                <div class="flex items-center space-x-1.5 font-medium text-gray-700">
                                    <ui-avatar :item="channelsIndexCardsStore.currentContext.list" class="w-6 h-6"></ui-avatar>
                                    <span>{{channelsIndexCardsStore.currentContext.list?.name}}</span>
                                </div>
                            </div>
                        </div>

                        <div v-else class="border border-gray-300 text-gray-700 hover:text-gray-900 rounded-md font-medium flex h-full">
                            <a @click.prevent="channelsIndexCardsStore.show('monitored')" href="#" class="flex items-center justify-center px-3" :class="channelsIndexCardsStore.isShowing == 'monitored' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                                Monitored
                            </a>
                            <a @click.prevent="channelsIndexCardsStore.show('requested')" href="#" class="flex items-center justify-center px-3" :class="channelsIndexCardsStore.isShowing == 'requested' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                                Requested
                            </a>
                            <a @click.prevent="channelsIndexCardsStore.show('lists')" href="#" class="flex items-center justify-center px-3" :class="channelsIndexCardsStore.isShowing == 'lists' ? 'border border-blue-500 text-blue-500 rounded-md -m-px h-[calc(100%+2px)]' : 'h-full'">
                                Lists
                            </a>
                        </div>
                    </div>
                </template>

                <template v-slot:actions>
                    <template v-if="channelsIndexCardsStore.isShowing == 'list' && ! channelsIndexCardsStore.currentContext.list?.curated">
                        <a @click.prevent="addChannel" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="plus" class="text-xl"></ui-icon> Add channel
                        </a>

                        <a @click.prevent="channelsIndexCardsStore.currentContext.source().export()" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="download" class="text-xl"></ui-icon> Export
                        </a>

                        <a @click.prevent="importList(channelsIndexCardsStore.currentContext.list, channelsIndexCardsStore.currentContext)" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="upload" class="text-xl"></ui-icon> Import
                        </a>

                        <alerts-menu :subject="channelsIndexCardsStore.currentContext.list"></alerts-menu>

                        <list-contextual-menu :channel-list="channelsIndexCardsStore.currentContext.list" :cards-store="channelsIndexCardsStore"></list-contextual-menu>
                    </template>
                    <template v-else-if="channelsIndexCardsStore.isShowing == 'list' && channelsIndexCardsStore.currentContext.list?.curated">
                        <a @click.prevent="isSubscribedByParent(channelsIndexCardsStore.currentContext.list) ? unsubscribeFromList(channelsIndexCardsStore.currentContext.list) : subscribeToList(channelsIndexCardsStore.currentContext.list)" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="subscription" class="text-xl"></ui-icon> {{ isSubscribedByParent(channelsIndexCardsStore.currentContext.list) ? 'Unsubscribe' : 'Subscribe' }}
                        </a>
                    </template>
                    <template v-else>
                        <a v-if="[ 'monitored', 'requested' ].includes(channelsIndexCardsStore.isShowing)" @click.prevent="requestMonitoring" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="plus" class="text-xl"></ui-icon> Request monitoring
                        </a>

                        <a v-if="channelsIndexCardsStore.isShowing == 'monitored'" @click.prevent="exportMonitored" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="download" class="text-xl"></ui-icon> Export
                        </a>

                        <a v-if="channelsIndexCardsStore.isShowing == 'lists'" @click.prevent="createList" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5">
                            <ui-icon name="plus" class="text-xl"></ui-icon> Create list
                        </a>
                    </template>

                    <monitored-stats></monitored-stats>
                </template>

                <template v-slot:filter>
                    <list-filter v-if="channelsIndexCardsStore.isShowing == 'monitored' || channelsIndexCardsStore.isShowing == 'list'" :store="channelsIndexCardsStore.currentContext"></list-filter>
                </template>

                <template v-slot:empty>
                    <div class="py-8 text-center text-gray-700">
                        <template v-if="channelsIndexCardsStore.isShowing == 'list'">
                            This list is currently empty.
                        </template>
                        <template v-else-if="channelsIndexCardsStore.isShowing == 'lists'">
                            You have not created any lists.
                        </template>
                        <template v-else-if="channelsIndexCardsStore.isShowing == 'requested'">
                            You have not requested monitoring of any channels.
                        </template>
                        <template v-else-if="channelsIndexCardsStore.isShowing == 'monitored'">
                            You are not monitoring any channels.
                        </template>
                    </div>
                </template>
            </ui-cards-section>
        </template>
    </div>
</template>

<script>
import AlertsMenu from '@/components/me/alerts/alerts-menu'
import ListContextualMenu from './contextual-menus/list'
import ListFilter from './partials/list-filter'
import MonitoredStats from './partials/monitored-stats'
import QuickSearch from './search/search'
import UiCardsSection from '@/components/ui/cards/cards-section'

import useChannelsIndexCardsStore from '@/stores/channels/index-cards'
import useChannelsIndexPinnedCardsStore from '@/stores/channels/index-pinned-cards'
import useChannelsModalsLibraryAddChannelStore from '@/stores/channels/modals/library-add-channel'
import useChannelsModalsListAddChannelStore from '@/stores/channels/modals/list-add-channel'
import useChannelsModalsListEditStore from '@/stores/channels/modals/list-edit'
import useIndexCardsMonitoredStore from '@/stores/channels/index-cards-monitored'
import useIndexCardsRequestedStore from '@/stores/channels/index-cards-requested'
import useIndexCardsListsStore from '@/stores/channels/index-cards-lists'
import useModalsImportOptionsStore from '@/stores/channels/modals/list-import-channels'
import useMyChannelListsStore from '@/stores/me/channel-lists'
import useMyMonitoredChannelsStore from '@/stores/me/monitored-channels'

import { mapActions, mapStores } from 'pinia'

export default {
    components: {
        AlertsMenu, ListContextualMenu, ListFilter, MonitoredStats, QuickSearch, UiCardsSection
    },

    computed: {
        ...mapStores(useChannelsIndexCardsStore, useChannelsIndexPinnedCardsStore),

        isShowingWelcomeMessage() {
            return useIndexCardsMonitoredStore().isInitialized && useIndexCardsMonitoredStore().isEmpty
                && useIndexCardsRequestedStore().isInitialized && useIndexCardsRequestedStore().isEmpty
                && useIndexCardsListsStore().isInitialized && useIndexCardsListsStore().isEmpty
        }
    },

    methods: {
        ...mapActions(useChannelsModalsLibraryAddChannelStore, { requestMonitoring: 'open' }),
        ...mapActions(useChannelsModalsListAddChannelStore, { addChannelToList: 'open' }),
        ...mapActions(useChannelsModalsListEditStore, { createList: 'open' }),
        ...mapActions(useModalsImportOptionsStore, { importList: 'open' }),
        ...mapActions(useMyChannelListsStore, [ 'subscribeToList', 'unsubscribeFromList', 'isSubscribedByParent' ]),
        ...mapActions(useMyMonitoredChannelsStore, { exportMonitored: 'export' }),

        addChannel() {
            this.addChannelToList(
                useChannelsIndexCardsStore().currentContext.list,
                () => useChannelsIndexCardsStore().source().reload()
            )
        }
    }
}
</script>
