<template>
    <ui-modal name="content-sentiment-edit" title="Report Wrong Sentiment" width="320px">
        <ui-form :store="contentModalsSentimentEditStore" v-slot="{ inputs, submitting, errors }">
            <div class="text-center">
                Please select the correct sentiment for this content.
            </div>

            <div class="space-y-1 text-gray-800 w-full mt-8">
                <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer hover:text-emerald-800 hover:bg-emerald-50 rounded-lg text-lg" :class="inputs.sentiment == 'positive' ? 'text-emerald-800 bg-emerald-50' : 'text-emerald-500 bg-gray-50'" @click.prevent="inputs.sentiment = 'positive'">
                    <ui-icon name="face-smile" class="shrink-0 text-2xl"></ui-icon>
                    <div class="px-3">
                        <div class="font-medium">Positive</div>
                    </div>
                </a>
                <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer hover:text-indigo-800 hover:bg-indigo-50 rounded-lg text-lg" :class="inputs.sentiment == 'neutral' ? 'text-indigo-800 bg-indigo-50' : 'text-indigo-500 bg-gray-50'" @click.prevent="inputs.sentiment = 'neutral'">
                    <ui-icon name="face-neutral" class="shrink-0 text-2xl"></ui-icon>
                    <div class="px-3">
                        <div class="font-medium">Neutral</div>
                    </div>
                </a>
                <a href="#" class="flex items-center w-full px-6 py-3 cursor-pointer hover:text-rose-800 hover:bg-rose-50 rounded-lg text-lg" :class="inputs.sentiment == 'negative' ? 'text-rose-800 bg-rose-50' : 'text-rose-500 bg-gray-50'" @click.prevent="inputs.sentiment = 'negative'">
                    <ui-icon name="face-smile" class="shrink-0 text-2xl"></ui-icon>
                    <div class="px-3">
                        <div class="font-frown">Negative</div>
                    </div>
                </a>
            </div>

            <div class="text-center mt-8">
                Your submission helps us to improve automatic sentiment detection in the future.
            </div>

            <div class="flex justify-center mt-8">
                <ui-button type="submit" color="blue" :disabled="submitting">
                    Submit
                </ui-button>
            </div>
        </ui-form>
    </ui-modal>
</template>

<script>
import useContentModalsSentimentEditStore from '@/stores/content/modals/sentiment-edit'

import {mapState, mapStores} from 'pinia'

export default {
    computed: {
        ...mapStores(useContentModalsSentimentEditStore),
        ...mapState(useContentModalsSentimentEditStore, [ 'originalSentiment' ])
    }
}
</script>
