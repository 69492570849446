<template>
    <div class="flex flex-wrap items-center max-w-full relative space-x-2">
        <!--<filter-types :filters="store.filters"></filter-types>-->
        <!--<filter-languages :filters="store.filters"></filter-languages>-->

        <div class="flex shrink-0 items-center ml-auto" v-if="showClearButton">
            <a href="#" @click="store.clearFilters" class="text-sm">
                Reset filters
            </a>
        </div>
    </div>
</template>

<script>
// import FilterTypes from './filters/types'
// import FilterLanguages from './filters/languages'

export default {
//     components: { FilterTypes, FilterLanguages },

    computed: {
        showClearButton() {
            return this.store.filters.isNotEmpty(false)
        }
    }
}
</script>
