<template>
    <ui-modal name="debug" title="Debug" :max-width="660" :max-height="800">
        <json-tree :data="item" :level="3"></json-tree>
    </ui-modal>
</template>

<script>
import JsonTree from 'vue-json-tree'

import useSupportDebugStore from '@/stores/support/debug'

import { mapState } from 'pinia'

export default {
    components: { JsonTree },

    computed: {
        ...mapState(useSupportDebugStore, [ 'item' ])
    }
}
</script>
