<template>
    <ui-dropdown width="w-60">
        <template v-slot:trigger>
            <div @click.prevent="click" href="#" class="inline-flex items-center justify-center transition text-2xl" :class="{ 'text-blue-600 hover:text-blue-900': hasEnabledAlerts, 'text-gray-700 hover:text-gray-900': ! hasEnabledAlerts }" v-tooltip="'Alerts'">
                <ui-icon name="notifications"></ui-icon>
            </div>
        </template>

        <template v-slot:content>
            <alerts-submenu-content :subject="subject"></alerts-submenu-content>
        </template>
    </ui-dropdown>
</template>

<script>
import AlertsSubmenuContent from '@/components/me/alerts/alerts-submenu-content.vue'

import useAlertCreateStore from '@/stores/alerts/modals/alert-create'

import { mapActions } from 'pinia'

export default {
    props: [ 'subject' ],

    components: {
        AlertsSubmenuContent
    },

    computed: {
        hasEnabledAlerts() {
            return this.subject?.alerts?.some(a => ! a.paused)
        }
    },

    methods: {
        ...mapActions(useAlertCreateStore, { createAlert: 'open' }),

        click($event) {
            if (! this.subject.alerts.length) {
                $event.stopPropagation()
                this.createAlert(this.subject)
            }
        }
    }
}
</script>
