<template>
    <div class="content-card max-w-md w-full relative flex flex-col" style="grid-row-end: span 29;">
        <div class="content-card-content flex flex-1 flex-col">
            <div class="bg-gray-300 border rounded-xl flex flex-1 flex-col"
                 :class="isSelected(id) ? ['border-blue-200', 'border-2'] : ['border-gray-200', 'border']">
                <div class="px-4 py-3 flex items-center justify-between">
                    <div class="flex items-center min-w-0">
                        <div class="flex items-center min-w-0 w-full">
                            <div>
                                <ui-icon v-if="data.object_type === 'url'" name="link" class="w-8 h-8"></ui-icon>
                                <ui-icon v-if="data.object_type === 'domain'" name="globe" class="w-8 h-8"></ui-icon>
                                <ui-icon v-if="data.object_type === 'place'" name="map-pin" class="w-8 h-8"></ui-icon>
                                <ui-icon v-if="data.object_type === 'bank-account'" name="dollar-sign" class="w-8 h-8"></ui-icon>

                                <ui-icon v-if="data.subclass === 'Fingerprint'" name="fingerprint" class="w-8 h-8"></ui-icon>
                            </div>

                            <div class="ml-3 min-w-0">
                                <div class="inline-flex items-center w-full">
                                    <p class="font-semibold truncate min-w-0 leading-tight">
                                        {{ data.label }}
                                    </p>
                                </div>

                                <div class="inline-flex items-center w-full">
                                    {{ data.subclass }} - {{ data.object_type }}
                                </div>
                            </div>

                            <ui-dropdown width="w-auto">
                                <template v-slot:trigger>
                                    <button aria-label="Options" aria-haspopup="true" aria-expanded="true">
                                        <ui-icon name="more-vertical"></ui-icon>
                                    </button>
                                </template>

                                <template v-slot:content>
                                    <ui-dropdown-link icon="info" :href="$router.resolve({ name: 'features.details', params: { id: id } }).href" target="_blank">
                                        Show detail
                                    </ui-dropdown-link>
                                </template>
                            </ui-dropdown>
                        </div>
                    </div>
                </div>

                <div v-if="data.image" class="w-full grayscale">
                    <div class="w-full max-h-96 bg-white relative cursor-pointer">
                        <img class="w-full h-full object-contain" :src="data.image">
                    </div>
                </div>
            </div>
        </div>
    </div>

    <Handle id="left-source" type="source" position="left"/>
    <Handle id="left-target" type="target" position="left"/>

    <Handle id="right-source" type="source" position="right"/>
    <Handle id="right-target" type="target" position="right"/>
</template>

<script>

import { Handle } from '@vue-flow/core'
import { mapActions } from "pinia"
import useEdgeExplainStore from "@/stores/connections/modals/edge-explain.js";

export default {
    components: {
        Handle
    },

    props: {
        id: { type: String, required: true },
        data: { type: Object, required: true }
    },

    methods: {
        ...mapActions(useEdgeExplainStore, [ 'isSelected' ])
    }
}
</script>
