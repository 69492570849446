<template>
    <div class="w-full h-full relative group" :class="{ 'bg-white border border-gray-200 shadow-sm rounded-lg p-4 flex flex-col': ! unstyled }">
        <div v-if="! unstyled" class="flex justify-between mb-2">
            <h1 class="text-lg font-medium">{{store.title}}</h1>
        </div>

        <slot></slot>

        <div v-if="dashboardStore.isEditing" class="flex flex-col items-center justify-between absolute inset-0 border border-gray-200 backdrop-blur-sm rounded-lg bg-gray-100 bg-opacity-20 group-hover:bg-gray-800 group-hover:bg-opacity-20 transition duration-150 z-20">
            <div class="w-full flex justify-end p-4">
                <ui-dropdown>
                    <template v-slot:trigger>
                        <a class="w-7 h-7 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-600 text-gray-800 hover:text-white" href="#">
                            <ui-icon name="more-horizontal"></ui-icon>
                        </a>
                    </template>
                    <template v-slot:content>
                        <slot name="actions"></slot>
                        <ui-dropdown-link icon="trash" @click="dashboardStore.deleteWidget(store)">Delete</ui-dropdown-link>
                    </template>
                </ui-dropdown>
            </div>

            <div class="px-4 py-2 bg-gray-900 bg-opacity-80 text-white text-medium text-xl rounded-lg shadow">
                {{store.title}}
            </div>

            <div class="w-full flex justify-end p-4">
                <div class="vue-resizable-handle w-7 h-7 flex items-center justify-center rounded-full bg-gray-100 hover:bg-gray-600 text-gray-800 hover:text-white">
                    <ui-icon name="resize"></ui-icon>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: { dashboardStore: {}, unstyled: Boolean }
}
</script>
