import { defineStore } from 'pinia'
import { content } from '@/helpers/api-three-tables'


export const useContentDetailsRepliesStore = defineStore({
    id: 'contentDetailsReplies',

    state: () => ({
        content: null,
        replies: [],

        sorting: 'date-desc',
        sort: null,

        isLoading: false,
        lastReloadTime: +new Date()
    }),

    actions: {
        async initialize(content) {
            this.content = content
            this.replies = []

            this.lastReloadTime = +new Date()
        },

        async loadAdditionalReplies(parent, infiniteScroll) {
            let replies = parent ? parent.replies : this.replies
            
            this.isLoading = true
            
            await content()
                .conversation(this.content.id)
                .parent(parent?.id || this.content.id)
                .when(replies.length && this.sort, c => c.after(this.sort))
                .sorting(this.sorting)
                .limit(30)
                .include([ 'publishedBy', 'publishedInto' ])
                .get((data, res) => {
                    replies.push(...data)
                    this.sort = res.sort
                    
                    if (infiniteScroll) data.length ? infiniteScroll.loaded() : infiniteScroll.complete()
                    
                    this.isLoading = false
                })
        },

        setSorting(sorting) {
            this.sorting = sorting
            this.replies = []

            this.loadAdditionalReplies().then(() => this.lastReloadTime = +new Date())
        }
    }
})

export default useContentDetailsRepliesStore
