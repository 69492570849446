import useIndexCardsAlertStore from './index-cards-alert'
import useIndexCardsAlertsStore from './index-cards-alerts'

import defineMultiCards from '@/stores/reusable/multi-cards'

export const useIndexCardsStore = defineMultiCards({
    id: 'alertsIndexCards',

    contexts: {
        'alerts': useIndexCardsAlertsStore,
        'alert': useIndexCardsAlertStore
    },

    isShowing: 'alerts',

    mapGetters: [
        'alert'
    ]
})

export default useIndexCardsStore
