import useMyBookmarksStore from './bookmarks'

import api from '@/api'

import { defineStore } from 'pinia'

export const useChannelsStore = defineStore({
    id: 'meChannels',

    actions: {
        isPinned(channel) {
            return channel.lists.find(l => l.type == 'bookmarks')
        },

        async pin(channel) {
            return api.route(`me lists channels store`, { id: 'bookmarks', channelId: channel.id }).post().res(() => {
                useMyBookmarksStore().reload()

                // @todo: this doesn't have to be reloaded, just push the list into the channel.lists
                return api.route(`monitor channels details`, { id: channel.id }).get().json(res => {
                    channel.lists = res.data.lists
                })
            })
        },

        async unpin(channel) {
            return api.route(`me lists channels delete`, { id: 'bookmarks', channelId: channel.id }).delete().res(() => {
                useMyBookmarksStore().reload()

                // @todo: this doesn't have to be reloaded, just remove the list from the channel.lists
                return api.route(`monitor channels details`, { id: channel.id }).get().json(res => {
                    channel.lists = res.data.lists
                })
            })
        }
    }
})

export default useChannelsStore
