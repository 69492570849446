import { useModal } from '@/helpers'

import { defineStore } from 'pinia'

export const useFeatureDetailsTextStore = defineStore({
    id: 'featuresModalsFeatureDetailsText',

    state: () => ({
        label: null,
        value: null
    }),

    actions: {
        open(label, value) {
            this.$reset()
            
            this.label = label
            this.value = value

            useModal().show('feature-details-text')
        },

        cancel() {
            useModal().hide('feature-details-text')
        }
    }
})

export default useFeatureDetailsTextStore
