<template>
    <div class="py-6 px-6 xl:px-16 max-w-12xl mx-auto min-h-full flex flex-col">
        <div class="flex items-center justify-between mb-6">
            <h1 class="text-[24px] font-semibold px-1">
                Analysis
            </h1>

            <ui-button icon="plus-square" color="blue" :to="{ name: 'analysis.my-analyses.new' }">
                New Analysis
            </ui-button>
        </div>

        <div v-if="isShowingWelcomeMessage" class="flex-1 flex items-center">
            <div class="w-full flex flex-col items-center justify-center text-center p-10 max-w-2xl mx-auto">
                <ui-icon name="navigation.analysis" class="text-6xl text-gray-400"></ui-icon>
                <h1 class="text-3xl font-semibold mt-6">Welcome to the Analysis section!</h1>
                <div class="space-y-4 my-6">
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2"><span class="font-medium">Visualize</span> the published content and find patterns in channel's behavior using one of our many types of analyses.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2">Specify the <span class="font-medium">data-series</span> in the form of a perspective, list or a particular channel. Some analyses might allow you to visualize multiple series of data.</p>
                    </div>
                    <div class="flex items-center space-x-3">
                        <ui-icon name="check-circle" class="shrink-0 text-4xl text-gray-400"></ui-icon>
                        <p class="text-justify my-2"><span class="font-medium">Export</span> and save line charts, bar charts and heatmaps for use in your own documents or presentations.</p>
                    </div>
                </div>
                <div>
                    <ui-button color="blue" size="lg" :to="{ name: 'analysis.my-analyses.new' }">
                        Create your first Analysis
                    </ui-button>
                </div>
            </div>
        </div>

        <template v-else>
            <ui-cards-section title="Pinned Analyses" :store="analysisIndexPinnedCardsStore" key="indexPinnedCards">
            </ui-cards-section>

            <ui-cards-section title="Analyses" :store="analysisIndexCardsStore" key="indexCards">
                <template v-slot:actions>
                    <router-link :to="{ name: 'analysis.my-analyses.new' }" href="#" class="flex items-center justify-center h-full text-gray-700 hover:text-gray-900 font-medium gap-x-1.5 px-2.5">
                        <ui-icon name="plus" class="text-xl"></ui-icon> New analysis
                    </router-link>
                </template>
            </ui-cards-section>
        </template>
    </div>
</template>

<script>
import useAnalysisIndexCardsStore from '@/stores/analysis/index-cards'
import useAnalysisIndexPinnedCardsStore from '@/stores/analysis/index-pinned-cards'

import { mapStores } from 'pinia'

export default {
    computed: {
        ...mapStores(useAnalysisIndexCardsStore, useAnalysisIndexPinnedCardsStore),

        isShowingWelcomeMessage() {
            return this.analysisIndexCardsStore.isInitialized && this.analysisIndexCardsStore.isEmpty
        }
    }
}
</script>
